import { useCallback, useEffect, useState } from 'react'
import { AtomEffect, atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { logger } from '@publica/ui-common-logger'

/**
 * Given a specific key, this effect will sync the value of the atom into localStorage
 * and react to changes to that localStorage value across other tabs/windows.
 *
 * @param key - The key used in localStorage
 */
export const localStorageEffect =
    <T>(key: string): AtomEffect<T> =>
    ({ setSelf, onSet, resetSelf }) => {
        // On load, if there's a value in localStorage, set the atom
        const savedValue = localStorage.getItem(key)

        if (savedValue != null) {
            setSelf(JSON.parse(savedValue))
        }

        // Subscribe to changes in the atom and update localStorage
        onSet((newValue, _, isReset) => {
            isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
        })

        // When the value changes in localstorage (from another tab), update the atom
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === key) {
                if (event.newValue === null) {
                    resetSelf()
                } else {
                    const newValue = JSON.parse(event.newValue)
                    setSelf(newValue)
                }
            }
        }
        window.addEventListener('storage', handleStorageChange)
        return () => {
            window.removeEventListener('storage', handleStorageChange)
        }
    }

const pollingState = atom<boolean>({
    key: 'pollingState',
    default: true,
    effects: [localStorageEffect('polling-enabled')],
})

export const usePollingRate = (speed: 'fast' | 'slow' = 'slow') => {
    const pollingEnabled = useRecoilValue(pollingState)

    if (!pollingEnabled) {
        return 0
    }

    return speed === 'fast' ? __FAST_POLLING__ : __SLOW_POLLING__
}

export const useIsPollingEnabled = () => useRecoilValue(pollingState)

export const useSetPollingRate = () => {
    const stateSetter = useSetRecoilState(pollingState)
    return useCallback(
        (setter: (current: boolean) => boolean) => {
            stateSetter(current => {
                const next = setter(current)

                if (current !== next) {
                    logger.info(`Setting poll state to ${next}`)
                }

                return next
            })
        },
        [stateSetter]
    )
}

export const useTogglePollingRate = () => {
    const setter = useSetPollingRate()

    return useCallback(() => {
        setter(current => !current)
    }, [setter])
}

type DarkMode = boolean | 'auto'

const darkModeState = atom<DarkMode>({
    key: 'darkMode',
    default: 'auto',
    effects: [localStorageEffect('dark-mode')],
})

const _darkModeMediaQuery = '(prefers-color-scheme: dark)'

export const useDarkModeSystemPreference = () => {
    const [enabled, setEnabled] = useState<boolean>(() => window.matchMedia(_darkModeMediaQuery).matches)

    useEffect(() => {
        const handler = (e: MediaQueryListEvent) => {
            setEnabled(e.matches)
        }

        window.matchMedia(_darkModeMediaQuery).addEventListener('change', handler)

        return () => {
            window.matchMedia(_darkModeMediaQuery).removeEventListener('change', handler)
        }
    }, [])

    return enabled
}

export const useIsDarkModeEnabled = (): boolean => {
    const darkMode = useRecoilValue(darkModeState)
    const darkModeSystemPreference = useDarkModeSystemPreference()

    if (darkMode === 'auto') {
        return darkModeSystemPreference
    }

    return darkMode
}

export const useDarkMode = () => useRecoilValue(darkModeState)

export const useSetDarkMode = () => {
    const stateSetter = useSetRecoilState(darkModeState)
    return useCallback(
        (setter: (current: DarkMode) => DarkMode) => {
            stateSetter(current => {
                const next = setter(current)

                if (current !== next) {
                    logger.info(`Setting dark mode to ${next}`)
                }

                return next
            })
        },
        [stateSetter]
    )
}
