import FormData from 'form-data'

import {
    CreateTemplateRequest,
    axios,
    createTemplateResponseSchema,
    createTemplateVersionResponseSchema,
} from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'

import { getAuthHeaders } from '../auth'
import { Download, createDownloadFromResponse } from './download'

export class TemplatesClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async downloadTemplateVersion(templateVersionId: string): Promise<Download> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<Blob>(this.endpoints.templates.downloadTemplateVersion(templateVersionId), {
                responseType: 'blob',
                headers,
            })
            .then(createDownloadFromResponse)
    }

    async createTemplateVersion(templateId: string, data: Blob) {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)
        const formData = new FormData()

        // The 'file' has to match what the server expects
        formData.append('file', data)

        return axios
            .post<unknown>(this.endpoints.templates.createTemplateVersion(templateId), formData, {
                headers,
            })
            .then(({ data }) => createTemplateVersionResponseSchema.parse(data))
    }

    async createTemplate(template: CreateTemplateRequest, data: Blob) {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)
        const formData = new FormData()

        // The 'file' has to match what the server expects
        formData.append('file', data)
        formData.append('name', template.name)
        formData.append('operationId', template.operationId)
        formData.append('target', template.target)
        formData.append('type', template.type)

        return axios
            .post<unknown>(this.endpoints.templates.createTemplate(), formData, {
                headers,
            })
            .then(({ data }) => createTemplateResponseSchema.parse(data))
    }
}
