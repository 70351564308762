import { Layout as BaseLayout } from 'antd'
import { ReactNode, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { insertBetweenElements } from '@publica/utils'

import { Header } from '../Header'
import { VerticalSpacer } from '../VerticalSpacer'
import { DarkModeToggle } from './DarkModeToggle/DarkModeToggle'
import { PollingToggle } from './PollingToggle'

const version = `v${__VERSION__}`

const useStyles = createUseStyles({
    layoutContent: {
        padding: [0, 50],
        paddingTop: 20,
    },
    layout: {
        minHeight: ['100vh', '!important'],
    },
    layoutFooter: {
        textAlign: 'center',
        color: colors.grey5,
    },
})

type LayoutProps = {
    footerChildren?: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children, footerChildren }) => {
    const styles = useStyles()

    const footerItems = useMemo(
        () =>
            insertBetweenElements([<DarkModeToggle key="darkMode" />, <PollingToggle key="polling" />, version], i => (
                <span key={i}> - </span>
            )),
        []
    )

    return (
        <BaseLayout className={`layout ${styles.layout}`}>
            <Header />
            <BaseLayout.Content className={styles.layoutContent}>
                <VerticalSpacer size={20}>{children}</VerticalSpacer>
            </BaseLayout.Content>
            <BaseLayout.Footer className={styles.layoutFooter}>
                <VerticalSpacer size={10}>
                    {footerChildren}
                    <div>{footerItems}</div>
                </VerticalSpacer>
            </BaseLayout.Footer>
        </BaseLayout>
    )
}
