import { Tooltip } from 'antd'
import { ReactElement } from 'react'

import { HasStatus, StatusDisplayInfo } from './types'

type StatusIconProps<S extends string, H extends HasStatus<S>> = {
    record: H
    statusDisplayInfo: StatusDisplayInfo<S>
}

export const StatusIcon = <S extends string, H extends HasStatus<S>>({
    record,
    statusDisplayInfo,
}: StatusIconProps<S, H>): ReactElement => {
    const displayInfo = statusDisplayInfo[record.status]
    return <Tooltip title={displayInfo.tooltip}>{displayInfo.icon}</Tooltip>
}
