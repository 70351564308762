import flatMap from 'lodash/flatMap'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import { z } from 'zod'

import {
    fieldInstanceKeyPattern,
    fieldInstanceParamKeyPattern,
    fieldInstanceParamPairPattern,
    fieldInstanceParamValuePattern,
    fieldInstanceProtocol,
} from './constants'

type FieldInstanceParamValue = string | number | boolean | undefined | FieldInstanceComplexParamValue
type FieldInstanceComplexParamValue = FieldInstanceParamValue[] | { [key: string]: FieldInstanceParamValue }

export type RawFieldInstance = {
    key: string
    params: Record<string, FieldInstanceParamValue>
}

type HasParamsWithPreHoistedAttributes = {
    params: {
        id?: string
        kind: string
        state: 'ERROR' | 'VALID'
    }
}

type WithHoistedAttributes<F extends HasParamsWithPreHoistedAttributes> = Omit<F, 'params'> & {
    kind: F['params']['kind']
    state: F['params']['state']
    params: Omit<F['params'], 'kind' | 'state' | 'id'>
} & (F['params'] extends { id: string } ? { id: string } : unknown)

// In the serialized form, the `kind` is stored in the params string, but in order
// to use it as a field for Typescript discriminating unions, we hoist the `kind`
// param up to the main field object
// { key: 'myKey', params: { kind: 'simple' } } -> { key: 'myKey', kind: 'simple', params: {} }
// We do the reverse during serialization

export const hoistAttributes = <F extends HasParamsWithPreHoistedAttributes>(field: F): WithHoistedAttributes<F> => {
    const { params, ...other } = field
    const { kind, state, id, ...otherParams } = params

    if (id === undefined) {
        return {
            kind,
            state,
            ...other,
            params: otherParams,
        } as WithHoistedAttributes<F>
    }
    return {
        kind,
        id,
        state,
        ...other,
        params: otherParams,
    } as unknown as WithHoistedAttributes<F>
}

export const fieldInstanceStateSchema = z.enum(['VALID', 'ERROR'])

export const serializeRawFieldInstance = ({ key, params }: RawFieldInstance): string => {
    if (!fieldInstanceKeyPattern.test(key)) {
        throw new Error(`Invalid field key: ${key}`)
    }

    const serializedParams: string[] = flatMap(params, (param, key) => serializeParameter(key, param))

    return `${fieldInstanceProtocol}${key}?${serializedParams.sort().join('&')}`
}

const serializeParameter = (key: string, value: FieldInstanceParamValue): string[] => {
    if (!fieldInstanceParamKeyPattern.test(key)) {
        throw new Error(`Invalid field param key: ${key}`)
    }

    if (value === undefined) {
        return []
    }

    if (isString(value)) {
        if (!fieldInstanceParamValuePattern.test(value)) {
            throw new Error(`Invalid field param value: ${key}=${value}`)
        }

        return [`${key}=${value}`]
    }

    if (isNumber(value) || isBoolean(value)) {
        return [`${key}=${value}`]
    }

    if (isArray(value)) {
        return flatMap(value, (v, idx) => {
            const serialized = serializeParameter(idx.toString(), v)
            return serialized.map(p => `${key}.${p}`)
        })
    }

    let serializedParams: string[] = []

    for (const subKey in value) {
        const subValue = value[subKey]!
        serializedParams = [...serializedParams, ...serializeParameter(subKey, subValue).map(p => `${key}.${p}`)]
    }

    return serializedParams
}

export const parseRawFieldInstance = (serializedFieldInstance: string): RawFieldInstance => {
    const fail = (reason: string) => `Invalid field value - ${reason} - ${serializedFieldInstance}`

    if (!serializedFieldInstance.startsWith(fieldInstanceProtocol)) {
        throw new Error(fail('missing protocol'))
    }

    const withoutProtocol = serializedFieldInstance.substring(fieldInstanceProtocol.length)
    const [key, rawParams, ...other] = withoutProtocol.split('?')

    if (other.length > 0) {
        throw new Error(fail('trailing query'))
    }

    const params: { [key: string]: FieldInstanceParamValue } = {}

    if (key === undefined || !fieldInstanceKeyPattern.test(key)) {
        throw new Error(fail('invalid key'))
    }

    if (rawParams !== undefined && rawParams.length > 0) {
        const rawParamPairs = rawParams.split('&')

        for (const rawPair of rawParamPairs) {
            if (!fieldInstanceParamPairPattern.test(rawPair)) {
                throw new Error(fail(`invalid param pair (${rawPair})`))
            }

            // We can cast, as the regex has validated the format
            const [pKey, pValue] = rawPair.split('=') as [string, string]

            const parts = pKey.split('.')
            let parent: FieldInstanceComplexParamValue = params

            for (let i = 0; i < parts.length; i++) {
                const part = parts[i]!
                const isArrayIndex = /^[0-9]+$/.test(part)
                const isLast = i === parts.length - 1

                // Check that the parent is the right type, based on the type of key
                if (isArrayIndex && !isArray(parent)) {
                    throw new Error(`Expected to find array at depth ${i} of ${pKey}`)
                }

                if (!isArrayIndex && isArray(parent)) {
                    throw new Error(`Expected to find object at depth ${i} of ${pKey}`)
                }

                const key = isArrayIndex ? parseInt(part) : part
                // We cast as any because we know that it's the right type, using the assertions above
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const curVal: FieldInstanceParamValue = (parent as any)[key]

                // If we have the last part of the path, we just want to set the value
                if (isLast) {
                    if (curVal !== undefined) {
                        throw new Error(`Overwriting value at ${pKey}`)
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ;(parent as any)[key] = pValue
                    continue
                }

                // If we're in the middle of the path, and the current value is already set
                // we can just push the stack - the next iteration will check the type for us
                if (curVal !== undefined) {
                    if (!isArray(curVal) && !isPlainObject(curVal)) {
                        throw new Error(`Expected to find array or object at depth ${i + 1} of ${pKey}`)
                    }

                    parent = curVal as FieldInstanceComplexParamValue
                    continue
                }

                // If the value isn't set, we need to sniff the next part to determine whether we're an
                // array or a pojo
                const nextPart = parts[i + 1]!
                const isNextPartArrayIndex = /^[0-9]+$/.test(nextPart)

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ;(parent as any)[key] = isNextPartArrayIndex ? [] : {}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                parent = (parent as any)[key]
            }
        }
    }

    return {
        key,
        params,
    }
}
