import { Button, Space } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { DateTime } from 'luxon'
import { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'

import { icons } from '..'
import { ActionButton } from '../ActionButton'
import { DatePicker } from '../DatePicker'
import { SearchIcon } from '../FilterTable'
import { VerticalSpacer } from '../VerticalSpacer'

type DateFilterable<K extends string> = Record<K, DateTime | undefined>

export const filterByDate = <K extends string, F extends DateFilterable<K>>(key: K): ColumnType<F> => ({
    filterDropdown: props => <DateRangeFilter {...props} />,
    filterIcon: filtered => <SearchIcon filtered={filtered} />,
    onFilter: (value, record) => {
        const dateRange = value as unknown as DateRange | undefined
        const [lower, upper] = dateRange ?? []

        const recordDate = record[key]

        if (recordDate === undefined) {
            return false
        }

        if (lower !== undefined && recordDate < lower) {
            return false
        }

        if (upper !== undefined && recordDate > upper) {
            return false
        }

        return true
    },
})

type DateRangeFilterProps = FilterDropdownProps

type DateRange = [DateTime | undefined, DateTime | undefined]

export const DateRangeFilter: FC<DateRangeFilterProps> = ({ clearFilters, confirm, setSelectedKeys, selectedKeys }) => {
    const providedDates = selectedKeys[0] as DateRange | undefined
    const [providedLower, providedUpper] = providedDates ?? []

    const [lowerBound, setLowerBound] = useState<DateTime | undefined>(providedLower)
    const [upperBound, setUpperBound] = useState<DateTime | undefined>(providedUpper)

    const onLowerBoundChange = useCallback((value: DateTime | null) => {
        setLowerBound(value ?? undefined)
    }, [])

    const onUpperBoundChange = useCallback((value: DateTime | null) => {
        setUpperBound(value ?? undefined)
    }, [])

    const onReset = useCallback(() => {
        setLowerBound(undefined)
        setUpperBound(undefined)
        clearFilters?.()
        confirm({ closeDropdown: true })
    }, [clearFilters, confirm])

    const onSearch = useCallback(() => {
        // This is a workaround since the types are too strict
        setSelectedKeys([[lowerBound, upperBound] as unknown as string])
        confirm()
    }, [confirm, lowerBound, setSelectedKeys, upperBound])

    const searchEnabled = upperBound !== undefined || lowerBound !== undefined

    const { t } = useDateRangeFilterTranslation()
    const styles = useDateRangeFilterStyles()

    return (
        <div className={styles.box}>
            <VerticalSpacer size={20}>
                <div>
                    <div className={styles.firstPicker}>
                        <p>{t('after')}</p>
                        <DatePicker
                            className={styles.datePicker}
                            onChange={onLowerBoundChange}
                            allowClear={true}
                            value={lowerBound}
                        />
                    </div>
                    <div>
                        <p>{t('before')}</p>
                        <DatePicker
                            className={styles.datePicker}
                            onChange={onUpperBoundChange}
                            allowClear={true}
                            value={upperBound}
                        />
                    </div>
                </div>
                <div className={styles.controls}>
                    <Space>
                        <Button onClick={onReset} size="small">
                            {t('reset')}
                        </Button>
                        <ActionButton disabled={!searchEnabled} onClick={onSearch} icon={icons.Search} size="small">
                            {t('search')}
                        </ActionButton>
                    </Space>
                </div>
            </VerticalSpacer>
        </div>
    )
}

const useDateRangeFilterTranslation = createUseTranslation({
    FR: {
        before: 'Avant',
        after: 'Après',
        search: 'Filtrer',
        reset: 'Annuler',
    },
    EN: {
        before: 'Before',
        after: 'After',
        search: 'Filter',
        reset: 'Reset',
    },
})

const useDateRangeFilterStyles = createUseStyles({
    datePicker: {
        width: '100%',
    },
    controls: {
        textAlign: 'right',
    },
    box: {
        padding: 12,
        minWidth: 300,
    },
    firstPicker: {
        marginBottom: 15,
    },
})
