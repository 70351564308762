import { z } from 'zod'

export const createTemplateVersionResponseSchema = z.object({
    id: z.string(),
})

export type CreateTemplateVersionResponse = z.infer<typeof createTemplateVersionResponseSchema>

export const createTemplateRequestSchema = z.object({
    operationId: z.string(),
    name: z.string(),
    target: z.enum(['DOCUMENT', 'EMAIL']),
    type: z.enum(['STATIC', 'DYNAMIC']),
})

export type CreateTemplateRequest = z.infer<typeof createTemplateRequestSchema>

export const createTemplateResponseSchema = z.object({
    id: z.string(),
})

export type CreateTemplateResponse = z.infer<typeof createTemplateResponseSchema>
