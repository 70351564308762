import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

import { useUnarchiveDocumentSetsMutation } from '../../../../../../../data'
import { DocumentSet } from '../../../types'

const useRestoreDocumentSetTranslation = createUseTranslation({
    FR: {
        restore: 'Rétablir',
    },
    EN: {
        restore: 'Restore',
    },
})

type RestoreDocumentSetProps = {
    documentSet: DocumentSet
}

export const RestoreDocumentSet: FC<RestoreDocumentSetProps> = ({ documentSet }) => {
    const { t } = useRestoreDocumentSetTranslation()
    const [unarchiveDocumentSetsMutation, { loading }] = useUnarchiveDocumentSetsMutation()
    const enabled = !loading

    const onClick = useAsyncCallback(async () => {
        await unarchiveDocumentSetsMutation({
            variables: {
                ids: [documentSet.id],
            },
        })
    }, [documentSet.id, unarchiveDocumentSetsMutation])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('restore')}
        </ActionButton>
    )
}
