export const grey1 = '#ffffff'
export const grey2 = '#fafafa'
export const grey3 = '#f5f5f5'
export const grey4 = '#f0f0f0'
export const grey5 = '#d9d9d9'
export const grey6 = '#bfbfbf'
export const grey7 = '#8c8c8c'
export const grey8 = '#595959'
export const grey9 = '#434343'
export const grey10 = '#262626'
export const grey11 = '#1f1f1f'
export const grey12 = '#141414'
export const grey13 = '#000000'

export const success = '#52c41a'
export const warning = '#faad14'
export const failure = '#ff0000'

export const blue1 = '#272c35'

export const nexdoOrange = '#cf5d3d'
