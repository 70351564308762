import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { useIsPollingEnabled, useTogglePollingRate } from '@publica/ui-web-state'

import { FooterToggle } from '../FooterToggle'

const usePollingStateToggleTranslation = createUseTranslation({
    FR: {
        disable: `Désactiver le rechargement automatique`,
        enable: `Activer le rechargement automatique`,
    },
    EN: {
        disable: 'Disable auto refresh',
        enable: 'Enable auto refresh',
    },
})

export const PollingToggle: FC = () => {
    const isPollingEnabled = useIsPollingEnabled()
    const toggle = useTogglePollingRate()
    const { t } = usePollingStateToggleTranslation()

    const text = isPollingEnabled ? t('disable') : t('enable')

    return <FooterToggle text={text} onClick={toggle} />
}
