import { Space } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { Operation } from '../types'
import { AddParticipantControl } from './AddParticipantControl'
import { ExportParticipantValuesControl } from './ExportParticipantValuesControl'
import { SendParticipantInvitesControl } from './SendParticipantInvitesControl'

type ParticipantsControlsProps = {
    operation: Operation
}

export const ParticipantsControls: FC<ParticipantsControlsProps> = ({ operation }) => {
    const styles = utils.useControlsStyles()

    return (
        <div className={styles.controls}>
            <Space>
                <AddParticipantControl />
                <SendParticipantInvitesControl operation={operation} />
                <ExportParticipantValuesControl operation={operation} />
            </Space>
        </div>
    )
}
