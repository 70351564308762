import { Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { useCallback } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { LookupValueDictionary } from '../../../../../../data'
import { SortedStringSelect } from '../../../../../components'

const lookupDictionaryPath = ['parameters', 'dictionary']

const rules: Rule[] = [
    {
        required: true,
        message: 'Vous devez choisir une source',
    },
]

type LookupProps = {
    disabled?: boolean
}

const useLookupTranslation = createUseTranslation({
    EN: {
        source: 'Source',
    },
    FR: {
        source: 'Source',
    },
})

export const Lookup: FC<LookupProps> = ({ disabled }) => {
    const formStyle = utils.useFormStyles()
    const { t } = useLookupTranslation()

    const labelForLookupDictionary = useLabelForLookupDictionary()

    return (
        <Form.Item label={t('source')} required>
            <Form.Item name={lookupDictionaryPath} hasFeedback rules={rules} className={formStyle.nestedFormItem}>
                <SortedStringSelect
                    disabled={disabled}
                    items={dictionaryOptions}
                    labelForItem={labelForLookupDictionary}
                />
            </Form.Item>
        </Form.Item>
    )
}

const dictionaryOptions: LookupValueDictionary[] = ['COUNTRY', 'MARITAL_STATUS', 'NATIONALITY', 'TITLE']

const useLabelForLookupDictionaryTranslation = createUseTranslation({
    EN: {
        country: 'Country',
        nationality: 'Nationality',
        maritalStatus: 'Marital status',
        title: 'Title',
    },
    FR: {
        country: 'Pays',
        nationality: 'Nationalité',
        maritalStatus: 'Statut marital',
        title: 'Civilité',
    },
})

const useLabelForLookupDictionary = () => {
    const { t } = useLabelForLookupDictionaryTranslation()

    return useCallback(
        (dictionary: LookupValueDictionary): string => {
            switch (dictionary) {
                case 'COUNTRY':
                    return t('country')
                case 'NATIONALITY':
                    return t('nationality')
                case 'MARITAL_STATUS':
                    return t('maritalStatus')
                case 'TITLE':
                    return t('title')
            }
        },
        [t]
    )
}
