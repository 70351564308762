import { FC } from '@publica/ui-common-utils'

import { AttachmentsIndex, AttachmentsIndexProps } from './AttachmentsIndex'

type AttachmentsProps = {
    operation: AttachmentsIndexProps['operation']
}

export const Attachments: FC<AttachmentsProps> = ({ operation }) => {
    return <AttachmentsIndex operation={operation} />
}
