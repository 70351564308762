import { Space } from 'antd'
import { useEffect } from 'react'

import { documentIsGenerated } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, DocumentActionsProps, DocumentDownloadButton, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { usePublishDocumentsMutation, useUnpublishDocumentsMutation } from '../../../data'

const useDocumentActionsTranslation = createUseTranslation({
    FR: {
        sendForSignature: 'Envoyer pour signature',
        publish: 'Publier',
        unpublish: 'Retirer',
    },
    EN: {
        sendForSignature: 'Send for signature',
        publish: 'Publish',
        unpublish: 'Retract',
    },
})

export const DocumentActions: FC<DocumentActionsProps> = ({
    operationId,
    document,
    config,
    setDocumentOperationState,
    isDocumentOperationInProgress,
}) => {
    const { t } = useDocumentActionsTranslation()

    const [publishDocumentsMutation] = usePublishDocumentsMutation()
    const [unpublishDocumentsMutation] = useUnpublishDocumentsMutation()

    const documentOperationInProgress = isDocumentOperationInProgress(document.id)

    const styles = utils.useActionsStyles()

    // This clears the flag after the document has actually been published
    useEffect(() => {
        setDocumentOperationState({ [document.id]: false })
    }, [document.id, document.status, setDocumentOperationState])

    const publishDocument = useAsyncCallback(async () => {
        setDocumentOperationState({ [document.id]: true })
        await publishDocumentsMutation({
            variables: {
                ids: [document.id],
            },
        })
    }, [document.id, publishDocumentsMutation, setDocumentOperationState])

    const unpublishDocument = useAsyncCallback(async () => {
        setDocumentOperationState({ [document.id]: true })
        await unpublishDocumentsMutation({
            variables: {
                ids: [document.id],
            },
        })
    }, [document.id, setDocumentOperationState, unpublishDocumentsMutation])

    const documentFiles = document.files

    const canPublish = config.mutable && document.status === 'READY' && !documentOperationInProgress

    const canUnpublish =
        config.mutable && document.status === 'PUBLISHED' && !documentOperationInProgress && !document.requiresSignature

    const canDownload = documentIsGenerated(document)

    return (
        <Space size="middle">
            {!canUnpublish ? (
                <ActionButton
                    className={styles.actions}
                    inProgress={documentOperationInProgress}
                    icon={icons.Publish}
                    disabled={!canPublish}
                    onClick={publishDocument}
                >
                    {document.requiresSignature ? t('sendForSignature') : t('publish')}
                </ActionButton>
            ) : null}
            {canUnpublish ? (
                <ActionButton
                    className={styles.actions}
                    inProgress={documentOperationInProgress}
                    icon={icons.Unpublish}
                    disabled={!canUnpublish}
                    onClick={unpublishDocument}
                >
                    {t('unpublish')}
                </ActionButton>
            ) : null}
            <DocumentDownloadButton disabled={!canDownload} documentFiles={documentFiles} operationId={operationId} />
        </Space>
    )
}
