import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import { LocalizedString } from '@publica/api-graphql'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

type Group = {
    id: string
    name: LocalizedString
}

type GroupTableProps = {
    groups: Group[]
}

const useGroupTableTranslations = createUseTranslation({
    FR: {
        group: 'Groupe',
    },
    EN: {
        group: 'Group',
    },
})

export const GroupTable: FC<GroupTableProps> = ({ groups }) => {
    const { t } = useGroupTableTranslations()
    const resolveLocalizedString = useLocalizedStringResolver()

    const columns = useMemo<FilterColumnType<Group>[]>(
        () => [
            {
                title: t('group'),
                render: (_, group) => resolveLocalizedString(group.name),
            },
        ],
        [t, resolveLocalizedString]
    )

    const sortedGroups = useMemo(() => sortBy(groups, group => group.name), [groups])

    return <FilterTable<Group> dataSource={sortedGroups} columns={columns} />
}
