import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import {
    DocumentActionsProps,
    DocumentControlsProps,
    DocumentTable,
    DocumentTableConfig,
} from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationDocumentSetDocumentsQuery } from '../../../../../../data'
import { DocumentActions, DocumentControls } from '../../../../../components'
import { DocumentSet, Operation } from '../../types'

type DocumentTableForDocumentSetProps = {
    mutable: boolean
    documentSet: DocumentSet
    operation: Operation
}

export const DocumentTableForDocumentSet: FC<DocumentTableForDocumentSetProps> = ({
    documentSet,
    mutable,
    operation,
}) => {
    const { data, loading } = useGetOperationDocumentSetDocumentsQuery({
        variables: {
            documentSetId: documentSet.id,
            operationId: operation.id,
        },
        pollInterval: usePollingRate(),
    })

    const documentSetWithDocuments = useMemo(
        () => ({ ...documentSet, documents: data?.operation?.documentSet.documents ?? [] }),
        [data?.operation?.documentSet.documents, documentSet]
    )

    const config: Partial<DocumentTableConfig> = useMemo(
        () => ({
            mutable,
            columns: {
                documentName: 'FULL',
            },
            displaySignatureStatus: documentSet.type === 'SIGNED_TEMPLATE',
            // Group by participant if it's a manual set, and if at least one
            // participant has multiple docs
            groupByParticipant:
                documentSet.type === 'MANUAL' &&
                Object.values(groupBy(documentSetWithDocuments.documents, doc => doc.participant.info.code)).some(
                    docs => docs.length > 1
                ),
        }),
        [documentSetWithDocuments.documents, documentSet.type, mutable]
    )

    return (
        <DocumentTable
            operationId={operation.id}
            loading={loading}
            documents={documentSetWithDocuments.documents}
            config={config}
            documentControls={renderDocumentControls}
            documentActions={renderDocumentActions}
        />
    )
}

const renderDocumentControls = (props: DocumentControlsProps) => <DocumentControls {...props} />
const renderDocumentActions = (props: DocumentActionsProps) => <DocumentActions {...props} />
