// Copyright 2020, OpenTelemetry Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file opentelemetry/proto/logs/v1/logs.proto (package opentelemetry.proto.logs.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Resource } from "../../resource/v1/resource_pb";
import { AnyValue, InstrumentationLibrary, InstrumentationScope, KeyValue } from "../../common/v1/common_pb";

/**
 * Possible values for LogRecord.SeverityNumber.
 *
 * @generated from enum opentelemetry.proto.logs.v1.SeverityNumber
 */
export enum SeverityNumber {
  /**
   * UNSPECIFIED is the default SeverityNumber, it MUST NOT be used.
   *
   * @generated from enum value: SEVERITY_NUMBER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SEVERITY_NUMBER_TRACE = 1;
   */
  TRACE = 1,

  /**
   * @generated from enum value: SEVERITY_NUMBER_TRACE2 = 2;
   */
  TRACE2 = 2,

  /**
   * @generated from enum value: SEVERITY_NUMBER_TRACE3 = 3;
   */
  TRACE3 = 3,

  /**
   * @generated from enum value: SEVERITY_NUMBER_TRACE4 = 4;
   */
  TRACE4 = 4,

  /**
   * @generated from enum value: SEVERITY_NUMBER_DEBUG = 5;
   */
  DEBUG = 5,

  /**
   * @generated from enum value: SEVERITY_NUMBER_DEBUG2 = 6;
   */
  DEBUG2 = 6,

  /**
   * @generated from enum value: SEVERITY_NUMBER_DEBUG3 = 7;
   */
  DEBUG3 = 7,

  /**
   * @generated from enum value: SEVERITY_NUMBER_DEBUG4 = 8;
   */
  DEBUG4 = 8,

  /**
   * @generated from enum value: SEVERITY_NUMBER_INFO = 9;
   */
  INFO = 9,

  /**
   * @generated from enum value: SEVERITY_NUMBER_INFO2 = 10;
   */
  INFO2 = 10,

  /**
   * @generated from enum value: SEVERITY_NUMBER_INFO3 = 11;
   */
  INFO3 = 11,

  /**
   * @generated from enum value: SEVERITY_NUMBER_INFO4 = 12;
   */
  INFO4 = 12,

  /**
   * @generated from enum value: SEVERITY_NUMBER_WARN = 13;
   */
  WARN = 13,

  /**
   * @generated from enum value: SEVERITY_NUMBER_WARN2 = 14;
   */
  WARN2 = 14,

  /**
   * @generated from enum value: SEVERITY_NUMBER_WARN3 = 15;
   */
  WARN3 = 15,

  /**
   * @generated from enum value: SEVERITY_NUMBER_WARN4 = 16;
   */
  WARN4 = 16,

  /**
   * @generated from enum value: SEVERITY_NUMBER_ERROR = 17;
   */
  ERROR = 17,

  /**
   * @generated from enum value: SEVERITY_NUMBER_ERROR2 = 18;
   */
  ERROR2 = 18,

  /**
   * @generated from enum value: SEVERITY_NUMBER_ERROR3 = 19;
   */
  ERROR3 = 19,

  /**
   * @generated from enum value: SEVERITY_NUMBER_ERROR4 = 20;
   */
  ERROR4 = 20,

  /**
   * @generated from enum value: SEVERITY_NUMBER_FATAL = 21;
   */
  FATAL = 21,

  /**
   * @generated from enum value: SEVERITY_NUMBER_FATAL2 = 22;
   */
  FATAL2 = 22,

  /**
   * @generated from enum value: SEVERITY_NUMBER_FATAL3 = 23;
   */
  FATAL3 = 23,

  /**
   * @generated from enum value: SEVERITY_NUMBER_FATAL4 = 24;
   */
  FATAL4 = 24,
}
// Retrieve enum metadata with: proto3.getEnumType(SeverityNumber)
proto3.util.setEnumType(SeverityNumber, "opentelemetry.proto.logs.v1.SeverityNumber", [
  { no: 0, name: "SEVERITY_NUMBER_UNSPECIFIED" },
  { no: 1, name: "SEVERITY_NUMBER_TRACE" },
  { no: 2, name: "SEVERITY_NUMBER_TRACE2" },
  { no: 3, name: "SEVERITY_NUMBER_TRACE3" },
  { no: 4, name: "SEVERITY_NUMBER_TRACE4" },
  { no: 5, name: "SEVERITY_NUMBER_DEBUG" },
  { no: 6, name: "SEVERITY_NUMBER_DEBUG2" },
  { no: 7, name: "SEVERITY_NUMBER_DEBUG3" },
  { no: 8, name: "SEVERITY_NUMBER_DEBUG4" },
  { no: 9, name: "SEVERITY_NUMBER_INFO" },
  { no: 10, name: "SEVERITY_NUMBER_INFO2" },
  { no: 11, name: "SEVERITY_NUMBER_INFO3" },
  { no: 12, name: "SEVERITY_NUMBER_INFO4" },
  { no: 13, name: "SEVERITY_NUMBER_WARN" },
  { no: 14, name: "SEVERITY_NUMBER_WARN2" },
  { no: 15, name: "SEVERITY_NUMBER_WARN3" },
  { no: 16, name: "SEVERITY_NUMBER_WARN4" },
  { no: 17, name: "SEVERITY_NUMBER_ERROR" },
  { no: 18, name: "SEVERITY_NUMBER_ERROR2" },
  { no: 19, name: "SEVERITY_NUMBER_ERROR3" },
  { no: 20, name: "SEVERITY_NUMBER_ERROR4" },
  { no: 21, name: "SEVERITY_NUMBER_FATAL" },
  { no: 22, name: "SEVERITY_NUMBER_FATAL2" },
  { no: 23, name: "SEVERITY_NUMBER_FATAL3" },
  { no: 24, name: "SEVERITY_NUMBER_FATAL4" },
]);

/**
 * Masks for LogRecord.flags field.
 *
 * @generated from enum opentelemetry.proto.logs.v1.LogRecordFlags
 */
export enum LogRecordFlags {
  /**
   * @generated from enum value: LOG_RECORD_FLAG_UNSPECIFIED = 0;
   */
  LOG_RECORD_FLAG_UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOG_RECORD_FLAG_TRACE_FLAGS_MASK = 255;
   */
  LOG_RECORD_FLAG_TRACE_FLAGS_MASK = 255,
}
// Retrieve enum metadata with: proto3.getEnumType(LogRecordFlags)
proto3.util.setEnumType(LogRecordFlags, "opentelemetry.proto.logs.v1.LogRecordFlags", [
  { no: 0, name: "LOG_RECORD_FLAG_UNSPECIFIED" },
  { no: 255, name: "LOG_RECORD_FLAG_TRACE_FLAGS_MASK" },
]);

/**
 * LogsData represents the logs data that can be stored in a persistent storage,
 * OR can be embedded by other protocols that transfer OTLP logs data but do not
 * implement the OTLP protocol.
 *
 * The main difference between this message and collector protocol is that
 * in this message there will not be any "control" or "metadata" specific to
 * OTLP protocol.
 *
 * When new fields are added into this message, the OTLP request MUST be updated
 * as well.
 *
 * @generated from message opentelemetry.proto.logs.v1.LogsData
 */
export class LogsData extends Message<LogsData> {
  /**
   * An array of ResourceLogs.
   * For data coming from a single resource this array will typically contain
   * one element. Intermediary nodes that receive data from multiple origins
   * typically batch the data before forwarding further and in that case this
   * array will contain multiple elements.
   *
   * @generated from field: repeated opentelemetry.proto.logs.v1.ResourceLogs resource_logs = 1;
   */
  resourceLogs: ResourceLogs[] = [];

  constructor(data?: PartialMessage<LogsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "opentelemetry.proto.logs.v1.LogsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_logs", kind: "message", T: ResourceLogs, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogsData {
    return new LogsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogsData {
    return new LogsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogsData {
    return new LogsData().fromJsonString(jsonString, options);
  }

  static equals(a: LogsData | PlainMessage<LogsData> | undefined, b: LogsData | PlainMessage<LogsData> | undefined): boolean {
    return proto3.util.equals(LogsData, a, b);
  }
}

/**
 * A collection of ScopeLogs from a Resource.
 *
 * @generated from message opentelemetry.proto.logs.v1.ResourceLogs
 */
export class ResourceLogs extends Message<ResourceLogs> {
  /**
   * The resource for the logs in this message.
   * If this field is not set then resource info is unknown.
   *
   * @generated from field: opentelemetry.proto.resource.v1.Resource resource = 1;
   */
  resource?: Resource;

  /**
   * A list of ScopeLogs that originate from a resource.
   *
   * @generated from field: repeated opentelemetry.proto.logs.v1.ScopeLogs scope_logs = 2;
   */
  scopeLogs: ScopeLogs[] = [];

  /**
   * A list of InstrumentationLibraryLogs that originate from a resource.
   * This field is deprecated and will be removed after grace period expires on June 15, 2022.
   *
   * During the grace period the following rules SHOULD be followed:
   *
   * For Binary Protobufs
   * ====================
   * Binary Protobuf senders SHOULD NOT set instrumentation_library_logs. Instead
   * scope_logs SHOULD be set.
   *
   * Binary Protobuf receivers SHOULD check if instrumentation_library_logs is set
   * and scope_logs is not set then the value in instrumentation_library_logs
   * SHOULD be used instead by converting InstrumentationLibraryLogs into ScopeLogs.
   * If scope_logs is set then instrumentation_library_logs SHOULD be ignored.
   *
   * For JSON
   * ========
   * JSON senders that set instrumentation_library_logs field MAY also set
   * scope_logs to carry the same logs, essentially double-publishing the same data.
   * Such double-publishing MAY be controlled by a user-settable option.
   * If double-publishing is not used then the senders SHOULD set scope_logs and
   * SHOULD NOT set instrumentation_library_logs.
   *
   * JSON receivers SHOULD check if instrumentation_library_logs is set and
   * scope_logs is not set then the value in instrumentation_library_logs
   * SHOULD be used instead by converting InstrumentationLibraryLogs into ScopeLogs.
   * If scope_logs is set then instrumentation_library_logs field SHOULD be ignored.
   *
   * @generated from field: repeated opentelemetry.proto.logs.v1.InstrumentationLibraryLogs instrumentation_library_logs = 1000 [deprecated = true];
   * @deprecated
   */
  instrumentationLibraryLogs: InstrumentationLibraryLogs[] = [];

  /**
   * This schema_url applies to the data in the "resource" field. It does not apply
   * to the data in the "scope_logs" field which have their own schema_url field.
   *
   * @generated from field: string schema_url = 3;
   */
  schemaUrl = "";

  constructor(data?: PartialMessage<ResourceLogs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "opentelemetry.proto.logs.v1.ResourceLogs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource", kind: "message", T: Resource },
    { no: 2, name: "scope_logs", kind: "message", T: ScopeLogs, repeated: true },
    { no: 1000, name: "instrumentation_library_logs", kind: "message", T: InstrumentationLibraryLogs, repeated: true },
    { no: 3, name: "schema_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceLogs {
    return new ResourceLogs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceLogs {
    return new ResourceLogs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceLogs {
    return new ResourceLogs().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceLogs | PlainMessage<ResourceLogs> | undefined, b: ResourceLogs | PlainMessage<ResourceLogs> | undefined): boolean {
    return proto3.util.equals(ResourceLogs, a, b);
  }
}

/**
 * A collection of Logs produced by a Scope.
 *
 * @generated from message opentelemetry.proto.logs.v1.ScopeLogs
 */
export class ScopeLogs extends Message<ScopeLogs> {
  /**
   * The instrumentation scope information for the logs in this message.
   * Semantically when InstrumentationScope isn't set, it is equivalent with
   * an empty instrumentation scope name (unknown).
   *
   * @generated from field: opentelemetry.proto.common.v1.InstrumentationScope scope = 1;
   */
  scope?: InstrumentationScope;

  /**
   * A list of log records.
   *
   * @generated from field: repeated opentelemetry.proto.logs.v1.LogRecord log_records = 2;
   */
  logRecords: LogRecord[] = [];

  /**
   * This schema_url applies to all logs in the "logs" field.
   *
   * @generated from field: string schema_url = 3;
   */
  schemaUrl = "";

  constructor(data?: PartialMessage<ScopeLogs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "opentelemetry.proto.logs.v1.ScopeLogs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scope", kind: "message", T: InstrumentationScope },
    { no: 2, name: "log_records", kind: "message", T: LogRecord, repeated: true },
    { no: 3, name: "schema_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScopeLogs {
    return new ScopeLogs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScopeLogs {
    return new ScopeLogs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScopeLogs {
    return new ScopeLogs().fromJsonString(jsonString, options);
  }

  static equals(a: ScopeLogs | PlainMessage<ScopeLogs> | undefined, b: ScopeLogs | PlainMessage<ScopeLogs> | undefined): boolean {
    return proto3.util.equals(ScopeLogs, a, b);
  }
}

/**
 * A collection of Logs produced by an InstrumentationLibrary.
 * InstrumentationLibraryLogs is wire-compatible with ScopeLogs for binary
 * Protobuf format.
 * This message is deprecated and will be removed on June 15, 2022.
 *
 * @generated from message opentelemetry.proto.logs.v1.InstrumentationLibraryLogs
 * @deprecated
 */
export class InstrumentationLibraryLogs extends Message<InstrumentationLibraryLogs> {
  /**
   * The instrumentation library information for the logs in this message.
   * Semantically when InstrumentationLibrary isn't set, it is equivalent with
   * an empty instrumentation library name (unknown).
   *
   * @generated from field: opentelemetry.proto.common.v1.InstrumentationLibrary instrumentation_library = 1;
   */
  instrumentationLibrary?: InstrumentationLibrary;

  /**
   * A list of logs that originate from an instrumentation library.
   *
   * @generated from field: repeated opentelemetry.proto.logs.v1.LogRecord log_records = 2;
   */
  logRecords: LogRecord[] = [];

  /**
   * This schema_url applies to all logs in the "logs" field.
   *
   * @generated from field: string schema_url = 3;
   */
  schemaUrl = "";

  constructor(data?: PartialMessage<InstrumentationLibraryLogs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "opentelemetry.proto.logs.v1.InstrumentationLibraryLogs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instrumentation_library", kind: "message", T: InstrumentationLibrary },
    { no: 2, name: "log_records", kind: "message", T: LogRecord, repeated: true },
    { no: 3, name: "schema_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstrumentationLibraryLogs {
    return new InstrumentationLibraryLogs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstrumentationLibraryLogs {
    return new InstrumentationLibraryLogs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstrumentationLibraryLogs {
    return new InstrumentationLibraryLogs().fromJsonString(jsonString, options);
  }

  static equals(a: InstrumentationLibraryLogs | PlainMessage<InstrumentationLibraryLogs> | undefined, b: InstrumentationLibraryLogs | PlainMessage<InstrumentationLibraryLogs> | undefined): boolean {
    return proto3.util.equals(InstrumentationLibraryLogs, a, b);
  }
}

/**
 * A log record according to OpenTelemetry Log Data Model:
 * https://github.com/open-telemetry/oteps/blob/main/text/logs/0097-log-data-model.md
 *
 * @generated from message opentelemetry.proto.logs.v1.LogRecord
 */
export class LogRecord extends Message<LogRecord> {
  /**
   * time_unix_nano is the time when the event occurred.
   * Value is UNIX Epoch time in nanoseconds since 00:00:00 UTC on 1 January 1970.
   * Value of 0 indicates unknown or missing timestamp.
   *
   * @generated from field: fixed64 time_unix_nano = 1;
   */
  timeUnixNano = protoInt64.zero;

  /**
   * Time when the event was observed by the collection system.
   * For events that originate in OpenTelemetry (e.g. using OpenTelemetry Logging SDK)
   * this timestamp is typically set at the generation time and is equal to Timestamp.
   * For events originating externally and collected by OpenTelemetry (e.g. using
   * Collector) this is the time when OpenTelemetry's code observed the event measured
   * by the clock of the OpenTelemetry code. This field MUST be set once the event is
   * observed by OpenTelemetry.
   *
   * For converting OpenTelemetry log data to formats that support only one timestamp or
   * when receiving OpenTelemetry log data by recipients that support only one timestamp
   * internally the following logic is recommended:
   *   - Use time_unix_nano if it is present, otherwise use observed_time_unix_nano.
   *
   * Value is UNIX Epoch time in nanoseconds since 00:00:00 UTC on 1 January 1970.
   * Value of 0 indicates unknown or missing timestamp.
   *
   * @generated from field: fixed64 observed_time_unix_nano = 11;
   */
  observedTimeUnixNano = protoInt64.zero;

  /**
   * Numerical value of the severity, normalized to values described in Log Data Model.
   * [Optional].
   *
   * @generated from field: opentelemetry.proto.logs.v1.SeverityNumber severity_number = 2;
   */
  severityNumber = SeverityNumber.UNSPECIFIED;

  /**
   * The severity text (also known as log level). The original string representation as
   * it is known at the source. [Optional].
   *
   * @generated from field: string severity_text = 3;
   */
  severityText = "";

  /**
   * A value containing the body of the log record. Can be for example a human-readable
   * string message (including multi-line) describing the event in a free form or it can
   * be a structured data composed of arrays and maps of other values. [Optional].
   *
   * @generated from field: opentelemetry.proto.common.v1.AnyValue body = 5;
   */
  body?: AnyValue;

  /**
   * Additional attributes that describe the specific event occurrence. [Optional].
   * Attribute keys MUST be unique (it is not allowed to have more than one
   * attribute with the same key).
   *
   * @generated from field: repeated opentelemetry.proto.common.v1.KeyValue attributes = 6;
   */
  attributes: KeyValue[] = [];

  /**
   * @generated from field: uint32 dropped_attributes_count = 7;
   */
  droppedAttributesCount = 0;

  /**
   * Flags, a bit field. 8 least significant bits are the trace flags as
   * defined in W3C Trace Context specification. 24 most significant bits are reserved
   * and must be set to 0. Readers must not assume that 24 most significant bits
   * will be zero and must correctly mask the bits when reading 8-bit trace flag (use
   * flags & TRACE_FLAGS_MASK). [Optional].
   *
   * @generated from field: fixed32 flags = 8;
   */
  flags = 0;

  /**
   * A unique identifier for a trace. All logs from the same trace share
   * the same `trace_id`. The ID is a 16-byte array. An ID with all zeroes
   * is considered invalid. Can be set for logs that are part of request processing
   * and have an assigned trace id. [Optional].
   *
   * @generated from field: bytes trace_id = 9;
   */
  traceId = new Uint8Array(0);

  /**
   * A unique identifier for a span within a trace, assigned when the span
   * is created. The ID is an 8-byte array. An ID with all zeroes is considered
   * invalid. Can be set for logs that are part of a particular processing span.
   * If span_id is present trace_id SHOULD be also present. [Optional].
   *
   * @generated from field: bytes span_id = 10;
   */
  spanId = new Uint8Array(0);

  constructor(data?: PartialMessage<LogRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "opentelemetry.proto.logs.v1.LogRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_unix_nano", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 11, name: "observed_time_unix_nano", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 2, name: "severity_number", kind: "enum", T: proto3.getEnumType(SeverityNumber) },
    { no: 3, name: "severity_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "body", kind: "message", T: AnyValue },
    { no: 6, name: "attributes", kind: "message", T: KeyValue, repeated: true },
    { no: 7, name: "dropped_attributes_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "flags", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 9, name: "trace_id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 10, name: "span_id", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogRecord {
    return new LogRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogRecord {
    return new LogRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogRecord {
    return new LogRecord().fromJsonString(jsonString, options);
  }

  static equals(a: LogRecord | PlainMessage<LogRecord> | undefined, b: LogRecord | PlainMessage<LogRecord> | undefined): boolean {
    return proto3.util.equals(LogRecord, a, b);
  }
}

