import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'

export const AddParticipantControl: FC = () => {
    const { t } = useAddParticipantControlTransaction()
    const navigate = useNavigate()

    const onClick = useCallback(() => {
        navigate('add')
    }, [navigate])

    return (
        <ActionButton size="middle" icon={icons.AddUsers} onClick={onClick}>
            {t('add')}
        </ActionButton>
    )
}

export const useAddParticipantControlTransaction = createUseTranslation({
    EN: {
        add: 'Add participants',
    },
    FR: {
        add: 'Ajouter participants',
    },
})
