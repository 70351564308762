import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'

export const useStyles = createUseStyles({
    toolbar: {
        borderBottom: [1, colors.grey4, 'solid'],
        paddingBottom: 10,
        marginBottom: 10,
    },
    editor: {
        fontFamily: 'sans-serif',
    },
    editorControls: {
        textAlign: 'right',
    },
    headers: {
        marginBottom: 10,
    },
    header: {
        padding: [5, 0],
        borderBottom: [1, colors.grey4, 'solid'],
    },
    headerLabel: {
        display: 'inline-block',
        width: '5em',
        textDecoration: 'underline',
    },
})
