import { addHostToEndpoints } from './endpoints'

export const apiEndpoints = {
    graphql: () => '/graphql',
    documents: {
        download: (id: string) => `/documents/${id}`,
        createArchive: () => `/documents/archive`,
        downloadArchive: (id: string) => `/documents/archive/${id}`,
    },
    documentSets: {
        createDocumentInDocumentSet: (documentSetId: string) => `/documentSets/${documentSetId}`,
    },
    auth: {
        getTokenForSignInCode: () => `/auth/getTokenForSignInCode`,
        createSignInCode: () => `/auth/createSignInCode`,
        refreshToken: () => `/auth/refreshToken`,
        account: () => `/auth/account`,
    },
    attachments: {
        download: (id: string) => `/attachments/${id}`,
        upload: (id: string) => `/attachments/${id}`,
        delete: (id: string) => `/attachments/${id}`,
    },
    templates: {
        downloadTemplateVersion: (templateVersionId: string) => `/templates/version/${templateVersionId}`,
        createTemplateVersion: (templateId: string) => `/templates/${templateId}`,
        createTemplate: () => `/templates`,
    },
    participants: {
        export: () => `/participants/export`,
    },
    operations: {
        downloadOperationExport: (id: string) => `/operations/exports/${id}`,
    },
} as const

export type ApiEndpoints = typeof apiEndpoints

export const apiEndpointsWithHost = (host: string): ApiEndpoints => {
    return addHostToEndpoints(host, apiEndpoints)
}
