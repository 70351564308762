import { HolderOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import type { XYCoord } from 'dnd-core'
import update from 'immutability-helper'
import { PropsWithChildren, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { useTheme } from '@publica/ui-web-styles'

// TODO(ui): replace dnd-core

type SortableListProps<I> = {
    items: I[]
    keyForItem: (item: I, index: number) => string | number
    renderItem: (item: I, index: number) => ReactNode
    onChange?: (items: I[]) => void
    sortable?: boolean
}

const useSortableListStyles = createUseStyles({
    items: {
        '& > *': {
            borderBottom: ['1px', 'solid', colors.grey4],
        },
        '& > :last-child': {
            border: 'none',
        },
    },
})

export const SortableList = <I,>({
    items,
    keyForItem,
    renderItem,
    onChange,
    sortable = true,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
SortableListProps<I>): ReactElement<any, any> | null => {
    const [sortedItems, setSortedItems] = useState(items)
    const styles = useSortableListStyles()

    useEffect(() => {
        setSortedItems(items)
    }, [items])

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortedItems((prevItems: I[]) =>
            update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex] as I],
                ],
            })
        )
    }, [])

    const onDrop = useCallback(() => {
        if (onChange !== undefined) {
            onChange(sortedItems)
        }
    }, [onChange, sortedItems])

    const renderListItem = useCallback(
        (item: I, index: number) => {
            const key = keyForItem(item, index)
            const content = renderItem(item, index)

            if (content === null) {
                return null
            }

            let listItem = <ListItem key={key}>{content}</ListItem>

            if (sortable) {
                listItem = (
                    <SortableListItem key={key} index={index} moveItem={moveItem} onDrop={onDrop}>
                        {listItem}
                    </SortableListItem>
                )
            }

            return listItem
        },
        [keyForItem, moveItem, onDrop, renderItem, sortable]
    )

    return <div className={styles.items}>{sortedItems.map(renderListItem)}</div>
}

type SortableListItemProps = {
    index: number
    moveItem: (dragIndex: number, hoverIndex: number) => void
    onDrop: (item: DragItem) => void
}

interface DragItem {
    index: number
}

const ListItemType = 'listItem'

const useSortableListItemStyles = createUseStyles({
    sortableListItem: {
        cursor: 'move',
        '&:hover': {
            backgroundColor: colors.grey2,
        },
    },
    handle: {
        display: 'flex',
        alignItems: 'center',
    },
})

const SortableListItem = ({ index, moveItem, children, onDrop }: PropsWithChildren<SortableListItemProps>) => {
    const ref = useRef<HTMLDivElement>(null)

    const style = useSortableListItemStyles()
    const theme = useTheme()

    const [_, drop] = useDrop({
        accept: ListItemType,
        drop: onDrop,
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ListItemType,
        item: () => {
            return { index }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    const opacityStyle = { opacity }
    const hoverStyle = isDragging ? { backgroundColor: theme.tenant.colors.primary, opacity: 0.1 } : undefined

    drag(drop(ref))

    return (
        <div style={hoverStyle}>
            <Row ref={ref} style={opacityStyle} className={style.sortableListItem}>
                <Col flex="30px" className={style.handle}>
                    <HolderOutlined />
                </Col>
                <Col flex="auto">{children}</Col>
            </Row>
        </div>
    )
}

const useListItemStyles = createUseStyles({
    listItem: {
        padding: [10, 0],
    },
})

const ListItem: FC = ({ children }) => {
    const style = useListItemStyles()
    return <div className={style.listItem}>{children}</div>
}
