import { FC } from '@publica/ui-common-utils'

import { Field, TextFieldParameters } from '../../types'
import { EmailValueFormItem } from './EmailValueFormItem'
import { IBANValueFormItem } from './IBANValueFormItem'
import { PhoneValueFormItem } from './PhoneValueFormItem'
import { PlainTextValueFormItem } from './PlainTextValueFormItem'

type TextValueFormItemProps = {
    field: Field<TextFieldParameters>
}

export const TextValueFormItem: FC<TextValueFormItemProps> = ({ field }) => {
    switch (field.parameters.textFormat) {
        case 'TEXT':
            return <PlainTextValueFormItem field={field} key={field.key} />
        case 'EMAIL':
            return <EmailValueFormItem field={field} key={field.key} />
        case 'IBAN':
            return <IBANValueFormItem field={field} key={field.key} />
        case 'PHONE':
            return <PhoneValueFormItem field={field} key={field.key} />
    }
}
