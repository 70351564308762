import { Tag } from 'antd'
import isArray from 'lodash/isArray'
import { ReactElement } from 'react'

import { TagField } from '../TagField'
import { HasStatus, StatusDisplayInfo } from './types'

type StatusTagsProps<S extends string, H extends HasStatus<S>> = {
    records: H[] | H
    statusDisplayInfo: StatusDisplayInfo<S>
    displayCount?: boolean
}

export const StatusTags = <S extends string, H extends HasStatus<S>>({
    records,
    statusDisplayInfo,
}: StatusTagsProps<S, H>): ReactElement => {
    const counters: Partial<Record<S, number>> = {}

    for (const record of isArray(records) ? records : [records]) {
        counters[record.status] = (counters[record.status] ?? 0) + 1
    }

    const tags: ReactElement[] = []

    for (const key in counters) {
        const displayInfo = statusDisplayInfo[key]
        const count = counters[key] ?? 0
        if (count > 0) {
            tags.push(
                <Tag key={key} icon={displayInfo.icon} color={displayInfo.color}>
                    {displayInfo.message(count)}
                </Tag>
            )
        }
    }

    return <TagField>{tags}</TagField>
}
