import { FC } from '@publica/ui-common-utils'

import { StatusIcon } from '../Status'
import { HasEmailStatus, useEmailStatusDisplayInfo } from './emailStatusInfo'

type EmailStatusIconProps = {
    email: HasEmailStatus
}

export const EmailStatusIcon: FC<EmailStatusIconProps> = ({ email }) => {
    const emailStatusDisplayInfo = useEmailStatusDisplayInfo()
    return <StatusIcon record={email} statusDisplayInfo={emailStatusDisplayInfo} />
}
