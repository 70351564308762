import { Checkbox, Divider, Form, Modal, Select, notification } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { OperationExportUploadedAttachmentsGroupBy } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

import { useExportOperationMutation } from '../../../../../data'

const useExportOperationFormStyles = createUseStyles({
    form: {
        padding: [10, 0],
    },
})

const useExportOperationTranslations = createUseTranslation({
    EN: {
        exportOperation: 'Export operation',
        exportOperationConfirmed: 'The operation is being exported',
        exportOperationMessage: 'You will receive a download link via email when the export is ready',
        formTitle: `Choose the content of the export`,
        documents: 'Documents',
        emails: 'Emails',
        participantData: `Participant Data`,
        templates: 'Templates',
        uploadedAttachments: `KYC Documents`,
        include: 'Include',
    },
    FR: {
        exportOperation: `Exporter l'opération`,
        exportOperationConfirmed: `L'export de l'opération est en cours`,
        exportOperationMessage: `Vous recevrez un lien de téléchargement par email lorsque l'export est prêt`,
        formTitle: `Choisir le contenu de l'export`,
        uploadedAttachments: `Documents KYC`,
        documents: `Documents`,
        emails: `Emails`,
        participantData: `Informations participants`,
        templates: `Modèles`,
        include: 'Inclure',
    },
})

// 30s in ms
const cooldownPeriod = 15 * 1000

type ExportOperationFormValues = {
    participantData: {
        include: boolean
    }
    uploadedAttachments: {
        include: boolean
        groupBy: OperationExportUploadedAttachmentsGroupBy
    }
    documents: {
        include: boolean
    }
    emails: {
        include: boolean
    }
    templates: {
        include: boolean
        includeAllVersions: boolean
    }
}

type ExportOperationProps = {
    operation: {
        id: string
    }
}

export const ExportOperation: FC<ExportOperationProps> = ({ operation }) => {
    const { t } = useExportOperationTranslations()
    const styles = useExportOperationFormStyles()

    const [notificationApi, notificationContext] = notification.useNotification()
    const [exportOperationMutation] = useExportOperationMutation({
        variables: {
            id: operation.id,
            options: {
                participantData: {
                    include: true,
                },
                uploadedAttachments: {
                    include: true,
                    groupBy: 'ATTACHMENT',
                },
                documents: {
                    include: true,
                },
                emails: {
                    include: true,
                },
                templates: {
                    include: true,
                    includeAllVersions: true,
                },
            },
        },
    })

    const [isCooling, setIsCooling] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [form] = Form.useForm<ExportOperationFormValues>()

    const exportOperation = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        const options = form.getFieldsValue()

        await exportOperationMutation({
            variables: {
                id: operation.id,
                options,
            },
        })

        notificationApi.open({
            message: t('exportOperationConfirmed'),
            description: t('exportOperationMessage'),
            duration: 7,
        })

        setShowModal(false)
        setIsCooling(true)
    }, [form, exportOperationMutation, operation.id, notificationApi, t])

    useEffect(() => {
        if (!isCooling) {
            return
        }

        const timer = setTimeout(() => {
            setIsCooling(false)
        }, cooldownPeriod)

        return () => {
            clearTimeout(timer)
        }
    }, [isCooling])

    const disabled = isCooling

    const open = useCallback(() => {
        if (isCooling) {
            return
        }

        setShowModal(true)
    }, [isCooling])

    const cancel = useCallback(() => {
        setShowModal(false)
    }, [])

    const initialValues = useMemo<ExportOperationFormValues>(
        () => ({
            participantData: {
                include: true,
            },
            documents: {
                include: true,
            },
            uploadedAttachments: {
                include: true,
                groupBy: 'PARTICIPANT',
            },
            templates: {
                include: true,
                includeAllVersions: true,
            },
            emails: {
                include: true,
            },
        }),
        []
    )

    return (
        <>
            {notificationContext}
            <ActionButton size="middle" disabled={disabled} inProgress={isCooling} onClick={open}>
                {t('exportOperation')}
            </ActionButton>
            <Modal
                title={t('formTitle')}
                open={showModal}
                onCancel={cancel}
                onOk={exportOperation}
                okText={t('exportOperation')}
            >
                <Form className={styles.form} form={form} initialValues={initialValues}>
                    <Form.Item name={participantDataIncludePath} valuePropName="checked" required>
                        <Checkbox>{t('participantData')}</Checkbox>
                    </Form.Item>
                    <Form.Item name={documentsIncludePath} valuePropName="checked" required>
                        <Checkbox>{t('documents')}</Checkbox>
                    </Form.Item>
                    <Form.Item name={emailsIncludePath} valuePropName="checked" required>
                        <Checkbox>{t('emails')}</Checkbox>
                    </Form.Item>
                    <Divider />
                    <p>{t('templates')}</p>
                    <Form.Item name="templates" required>
                        <ExportOperationTemplatesInput />
                    </Form.Item>

                    <Divider />
                    <p>{t('uploadedAttachments')}</p>
                    <Form.Item name="uploadedAttachments" required>
                        <ExportOperationUploadedAttachmentsInput />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const participantDataIncludePath = ['participantData', 'include']
const documentsIncludePath = ['documents', 'include']
const emailsIncludePath = ['emails', 'include']

type ExportOperationTemplatesInputProps = {
    value?: ExportOperationFormValues['templates'] | undefined
    onChange?: (value?: ExportOperationFormValues['templates']) => void
}

const useExportOperationTemplatesInputTranslations = createUseTranslation({
    FR: {
        includeAllVersions: `Inclure toutes les versions`,
        includeLatest: `Inclure les versions les plus récents`,
        doNotInclude: `Ne pas inclure`,
    },
    EN: {
        includeAllVersions: 'Include all versions',
        includeLatest: 'Include latest versions',
        doNotInclude: 'Do not include',
    },
})

type ExportOperationTemplatesInputValue = 'INCLUDE_ALL' | 'INCLUDE_LATEST' | 'DO_NOT_INCLUDE'

type ExportOperationTemplatesInputType = Omit<DefaultOptionType, 'value'> & {
    value?: ExportOperationTemplatesInputValue
}

const ExportOperationTemplatesInput: FC<ExportOperationTemplatesInputProps> = ({ value, onChange }) => {
    const { t } = useExportOperationTemplatesInputTranslations()

    const options = useMemo(
        (): ExportOperationTemplatesInputType[] => [
            {
                label: t('includeAllVersions'),
                value: 'INCLUDE_ALL',
            },
            {
                label: t('includeLatest'),
                value: 'INCLUDE_LATEST',
            },
            {
                label: t('doNotInclude'),
                value: 'DO_NOT_INCLUDE',
            },
        ],
        [t]
    )

    const selectedValue = useMemo((): ExportOperationTemplatesInputValue => {
        if (value === undefined || !value.include) {
            return 'DO_NOT_INCLUDE'
        }

        return value.includeAllVersions ? 'INCLUDE_ALL' : 'INCLUDE_LATEST'
    }, [value])

    const onSelect = useCallback(
        (value: ExportOperationTemplatesInputValue) => {
            let include: boolean
            let includeAllVersions: boolean

            switch (value) {
                case 'DO_NOT_INCLUDE':
                    include = false
                    includeAllVersions = false
                    break
                case 'INCLUDE_ALL':
                    include = true
                    includeAllVersions = true
                    break
                case 'INCLUDE_LATEST': {
                    include = true
                    includeAllVersions = false
                    break
                }
            }

            onChange?.({
                include,
                includeAllVersions,
            })
        },
        [onChange]
    )

    return (
        <Select<ExportOperationTemplatesInputValue, ExportOperationTemplatesInputType>
            options={options}
            value={selectedValue}
            onChange={onSelect}
        />
    )
}

type ExportOperationUploadedAttachmentsInputProps = {
    value?: ExportOperationFormValues['uploadedAttachments'] | undefined
    onChange?: (value?: ExportOperationFormValues['uploadedAttachments']) => void
}

const useExportOperationUploadedAttachmentsInputTranslations = createUseTranslation({
    FR: {
        includeGroupedByParticipant: `Inclure, groupés par participant`,
        includeGroupedByAttachment: `Inclure, groupés par type`,
        doNotInclude: `Ne pas inclure`,
    },
    EN: {
        includeGroupedByParticipant: 'Include, grouped by participant',
        includeGroupedByAttachment: 'Include, grouped by type',
        doNotInclude: 'Do not include',
    },
})

type ExportOperationUploadedAttachmentsInputValue =
    | 'INCLUDE_GROUPED_BY_ATTACHMENT'
    | 'INCLUDE_GROUPED_BY_PARTICIPANT'
    | 'DO_NOT_INCLUDE'

type ExportOperationUploadedAttachmentsInputType = Omit<DefaultOptionType, 'value'> & {
    value?: ExportOperationUploadedAttachmentsInputValue
}

const ExportOperationUploadedAttachmentsInput: FC<ExportOperationUploadedAttachmentsInputProps> = ({
    value,
    onChange,
}) => {
    const { t } = useExportOperationUploadedAttachmentsInputTranslations()

    const options = useMemo(
        (): ExportOperationUploadedAttachmentsInputType[] => [
            {
                label: t('includeGroupedByParticipant'),
                value: 'INCLUDE_GROUPED_BY_PARTICIPANT',
            },
            {
                label: t('includeGroupedByAttachment'),
                value: 'INCLUDE_GROUPED_BY_ATTACHMENT',
            },
            {
                label: t('doNotInclude'),
                value: 'DO_NOT_INCLUDE',
            },
        ],
        [t]
    )

    const selectedValue = useMemo((): ExportOperationUploadedAttachmentsInputValue => {
        if (value === undefined || !value.include) {
            return 'DO_NOT_INCLUDE'
        }

        return value.groupBy === 'ATTACHMENT' ? 'INCLUDE_GROUPED_BY_ATTACHMENT' : 'INCLUDE_GROUPED_BY_PARTICIPANT'
    }, [value])

    const onSelect = useCallback(
        (value: ExportOperationUploadedAttachmentsInputValue) => {
            let include: boolean
            let groupBy: OperationExportUploadedAttachmentsGroupBy

            switch (value) {
                case 'DO_NOT_INCLUDE':
                    include = false
                    groupBy = 'PARTICIPANT'
                    break
                case 'INCLUDE_GROUPED_BY_PARTICIPANT':
                    include = true
                    groupBy = 'PARTICIPANT'
                    break
                case 'INCLUDE_GROUPED_BY_ATTACHMENT': {
                    include = true
                    groupBy = 'ATTACHMENT'
                    break
                }
            }

            onChange?.({
                include,
                groupBy,
            })
        },
        [onChange]
    )

    return (
        <Select<ExportOperationUploadedAttachmentsInputValue, ExportOperationUploadedAttachmentsInputType>
            options={options}
            value={selectedValue}
            onChange={onSelect}
        />
    )
}
