import { Card } from 'antd'
import { ReactNode, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { operationIsMutable } from '@publica/common'
import { fieldGroupLabel } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'
import { ArrayItem } from '@publica/utils'

import * as graphql from '../../../../../data'
import { FieldControl } from '../FieldControl'
import { FieldGroupTable, FieldGroupTableProps } from '../FieldGroupTable/FieldGroupTable'
import { FieldType } from '../inputs'

type FieldGroupProps = {
    fields: FieldGroupTableProps['fields']
    operation: Pick<graphql.Operation, 'id' | 'status'>
    group: graphql.FieldGroup
    types?: FieldType[]
    requiresFormSection?: boolean
}

const useFieldGroupStyles = createUseStyles({
    footerButton: {
        marginTop: 20,
    },
})

export const FieldGroup: FC<FieldGroupProps> = ({ fields, operation, group, types, requiresFormSection }) => {
    const styles = utils.useControlsStyles()
    const footerStyles = useFieldGroupStyles()

    const filteredFields = useMemo(() => fields.filter(field => field.group === group), [fields, group])
    const showFooterControl = filteredFields.length > 15
    const mutable = operationIsMutable(operation)

    const createFieldControl = useCallback(
        (field?: ArrayItem<FieldGroupTableProps['fields']>): ReactNode => (
            <FieldControl
                requiresFormSection={requiresFormSection}
                operation={operation}
                mutable={mutable}
                group={group}
                field={field}
                types={types}
            />
        ),
        [requiresFormSection, operation, mutable, group, types]
    )

    const bottomFieldControlStyles = useMemo(
        () => [styles.controls, footerStyles.footerButton].join(' '),
        [footerStyles.footerButton, styles.controls]
    )

    return (
        <Card title={fieldGroupLabel(group)}>
            <div className={styles.controls}>{createFieldControl()}</div>
            <FieldGroupTable fields={filteredFields} fieldControlForField={createFieldControl} operation={operation} />
            {showFooterControl ? <div className={bottomFieldControlStyles}>{createFieldControl()}</div> : null}
        </Card>
    )
}
