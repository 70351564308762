import { FormOutlined } from '@ant-design/icons'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, ActionButtonProps } from '@publica/ui-web-components'

import * as graphql from '../../../../../data'
import { FieldType } from '../inputs'
import { fieldFormState } from '../state'
import { FieldForForm } from '../types'

type FieldControlProps = {
    mutable: boolean
    field?: FieldForForm
    operation: Pick<graphql.Operation, 'id'>
    group: graphql.FieldGroup
    types?: FieldType[]
    requiresFormSection?: boolean
}

const useFieldControlTranslation = createUseTranslation({
    EN: {
        add: 'Add a field',
    },
    FR: {
        add: 'Ajouter un champ actif',
    },
})

export const FieldControl: FC<FieldControlProps> = ({ mutable, field, group, types, requiresFormSection = false }) => {
    const setFieldForm = useSetRecoilState(fieldFormState)
    const { t } = useFieldControlTranslation()

    const onClick = useCallback(() => {
        setFieldForm({
            group,
            field: field === undefined ? null : field,
            types,
            requiresFormSection,
        })
    }, [field, group, requiresFormSection, setFieldForm, types])

    const isEdit = field !== undefined

    const buttonText = !isEdit ? t('add') : t('update')
    const icon = !isEdit ? <FormOutlined /> : null
    const size: ActionButtonProps['size'] = !isEdit ? 'middle' : 'small'

    return (
        <ActionButton disabled={!mutable} size={size} onClick={onClick} icon={icon}>
            {buttonText}
        </ActionButton>
    )
}
