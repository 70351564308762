import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { icons } from '..'
import { ActionButton, ActionButtonProps } from '../ActionButton'

type DownloadButtonProps = ActionButtonProps

const useDownloadButtonTranslation = createUseTranslation()

export const DownloadButton: FC<DownloadButtonProps> = ({ children, ...props }) => {
    const { t } = useDownloadButtonTranslation()

    return (
        <ActionButton icon={icons.Download} {...props}>
            {children === undefined ? t('download') : children}
        </ActionButton>
    )
}
