import { Checkbox } from 'antd'
import { useCallback, useMemo } from 'react'

import { LocalizedString } from '@publica/api-graphql'
import { allParticipantsGroupKey } from '@publica/common'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Spinner } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { buildMap } from '@publica/utils'

type Group = {
    id: string
    key: string
    name: LocalizedString
}

type GroupSelectProps = {
    groups: Group[] | undefined
    value?: Group[]
    onChange?: (value: Group[]) => void
    disabled?: boolean
}

const useGroupSelectTranslations = createUseTranslation({
    FR: {
        allParticipants: 'Si vous ne selectionnez aucun groupe, tous les participants sont concernés',
    },
    EN: {
        allParticipants: `If you do not select a group, all participants will be affected`,
    },
})

export const GroupSelect: FC<GroupSelectProps> = ({ groups, ...props }) => {
    if (groups === undefined) {
        return <Spinner />
    }

    return <GroupSelectWithLoadedGroups groups={groups} {...props} />
}

type GroupSelectWithLoadedGroupsProps = Omit<GroupSelectProps, 'groups'> & {
    groups: Group[]
}

const GroupSelectWithLoadedGroups: FC<GroupSelectWithLoadedGroupsProps> = ({ groups, disabled, value, onChange }) => {
    const resolveLocalizedString = useLocalizedStringResolver()
    const styles = utils.useFormStyles()
    const { t } = useGroupSelectTranslations()

    const { allParticipantsGroup, otherGroups } = useMemo(() => {
        let allParticipantsGroup: Group | undefined
        const otherGroups: Group[] = []

        for (const group of groups) {
            if (group.key === allParticipantsGroupKey) {
                allParticipantsGroup = group
            } else {
                otherGroups.push(group)
            }
        }

        if (allParticipantsGroup === undefined) {
            throw new Error(`Expected to find group with key: ${allParticipantsGroup}`)
        }
        return { allParticipantsGroup, otherGroups }
    }, [groups])

    // We memoize this since we'll be returning it as a possible value, and the value
    // needs to be an array
    const allParticipantsGroupAsArray = useMemo(() => [allParticipantsGroup], [allParticipantsGroup])

    const options = useMemo(
        () => otherGroups.map(group => ({ label: resolveLocalizedString(group.name), value: group.key })),
        [otherGroups, resolveLocalizedString]
    )

    const groupsByKey = useMemo(() => buildMap('key', otherGroups), [otherGroups])

    const selectedGroupKeys = useMemo(
        () => (value ?? []).filter(group => group.key !== allParticipantsGroupKey).map(group => group.key),
        [value]
    )

    const onChangeKeys = useCallback<(value: Array<string>) => void>(
        keys => {
            if (keys.length === 0) {
                onChange?.(allParticipantsGroupAsArray)
            } else {
                onChange?.(keys.map(key => groupsByKey[key]!))
            }
        },
        [allParticipantsGroupAsArray, groupsByKey, onChange]
    )

    return (
        <>
            <p className={styles.formLabelNote}>{t('allParticipants')}</p>
            <Checkbox.Group options={options} disabled={disabled} value={selectedGroupKeys} onChange={onChangeKeys} />
        </>
    )
}
