import { Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { useCallback } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { DateFieldRange } from '../../../../../../data'
import { SortedStringSelect } from '../../../../../components'

const dateRangePath = ['parameters', 'range']

const rules: Rule[] = [
    {
        required: true,
        message: 'Vous devez choisir une période',
    },
]

type DateRangeProps = {
    disabled?: boolean
}

const useDateRangeTranslation = createUseTranslation({
    EN: {
        range: 'Range',
        rangeNote: 'You can restrict the date range.',
    },
    FR: {
        range: 'Période',
        rangeNote: 'Vous pouvez restreindre le choix de la date.',
    },
})

export const DateRange: FC<DateRangeProps> = ({ disabled }) => {
    const styles = utils.useFormStyles()
    const { t } = useDateRangeTranslation()

    const labelForDateRange = useLabelForDateRange()

    return (
        <Form.Item label={t('range')} required>
            <div className={styles.formLabelNote}>{t('rangeNote')}</div>
            <Form.Item name={dateRangePath} hasFeedback rules={rules} className={styles.nestedFormItem}>
                <SortedStringSelect disabled={disabled} items={rangeOptions} labelForItem={labelForDateRange} />
            </Form.Item>
        </Form.Item>
    )
}

const rangeOptions: DateFieldRange[] = ['ANY', 'ONLY_FUTURE', 'ONLY_PAST']

const useLabelForDateRangeTranslation = createUseTranslation({
    EN: {
        any: 'No restriction',
        onlyFuture: 'Dates in the future',
        onlyPast: 'Dates in the past',
    },
    FR: {
        any: 'Sans restriction',
        onlyFuture: 'Date dans le futur',
        onlyPast: 'Date dans le passé',
    },
})

const useLabelForDateRange = () => {
    const { t } = useLabelForDateRangeTranslation()

    return useCallback(
        (range: DateFieldRange): string => {
            switch (range) {
                case 'ANY':
                    return t('any')
                case 'ONLY_FUTURE':
                    return t('onlyFuture')
                case 'ONLY_PAST':
                    return t('onlyPast')
            }
        },
        [t]
    )
}
