import type { ParticipantType } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const participantTypes: Record<KnownLocale, Record<ParticipantType, string>> = {
    FR: {
        INDIVIDUAL: 'Personne physique',
        COMPANY: 'Personne morale',
    },
    EN: {
        INDIVIDUAL: 'Individual',
        COMPANY: 'Company',
    },
}

export const participantTypeLookup = new LookupList(participantTypes)
