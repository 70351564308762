import { z } from 'zod'

export const uploadAttachmentRequestSchema = z.object({
    participantId: z.string(),
})

export type UploadAttachmentRequest = z.infer<typeof uploadAttachmentRequestSchema>

export const uploadAttachmentResponseSchema = z.object({
    id: z.string(),
    fileName: z.string(),
})

export type UploadAttachmentResponse = z.infer<typeof uploadAttachmentResponseSchema>
