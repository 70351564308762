import { FC } from '@publica/ui-common-utils'

import { FieldsIndex, FieldsIndexProps } from './FieldsIndex'

type FieldsProps = {
    operation: FieldsIndexProps['operation']
}

export const Fields: FC<FieldsProps> = ({ operation }) => {
    return <FieldsIndex operation={operation} />
}
