// eslint-disable-next-line you-dont-need-lodash-underscore/includes
import includes from 'lodash/includes'

import { normalizeString } from './string'

export const matchesFilter = (input: string | undefined, filter: string): boolean => {
    if (filter === '' || input === undefined) {
        return true
    }

    if (filter.length > input.length) {
        return false
    }

    return includes(normalizeString(input).toLocaleLowerCase(), filter.toLocaleLowerCase())
}
