import { DateTime } from 'luxon'
import { z } from 'zod'
import { jwtDecode } from 'jwt-decode'

const tokenSchema = z
    .object({
        exp: z.number().transform(exp => DateTime.fromSeconds(exp)),
    })
    .transform(payload => ({
        expiresAt: payload.exp,
    }))

type MinimumSchema = typeof tokenSchema

export interface Token {
    isValid: () => boolean
    expiresSoon: () => boolean
    expiresAt: DateTime
    accessToken: string
}

export class Jwt<S extends MinimumSchema = MinimumSchema> implements Token {
    private readonly payload: z.infer<S>

    constructor(public readonly accessToken: string, schema: S) {
        this.payload = schema.parse(jwtDecode(accessToken))
    }

    static withDefaultSchema(accessToken: string) {
        return new Jwt(accessToken, tokenSchema)
    }

    isValid() {
        return this.payload.expiresAt.diffNow('seconds').seconds > 0
    }

    expiresSoon() {
        return this.payload.expiresAt.diffNow('seconds').seconds < 10
    }

    get expiresAt() {
        return this.payload.expiresAt
    }
}
