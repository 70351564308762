import { FC } from '@publica/ui-common-utils'

import {
    DateFieldParameters,
    Field,
    FloatFieldParameters,
    LookupFieldParameters,
    MapFieldParameters,
    TextFieldParameters,
} from '../types'
import {
    DateValueFormItem,
    FloatValueFormItem,
    LookupValueFormItem,
    MapValueFormItem,
    TextValueFormItem,
} from './formItems'

type FieldFormItemProps = {
    field: Field
}

export const FieldFormItem: FC<FieldFormItemProps> = ({ field }) => {
    switch (field.parameters.__typename) {
        case 'TextFieldParameters':
            return <TextValueFormItem field={field as Field<TextFieldParameters>} key={field.key} />
        case 'LookupFieldParameters':
            return <LookupValueFormItem field={field as Field<LookupFieldParameters>} key={field.key} />
        case 'DateFieldParameters':
            return <DateValueFormItem field={field as Field<DateFieldParameters>} key={field.key} />
        case 'FloatFieldParameters':
            return <FloatValueFormItem field={field as Field<FloatFieldParameters>} key={field.key} />
        case 'MapFieldParameters':
            return <MapValueFormItem field={field as Field<MapFieldParameters>} key={field.key} />
    }
}
