import { Form, FormInstance, Input, InputProps } from 'antd'
import IMask from 'imask'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'

import { useCheckOperationCodeLazyQuery, useCheckOperationNameLazyQuery } from '../../../data'

export type OperationFormValues = {
    name: string | undefined
    fromAddress: string | undefined
    code: string | undefined
}

type OperationFormProps = {
    form: FormInstance<OperationFormValues>
    operation?: {
        id: string
        name: string
        code: string
        fromAddress?: string | null
    }
}

const useOperationFormTranslation = createUseTranslation({
    FR: {
        alreadyOperationWithName: `Il existe déjà une opération nommée "{{name}}`,
        alreadyOperationWithCode: `Il existe déjà une opération avec le code "{{code}}"`,
        name: 'Nom',
        code: 'Code',
        operationEmail: "Email associé à l'opération",
        operationEmailNote: `Cette adresse sera utilisée pour envoyer les emails aux participants de l'opération`,
    },
    EN: {
        alreadyOperationWithName: `There is already an operation named "{{name}}`,
        alreadyOperationWithCode: `There is already an operation using the code "{{code}}"`,
        name: 'Name',
        code: 'Code',
        operationEmail: 'Operation email address',
        operationEmailNote: `This address will be used to send emails on behalf of the operation`,
    },
})

export const OperationForm: FC<OperationFormProps> = ({ form, operation }) => {
    const formStyles = utils.useFormStyles()
    const { t } = useOperationFormTranslation()

    const [checkOperationNameQuery] = useCheckOperationNameLazyQuery()
    const [checkOperationCodeQuery] = useCheckOperationCodeLazyQuery()

    const [codeSetManually, setCodeSetManually] = useState(false)

    const rules = useCommonRules()

    const onManualCodeChange = useCallback(() => {
        setCodeSetManually(true)
    }, [])

    const name = Form.useWatch('name', form)

    const codeIsMutable = operation?.id === undefined

    const initialValues = useMemo<OperationFormValues>(
        () => ({
            name: operation?.name,
            code: operation?.code,
            fromAddress: operation?.fromAddress ?? undefined,
        }),
        [operation?.code, operation?.fromAddress, operation?.name]
    )

    const normalizeCode = useCallback((val: string) => {
        // TODO: replace with react hook
        const normal = val.trim().toUpperCase().replaceAll(/\s/g, '')

        const mask = IMask.createMask({
            mask: '[################]',
            definitions: {
                '#': /[A-Z0-9]/,
            },
        })

        mask.resolve(normal)

        return mask.value
    }, [])

    useEffect(() => {
        if (!codeIsMutable) {
            return
        }

        if (name !== undefined && !codeSetManually) {
            form.setFieldsValue({ code: normalizeCode(name) })
        }
    }, [name, form, normalizeCode, codeSetManually, codeIsMutable])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkOperationName = useCallback(
        debounce((name: string, callback: (error?: string) => void): void => {
            void (async () => {
                const { data } = await checkOperationNameQuery({
                    variables: {
                        name,
                    },
                })

                const idOfOperationWithSameName = data?.operationByName?.id

                if (idOfOperationWithSameName !== undefined && idOfOperationWithSameName !== operation?.id) {
                    callback(t('alreadyOperationWithName', { name }))
                    return
                }

                callback()
            })()
        }, 500),
        [checkOperationNameQuery]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkOperationCode = useCallback(
        debounce((code: string, callback: (error?: string) => void): void => {
            void (async () => {
                const { data } = await checkOperationCodeQuery({
                    variables: {
                        code,
                    },
                })

                // We probably don't need this, since the code is immutable, but we'll leave
                // it for completeness
                const idOfOperationWithSameCode = data?.operationByCode?.id

                if (idOfOperationWithSameCode !== undefined && idOfOperationWithSameCode !== operation?.id) {
                    callback(t('alreadyOperationWithCode', { code }))
                    return
                }

                callback()
            })()
        }, 500),
        [checkOperationNameQuery]
    )

    const validationRules = useMemo<FormRules<OperationFormValues>>(
        () => ({
            fromAddress: rules.requiredEmail,
            name: [
                ...rules.required,
                {
                    validator: async (_, name) =>
                        new Promise<void>((resolve, reject) => {
                            checkOperationName(name, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
            code: [
                ...rules.required,
                {
                    validator: async (_, code) =>
                        new Promise<void>((resolve, reject) => {
                            checkOperationCode(code, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
        }),
        [checkOperationCode, checkOperationName, rules.required, rules.requiredEmail]
    )

    return (
        <Form form={form} layout="vertical" initialValues={initialValues}>
            <Form.Item name="name" label={t('name')} hasFeedback validateFirst={true} rules={validationRules.name}>
                <Input />
            </Form.Item>
            <Form.Item
                name="code"
                label={t('code')}
                hasFeedback
                validateFirst={true}
                rules={validationRules.code}
                normalize={normalizeCode}
            >
                <MaybeManualInput onManualChange={onManualCodeChange} disabled={!codeIsMutable} />
            </Form.Item>
            <Form.Item label={t('operationEmail')} required hasFeedback>
                <div className={formStyles.formLabelNote}>{t('operationEmailNote')}</div>
                {/* TODO(admin-ui): add email validation to create/update operation
                 */}
                <Form.Item name="fromAddress" hasFeedback rules={validationRules.fromAddress} noStyle>
                    <Input />
                </Form.Item>
            </Form.Item>
        </Form>
    )
}

type MaybeManualInputProps = InputProps & {
    onManualChange?: (value: string) => void
}

const MaybeManualInput: FC<MaybeManualInputProps> = ({ onChange, onFocus, onBlur, onManualChange, ...props }) => {
    const [focused, setFocused] = useState(false)

    const onLocalFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        ev => {
            setFocused(true)
            if (onFocus !== undefined) {
                onFocus(ev)
            }
        },
        [onFocus]
    )

    const onLocalBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        ev => {
            setFocused(false)
            if (onBlur !== undefined) {
                onBlur(ev)
            }
        },
        [onBlur]
    )

    const onLocalChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        ev => {
            if (focused) {
                if (onManualChange !== undefined) {
                    onManualChange(ev.target.value)
                }
            }

            if (onChange !== undefined) {
                onChange(ev)
            }
        },
        [focused, onChange, onManualChange]
    )

    return <Input onFocus={onLocalFocus} onBlur={onLocalBlur} onChange={onLocalChange} {...props} />
}
