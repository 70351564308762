import { ClockCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { useMemo } from 'react'

import { SignatoryStatus } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'

import { HasStatus, StatusDisplayInfo } from '../Status'
import { useStatuses } from '../statuses'

export type HasSignatoryStatus = HasStatus<SignatoryStatus>

const useSignatoryStatusDisplayInfoTranslation = createUseTranslation({
    EN: {
        awaitingSend_t: 'Awaiting send',
        awaitingSend_one: `{{count}} awaiting send`,
        awaitingSend_other: `{{count}} awaiting send`,
        declined_t: 'Declined ',
        declined_one: `{{count}} declined`,
        declined_other: `{{count}} declined`,
        error_t: 'An error occurred during the signature process',
    },
    FR: {
        awaitingSend_t: "En attente d'envoi",
        awaitingSend_one: `{{count}} en attente d'envoi`,
        awaitingSend_other: `{{count}} en attente d'envoi`,
        declined_t: 'Signature refusé',
        declined_one: `{{count}} refusé`,
        declined_other: `{{count}} refusés`,
        error_t: "Une erreur s'est produite lors de la signature",
    },
})

export const useSignatoryStatusDisplayInfo: () => StatusDisplayInfo<SignatoryStatus> = () => {
    const statuses = useStatuses()
    const { t } = useSignatoryStatusDisplayInfoTranslation()

    return useMemo(
        () => ({
            ...statuses,
            AWAITING_SEND: {
                message: count => t('awaitingSend', { count }),
                tooltip: t('awaitingSend_t'),
                icon: <ClockCircleTwoTone twoToneColor={colors.grey6} />,
            },
            DECLINED: {
                message: count => t('declined', { count }),
                tooltip: t('declined_t'),
                icon: <CloseCircleTwoTone twoToneColor={colors.failure} />,
                color: 'red',
            },
            ERROR: {
                ...statuses.ERROR,
                tooltip: t('error_t'),
            },
        }),
        [statuses, t]
    )
}
