import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, Modal, Switch } from 'antd'
import { Rule } from 'antd/es/form'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useRecoilValue } from 'recoil'

import { type CarbonCopyRecipient } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'
import { FormRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    GetOperationDocumentSetsDocument,
    GetOperationPaginatedDocumentSetsDocument,
    useCreateGroupedEnvelopesFromDocumentSetsMutation,
} from '../../../../../../data'
import { selectedDocumentSetsState } from '../../state'
import { DocumentSet } from '../../types'

type CreateGroupedEnvelopeFromDocumentSetsControlProps = Record<string, never>

const useCreateGroupedEnvelopeFromDocumentSetsControlTranslation = createUseTranslation({
    FR: {
        createGroupedEnvelope: 'Envoyer pour signature groupée',
    },
    EN: {
        createGroupedEnvelope: 'Send for grouped signature',
    },
})

export const CreateGroupedEnvelopeFromDocumentSetsControl: FC<
    CreateGroupedEnvelopeFromDocumentSetsControlProps
> = () => {
    const { t } = useCreateGroupedEnvelopeFromDocumentSetsControlTranslation()
    const [open, setOpen] = useState(false)

    const selectedDocumentSets = useRecoilValue(selectedDocumentSetsState)

    const disabled = useMemo(() => {
        if (selectedDocumentSets.length === 0) {
            return true
        }

        return selectedDocumentSets.some(
            documentSet =>
                documentSet.type !== 'SIGNED_TEMPLATE' ||
                documentSet.documents.some(document => document.status !== 'READY')
        )
    }, [selectedDocumentSets])

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <ActionButton size="middle" disabled={disabled} icon={icons.Publish} onClick={onClick}>
                {t('createGroupedEnvelope')}
            </ActionButton>
            <CreateGroupedEnvelopeFromDocumentSetsForm
                open={open}
                onClose={onClose}
                documentSets={selectedDocumentSets}
            />
        </>
    )
}

type CreateGroupedEnvelopeFromDocumentSetsFormValues = {
    envelopeName?: string
    carbonCopies?: CarbonCopyRecipient[]
    keepAsDraft?: boolean
}

const useCreateGroupedEnvelopesFromDocumentSetsFormTranslation = createUseTranslation({
    FR: {
        envelopeName: `Le nom de l'envelope`,
        title: `Envoi groupé`,
        send: 'Envoyer',
        explanation: `Les documents séléctionnés seront groupés par participant, créant une envelope par participant`,
        envelopeNameRequired: `Vous devez renseigner le nom de l'envelope`,
        keepAsDraft: `Garder en tant que brouillon`,
    },
    EN: {
        envelopeName: `The name of the envelope`,
        title: 'Grouped send',
        send: 'Send',
        explanation: `The selected documents will be grouped together by participant, creating one envelope per participant.`,
        envelopeNameRequired: `The envelope name is required`,
        keepAsDraft: 'Keep as a draft envelope',
    },
})

const useCreateGroupedEnvelopesFromDocumentSetsFormStyles = createUseStyles({
    explanation: {
        marginTop: 15,
    },
})

type CreateGroupedEnvelopeFromDocumentSetsFormProps = {
    open: boolean
    onClose: () => void
    documentSets: DocumentSet[]
}

const CreateGroupedEnvelopeFromDocumentSetsForm: FC<CreateGroupedEnvelopeFromDocumentSetsFormProps> = ({
    open,
    onClose,
    documentSets,
}) => {
    const [form] = Form.useForm<CreateGroupedEnvelopeFromDocumentSetsFormValues>()
    const styles = useCreateGroupedEnvelopesFromDocumentSetsFormStyles()
    const [createGroupedEnvelopesFromDocumentSets] = useCreateGroupedEnvelopesFromDocumentSetsMutation()

    const { t } = useCreateGroupedEnvelopesFromDocumentSetsFormTranslation()

    const formRules = useMemo<FormRules<CreateGroupedEnvelopeFromDocumentSetsFormValues>>(() => {
        return {
            envelopeName: [
                {
                    required: true,
                    message: t('envelopeNameRequired'),
                },
            ],
        }
    }, [t])

    const close = useCallback(() => {
        form.resetFields()
        onClose()
    }, [form, onClose])

    const create = useCallback(async () => {
        const { envelopeName, carbonCopies, keepAsDraft } = form.getFieldsValue()

        assert.defined(envelopeName)

        await createGroupedEnvelopesFromDocumentSets({
            variables: {
                ids: documentSets.map(documentSet => documentSet.id),
                envelopeName,
                options: {
                    carbonCopies,
                    keepAsDraft,
                },
            },
            refetchQueries: [GetOperationPaginatedDocumentSetsDocument, GetOperationDocumentSetsDocument],
        })

        close()
    }, [close, createGroupedEnvelopesFromDocumentSets, documentSets, form])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        await create()
    }, [create, form])

    return (
        <Modal open={open} onCancel={close} title={t('title')} okText={t('send')} onOk={onOk} destroyOnClose>
            <p className={styles.explanation}>{t('explanation')}</p>
            <Divider />
            <Form form={form} layout="vertical">
                <Form.Item
                    label={t('envelopeName')}
                    name="envelopeName"
                    hasFeedback
                    required
                    rules={formRules.envelopeName}
                >
                    <Input />
                </Form.Item>
                <Form.Item label={t('keepAsDraft')} required>
                    {/* We have to double-wrap the Form.Items to ensure that the `name` attribute maps onto the input */}
                    <Form.Item name="keepAsDraft" valuePropName="checked" noStyle>
                        <Switch />
                    </Form.Item>
                </Form.Item>
                <Divider />
                <Form.Item name="carbonCopies" hasFeedback required>
                    <CarbonCopyRecipients />
                </Form.Item>
            </Form>
            <Divider />
        </Modal>
    )
}

type CarbonCopyRecipientsProps = {
    value?: CarbonCopyRecipient[] | undefined
    onChange?: (value: CarbonCopyRecipient[] | undefined) => void
}

const useCarbonCopyRecipientsStyles = createUseStyles({
    none: {
        fontStyle: 'italic',
        color: colors.grey6,
    },
    add: {
        marginTop: 5,
        textAlign: 'center',
    },
})

const useCarbonCopyRecipientsTranslation = createUseTranslation({
    FR: {
        add: 'Ajouter destinataire en copie',
    },
    EN: {
        add: 'Add carbon copy recipient',
    },
})

const CarbonCopyRecipients: FC<CarbonCopyRecipientsProps> = ({ value = [], onChange }) => {
    const { t } = useCarbonCopyRecipientsTranslation()
    const styles = useCarbonCopyRecipientsStyles()
    const addCarbonCopyRecipient = useCallback(() => {
        onChange?.([...value, { name: '', email: '' }])
    }, [onChange, value])

    const removeCarbonCopyRecipient = useCallback(
        (index: number) => {
            onChange?.(value.filter((_, idx) => idx !== index))
        },
        [onChange, value]
    )

    return (
        <>
            {value.map((carbonCopyRecipient, index) => (
                <CarbonCopyRecipient
                    key={index}
                    index={index}
                    value={carbonCopyRecipient}
                    // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                    onRemove={() => removeCarbonCopyRecipient(index)}
                />
            ))}
            <div className={styles.add}>
                <Button onClick={addCarbonCopyRecipient} tabIndex={-1}>
                    {t('add')}
                </Button>
            </div>
        </>
    )
}

type CarbonCopyRecipientProps = {
    index: number
    value: CarbonCopyRecipient
    onRemove: () => void
}

const useCarbonCopyRecipientTranslation = createUseTranslation({
    EN: {
        carbonCopyRecipient: 'Carbon Copy recipient {{index}}',
        name: 'Name',
        email: 'Email',
        requiredName: `You must provide a name`,
        requiredEmail: `You must provide an email`,
        invalidEmail: `The email address is invalid`,
    },
    FR: {
        carbonCopyRecipient: 'Destinataire en copie {{index}}',
        name: 'Nom',
        email: 'Email',
        requiredName: 'Vous devez renseigner un nom',
        requiredEmail: "Vous devez renseigner l'email",
        invalidEmail: `L'adresse email est invalide`,
    },
})

const useCarbonCopyRecipientStyles = createUseStyles({
    input: {
        marginBottom: 5,
    },
    inputIcon: {
        marginRight: 5,
        color: colors.grey6,
    },
    recipient: {
        marginBottom: 15,
    },
    recipientForm: {
        display: 'flex',
    },
    recipientFormDetails: {
        flexGrow: 20,
    },
    recipientFormRemove: {
        display: 'flex',
        justifyContent: 'end',
        flexGrow: 1,
    },
    removeButton: {
        width: 30,
    },
})

const CarbonCopyRecipient: FC<CarbonCopyRecipientProps> = ({ value, index, onRemove }) => {
    const styles = useCarbonCopyRecipientStyles()
    const { t } = useCarbonCopyRecipientTranslation()

    const userIcon = useMemo(() => <UserOutlined className={styles.inputIcon} />, [styles.inputIcon])
    const mailIcon = useMemo(() => <MailOutlined className={styles.inputIcon} />, [styles.inputIcon])

    const namePath = useMemo(() => ['carbonCopies', index, 'name'], [index])
    const emailPath = useMemo(() => ['carbonCopies', index, 'email'], [index])

    const nameRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('requiredName'),
            },
        ],
        [t]
    )

    const emailRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('requiredEmail'),
            },
            {
                type: 'email',
                message: t('invalidEmail'),
                validateTrigger: 'onBlur',
            },
        ],
        [t]
    )

    return (
        <Form.Item
            label={t('carbonCopyRecipient', { index: index + 1 })}
            required
            className={[styles.input, styles.recipient].join(' ')}
        >
            <div className={styles.recipientForm}>
                <div className={styles.recipientFormDetails}>
                    <Form.Item name={namePath} hasFeedback rules={nameRules} className={styles.input}>
                        <Input prefix={userIcon} placeholder={t('name')} value={value.name} />
                    </Form.Item>
                    <Form.Item
                        name={emailPath}
                        hasFeedback
                        rules={emailRules}
                        className={styles.input}
                        validateTrigger={emailValidateTrigger}
                    >
                        <Input prefix={mailIcon} placeholder={t('email')} value={value.email} />
                    </Form.Item>
                </div>
                <div className={styles.recipientFormRemove}>
                    <Button className={styles.removeButton} tabIndex={-1} onClick={onRemove}>
                        -
                    </Button>
                </div>
            </div>
        </Form.Item>
    )
}

const emailValidateTrigger = ['onChange', 'onBlur']
