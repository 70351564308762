import { FC } from '@publica/ui-common-utils'

import { DocumentSetControls } from './DocumentSetControls'
import { DocumentSetTable } from './DocumentSetTable'
import { Operation } from './types'

export type DocumentsIndexProps = {
    operation: Operation
}

export const DocumentsIndex: FC<DocumentsIndexProps> = ({ operation }) => {
    return (
        <>
            <DocumentSetControls operation={operation} />
            <DocumentSetTable operation={operation} />
        </>
    )
}
