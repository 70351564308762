import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { DownloadOperationExport } from './DownloadOperationExport'
import { OperationIndex } from './OperationIndex'
import { ShowOperation } from './ShowOperation'

const operationIndex = <OperationIndex />
const showOperation = <ShowOperation />
const downloadOperationExport = <DownloadOperationExport />

export const Operations: FC = () => (
    <Routes>
        <Route index element={operationIndex} />
        <Route path=":operationId/*" element={showOperation} />
        <Route path="exports/:operationExportId" element={downloadOperationExport} />
    </Routes>
)
