import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'

import { inBrowser } from './env'

const createFingerprint = () => {
    if (!inBrowser) {
        return {}
    }

    const instanceId = uuid()

    return {
        [clientIdKey]: getClientId(),
        [instanceIdKey]: instanceId,
        userAgent: navigator.userAgent,
    }
}

export const clientIdKey = 'clientId'
export const instanceIdKey = 'instanceId'

const getClientId = (): string => {
    let clientId = Cookies.get(clientIdKey)
    if (clientId === undefined) {
        clientId = uuid()
        Cookies.set(clientIdKey, clientId, { expires: 365 * 10 })
    }
    return clientId
}

export const fingerprint = createFingerprint()
