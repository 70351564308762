import { DateTime } from 'luxon'

// TODO(logger): convert to union type
export enum LogLevel {
    DEBUG,
    INFO,
    WARN,
    ERROR,
}

export const levelNames = {
    [LogLevel.DEBUG]: 'debug',
    [LogLevel.ERROR]: 'error',
    [LogLevel.INFO]: 'info',
    [LogLevel.WARN]: 'warn',
} as const

export type LogLevelNames = typeof levelNames
export type LogLevelName = LogLevelNames[keyof LogLevelNames]

export type LogLabelValue = string | boolean | null | number | undefined
export type LogLabels = Record<string, LogLabelValue>

export type LogTraceInfo = {
    traceId: string
    spanId: string
}

export type LogContext = {
    labels?: LogLabels
    trace?: LogTraceInfo
    error?: unknown
    payload?: Record<string, unknown>
}

export type LogRecord = {
    name: string
    component: string | undefined
    message: string
    level: LogLevel
    levelName: LogLevelName
    timestamp: DateTime
    context: LogContext
}

export interface LogTransport {
    log(record: LogRecord): void
}
