import { Tag } from 'antd'
import isNil from 'lodash/isNil'
import { ReactNode } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { TagField } from '@publica/ui-web-components'
import { lang } from '@publica/utils'

import { Participant } from '../../types'

type ParticipantStatusTagsProps = {
    participant: Participant
}

const useParticipantStatusTagsTranslation = createUseTranslation({
    EN: {
        missingPersonalInformationValues: 'Some personal information is missing',
        missingAttachments: 'Some KYC documents are missing',
        pendingSignatures: 'There are documents awaiting signature',
        pendingInvitation: 'Invitation not yet sent',
        missingBankingValues: 'Some banking information is missing',
        noNotifications: 'No pending actions',
    },
    FR: {
        missingPersonalInformationValues: "La fiche d'informations personnelles est incomplète",
        missingBankingValues: `La fiche d'informations bancaires est incomplète`,
        missingAttachments: 'Des documents KYC sont manquants',
        pendingSignatures: 'Des documents sont en attente de signature',
        pendingInvitation: "Pas encore invité à l'opération",
        noNotifications: 'Aucune action en attente',
    },
})

export const ParticipantStatusTags: FC<ParticipantStatusTagsProps> = ({ participant }) => {
    const { t } = useParticipantStatusTagsTranslation()

    const pendingInvitation = isNil(participant.lastInvitationSentAt)

    if (pendingInvitation) {
        return (
            <TagField>
                <Tag key="pendingInvitation">{t('pendingInvitation')}</Tag>
            </TagField>
        )
    }

    const tags: ReactNode[] = []

    let hasMissingPersonalInformationValues = false
    let hasMissingBankingValues = false
    let hasMissingAttachments = false
    let hasPendingSignatures = false

    for (const notification of participant.notifications) {
        if (
            hasPendingSignatures &&
            hasMissingAttachments &&
            hasMissingBankingValues &&
            hasMissingPersonalInformationValues
        ) {
            break
        }

        switch (notification.__typename) {
            case 'MissingValuesNotification':
                switch (notification.group) {
                    case 'PERSONAL_INFORMATION':
                        hasMissingPersonalInformationValues = true
                        break
                    case 'BANKING_INFORMATION':
                        hasMissingBankingValues = true
                        break
                }
                break

            case 'MissingAttachmentsNotification':
                hasMissingAttachments = true
                break

            case 'RequiredSignaturesNotification':
                hasPendingSignatures = true
                break

            default:
                throw lang.NotExhaustedError(notification)
        }
    }

    if (hasMissingPersonalInformationValues) {
        tags.push(
            <Tag key="missingPersonalInformationValues" color="warning">
                {t('missingPersonalInformationValues')}
            </Tag>
        )
    }

    if (hasMissingBankingValues) {
        tags.push(
            <Tag key="hasMissingBankingValues" color="warning">
                {t('missingBankingValues')}
            </Tag>
        )
    }

    if (hasMissingAttachments) {
        tags.push(
            <Tag key="missingAttachments" color="warning">
                {t('missingAttachments')}
            </Tag>
        )
    }

    if (hasPendingSignatures) {
        tags.push(
            <Tag key="pendingSignatures" color="warning">
                {t('pendingSignatures')}
            </Tag>
        )
    }

    if (tags.length === 0) {
        tags.push(
            <Tag key="noNotifications" color="success">
                {t('noNotifications')}
            </Tag>
        )
    }

    return <TagField>{tags}</TagField>
}
