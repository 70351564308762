import { Input } from 'antd'
import { Rule } from 'antd/lib/form'
import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { useCommonRules } from '@publica/ui-web-utils'

import { Field } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules as useCommonFormRules } from './rules'

type EmailValueFormItemProps = {
    field: Field
}

export const EmailValueFormItem: FC<EmailValueFormItemProps> = ({ field }) => {
    const { email } = useCommonRules()
    const commonRules = useCommonFormRules()

    const emailValueRules = useMemo<Rule[]>(() => [...email, ...commonRules], [commonRules, email])

    return (
        <FormItem field={field} rules={emailValueRules}>
            <Input />
        </FormItem>
    )
}
