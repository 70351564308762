import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const ledgerTransactionTypeSchema = z.enum([
    'SUBSCRIPTION_CASH',
    'SUBSCRIPTION_EQUITY',
    'EMISSION',
    'TRANSFER_CASH',
    'TRANSFER_EQUITY',
    'CANCELLATION',
    'DONATION_FULL_OWNERSHIP',
    'DONATION_DISMEMBERMENT',
    'PLEDGE',
    'PLEDGE_RELEASE',
    'RECONSTITUTION',
    'CONVERSION',
])

export type LedgerTransactionType = z.infer<typeof ledgerTransactionTypeSchema>

const ledgerTransactionTypes: Record<KnownLocale, Record<LedgerTransactionType, string>> = {
    FR: {
        SUBSCRIPTION_CASH: 'Souscription en numéraire',
        SUBSCRIPTION_EQUITY: 'Souscription en apport de titres',
        EMISSION: `Emission d'actions gratuites`,
        TRANSFER_CASH: 'Transfert en numéraire',
        TRANSFER_EQUITY: 'Transfer en apport de titres',
        DONATION_FULL_OWNERSHIP: 'Donation de pleine propriété',
        DONATION_DISMEMBERMENT: 'Donation avec démembrement',
        PLEDGE: 'Nantissement',
        PLEDGE_RELEASE: `Main levée de nantissement`,
        CANCELLATION: 'Annulation',
        RECONSTITUTION: 'Reconstitution de titres démembrés',
        CONVERSION: 'Conversion',
    },
    EN: {
        SUBSCRIPTION_CASH: 'Cash subscription',
        SUBSCRIPTION_EQUITY: 'Equity subscription',
        EMISSION: 'Emission of free shares',
        TRANSFER_CASH: 'Cash transfer',
        TRANSFER_EQUITY: 'Equity transfer',
        DONATION_FULL_OWNERSHIP: 'Donation of full ownership',
        DONATION_DISMEMBERMENT: 'Donation with dismemberment',
        PLEDGE: 'Pledge',
        PLEDGE_RELEASE: `Pledge release`,
        CANCELLATION: 'Cancellation',
        RECONSTITUTION: 'Reconstitution of dismembered shares',
        CONVERSION: 'Conversion',
    },
}

export const ledgerTransactionTypeLookup = new LookupList(ledgerTransactionTypes)
