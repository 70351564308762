import { ButtonProps } from 'antd'
import { ColumnType } from 'antd/lib/table'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import * as graphql from '@publica/api-graphql'
import { operationStatusLookup } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { filterItemsFromLookup } from '@publica/ui-web-utils'

import { OperationStatusTag, icons } from '..'
import { FilterColumnType, FilterTable } from '../FilterTable'
import { LinkButton } from '../LinkButton'

type Operation = Pick<graphql.Operation, 'id' | 'code' | 'status' | 'name'>

export type OperationTableProps = {
    loading?: boolean
    operations: Operation[]
}

const statusOrder: graphql.OperationStatus[] = ['OPEN', 'DRAFT', 'CLOSED']

const useOperationTableTranslation = createUseTranslation({
    FR: {
        code: 'Code',
        state: 'État',
        actions: 'Actions',
        view: 'Consulter',
    },
    EN: {
        code: 'Code',
        state: 'State',
        actions: 'Actions',
        view: 'View',
    },
})

export const OperationTable: FC<OperationTableProps> = ({ loading = false, operations }) => {
    const sortedOperations = useMemo(
        () => sortBy(operations, op => [statusOrder.indexOf(op.status), op.name]),
        [operations]
    )
    const { t } = useOperationTableTranslation()

    const columns = useMemo<(FilterColumnType<Operation> | ColumnType<Operation>)[]>(
        () => [
            {
                title: t('code'),
                align: 'left',
                width: 100,
                render: (_, operation) => <OperationGoTo {...operation} title={operation.code} />,
                filterValue: operation => operation.code,
            },
            {
                title: t('operation'),
                render: (_, operation) => <OperationGoTo {...operation} />,
                filterValue: operation => operation.name,
            },
            {
                title: t('state'),
                align: 'center',
                width: 150,
                render: (_, operation) => <OperationStatusTag {...operation} />,
                filters: filterItemsFromLookup(operationStatusLookup),
                onFilter: (value, { status }) => value === status,
            },
            {
                title: t('actions'),
                align: 'center',
                width: 180,
                render: (_, operation) => <OperationGoTo {...operation} title={t('view')} type="primary" />,
            },
        ],
        [t]
    )

    return <FilterTable<Operation> dataSource={sortedOperations} rowKey="id" loading={loading} columns={columns} />
}

type OperationGoToProps = {
    id: string
    name: string
    title?: string
    type?: ButtonProps['type']
}

const OperationGoTo: FC<OperationGoToProps> = ({ id, name, title, type }) => (
    <LinkButton to={id} type={type ?? 'link'} icon={type === 'primary' ? icons.View : undefined}>
        {title ?? name}
    </LinkButton>
)
