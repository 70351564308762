import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const languages: Record<KnownLocale, Record<KnownLocale, string>> = {
    FR: {
        FR: 'Français',
        EN: 'Anglais',
    },
    EN: {
        FR: 'French',
        EN: 'English',
    },
}

export const languagesLookup = new LookupList(languages)
