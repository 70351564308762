import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    FormInstance,
    Input,
    Modal,
    Row,
    Space,
    Switch,
    Upload,
    UploadProps,
} from 'antd'
import { Rule } from 'antd/lib/form'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import times from 'lodash/times'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { DocumentSignatory } from '@publica/common'
import { documentCategoryLookup } from '@publica/lookups'
import { GraphQLValue } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { useApiClient } from '@publica/ui-common-network'
import { colors } from '@publica/ui-common-styles'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import {
    ActionButton,
    LinkButton,
    LookupSelect,
    NotFound,
    PhoneInput,
    SortableList,
    Spinner,
    icons,
    usePhoneValidationRules,
} from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'
import { sizes, utils } from '@publica/ui-web-styles'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    DocumentCategory,
    DocumentFileFormat,
    GetOperationDocumentSetsDocument,
    GetOperationPaginatedDocumentSetsDocument,
    GetOperationParticipantsAndTemplatesDocument,
    TemplateDocumentSetCreateSignatoriesInput,
    useCreateTemplateDocumentSetMutation,
    useGetOperationParticipantsAndTemplatesQuery,
} from '../../../../data'
import * as graphql from '../../../../data'
import {
    ParticipantRequirement,
    ParticipantSelect,
    SelectedTemplate,
    TemplateSelect,
    createFieldRequirement,
    useParticipantSelectRules,
    useTemplateSelectRules,
} from '../../../components'

// FIXME(new-document): docusign email subject length
// FIXME(signatory-order): drag and drop bug

type Operation = Pick<graphql.Operation, 'id'>

export type NewDocumentSetProps = {
    operation: Operation
}

type Participant = {
    id: string
    info: {
        name: string
        code: string
    }
    groups: Pick<graphql.Group, 'key' | 'name'>[]
    values: GraphQLValue[]
    legalEntity: {
        representative: {
            phoneNumber?: string | null
        }
    }
}

type ExternalSignatory = Omit<DocumentSignatory, 'order'> & {
    type: 'external'
}

type ParticipantSignatory = {
    type: 'participant'
    index: 0
}

type PowerOfAttorneySignatory = Omit<ExternalSignatory, 'type' | 'index'> & {
    type: 'powerOfAttorney'
    index: 0
}

type Signatory = ParticipantSignatory | ExternalSignatory | PowerOfAttorneySignatory
type SortableSignatory = Signatory & { order: number }

type DocumentSignatureInfo = {
    requiresSMSAuthentication: boolean
    withPowerOfAttorney: boolean
    hasSigningOrder: boolean
    signatories: Record<string, SortableSignatory>
}

type SelectedTemplateWithFields = SelectedTemplate & {
    latestVersion: {
        fields: { key: string }[]
    }
}

type FormValues = {
    template: SelectedTemplateWithFields | undefined
    pdf: boolean
    participants: Participant[]
    name: string | undefined
    categories: DocumentCategory[]
    signatureInfo: DocumentSignatureInfo | undefined
}

const useCreateDocumentSetStyles = createUseStyles({
    note: {
        paddingBottom: 0,
    },
})

const useCreateDocumentSet = (form: FormInstance<FormValues>, operation: Operation) => {
    const [createTemplateDocumentSetMutation, { loading }] = useCreateTemplateDocumentSetMutation()

    const createDocumentSet = useCallback(async () => {
        const { template, participants, name, pdf, categories, signatureInfo } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(template)
        assert.defined(participants)

        const requiresSignature = template.latestVersion.signatureCount > 0

        const forcedPDF = requiresSignature || template.type === 'STATIC'
        const requiresSMSAuthentication = signatureInfo?.requiresSMSAuthentication

        let signatories: TemplateDocumentSetCreateSignatoriesInput | undefined

        if (signatureInfo !== undefined) {
            signatories = {
                externalSignatories: [],
            }

            for (const signatory of Object.values(signatureInfo.signatories)) {
                const order = signatureInfo.hasSigningOrder ? signatory.order : undefined
                if (signatory.type === 'participant') {
                    signatories.participant = {
                        order,
                        requiresSMSAuthentication,
                    }
                } else {
                    const { email, name } = signatory

                    const smsAuthentication: graphql.DocumentSignatorySmsAuthenticationInput | undefined =
                        signatory.smsAuthentication !== undefined
                            ? {
                                  prefix: signatory.smsAuthentication.prefix,
                                  number: signatory.smsAuthentication.number,
                              }
                            : undefined

                    if (signatory.type === 'powerOfAttorney') {
                        signatories.participant = {
                            order,
                            requiresSMSAuthentication,
                            powerOfAttorney: {
                                name,
                                email,
                                smsAuthentication,
                            },
                        }
                    } else {
                        signatories.externalSignatories!.push({
                            name,
                            email,
                            index: signatory.index,
                            order,
                            smsAuthentication,
                        })
                    }
                }
            }
        }

        // We force the format to PDF for documents that are going to be signed
        const format: DocumentFileFormat = pdf || forcedPDF ? 'PDF' : 'WORD'
        const participantIds = participants.map(p => p.id)
        const templateVersionId = template.latestVersion.id

        await createTemplateDocumentSetMutation({
            variables: {
                operationId: operation.id,
                participantIds,
                templateDocumentSet: {
                    name,
                    format,
                    categories: categories ?? [],
                    templateVersionId,
                    signatories,
                },
            },
            refetchQueries: [GetOperationDocumentSetsDocument, GetOperationPaginatedDocumentSetsDocument],
        })
    }, [createTemplateDocumentSetMutation, form, operation.id])

    return { createDocumentSet, creating: loading } as const
}

const useSignatureLabelTranslation = createUseTranslation({
    EN: {
        sentToTheParticipant: 'The document will be sent to be signed by the participant',
        sentToThePowerOfAttorney: 'The document will be sent to the person with power of attorney',
        sentToTheParticipantAndExternalSignatories_one:
            'The document will be sent to be signed by the participant and the external signatory',
        sentToTheParticipantAndExternalSignatories_other:
            'The document will be sent to be signed by the participant and the external signatories',
        sentToThePowerOfAttorneyAndExternalSignatories_one:
            'The document will be sent to be signed by the person with power of attorney and the external signatory',
        sentToThePowerOfAttorneyAndExternalSignatories_other:
            'The document will be sent to be signed by the person with power of attorney and the external signatories',
    },
    FR: {
        sentToTheParticipant: 'Le document sera envoyé pour signature au participant',
        sentToThePowerOfAttorney: 'Le documents sera envoyé à la personne ayant le pouvoir de signature',
        sentToTheParticipantAndExternalSignatories_one:
            'Le document sera envoyé pour signature au participant et au signataire externe',
        sentToTheParticipantAndExternalSignatories_other:
            'Le document sera envoyé pour signature au participant et aux signataires externes',
        sentToThePowerOfAttorneyAndExternalSignatories_one:
            'Le document sera envoyé pour signature à la personne ayant le pouvoir de signature et au signataire externe',
        sentToThePowerOfAttorneyAndExternalSignatories_other:
            'Le document sera envoyé pour signature à la personne ayant le pouvoir de signature et aux signataires externes',
    },
})

const useSignatureLabel = (requiredSignatoryCount: number, withPowerOfAttorney: boolean) => {
    const { t } = useSignatureLabelTranslation()

    return useMemo(() => {
        if (requiredSignatoryCount === 1) {
            return withPowerOfAttorney ? t('sentToThePowerOfAttorney') : t('sentToTheParticipant')
        } else if (withPowerOfAttorney) {
            return t('sentToThePowerOfAttorneyAndExternalSignatories', { count: requiredSignatoryCount - 1 })
        } else {
            return t('sentToTheParticipantAndExternalSignatories', { count: requiredSignatoryCount - 1 })
        }
    }, [requiredSignatoryCount, t, withPowerOfAttorney])
}

const initialValues: FormValues = {
    template: undefined,
    pdf: false,
    name: undefined,
    categories: [],
    signatureInfo: undefined,
    participants: [],
}

const useNewDocumentSetTranslation = createUseTranslation({
    EN: {
        participants: 'Participants',
        unknownOperation: 'Unknown operation',
        generateADocument: 'Generate a document',
        template: 'Template',
        name: 'Name',
        sendAsPdf: 'Send as PDF',
        categories: 'Categories',
        signatories: 'Signatories',
        dyamicTemplateSelected:
            'You have selected a dynamic template. The values provided by the participants will be used to personalise each document',
        staticTemplateSelected:
            'You have selected a static template. All participants will receive a copy of the same document.',
        generate: 'Generate',
    },
    FR: {
        participants: 'Participants',
        unknownOperation: 'Opération inconnue',
        generateADocument: 'Générer un document',
        template: 'Modèle',
        name: 'Nom',
        sendAsPdf: 'Envoyer en PDF',
        categories: 'Rubriques',
        signatories: 'Signataires',
        dyamicTemplateSelected:
            'Vous avez choisi un modèle dynamique. Les informations fournies par les participants seront utilisées pour personnaliser chaque document.',
        staticTemplateSelected:
            'Vous avez choisi un modèle statique. Tous les participants recevront le même document.',
        generate: 'Générer',
    },
})

export const NewDocumentSet: FC<NewDocumentSetProps> = props => {
    const navigate = useNavigate()
    const { t } = useNewDocumentSetTranslation()

    const { loading, data } = useGetOperationParticipantsAndTemplatesQuery({
        variables: { operationId: props.operation.id },
        pollInterval: usePollingRate(),
    })

    const [form] = Form.useForm<FormValues>()
    const template = Form.useWatch('template', form)

    const signatureInfo = Form.useWatch('signatureInfo', form)

    const { createDocumentSet, creating } = useCreateDocumentSet(form, props.operation)
    const styles = utils.useFormStyles()

    const requiredSignatoryCount = template?.latestVersion.signatureCount ?? 0
    const requiresSignatories = requiredSignatoryCount > 0

    const signatureLabel = useSignatureLabel(requiredSignatoryCount, signatureInfo?.withPowerOfAttorney ?? false)

    const rules = useCommonRules()
    const participantSelectRules = useParticipantSelectRules()
    const templateSelectRules = useTemplateSelectRules()

    const allowFormatSelection = !requiresSignatories && !(template?.type === 'STATIC')

    const templateTextStyles = useCreateDocumentSetStyles()

    const templateText = useMemo(() => {
        switch (template?.type) {
            case 'DYNAMIC':
                return t('dyamicTemplateSelected')
            case 'STATIC':
                return t('staticTemplateSelected')
            default:
                return undefined
        }
    }, [template?.type, t])

    const onCreate = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await createDocumentSet().then(() => {
            navigate('..')
        })
    }, [createDocumentSet, form, navigate])

    const validationRules = useMemo<FormRules<FormValues>>(
        () => ({
            template: templateSelectRules,
            participants: participantSelectRules,
            name: rules.required,
        }),
        [participantSelectRules, rules.required, templateSelectRules]
    )

    const participantRequirements = useMemo(() => {
        const requirements: ParticipantRequirement<Participant>[] = []

        if (signatureInfo?.requiresSMSAuthentication && !signatureInfo.withPowerOfAttorney) {
            requirements.push(participant => !isNil(participant.legalEntity.representative.phoneNumber))
        }

        if (template?.latestVersion.fields !== undefined) {
            requirements.push(createFieldRequirement(template?.latestVersion.fields))
        }

        return requirements
    }, [signatureInfo?.requiresSMSAuthentication, signatureInfo?.withPowerOfAttorney, template?.latestVersion.fields])

    if (loading || data === undefined) {
        return <Spinner />
    }

    const { operation } = data

    // FIXME: use error boundary
    if (isNil(operation)) {
        return <NotFound title={t('unknownOperation')} path=".." card />
    }

    const { templates, participants } = operation

    return (
        <Card title={t('generateADocument')}>
            <Form.Provider>
                <Form layout="vertical" form={form} initialValues={initialValues}>
                    <Row gutter={sizes.gutter}>
                        <Col span={8}>
                            <Form.Item
                                name="template"
                                label={t('template')}
                                hasFeedback
                                rules={validationRules.template}
                            >
                                <TemplateSelect<SelectedTemplateWithFields>
                                    templateTarget="DOCUMENT"
                                    templates={templates}
                                />
                            </Form.Item>

                            <ManageStaticTemplates operation={operation} templates={templates} />

                            {templateText === undefined ? null : (
                                <div className={[styles.formLabelNote, templateTextStyles.note].join(' ')}>
                                    {templateText}
                                </div>
                            )}

                            <Divider />

                            <Form.Item name="name" label={t('name')} hasFeedback rules={validationRules.name}>
                                <Input />
                            </Form.Item>

                            {/* If the document needs to be signed, it has to be a PDF */}
                            {!allowFormatSelection ? null : (
                                <Form.Item label={t('sendAsPdf')} required>
                                    {/* We have to double-wrap the Form.Items to ensure that the `name` attribute maps onto the input */}
                                    <Form.Item name="pdf" valuePropName="checked" noStyle>
                                        <Switch />
                                    </Form.Item>
                                </Form.Item>
                            )}

                            <Form.Item name="categories" label={t('categories')}>
                                <LookupSelect lookup={documentCategoryLookup} allowClear mode="multiple" />
                            </Form.Item>

                            {requiresSignatories ? (
                                <>
                                    <Divider />
                                    <Form.Item label={t('signatories')} required>
                                        <div className={styles.formLabelNote}>{signatureLabel}</div>
                                        <Form.Item name="signatureInfo" noStyle>
                                            <DocumentSetSignatories requiredSignatoryCount={requiredSignatoryCount} />
                                        </Form.Item>
                                    </Form.Item>
                                    <Divider />
                                </>
                            ) : null}
                        </Col>

                        <Col span={1}>
                            <Divider type="vertical" style={verticalDividerStyle} />
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label={t('participants')}
                                name="participants"
                                hasFeedback
                                validateFirst={true}
                                rules={validationRules.participants}
                            >
                                <ParticipantSelect<Participant>
                                    participants={participants}
                                    fields={operation.fields}
                                    groups={operation.groups}
                                    requirements={participantRequirements}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row justify="center">
                        <Col>
                            <Form.Item>
                                <Space direction="horizontal">
                                    <LinkButton to=".." disabled={creating}>
                                        {t('cancel')}
                                    </LinkButton>
                                    <ActionButton size="middle" inProgress={creating} onClick={onCreate}>
                                        {t('generate')}
                                    </ActionButton>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Form.Provider>
        </Card>
    )
}

const verticalDividerStyle = { height: '100%' }

type DocumentSetSignatoriesProps = {
    value?: DocumentSignatureInfo
    onChange?: (value: DocumentSignatureInfo | undefined) => void
    requiredSignatoryCount: number
}

const useDocumentSetSignatoriesStyles = createUseStyles({
    signatureOption: {
        marginTop: 20,
    },
})

const keyForSignatory = (signatory: Signatory) => {
    switch (signatory.type) {
        case 'external':
            return `external_${signatory.index}`
        case 'participant':
        case 'powerOfAttorney':
            return 'participant'
    }
}

const createSignatoryMap = (signatories: SortableSignatory[]): DocumentSignatureInfo['signatories'] =>
    signatories.reduce(
        (info, signatory, order) => ({
            ...info,
            [keyForSignatory(signatory)]: { ...signatory, order },
        }),
        {} as DocumentSignatureInfo['signatories']
    )

const initializeSignatureInfo = (
    externalSignatoryCount: number,
    value?: DocumentSignatureInfo
): DocumentSignatureInfo => {
    let signatories = value?.signatories

    const withPowerOfAttorney = value?.withPowerOfAttorney ?? false

    if (signatories === undefined) {
        const signatoryCollection: SortableSignatory[] = [
            withPowerOfAttorney
                ? { type: 'powerOfAttorney', name: '', email: '', index: 0, order: 0 }
                : { type: 'participant', index: 0, order: 0 },
            ...times(
                externalSignatoryCount,
                n =>
                    ({
                        type: 'external',
                        index: n + 1,
                        order: n + 1,
                        name: '',
                        email: '',
                    }) as const
            ),
        ]

        signatories = createSignatoryMap(signatoryCollection)
    }

    return {
        requiresSMSAuthentication: value?.requiresSMSAuthentication ?? false,
        hasSigningOrder: value?.hasSigningOrder ?? false,
        withPowerOfAttorney,
        signatories,
    }
}

const useDocumentSetSignatoriesTranslation = createUseTranslation({
    EN: {
        participant: 'Participant',
        specifyOrder: 'Specify the signing order',
        requiresSMSAuthentication: 'Require SMS authentication',
        withPowerOfAttorney: 'Sign with power of attorney',
    },
    FR: {
        participant: 'Participant',
        specifyOrder: `Spécifier l'ordre des signataires`,
        requiresSMSAuthentication: `Exiger la vérification par SMS`,
        withPowerOfAttorney: 'Signature par pouvoir',
    },
})

const DocumentSetSignatories: FC<DocumentSetSignatoriesProps> = ({ value, onChange, requiredSignatoryCount }) => {
    const styles = utils.useFormStyles()
    const sigStyles = useDocumentSetSignatoriesStyles()
    const externalSignatoryCount = requiredSignatoryCount - 1
    const { t } = useDocumentSetSignatoriesTranslation()

    const [signatureInfo, setSignatureInfo] = useState<DocumentSignatureInfo>(() =>
        initializeSignatureInfo(externalSignatoryCount, value)
    )

    useEffect(() => {
        if (value !== undefined && !isEqual(value, signatureInfo)) {
            setSignatureInfo(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // This occurs when the template picker is used to change templates
    useEffect(() => {
        setSignatureInfo(initializeSignatureInfo(externalSignatoryCount))
    }, [externalSignatoryCount])

    const onChangeHasSigningOrder: SwitchChangeEventHandler = useCallback(checked => {
        setSignatureInfo(info => ({
            ...info,
            hasSigningOrder: checked,
        }))
    }, [])

    const onChangeSigningOrder = useCallback((signatories: SortableSignatory[]) => {
        const sigs = createSignatoryMap(signatories)
        setSignatureInfo(info => ({
            ...info,
            signatories: sigs,
        }))
    }, [])

    // FIXME: disappearing sms info when has signing order is toggled
    const onChangeRequiresSMSAuthentication: SwitchChangeEventHandler = useCallback(checked => {
        setSignatureInfo(info => ({
            ...info,
            requiresSMSAuthentication: checked,
        }))
    }, [])

    const onChangeWithPowerOfAttorney: SwitchChangeEventHandler = useCallback(checked => {
        setSignatureInfo(info => ({
            ...info,
            signatories: createSignatoryMap(
                Object.values(info.signatories).map((signatory): SortableSignatory => {
                    if (signatory.type === 'external') {
                        return signatory
                    }

                    if (checked) {
                        if (signatory.type === 'powerOfAttorney') {
                            return signatory
                        }

                        return { type: 'powerOfAttorney', name: '', email: '', index: 0, order: signatory.order }
                    } else {
                        return { type: 'participant', index: 0, order: signatory.order }
                    }
                })
            ),
            withPowerOfAttorney: checked,
        }))
    }, [])

    useEffect(() => {
        if (onChange !== undefined) {
            onChange(signatureInfo)
        }
        // The onChange callback isn't memoized by
        // antd, so we can't let it trigger the effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureInfo])

    const renderSignatory = useCallback(
        (signatory: SortableSignatory) => {
            if (signatory.type === 'external') {
                return (
                    <DocmentSetSignatory
                        value={signatory}
                        requiresSMSAuthentication={signatureInfo.requiresSMSAuthentication}
                    />
                )
            } else if (signatory.type === 'powerOfAttorney') {
                return (
                    <DocmentSetSignatory
                        value={signatory}
                        requiresSMSAuthentication={signatureInfo.requiresSMSAuthentication}
                    />
                )
            } else {
                if (signatureInfo.hasSigningOrder) {
                    return t('participant')
                }
            }

            return null
        },
        [signatureInfo.requiresSMSAuthentication, signatureInfo.hasSigningOrder, t]
    )

    const keyForItem = useCallback((signatory: SortableSignatory) => signatory.index, [])
    const asItems = useMemo(
        () => sortBy(Object.values(signatureInfo.signatories), sig => sig.order),
        [signatureInfo.signatories]
    )

    const hasExternalSignatories = externalSignatoryCount > 0

    return (
        <>
            <SortableList<SortableSignatory>
                items={asItems}
                renderItem={renderSignatory}
                keyForItem={keyForItem}
                onChange={onChangeSigningOrder}
                sortable={signatureInfo.hasSigningOrder && hasExternalSignatories}
            />

            <Divider />

            {hasExternalSignatories ? (
                <div className={sigStyles.signatureOption}>
                    <Switch checked={signatureInfo.hasSigningOrder} onChange={onChangeHasSigningOrder} />
                    <span className={styles.formSideLabelNote}>{t('specifyOrder')}</span>
                </div>
            ) : null}

            <div className={sigStyles.signatureOption}>
                <Switch
                    checked={signatureInfo.requiresSMSAuthentication}
                    onChange={onChangeRequiresSMSAuthentication}
                />
                <span className={styles.formSideLabelNote}>{t('requiresSMSAuthentication')}</span>
            </div>

            <div className={sigStyles.signatureOption}>
                <Switch checked={signatureInfo.withPowerOfAttorney} onChange={onChangeWithPowerOfAttorney} />
                <span className={styles.formSideLabelNote}>{t('withPowerOfAttorney')}</span>
            </div>
        </>
    )
}

type DocmentSetSignatoryProps = {
    value: ExternalSignatory | PowerOfAttorneySignatory
    requiresSMSAuthentication?: boolean
}

const useDocumentSetSignatoryStyles = createUseStyles({
    input: {
        marginBottom: 5,
    },
    inputIcon: {
        marginRight: 5,
        color: colors.grey6,
    },
})

const useDocmentSetSignatoryTranslation = createUseTranslation({
    EN: {
        externalSignatory: 'External Signatory {{index}}',
        name: 'Name',
        email: 'Email',
        requiredSignatoryName: `You must provide the signatory's name`,
        requiredSignatoryEmail: `You must provide the signatory's email`,
        requiredSignatorySMSAuthentication: `You must provide the signatory's phone number`,
        invalidSignatoryEmail: `The email address is invalid`,
        powerOfAttorney: 'Power of attorney',
    },
    FR: {
        externalSignatory: 'Signataire Externe {{index}}',
        name: 'Nom',
        email: 'Email',
        requiredSignatoryName: 'Vous devez renseigner le nom du signataire',
        requiredSignatoryEmail: "Vous devez renseigner l'email du signataire",
        requiredSignatorySMSAuthentication: 'Vous devez renseigner le numéro de téléphone du signataire',
        invalidSignatoryEmail: `L'adresse email est invalide`,
        powerOfAttorney: 'Signataire par pouvoir',
    },
})

const DocmentSetSignatory: FC<DocmentSetSignatoryProps> = ({ value, requiresSMSAuthentication = false }) => {
    const styles = useDocumentSetSignatoryStyles()
    const { t } = useDocmentSetSignatoryTranslation()

    const userIcon = useMemo(() => <UserOutlined className={styles.inputIcon} />, [styles.inputIcon])
    const mailIcon = useMemo(() => <MailOutlined className={styles.inputIcon} />, [styles.inputIcon])

    const { namePath, emailPath, smsAuthenticationPath, label } = useMemo(() => {
        const key = keyForSignatory(value)

        const namePath = ['signatureInfo', 'signatories', key, 'name']
        const emailPath = ['signatureInfo', 'signatories', key, 'email']
        const smsAuthenticationPath = ['signatureInfo', 'signatories', key, 'smsAuthentication']

        let label: string

        if (value.type === 'external') {
            label = t('externalSignatory', { index: value.index })
        } else {
            label = t('powerOfAttorney')
        }

        return {
            label,
            namePath,
            emailPath,
            smsAuthenticationPath,
        }
    }, [t, value])

    const signatoryNameRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('requiredSignatoryName'),
            },
        ],
        [t]
    )

    const signatoryEmailRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('requiredSignatoryEmail'),
            },
            {
                type: 'email',
                message: t('invalidSignatoryEmail'),
                validateTrigger: 'onBlur',
            },
        ],
        [t]
    )

    const phoneValidationRules = usePhoneValidationRules()

    const signatorySmsAuthenticationRules = useMemo<Rule[]>(
        () => [
            ...phoneValidationRules,
            {
                required: true,
                message: t('requiredSignatorySMSAuthentication'),
            },
        ],
        [phoneValidationRules, t]
    )

    return (
        <Form.Item label={label} required className={styles.input}>
            <Form.Item name={namePath} hasFeedback rules={signatoryNameRules} className={styles.input}>
                <Input prefix={userIcon} placeholder={t('name')} value={value.name} />
            </Form.Item>
            <Form.Item
                name={emailPath}
                hasFeedback
                rules={signatoryEmailRules}
                className={styles.input}
                validateTrigger={emailValidateTrigger}
            >
                <Input prefix={mailIcon} placeholder={t('email')} value={value.email} />
            </Form.Item>
            {!requiresSMSAuthentication ? null : (
                <Form.Item
                    name={smsAuthenticationPath}
                    hasFeedback
                    rules={signatorySmsAuthenticationRules}
                    className={styles.input}
                >
                    <PhoneInput value={value.smsAuthentication} />
                </Form.Item>
            )}
        </Form.Item>
    )
}

const emailValidateTrigger = ['onChange', 'onBlur']

const useManageStateTemplateTranslations = createUseTranslation({
    FR: {
        add: 'Ajouter un modèle statique',
        name: 'Nom du modèle',
        file: 'Modèle',
        selectFile: 'Choisir modèle',
        nameAlreadyUsed: 'Il existe déjà un modèle avec ce nom',
    },
    EN: {
        add: 'Add a static template',
        name: 'Template name',
        file: 'Template',
        selectFile: 'Select template',
        nameAlreadyUsed: 'There is already a template with that name',
    },
})

type ManageStateTemplatesProps = {
    operation: Pick<Operation, 'id'>
    templates: { name: string }[]
}

const useManageStaticTemplateControlsStyles = createUseStyles({
    controls: {
        textAlign: 'right',
        marginBottom: 15,
    },
})

type AddStaticTemplateForm = {
    name: string | undefined
    file: { file: File } | undefined
}

const ManageStaticTemplates: FC<ManageStateTemplatesProps> = ({ operation, templates }) => {
    const { t } = useManageStateTemplateTranslations()
    const styles = useManageStaticTemplateControlsStyles()
    const rules = useCommonRules()

    const apiClient = useApiClient()
    const apollo = useApolloClient()

    const [open, setOpen] = useState(false)
    const [creating, setCreating] = useState(false)

    const [form] = Form.useForm<AddStaticTemplateForm>()

    const showModal = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        setCreating(false)
        form.resetFields()
    }, [form])

    const createStaticTemplate = useCallback<() => Promise<void>>(async () => {
        setCreating(true)
        const { name, file } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(file)

        return (
            apiClient.templates
                .createTemplate(
                    {
                        target: 'DOCUMENT',
                        type: 'STATIC',
                        operationId: operation.id,
                        name,
                    },
                    file.file
                )
                // We refetch the query that populates the template select
                .then(async () =>
                    apollo.refetchQueries({
                        include: [GetOperationParticipantsAndTemplatesDocument],
                    })
                )
                .then(() => {
                    close()
                })
        )
    }, [apiClient.templates, apollo, close, form, operation.id])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await createStaticTemplate()
    }, [createStaticTemplate, form])

    // This just stops the Upload element from trying to upload the file on its
    // own - we take the data and submit with other form values
    const handleFileSelect = useCallback<NonNullable<UploadProps['beforeUpload']>>(() => {
        return false
    }, [])

    const checkTemplateName = useCallback(
        (name: string): boolean => {
            return !templates.some(template => template.name.trim().toLowerCase() === name.trim().toLowerCase())
        },
        [templates]
    )

    const validationRules = useMemo<FormRules<AddStaticTemplateForm>>(
        () => ({
            name: [
                ...rules.required,
                {
                    validator: async (_, name) =>
                        new Promise<void>((resolve, reject) => {
                            const uniqueName = checkTemplateName(name)
                            if (uniqueName) {
                                resolve()
                            } else {
                                reject(t('nameAlreadyUsed'))
                            }
                        }),
                },
            ],
            file: rules.required,
        }),
        [checkTemplateName, rules.required, t]
    )

    return (
        <>
            <div className={styles.controls}>
                <ActionButton onClick={showModal} disabled={open} icon={icons.AddFile} type="default">
                    {t('add')}
                </ActionButton>
            </div>
            <Modal open={open} title={t('add')} onCancel={close} onOk={onOk} confirmLoading={creating}>
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label={t('name')} hasFeedback rules={validationRules.name}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="file" label={t('file')} hasFeedback rules={validationRules.file}>
                        <Upload
                            beforeUpload={handleFileSelect}
                            multiple={false}
                            maxCount={1}
                            listType="picture"
                            accept={acceptedStaticTemplateFileTypes}
                        >
                            <Button icon={icons.Upload}>{t('selectFile')}</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const acceptedStaticTemplateFileTypes = '.pdf,application/pdf'
