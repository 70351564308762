import { Input, Space } from 'antd'
import { Rule } from 'antd/lib/form'
import parsePhoneNumber from 'libphonenumber-js'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import { CSSProperties, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react'

import {
    CountryCode,
    CountryDiallingPrefix,
    LookupItem,
    countryToDiallingPrefixMap,
    diallingPrefixLookup,
} from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { LookupSelect } from '../LookupSelect'

export type PhoneNumber = {
    input?: string
    prefix?: string
    countryCode?: CountryCode
    number?: string
    validNumber?: string
}

type PhoneInputProps = {
    disabled?: boolean
    value?: PhoneNumber | string | null
    onChange?: (value: PhoneNumber | undefined) => void
}

const usePhoneValueTranslation = createUseTranslation({
    FR: {
        invalid: `Le numéro n'est pas valide`,
    },
    EN: {
        invalid: `The number is invalid`,
    },
})

export const usePhoneValidationRules = () => {
    const { t } = usePhoneValueTranslation()

    return useMemo<Rule[]>(
        () => [
            {
                validator: async (_, value: PhoneNumber | undefined) =>
                    new Promise<PhoneNumber | undefined>((resolve, reject) => {
                        if (value?.validNumber === undefined && value?.input !== undefined) {
                            reject(new Error(t('invalid')))
                        }

                        resolve(value)
                    }),
            },
        ],
        [t]
    )
}

export const PhoneInput: FC<PhoneInputProps> = ({ value, onChange, disabled = false }) => {
    const parsedPhone = useMemo(() => {
        if (isNil(value)) {
            return undefined
        }

        if (isString(value)) {
            return parsePhoneNumber(value)
        }

        if (value.input !== undefined) {
            return parsePhoneNumber(value.input)
        }

        return undefined
    }, [value])

    const [number, setNumber] = useState<string | undefined>(parsedPhone?.nationalNumber)
    const [countryCode, setCountryCode] = useState<CountryCode | undefined>(parsedPhone?.country)

    const onChangePrefix = useCallback((code: CountryCode) => {
        setCountryCode(code)
    }, [])

    const onChangeNumber = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
        setNumber(event.target.value)
    }, [])

    useEffect(() => {
        if (onChange === undefined) {
            return
        }

        if (countryCode === undefined && (number === undefined || number.length === 0)) {
            if (value !== undefined) {
                onChange(undefined)
            }
            return
        }

        const prefix = countryCode === undefined ? undefined : countryToDiallingPrefixMap[countryCode]
        const input = `${prefix} ${number}`

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsed = parsePhoneNumber(input, countryCode as any)

        onChange({
            input,
            countryCode,
            number: parsed?.formatNational(),
            prefix: parsed?.countryCallingCode,
            validNumber: parsed?.isValid() ? parsed.formatInternational() : undefined,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number, countryCode])

    const label = useCallback((item: LookupItem<string, CountryDiallingPrefix>) => {
        return item.value.prefix
    }, [])

    return (
        <Space.Compact block={true} direction="horizontal" size="middle">
            <LookupSelect
                popupMatchSelectWidth={false}
                style={inputStyles.prefix}
                lookup={diallingPrefixLookup}
                label={label}
                value={countryCode}
                onChange={onChangePrefix}
                disabled={disabled}
            />
            <Input style={inputStyles.number} value={number} onChange={onChangeNumber} disabled={disabled} />
        </Space.Compact>
    )
}

const prefixWidthPct = 15

const inputStyles: { prefix: CSSProperties; number: CSSProperties } = {
    prefix: {
        width: `${prefixWidthPct}%`,
    },
    number: {
        width: `${100 - prefixWidthPct}%`,
    },
}
