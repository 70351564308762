import { Card, Col, Form, Input, Row, Space } from 'antd'
import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { AccountTitle, Locale } from '@publica/api-graphql'
import { languagesLookup, titlesLookup } from '@publica/lookups'
import { useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { ActionButton } from '../ActionButton'
import { LookupSelect } from '../LookupSelect'
import { PhoneInput, PhoneNumber, usePhoneValidationRules } from '../PhoneInput'

export type UpdateAccountDelegate = {
    checkEmailIsUnique: (email: string) => Promise<boolean>
    onCancel: () => void
    onUpdate: (id: string, values: UpdateAccountValues) => Promise<void>
    onUpdateComplete: () => void
}

type AccountProps = {
    updateAccount: UpdateAccountDelegate
    mutable?: boolean
    allowCancel?: boolean
}

const useAccountTranslation = createUseTranslation({
    FR: {
        manage: 'Gérer votre compte',
    },
    EN: {
        manage: 'Manage your account',
    },
})

export const Account: FC<AccountProps> = ({ updateAccount, mutable = false, allowCancel = true }) => {
    const { t } = useAccountTranslation()

    return (
        <Card title={t('manage')}>
            <Row justify="center">
                <Col span={8}>
                    <UpdateAccountForm updateAccount={updateAccount} mutable={mutable} allowCancel={allowCancel} />
                </Col>
            </Row>
        </Card>
    )
}

type UpdateAccountFormValues = {
    title: AccountTitle | undefined
    firstName: string
    lastName: string
    email: string
    phoneNumber: PhoneNumber | undefined
    locale: Locale
}

type UpdateAccountValues = Omit<UpdateAccountFormValues, 'phoneNumber'> & { phoneNumber?: string }

const useUpdateAccountFormStyles = createUseStyles({
    controls: {
        textAlign: 'right',
    },
})

const useUpdateAccountsFormTranslation = createUseTranslation({
    FR: {
        accountEmailAlreadyUsed: 'Cet email est déjà utilisé',
        title: 'Civilité',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'Email',
        locale: 'Langue',
        update: 'Mettre à jour',
        phoneNumber: 'Numéro de téléphone portable',
    },
    EN: {
        accountEmailAlreadyUsed: 'This email address is already used',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Surname',
        email: 'Email',
        locale: 'Language',
        update: 'Update',
        phoneNumber: 'Mobile phone number',
    },
})

type UpdateAccountFormProps = {
    updateAccount: UpdateAccountDelegate
    mutable: boolean
    allowCancel: boolean
}

const UpdateAccountForm: FC<UpdateAccountFormProps> = ({ updateAccount, mutable, allowCancel }) => {
    const [form] = Form.useForm<UpdateAccountFormValues>()
    const { state } = useAuthState()
    const styles = useUpdateAccountFormStyles()
    const account = useMemo(() => state.getAccount(), [state])
    const [isUpdating, setIsUpdating] = useState(false)

    assert.defined(account)

    const onCancel = useCallback(() => updateAccount.onCancel(), [updateAccount])
    const onUpdate = useAsyncCallback(async () => {
        setIsUpdating(true)
        try {
            await form.validateFields()

            const { phoneNumber, ...accountValues } = form.getFieldsValue()

            await updateAccount
                .onUpdate(account.id, {
                    ...accountValues,
                    phoneNumber: phoneNumber?.validNumber,
                })
                .then(async () => state.refreshToken())

            updateAccount.onUpdateComplete()
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            setIsUpdating(false)
        }
    }, [account.id, form, state, updateAccount])

    const { t } = useUpdateAccountsFormTranslation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkAccountEmail = useCallback(
        debounce((email: string, callback: (error?: string) => void): void => {
            void (async () =>
                updateAccount.checkEmailIsUnique(email).then(isUnique => {
                    if (isUnique) {
                        callback()
                    } else {
                        callback(t('accountEmailAlreadyUsed', { email }))
                    }
                }))()
        }, 500),
        [updateAccount]
    )

    const rules = useCommonRules()

    const phoneValidationRules = usePhoneValidationRules()

    const validationRules = useMemo<FormRules<UpdateAccountFormValues>>(
        () => ({
            locale: rules.required,
            firstName: rules.required,
            lastName: rules.required,
            email: [
                ...rules.requiredEmail,
                {
                    validator: async (_, email: string) =>
                        new Promise<void>((resolve, reject) => {
                            // Don't bother checking if the email hasn't been changed
                            if (email.trim().toLowerCase() === account.email.trim().toLowerCase()) {
                                resolve()
                            }

                            checkAccountEmail(email, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
            phoneNumber: [...phoneValidationRules, ...rules.required],
        }),
        [account.email, checkAccountEmail, phoneValidationRules, rules.required, rules.requiredEmail]
    )

    return (
        <Form form={form} layout="vertical" initialValues={account}>
            <Form.Item name="title" label={t('title')} required hasFeedback rules={validationRules.title}>
                <LookupSelect lookup={titlesLookup} disabled={!mutable} />
            </Form.Item>
            <Form.Item name="firstName" label={t('firstName')} required hasFeedback rules={validationRules.firstName}>
                <Input disabled={!mutable} />
            </Form.Item>
            <Form.Item name="lastName" label={t('lastName')} required hasFeedback rules={validationRules.lastName}>
                <Input disabled={!mutable} />
            </Form.Item>
            <Form.Item name="email" label={t('email')} required hasFeedback rules={validationRules.email}>
                <Input disabled={!mutable} />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label={t('phoneNumber')}
                required
                hasFeedback
                rules={validationRules.phoneNumber}
            >
                <PhoneInput />
            </Form.Item>
            <Form.Item name="locale" label={t('locale')} required hasFeedback rules={validationRules.locale}>
                <LookupSelect lookup={languagesLookup} />
            </Form.Item>
            <Form.Item>
                <div className={styles.controls}>
                    <Space>
                        {allowCancel ? (
                            <ActionButton disabled={isUpdating} size="middle" type="default" onClick={onCancel}>
                                {t('cancel')}
                            </ActionButton>
                        ) : null}

                        <ActionButton inProgress={isUpdating} size="middle" onClick={onUpdate}>
                            {t('update')}
                        </ActionButton>
                    </Space>
                </div>
            </Form.Item>
        </Form>
    )
}
