import { v4 as uuid } from 'uuid'

export class Scheduler {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly resultMap: Map<string, Promise<any>> = new Map()
    private readonly queue: (() => void)[] = []
    private working = 0

    constructor(private readonly maxConcurrent: number) {}

    async schedule<R>(fn: () => Promise<R>, key?: string): Promise<R> {
        let promise: Promise<R> | undefined
        let resolvedKey: string

        if (key !== undefined) {
            const existingPromise = this.resultMap.get(key)

            if (existingPromise !== undefined) {
                promise = existingPromise
            }

            resolvedKey = key
        } else {
            resolvedKey = uuid()
        }

        if (promise === undefined) {
            promise = new Promise((resolve, reject) => {
                this.queue.push(() => {
                    this.working++
                    fn()
                        .then(v => {
                            resolve(v)
                        })
                        .catch(e => {
                            reject(e)
                        })
                        .finally(() => {
                            this.working--
                            this.resultMap.delete(resolvedKey)
                            this.tick()
                        })
                })
            })

            this.resultMap.set(resolvedKey, promise)
        }

        this.tick()

        return promise
    }

    /* istanbul ignore next */
    get queueSize() {
        return this.queue.length
    }

    private tick() {
        if (this.working >= this.maxConcurrent) {
            return
        }

        let task = this.queue.shift()

        while (task !== undefined) {
            task()
            task = undefined

            if (this.working < this.maxConcurrent) {
                task = this.queue.shift()
            }
        }
    }
}
