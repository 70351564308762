import isError from 'lodash/isError'
import { z } from 'zod'

import { parseRawFieldInstance, serializeRawFieldInstance } from './rawFieldInstance'
import { signatureFieldInstanceSchema } from './signatureFieldInstance'
import { valueFieldInstanceSchema } from './valueFieldInstance'

export const fieldInstanceSchema = z.union([signatureFieldInstanceSchema, valueFieldInstanceSchema])

export type FieldInstance = z.infer<typeof fieldInstanceSchema>

export const serializeFieldInstance = (fieldInstance: FieldInstance): string => {
    const { kind, state, id, params, key } = fieldInstance as FieldInstance & { id?: string }
    return serializeRawFieldInstance({ key, params: { kind, id, state, ...params } })
}

export const parseFieldInstance = (serializedFieldInstance: string): FieldInstance => {
    try {
        return fieldInstanceSchema.parse(parseRawFieldInstance(serializedFieldInstance))
        /* istanbul ignore next */
    } catch (e) {
        let cause: Error | undefined

        if (isError(e)) {
            cause = e
        }

        throw new Error(`Unable to parse field instance: ${serializedFieldInstance}`, { cause })
    }
}
