import { useContext, useEffect, useState } from 'react'
import { AuthStateContext } from './context'
import { Account, AuthState } from './state'
import { Token } from './token'
import { logger } from '@publica/ui-common-logger'

export type UseAuthStateValues<T extends Token = Token, A extends AuthState<T> = AuthState<T>> = {
    isInitialized: boolean
    isAuthenticating: boolean
    isAuthenticated: boolean
    state: A
}

export function useAuthState<T extends Token = Token, A extends AuthState<T> = AuthState<T>>(): UseAuthStateValues<T, A>
export function useAuthState<T extends Token = Token, A extends AuthState<T> = AuthState<T>>(
    allowUndefined: true
): UseAuthStateValues<T, A> | undefined
export function useAuthState<T extends Token = Token, A extends AuthState<T> = AuthState<T>>(
    allowUndefined = false
): UseAuthStateValues<T, A> | undefined {
    const state = useContext(AuthStateContext) as A | undefined

    if (state === undefined && !allowUndefined) {
        throw new Error('Auth state not configured')
    }

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(state?.isAuthenticated() ?? false)
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(state?.isAuthenticating() ?? false)
    const [isInitialized, setIsInitialized] = useState<boolean>(state?.isInitialized() ?? false)

    // Each effect updates the current state to avoid a race condition where
    // the state changes before the listeners are registered

    useEffect(() => {
        if (state === undefined) {
            return
        }

        const unsubscribeFns: (() => void)[] = []

        unsubscribeFns.push(
            state.events.on(['loginSuccess', 'refreshSuccess'], () => {
                setIsAuthenticated(true)
            })
        )
        setIsAuthenticated(state.isAuthenticated())

        unsubscribeFns.push(
            state.events.on('initialized', () => {
                setIsInitialized(true)
            })
        )
        setIsInitialized(state.isInitialized())

        unsubscribeFns.push(
            state.events.on(['logout', 'refreshFailure', 'loginFailure'], () => {
                setIsAuthenticated(false)
            })
        )
        setIsAuthenticated(state.isAuthenticated())

        unsubscribeFns.push(
            state.events.on('loginAttempt', () => {
                setIsAuthenticating(true)
            })
        )

        unsubscribeFns.push(
            state.events.on(['loginFailure', 'loginSuccess'], () => {
                setIsAuthenticating(false)
            })
        )

        setIsAuthenticating(state.isAuthenticating())

        return () => {
            for (const unsubFn of unsubscribeFns) {
                unsubFn()
            }
        }
    }, [state])

    if (state === undefined) {
        return undefined
    }

    return { isAuthenticated, isAuthenticating, isInitialized, state }
}

export function useCurrentAccount (): Account
export function useCurrentAccount (allowUndefined: true): Account | undefined
export function useCurrentAccount (allowUndefined = false): Account | undefined {
    // We cast 'as true', even though it could be false – this is just a type hack
    const authState = useAuthState(allowUndefined as true)

    if (authState === undefined) {
        if (allowUndefined) {
            return undefined
        }
        throw new Error('Auth state not configured')
    }

    const account = authState.state.getAccount()

    if (account === undefined) {

        logger.error(`Account not configured`, {
            payload: {
                authState
            }
        })

        throw new Error('Account not configured')
    }

    return account
}

