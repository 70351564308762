import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const currencySchema = z.enum(['EUR', 'USD', 'GBP'])

export type Currency = z.infer<typeof currencySchema>

const currencies: Record<KnownLocale, Record<Currency, string>> = {
    FR: {
        EUR: 'Euros',
        USD: 'Dollars américains',
        GBP: 'Livres sterling',
    },
    EN: {
        EUR: 'Euros',
        USD: 'US dollars',
        GBP: 'British pounds sterling',
    },
}

export const currenciesLookup = new LookupList(currencies)
