import { FC } from '@publica/ui-common-utils'

import { StatusTags } from '../Status'
import { HasEmailStatus, useEmailStatusDisplayInfo } from './emailStatusInfo'

type EmailStatusTagsProps = {
    emails: HasEmailStatus[] | HasEmailStatus
    displayCount?: boolean
}

export const EmailStatusTags: FC<EmailStatusTagsProps> = ({ emails, displayCount }) => {
    const emailStatusDisplayInfo = useEmailStatusDisplayInfo()
    return <StatusTags records={emails} statusDisplayInfo={emailStatusDisplayInfo} displayCount={displayCount} />
}
