import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
// eslint-disable-next-line you-dont-need-lodash-underscore/map
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useState } from 'react'

import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { assert, buildIdMap, buildMap, buildMapWithFn } from '@publica/utils'

import {
    AccountTitle,
    GetOperationPaginatedParticipantsDocument,
    GetOperationParticipantsDocument,
    useCreateAccountsMutation,
    useCreateLegalEntitiesMutation,
    useCreateParticipantsMutation,
    useGetAccountsAndLegalEntitiesForBulkAddLazyQuery,
} from '../../../../../../data'
import {
    AddParticipantAction,
    AddParticipantActionForStatus,
    AddParticipantActionWithProgress,
    UploadedParticipant,
    UploadedParticipantOrError,
} from '../types'
import { ImportParticipantsPlanSummary } from './ImportParticipantsPlanSummary'

type ImportParticipantsPlanProps = {
    operationId: string
    participantsPendingImport: UploadedParticipantOrError[]
    onCancel: () => void
    onComplete: () => void
}

export const ImportParticipantsPlan: FC<ImportParticipantsPlanProps> = ({
    participantsPendingImport,
    operationId,
    onCancel,
    onComplete,
}) => {
    const { actions, loading } = useImportParticipantActions(participantsPendingImport, operationId)

    const { actionsWithProgress, inProgress, doImport } = useImporter(actions, operationId)

    const onConfirm = useAsyncCallback(async () => {
        void doImport().then(() => {
            onComplete()
        })
    }, [doImport, onComplete])

    return (
        <ImportParticipantsPlanSummary
            actions={actionsWithProgress}
            loading={loading}
            inProgress={inProgress}
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    )
}

const useImportParticipantActions = (participantsPendingImport: UploadedParticipantOrError[], operationId: string) => {
    const [getAccountsAndLegalEntitiesForBulkAddQuery] = useGetAccountsAndLegalEntitiesForBulkAddLazyQuery()
    const [actions, setActions] = useState<AddParticipantAction[] | undefined>(undefined)

    useEffect(() => {
        void (async () => {
            if (participantsPendingImport.length === 0) {
                setActions(undefined)
                return
            }

            const nonErroredParticipants: UploadedParticipant[] = []

            for (const uploadedParticipant of participantsPendingImport) {
                if (uploadedParticipant.type !== 'ERROR') {
                    nonErroredParticipants.push(uploadedParticipant)
                }
            }

            const { accounts, legalEntities, participants } = await getAccountsAndLegalEntitiesForBulkAddQuery({
                variables: {
                    ...nonErroredParticipants.reduce(
                        (vars, uploadedParticipant) => {
                            vars.emails.push(uploadedParticipant.account.email)
                            vars.identifiers.push(uploadedParticipant.legalEntity.identifier)
                            return vars
                        },
                        {
                            emails: [],
                            identifiers: [],
                        } as { emails: string[]; identifiers: string[] }
                    ),
                    operationId,
                },
                fetchPolicy: 'no-cache',
            }).then(({ data }) => {
                assert.defined(data)
                const { accountsByEmail, legalEntitiesByIdentifiers, operation } = data

                return {
                    accounts: accountsByEmail,
                    legalEntities: legalEntitiesByIdentifiers,
                    participants: operation?.participants ?? [],
                }
            })

            const accountsByEmail = buildMap('email', accounts)
            const legalEntitiesByIdentifiers = buildMap('identifier', legalEntities)
            const participantsByLegalEntityId = buildMapWithFn(p => p.legalEntity.id, participants)

            const actions = participantsPendingImport.map((uploadedParticipant): AddParticipantAction => {
                const rowAndId = {
                    row: uploadedParticipant.row,
                    id: uploadedParticipant.row.toString(),
                } as const

                if (uploadedParticipant.type === 'ERROR') {
                    return {
                        status: 'ERROR',
                        errorType: uploadedParticipant.errorType,
                        ...rowAndId,
                    }
                }

                const account = accountsByEmail[uploadedParticipant.account.email]

                if (account === undefined) {
                    return {
                        status: 'CREATE_ACCOUNT',
                        uploadedParticipant,
                        ...rowAndId,
                    }
                }

                if (account.type !== 'PARTICIPANT') {
                    return {
                        status: 'ERROR',
                        errorType: 'ACCOUNT_IS_ADMIN',
                        ...rowAndId,
                    }
                }

                const legalEntity = legalEntitiesByIdentifiers[uploadedParticipant.legalEntity.identifier]

                if (legalEntity === undefined) {
                    return {
                        status: 'CREATE_LEGAL_ENTITY',
                        account,
                        uploadedParticipant,
                        ...rowAndId,
                    }
                }

                if (legalEntity.representative.id !== account.id) {
                    return {
                        status: 'ERROR',
                        errorType: 'ACCOUNT_LEGAL_ENTITY_MISMATCH',
                        ...rowAndId,
                    }
                }

                const participant = participantsByLegalEntityId[legalEntity.id]

                if (participant === undefined) {
                    return {
                        status: 'CREATE_PARTICIPANT',
                        uploadedParticipant,
                        account,
                        legalEntity,
                        ...rowAndId,
                    }
                }

                return {
                    status: 'NO_ACTION_REQUIRED',
                    uploadedParticipant,
                    account,
                    legalEntity,
                    participant,
                    ...rowAndId,
                }
            })

            setActions(actions)
        })()
    }, [getAccountsAndLegalEntitiesForBulkAddQuery, operationId, participantsPendingImport])

    return { loading: actions === undefined, actions: actions ?? [] }
}

const useImporter = (actions: AddParticipantAction[], operationId: string) => {
    const [inProgress, setInProgress] = useState(false)
    const [createParticipants] = useCreateParticipantsMutation()
    const [createAccounts] = useCreateAccountsMutation()
    const [createLegalEntities] = useCreateLegalEntitiesMutation()

    const [actionsWithProgress, setActionsWithProgress] = useState<AddParticipantActionWithProgress[]>([])

    useEffect(() => {
        setActionsWithProgress(() =>
            actions.map(action => {
                let progress: AddParticipantActionWithProgress['progress']

                switch (action.status) {
                    case 'ERROR':
                        progress = 'WONT_START'
                        break
                    case 'CREATE_ACCOUNT':
                    case 'CREATE_LEGAL_ENTITY':
                    case 'CREATE_PARTICIPANT':
                        progress = 'NOT_STARTED'
                        break
                    case 'NO_ACTION_REQUIRED':
                        progress = 'COMPLETE'
                        break
                }

                return {
                    ...action,
                    progress,
                }
            })
        )
    }, [actions])

    const setProgressForActions = useCallback(
        (actions: AddParticipantAction[], progress: AddParticipantActionWithProgress['progress']) => {
            const actionMap = buildIdMap(actions)
            setActionsWithProgress(origActions =>
                origActions.map(action => {
                    const updatedAction = actionMap[action.id]

                    if (updatedAction === undefined) {
                        return action
                    }

                    return {
                        ...action,
                        progress,
                    }
                })
            )
        },
        []
    )

    const doImport = useCallback(async () => {
        setInProgress(true)

        const participantsNeedingAccounts: AddParticipantActionForStatus<'CREATE_ACCOUNT'>[] = []
        const participantsNeedingLegalEntities: AddParticipantActionForStatus<'CREATE_LEGAL_ENTITY'>[] = []
        const participantsNeedingParticipants: AddParticipantActionForStatus<'CREATE_PARTICIPANT'>[] = []
        const participantsRequiringNoAction: AddParticipantActionForStatus<'NO_ACTION_REQUIRED'>[] = []

        for (const participant of actionsWithProgress) {
            switch (participant.status) {
                case 'CREATE_ACCOUNT':
                    participantsNeedingAccounts.push(participant)
                    break
                case 'CREATE_LEGAL_ENTITY':
                    participantsNeedingLegalEntities.push(participant)
                    break
                case 'CREATE_PARTICIPANT':
                    participantsNeedingParticipants.push(participant)
                    break
                case 'NO_ACTION_REQUIRED':
                    participantsRequiringNoAction.push(participant)
                    break
            }
        }

        // This will squash accounts with the same email
        const participantsNeedingAccountsByEmail = buildMapWithFn(
            p => p.uploadedParticipant.account.email,
            participantsNeedingAccounts
        )

        // We create this so we can lookup the participants that each account
        // will represent
        const participantsNeedingAccountsByAccountEmail = groupBy(
            participantsNeedingAccounts,
            p => p.uploadedParticipant.account.email
        )

        setProgressForActions(participantsNeedingAccounts, 'IN_PROGRESS')

        const createdAccounts: AddParticipantActionForStatus<'CREATE_LEGAL_ENTITY'>[] = await createAccounts({
            variables: {
                accounts: map(
                    participantsNeedingAccountsByEmail,
                    ({ uploadedParticipant }) => uploadedParticipant.account
                ),
            },
        }).then(({ data }) =>
            flatMap(
                data?.createAccounts,
                ({ id: accountId, email }): AddParticipantActionForStatus<'CREATE_LEGAL_ENTITY'>[] =>
                    (participantsNeedingAccountsByAccountEmail[email] ?? []).map(
                        ({ uploadedParticipant, id, row }) => ({
                            account: {
                                id: accountId,
                            },
                            status: 'CREATE_LEGAL_ENTITY',
                            uploadedParticipant,
                            id,
                            row,
                        })
                    )
            )
        )

        participantsNeedingLegalEntities.push(...createdAccounts)

        setProgressForActions(participantsNeedingLegalEntities, 'IN_PROGRESS')

        const participantsNeedingLegalEntitiesByIdentifier = buildMapWithFn(
            p => p.uploadedParticipant.legalEntity.identifier,
            participantsNeedingLegalEntities
        )

        const createdLegalEntities: AddParticipantActionForStatus<'CREATE_PARTICIPANT'>[] = await createLegalEntities({
            variables: participantsNeedingLegalEntities.reduce(
                (vars, participant) => {
                    const representativeId = participant.account.id
                    const { legalEntity } = participant.uploadedParticipant

                    if (legalEntity.type === 'INDIVIDUAL') {
                        vars.individuals.push({
                            representativeId,
                            email: legalEntity.email,
                            firstName: legalEntity.firstName,
                            lastName: legalEntity.lastName,
                            title: legalEntity.title,
                        })
                    } else {
                        vars.companies.push({
                            representativeId,
                            registrationNumber: legalEntity.registrationNumber,
                            name: legalEntity.name,
                        })
                    }

                    return vars
                },
                {
                    companies: [],
                    individuals: [],
                } as {
                    companies: {
                        representativeId: string
                        name: string
                        registrationNumber: string
                    }[]
                    individuals: {
                        representativeId: string
                        title: AccountTitle
                        firstName: string
                        lastName: string
                        email: string
                    }[]
                }
            ),
        }).then(({ data }) =>
            [...(data?.createLegalEntityCompanies ?? []), ...(data?.createLegalEntityIndividuals ?? [])].map(
                ({ id, identifier }): AddParticipantActionForStatus<'CREATE_PARTICIPANT'> => ({
                    id: participantsNeedingLegalEntitiesByIdentifier[identifier]!.id,
                    status: 'CREATE_PARTICIPANT',
                    uploadedParticipant: participantsNeedingLegalEntitiesByIdentifier[identifier]!.uploadedParticipant,
                    account: participantsNeedingLegalEntitiesByIdentifier[identifier]!.account,
                    legalEntity: {
                        id,
                    },
                    row: participantsNeedingLegalEntitiesByIdentifier[identifier]!.row,
                })
            )
        )

        participantsNeedingParticipants.push(...createdLegalEntities)

        setProgressForActions(participantsNeedingParticipants, 'IN_PROGRESS')

        const participantsNeedingParticipantsByLegalEntityId = buildMapWithFn(
            p => p.legalEntity.id,
            participantsNeedingParticipants
        )

        await createParticipants({
            variables: {
                operationId,
                participants: sortBy(participantsNeedingParticipants, p => p.row).map(({ legalEntity }) => ({
                    legalEntityId: legalEntity.id,
                })),
            },
            refetchQueries: [GetOperationParticipantsDocument, GetOperationPaginatedParticipantsDocument],
        }).then(({ data }) =>
            (data?.createParticipants ?? []).map(
                ({ id, legalEntity }): AddParticipantActionForStatus<'NO_ACTION_REQUIRED'> => ({
                    participant: {
                        id,
                    },
                    id: participantsNeedingParticipantsByLegalEntityId[legalEntity.id]!.id,
                    uploadedParticipant:
                        participantsNeedingParticipantsByLegalEntityId[legalEntity.id]!.uploadedParticipant,
                    status: 'NO_ACTION_REQUIRED',
                    account: participantsNeedingParticipantsByLegalEntityId[legalEntity.id]!.account,
                    legalEntity: {
                        id: legalEntity.id,
                    },
                    row: participantsNeedingParticipantsByLegalEntityId[legalEntity.id]!.row,
                })
            )
        )

        setProgressForActions(participantsNeedingParticipants, 'COMPLETE')

        setInProgress(false)
    }, [
        actionsWithProgress,
        createAccounts,
        createLegalEntities,
        createParticipants,
        operationId,
        setProgressForActions,
    ])

    return { inProgress, doImport, actionsWithProgress }
}
