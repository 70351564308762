import { Button, Card, Col, Result, Row } from 'antd'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { ErrorFallbackProps } from '@publica/ui-common-components'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, getErrorMessage } from '@publica/ui-common-utils'

import { Layout } from '../Layout'

const useStyles = createUseStyles({
    error: {
        marginTop: 100,
    },
})

const useErrorTranslation = createUseTranslation({
    FR: {
        'error.title': `Une erreur s'est produite`,
        'error.subtitle': `Veuillez recharger la page`,
        'error.back': 'Retour',
    },
    EN: {
        'error.title': 'An error has occurred',
        'error.subtitle': 'Please reload the page',
        'error.back': 'Back',
    },
})

export const ErrorComponent: FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    const styles = useStyles()
    const { t } = useErrorTranslation()

    const errorMessage = getErrorMessage(error, t('error.title'), t('error.subtitle'))

    let title: string
    let message: string | undefined

    if (errorMessage.title === undefined) {
        title = errorMessage.message
    } else {
        title = errorMessage.title
        message = errorMessage.message
    }

    const navigate = useNavigate()

    const goBack = useCallback(() => {
        resetErrorBoundary()
        navigate(errorMessage.back ?? '/')
    }, [errorMessage.back, navigate, resetErrorBoundary])

    const back = useMemo(() => <Button onClick={goBack}>{t('back')}</Button>, [goBack, t])

    return (
        <Layout>
            <Row className={styles.error}>
                <Col offset={6} span={12}>
                    <Card>
                        <Result status="error" title={title} subTitle={message} extra={back} />
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}
