import type { ParsingFunctionsObject } from 'apollo-link-scalars'
import { Kind, type ValueNode } from 'graphql'
import isString from 'lodash/isString'
import { DateTime } from 'luxon'

export class DateTimeScalar implements ParsingFunctionsObject<DateTime, string> {
    serialize(value: unknown) {
        if (!DateTime.isDateTime(value) || !value.isValid) {
            throw new Error('Unable to serialize unknown value to DateTime')
        }
        return value.toISO()
    }

    parseValue(value: unknown) {
        if (!isString(value)) {
            throw new Error('Expected string whilst trying to parse DateTime')
        }

        return DateTime.fromISO(value).setZone('utc')
    }

    parseLiteral(ast: ValueNode) {
        if (ast.kind !== Kind.STRING) {
            throw new TypeError(`Date cannot represent non string type ${JSON.stringify(ast)}`)
        }
        return DateTime.fromISO(ast.value).setZone('utc')
    }
}

const base64Pattern = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

const validateBase64 = (val: string): string => {
    if (base64Pattern.test(val)) {
        return val
    } else {
        throw new Error('Invalid base64 string!')
    }
}

export class Base64StringScalar {
    description = 'Base64-encoded string custom scalar type'

    // Encodes the data sent to the client
    serialize(value: string) {
        return validateBase64(value)
    }

    // There are two types of parsing: https://stackoverflow.com/a/41513681

    // This handles values that are provided to graphql
    // as variables, which have already been parsed to
    // the correct type
    parseValue(value: string) {
        return validateBase64(value)
    }

    // This handles literals from the AST that haven't yet
    // been parsed to the correct type
    parseLiteral(ast: ValueNode) {
        if (ast.kind === Kind.STRING) {
            return validateBase64(ast.value)
        }
        return null
    }
}
