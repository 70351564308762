import { axios } from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'

import { getAuthHeaders } from '../auth'
import { Download, createDownloadFromResponse } from './download'

export class OperationsClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async downloadOperationExport(id: string): Promise<Download> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<Blob>(this.endpoints.operations.downloadOperationExport(id), {
                responseType: 'blob',
                headers,
            })
            .then(createDownloadFromResponse)
    }
}
