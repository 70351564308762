import { atom, selector } from 'recoil'

import * as graphql from '../../../../data'
import { FieldType } from './inputs'
import { FieldForForm } from './types'

type FieldFormState = {
    field: FieldForForm | undefined | null
    group: graphql.FieldGroup
    types?: FieldType[]
    requiresFormSection: boolean
}

export const fieldFormState = atom<FieldFormState | undefined>({
    key: 'fieldForm',
    default: undefined,
})

export const showFieldFormState = selector({
    key: 'showFieldForm',
    get: ({ get }) => get(fieldFormState) !== undefined,
})
