import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'

export const useNoValueStyles = createUseStyles({
    noValue: {
        color: colors.grey6,
        fontStyle: 'italic',
    },
})
