import { ReloadOutlined, SendOutlined } from '@ant-design/icons'
import { Alert, ButtonProps, Popconfirm } from 'antd'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanDate } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { useScheduleSendInvitationForParticipantsMutation } from '../../../../../../../data'
import { participantInvitationStatusState } from '../../../state'
import { Operation } from '../../../types'

const useParticipantsInvitationTranslation = createUseTranslation({
    EN: {
        resend: 'Resend invitation',
        send: 'Send invitation',
        sendButton: 'Invite',
        lastInvitationSentAt: 'Last invitation sent on {{lastInvitationSentAt}}',
    },
    FR: {
        resend: `Renvoyer l'invitation`,
        send: `Envoyer l'invitation`,
        sendButton: 'Inviter',
        lastInvitationSentAt: 'Dernière invitation envoyée le {{lastInvitationSentAt}}',
    },
})

type SendParticipantInvitationProps = {
    participant: {
        id: string
        lastInvitationSentAt?: DateTime | null | undefined
    }
    operation: Operation
}

const reInviteIcon = <ReloadOutlined />
const inviteIcon = <SendOutlined />

// 30s in ms
const sendInviteCooldown = 30 * 1000

export const SendParticipantInvitation: FC<SendParticipantInvitationProps> = ({ participant, operation }) => {
    const { t } = useParticipantsInvitationTranslation()
    const [participantInvitationState, setParticipantInvitationState] = useRecoilState(
        participantInvitationStatusState(participant.id)
    )
    const [isMounted, setIsMounted] = useState(true)
    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false)

    const [scheduleSendInvitationForParticipantsMutation, { loading }] =
        useScheduleSendInvitationForParticipantsMutation()

    const styles = utils.useConfirmationStyles()

    const lastInvitationSentAt = participant.lastInvitationSentAt
    const hasBeenInvited = !isNil(lastInvitationSentAt)
    const shouldBeCooling = hasBeenInvited ? lastInvitationSentAt.diffNow('milliseconds').milliseconds > -30 : false

    useEffect(
        () => () => {
            setIsMounted(false)
        },
        []
    )

    useEffect(() => {
        if (participantInvitationState.isCooling) {
            setTimeout(() => {
                if (isMounted) {
                    setParticipantInvitationState(state => ({ ...state, isCooling: false }))
                }
            }, sendInviteCooldown)
        } else {
            setParticipantInvitationState(state => {
                if (shouldBeCooling) {
                    return { ...state, isCooling: true }
                }
                return state
            })
        }
    }, [participantInvitationState.isCooling, isMounted, setParticipantInvitationState, shouldBeCooling])

    const displayConfirmation = useCallback(() => {
        setConfirmationIsOpen(true)
    }, [])

    const hideConfirmation = useCallback(() => {
        setConfirmationIsOpen(false)
    }, [])

    const scheduleInvitation = useCallback(() => {
        hideConfirmation()
        setParticipantInvitationState({
            isSending: true,
            isCooling: false,
        })

        void scheduleSendInvitationForParticipantsMutation({
            variables: {
                participantIds: [participant.id],
                operationId: operation.id,
            },
        }).then(() => {
            setParticipantInvitationState({
                isSending: false,
                isCooling: true,
            })
        })
    }, [
        hideConfirmation,
        operation.id,
        participant.id,
        scheduleSendInvitationForParticipantsMutation,
        setParticipantInvitationState,
    ])

    const okButtonProps = useMemo<ButtonProps>(() => ({ loading }), [loading])

    const title = useMemo(() => {
        if (!hasBeenInvited) {
            return t('send')
        }

        const lastSentMessage = t('lastInvitationSentAt', { lastInvitationSentAt: humanDate(lastInvitationSentAt) })

        return (
            <>
                <p>{t('resend')}</p>
                <Alert type="info" message={lastSentMessage} className={styles.confirmationAlert} showIcon />
            </>
        )
    }, [hasBeenInvited, lastInvitationSentAt, styles.confirmationAlert, t])

    const disabled =
        !operationIsOpen(operation) || participantInvitationState.isCooling || participantInvitationState.isSending

    return (
        <Popconfirm
            title={title}
            open={confirmationIsOpen}
            placement="bottom"
            onCancel={hideConfirmation}
            okButtonProps={okButtonProps}
            onConfirm={scheduleInvitation}
            okText={t('send')}
            icon={null}
            overlayClassName={styles.confirmation}
        >
            <ActionButton
                size="small"
                icon={hasBeenInvited ? reInviteIcon : inviteIcon}
                onClick={displayConfirmation}
                inProgress={confirmationIsOpen || participantInvitationState.isSending}
                disabled={disabled}
            >
                {t('sendButton')}
            </ActionButton>
        </Popconfirm>
    )
}
