import { Form, FormInstance } from 'antd'
import { Rule } from 'antd/lib/form'
import { useCallback, useEffect, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import type { FieldParameters } from '../../../../../../data'
import { SortedStringSelect } from '../../../../../components'
import { DateRange } from '../DateRange'
import { FloatCurrency } from '../FloatCurrency'
import { FloatPrecision } from '../FloatPrecision'
import { Lookup } from '../Lookup'

const iban = 'text.iban'
const phone = 'text.phone'
const email = 'text.email'
const text = 'text.plain'
const date = 'date.date'
const float = 'float.float'
const lookup = 'lookup.lookup'
const address = 'map.address'

const types = [iban, phone, email, text, date, float, lookup, address] as const

export type FieldType = (typeof types)[number]

type FieldTypeForm = {
    parameters: FieldParameters | undefined
    __type: FieldType | undefined
}

type FieldTypeProps = {
    disabled?: boolean
    types?: FieldType[]
}

const useFieldTypeTranslation = createUseTranslation({
    EN: {
        required: 'You must choose a type',
        type: 'Type',
    },
    FR: {
        required: 'Vous devez choisir un type',
        type: 'Type',
    },
})

export const FieldType: FC<FieldTypeProps> = ({ types: filteredTypes, disabled }) => {
    const { t } = useFieldTypeTranslation()

    const form = Form.useFormInstance<FieldTypeForm>()
    const __type = Form.useWatch('__type', form)

    useEffect(() => {
        if (disabled) {
            return
        }
        setType(form, __type)
    }, [__type, disabled, form])

    const rules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('required'),
            },
        ],
        [t]
    )

    const selectTypes = useMemo(() => [...(filteredTypes ?? types)], [filteredTypes])
    const labelForType = useLabelForType()

    return (
        <>
            <Form.Item label={t('type')} name="__type" hasFeedback rules={rules}>
                <SortedStringSelect disabled={disabled} items={selectTypes} labelForItem={labelForType} />
            </Form.Item>
            <FieldParameters type={__type} disabled={disabled} />
        </>
    )
}

const uselabelForTypeTranslation = createUseTranslation({
    EN: {
        iban: 'IBAN',
        phone: 'Phone number',
        text: 'Text',
        email: 'Email',
        numerical: 'Numerical value',
        date: 'Date',
        lookup: 'Dropdown menu',
        address: 'Address',
    },
    FR: {
        iban: 'IBAN',
        phone: 'Numéro de téléphone',
        text: 'Texte',
        email: 'Email',
        numerical: 'Valeur numérique',
        date: 'Date',
        lookup: 'Menu déroulant',
        address: 'Adresse',
    },
})

const useLabelForType = () => {
    const { t } = uselabelForTypeTranslation()

    return useCallback(
        (type: FieldType): string => {
            switch (type) {
                case 'text.iban':
                    return t('iban')
                case 'text.phone':
                    return t('phone')
                case 'text.plain':
                    return t('text')
                case 'text.email':
                    return t('email')
                case 'date.date':
                    return t('date')
                case 'float.float':
                    return t('numerical')
                case 'lookup.lookup':
                    return t('lookup')
                case 'map.address':
                    return t('address')
            }
        },
        [t]
    )
}

const setType = (form: FormInstance<FieldTypeForm>, type: FieldType | undefined): void => {
    form.setFieldsValue({ parameters: undefined })

    if (type === undefined) {
        return
    }

    let parameters: FieldParameters

    switch (type) {
        case 'text.iban':
            parameters = {
                __typename: 'TextFieldParameters',
                textFormat: 'IBAN',
            }
            break
        case 'text.phone':
            parameters = {
                __typename: 'TextFieldParameters',
                textFormat: 'PHONE',
            }
            break
        case 'text.email':
            parameters = {
                __typename: 'TextFieldParameters',
                textFormat: 'EMAIL',
            }
            break
        case 'text.plain':
            parameters = {
                __typename: 'TextFieldParameters',
                textFormat: 'TEXT',
            }
            break
        case 'date.date':
            parameters = {
                __typename: 'DateFieldParameters',
                range: 'ANY',
            }
            break
        case 'float.float':
            parameters = {
                __typename: 'FloatFieldParameters',
                precision: 2,
            }
            break
        case 'lookup.lookup':
            parameters = {
                __typename: 'LookupFieldParameters',
                dictionary: 'COUNTRY',
            }
            break
        case 'map.address':
            parameters = {
                __typename: 'MapFieldParameters',
                subType: 'ADDRESS',
            }
            break
    }

    form.setFieldsValue({ parameters })
}

type FieldParametersProps = {
    type: FieldType | undefined
    disabled?: boolean
}

const FieldParameters: FC<FieldParametersProps> = ({ type, disabled = false }) => {
    if (type === undefined) {
        return null
    }

    switch (type) {
        case 'float.float':
            return fieldParameterFormItemsForFloat(disabled)
        case 'date.date':
            return fieldParameterFormItemsForDate(disabled)
        case 'lookup.lookup':
            return fieldParameterFormItemsForLookup(disabled)
        case 'map.address':
        case 'text.email':
        case 'text.iban':
        case 'text.phone':
        case 'text.plain':
            return null
    }
}

const fieldParameterFormItemsForFloat = (disabled: boolean) => (
    <>
        <FloatPrecision disabled={disabled} />
        <FloatCurrency disabled={disabled} />
    </>
)

const fieldParameterFormItemsForDate = (disabled: boolean) => <DateRange disabled={disabled} />

const fieldParameterFormItemsForLookup = (disabled: boolean) => <Lookup disabled={disabled} />

export const typeFromFieldParameter = (parameters?: FieldParameters): FieldType | undefined => {
    if (parameters === undefined) {
        return undefined
    }

    switch (parameters.__typename) {
        case 'DateFieldParameters':
            return 'date.date'
        case 'FloatFieldParameters':
            return 'float.float'
        case 'LookupFieldParameters':
            return 'lookup.lookup'
        case 'MapFieldParameters':
            switch (parameters.subType) {
                case 'ADDRESS':
                    return 'map.address'
            }
            break
        case 'TextFieldParameters':
            switch (parameters.textFormat) {
                case 'EMAIL':
                    return 'text.email'
                case 'PHONE':
                    return 'text.phone'
                case 'TEXT':
                    return 'text.plain'
                case 'IBAN':
                    return 'text.iban'
            }
    }
}
