import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import { AccountTokenPair, BaseAuthState, Jwt } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'

type AppAuthConfig = {
    host: string
    msal: Configuration
    scopes: string[]
}

export class AppAuthState extends BaseAuthState<Jwt, AppAuthConfig> {
    private msal: PublicClientApplication

    constructor(
        private name: string,
        config: AppAuthConfig
    ) {
        super(config)
        this.msal = new PublicClientApplication(config.msal)
        void this.initialize()
    }

    private async initialize(): Promise<void> {
        logger.info(`Initializing ${this.name}`)

        await this.msal.initialize()

        this.performLogin(async (): Promise<AccountTokenPair<Jwt>> => {
            const tokenFromRedirect = await this.msal.handleRedirectPromise().then(tokenResponse => {
                if (tokenResponse !== null) {
                    logger.info('Found token from redirect')
                    const token = Jwt.withDefaultSchema(tokenResponse.accessToken)
                    this.msal.setActiveAccount(tokenResponse.account)
                    return token
                }
                return undefined
            })
            if (tokenFromRedirect !== undefined) {
                return this.getAccountTokenPairForToken(tokenFromRedirect)
            }
            logger.info('Attempting to login with token from session')
            return this.refreshToken()
        })
            .catch(() => {})
            .finally(() => {
                void this.markAsInitialized()
            })
    }

    async refreshToken(): Promise<AccountTokenPair<Jwt>> {
        return this.peformRefresh(async () => {
            const response = await this.msal.acquireTokenSilent({
                scopes: this.config.scopes,
            })
            const token = Jwt.withDefaultSchema(response.accessToken)
            return this.getAccountTokenPairForToken(token)
        })
    }

    async login(): Promise<AccountTokenPair<Jwt>> {
        return this.performLogin(async () => {
            // This will redirect the page to the MS login
            await this.msal.loginRedirect({
                scopes: this.config.scopes,
            })
            // This should never be reached
            throw new Error('Expected redirect to occur')
        })
    }

    async logout(): Promise<void> {
        return this.performLogout(async () => {
            await this.msal.logoutRedirect()
        })
    }
}

let authState: AppAuthState | undefined

type Config = {
    apiHost: string
    msal: {
        clientId: string
        authority: string
        redirectUri: string
        scopes: string[]
    }
}

type AppAuthStateBuilder = () => AppAuthState

export const createAuthStateBuilder =
    (name: string, configFn: () => Config): AppAuthStateBuilder =>
    () => {
        const config = configFn()

        if (authState === undefined) {
            authState = new AppAuthState(name, {
                host: config.apiHost,
                msal: {
                    auth: config.msal,
                    cache: {
                        cacheLocation: 'localStorage',
                    },
                },
                scopes: config.msal.scopes,
            })
        }

        return authState
    }
