import { Input } from 'antd'

import { FC } from '@publica/ui-common-utils'

import { Field, TextFieldParameters } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type PlainTextValueFormItemProps = {
    field: Field<TextFieldParameters>
}

export const PlainTextValueFormItem: FC<PlainTextValueFormItemProps> = ({ field }) => {
    const plainTextValueRules = useCommonRules()

    return (
        <FormItem field={field} rules={plainTextValueRules}>
            <Input />
        </FormItem>
    )
}
