import { Card, Col, Divider, Form, FormInstance, Input, Row, Select, Space, Tag } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { emailSchema, operationIsOpen } from '@publica/common'
import { GraphQLValue } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, LinkButton, NotFound, Spinner } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'
import { sizes } from '@publica/ui-web-styles'
import { utils } from '@publica/ui-web-styles'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    GetOperationEmailSetsDocument,
    useCreateEmailSetMutation,
    useGetOperationParticipantsAndTemplatesQuery,
} from '../../../../data'
import * as graphql from '../../../../data'
import {
    OperationNotMutable,
    ParticipantRequirement,
    ParticipantSelect,
    SelectedTemplate,
    TemplateSelect,
    createFieldRequirement,
    useParticipantSelectRules,
    useTemplateSelectRules,
} from '../../../components'

type Operation = Pick<graphql.Operation, 'id' | 'status'>

export type NewEmailSetProps = {
    operation: Operation
}

type Participant = {
    id: string
    info: {
        name: string
        code: string
    }
    groups: Pick<graphql.Group, 'key' | 'name'>[]
    values: GraphQLValue[]
}

type SelectedTemplateWithFields = SelectedTemplate & {
    latestVersion: {
        fields: { key: string }[]
    }
}

type FormValues = {
    template: SelectedTemplateWithFields | undefined
    subject: string | undefined
    participants: Participant[]
    ccRecipients: string[]
    bccRecipients: string[]
}

const useCreateEmailSet = (form: FormInstance<FormValues>, operation: Operation) => {
    const [createEmailSetMutation, { loading }] = useCreateEmailSetMutation()

    const createEmailSet = useCallback(async () => {
        const { template, participants, subject, ccRecipients, bccRecipients } = form.getFieldsValue()

        assert.defined(subject)
        assert.defined(template)
        assert.defined(participants)

        const templateVersionId = template.latestVersion.id
        const participantIds = participants.map(p => p.id)

        const cc: string[] = (ccRecipients ?? []).filter(addr => emailSchema.safeParse(addr).success)
        const bcc: string[] = (bccRecipients ?? []).filter(addr => emailSchema.safeParse(addr).success)

        await createEmailSetMutation({
            variables: {
                operationId: operation.id,
                participantIds,
                emailSet: {
                    templateVersionId,
                    subject,
                    cc,
                    bcc,
                },
            },
            refetchQueries: [GetOperationEmailSetsDocument],
        })
    }, [createEmailSetMutation, form, operation.id])

    return { createEmailSet, creating: loading } as const
}

const initialValues: FormValues = {
    template: undefined,
    subject: undefined,
    participants: [],
    ccRecipients: [],
    bccRecipients: [],
}

const useNewEmailSetTranslation = createUseTranslation({
    EN: {
        generate: 'Generate',
        operationNotFound: 'Operation not found',
        sendAnEmail: 'Send an email',
        sameSubject: `The email subject will be the same for recipients`,
        cc: `Other recipients, cc'd`,
        ccExplain: `These recipients will recieve a copy of each email sent`,
        bcc: `Other recipients, bcc'd`,
        bccExplain: `These recipients will receive a blind carbon copy of each email sent`,
        recipients: 'Recipients',
        subject: 'Subject',
        template: 'Template',
        invalidEmail: 'The email address is invalid',
    },
    FR: {
        generate: 'Générer',
        operationNotFound: 'Opération non trouvée',
        sendAnEmail: 'Envoyer un email',
        sameSubject: `L'objet de l'email sera identique pour tous les destinataires`,
        cc: `Autres destinataires, en CC`,
        ccExplain: `Ces destinataires seront en copie de tous les emails envoyés`,
        bcc: `Autres destinataires, en BCC`,
        bccExplain: `Ces destinataires seront en copie cachée de tous les emails envoyés`,
        recipients: 'Destinataires',
        subject: 'Objet',
        template: 'Modèle',
        invalidEmail: "L'adresse email n'est pas valide",
    },
})

export const NewEmailSet: FC<NewEmailSetProps> = props => {
    const { t } = useNewEmailSetTranslation()

    const { loading, data } = useGetOperationParticipantsAndTemplatesQuery({
        variables: { operationId: props.operation.id },
        pollInterval: usePollingRate(),
    })

    const navigate = useNavigate()

    const [form] = Form.useForm<FormValues>()
    const template = Form.useWatch('template', form)

    const { createEmailSet, creating } = useCreateEmailSet(form, props.operation)

    const styles = utils.useFormStyles()
    const mutable = operationIsOpen(props.operation)

    const onCreate = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await createEmailSet().then(() => {
            navigate('..')
        })
    }, [createEmailSet, form, navigate])

    const rules = useCommonRules()
    const participantSelectRules = useParticipantSelectRules()
    const templateSelectRules = useTemplateSelectRules()

    const emailListValidator = useCallback(
        async (_: unknown, emails: string[]) => {
            if (emails.some(email => !emailSchema.safeParse(email).success)) {
                return Promise.reject(t('invalidEmail'))
            }
            return Promise.resolve()
        },
        [t]
    )

    const validationRules = useMemo<FormRules<FormValues>>(
        () => ({
            participants: participantSelectRules,
            template: templateSelectRules,
            subject: rules.required,
            ccRecipients: [{ validator: emailListValidator }],
            bccRecipients: [{ validator: emailListValidator }],
        }),
        [participantSelectRules, templateSelectRules, rules.required, emailListValidator]
    )

    const participantRequirements = useMemo(() => {
        const requirements: ParticipantRequirement<Participant>[] = []

        if (template?.latestVersion.fields !== undefined) {
            requirements.push(createFieldRequirement(template.latestVersion.fields))
        }

        return requirements
    }, [template?.latestVersion.fields])

    if (!mutable) {
        return <OperationNotMutable />
    }

    if (loading || data === undefined) {
        return <Spinner />
    }

    const { operation } = data

    // FIXME: use error boundary
    if (isNil(operation)) {
        return <NotFound title={t('operationNotFound')} path=".." card />
    }

    const { templates, participants } = operation

    return (
        <Card title={t('sendAnEmail')}>
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Row gutter={sizes.gutter}>
                    <Col span={8}>
                        <Form.Item name="template" label={t('template')} hasFeedback rules={validationRules.template}>
                            <TemplateSelect<SelectedTemplateWithFields> templates={templates} templateTarget="EMAIL" />
                        </Form.Item>
                        {/* We have to double-wrap the Form.Items to ensure that the `name` attribute
                 maps onto the input
             */}
                        <Form.Item label={t('subject')} required>
                            <div className={styles.formLabelNote}>{t('sameSubject')}</div>
                            <Form.Item name="subject" rules={validationRules.subject} hasFeedback>
                                <Input />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label={t('cc')}>
                            <div className={styles.formLabelNote}>{t('ccExplain')}</div>
                            <Form.Item name="ccRecipients" noStyle hasFeedback rules={validationRules.ccRecipients}>
                                <CustomEmailSelect />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label={t('bcc')}>
                            <div className={styles.formLabelNote}>{t('bccExplain')}</div>
                            <Form.Item name="bccRecipients" noStyle hasFeedback rules={validationRules.bccRecipients}>
                                <CustomEmailSelect />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" style={verticalDividerStyle} />
                    </Col>
                    <Col span={15}>
                        <Form.Item
                            label={t('recipients')}
                            name="participants"
                            validateFirst={true}
                            rules={validationRules.participants}
                            hasFeedback
                        >
                            <ParticipantSelect<Participant>
                                fields={operation.fields}
                                groups={operation.groups}
                                participants={participants}
                                requirements={participantRequirements}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Space direction="horizontal">
                                <LinkButton to=".." disabled={creating}>
                                    {t('cancel')}
                                </LinkButton>
                                <ActionButton size="middle" inProgress={creating} onClick={onCreate}>
                                    {t('generate')}
                                </ActionButton>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

const verticalDividerStyle = { height: '100%' } as const

type CustomEmailSelectProps = {
    value?: string[]
    onChange?: (value: string[]) => void
}

const renderEmailTag = (props: CustomTagProps) => {
    const { success } = emailSchema.safeParse(props.value)
    return <Tag color={success ? 'default' : 'error'}>{props.label}</Tag>
}

const CustomEmailSelect: FC<CustomEmailSelectProps> = props => {
    return <Select mode="tags" {...props} tagRender={renderEmailTag}></Select>
}

type CustomTagProps = {
    label: React.ReactNode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
    disabled: boolean
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
    closable: boolean
}
