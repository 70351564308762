import { FC } from '@publica/ui-common-utils'

import { StatusIcon } from '../Status'
import { HasSignatoryStatus, useSignatoryStatusDisplayInfo } from './signatoryStatusInfo'

type SignatoryStatusIconProps = {
    signatory: HasSignatoryStatus
}

export const SignatoryStatusIcon: FC<SignatoryStatusIconProps> = ({ signatory }) => {
    const signatoryStatusDisplayInfo = useSignatoryStatusDisplayInfo()
    return <StatusIcon record={signatory} statusDisplayInfo={signatoryStatusDisplayInfo} />
}
