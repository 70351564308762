import { FC } from '@publica/ui-common-utils'

import { ParticipantsControls } from './ParticipantControls'
import { ParticipantsTable } from './ParticipantsTable'
import { Operation } from './types'

type ParticipantsIndexProps = {
    operation: Operation
}

export const ParticipantsIndex: FC<ParticipantsIndexProps> = ({ operation }) => {
    return (
        <>
            <ParticipantsControls operation={operation} />
            <ParticipantsTable operation={operation} />
        </>
    )
}
