import isArray from 'lodash/isArray'
import isError from 'lodash/isError'
import isString from 'lodash/isString'
// eslint-disable-next-line you-dont-need-lodash-underscore/map
import map from 'lodash/map'
import { DateTime } from 'luxon'

import { type LogContext, type LogLabels, LogLevel, type LogRecord, type LogTransport, levelNames } from './types'

type BannerInfo = {
    app: string
    version: string
    debug: boolean
    platform: 'publica'
}

export const appLabels = (): BannerInfo => ({
    app: __APP__,
    version: __VERSION__,
    debug: __DEBUG__,
    platform: 'publica',
})

type LoggerOptions = {
    name: string
    labels?: LogLabels
    transport: LogTransport[] | LogTransport
}
export class Logger {
    readonly name: string
    component?: string
    logLevel: LogLevel = __DEBUG__ ? LogLevel.DEBUG : LogLevel.INFO
    private labels: LogLabels
    private transports: LogTransport[]

    constructor(options: LoggerOptions) {
        const labels = structuredClone(options.labels ?? {})

        this.labels = { ...appLabels(), ...labels }
        this.transports = isArray(options.transport) ? options.transport : [options.transport]
        this.name = options.name
    }

    log(message: string, context?: LogContext) {
        this.logWithLevel(message, LogLevel.INFO, context)
    }

    info(message: string, context?: LogContext) {
        this.logWithLevel(message, LogLevel.INFO, context)
    }

    warn(message: string, context?: LogContext) {
        this.logWithLevel(message, LogLevel.WARN, context)
    }

    error(message: string, context?: LogContext) {
        const { error } = context ?? {}

        if (isString(error)) {
            this.logWithLevel(`${message}: ${error}`, LogLevel.ERROR, context)
            return
        }

        if (!isError(error)) {
            this.logWithLevel(message, LogLevel.ERROR, context)
            return
        }

        let errorMessage = `${error.name}: ${message} - ${error.message}`

        if (error.stack !== undefined) {
            errorMessage = `${errorMessage}\n${error.stack}`
        }

        this.logWithLevel(errorMessage, LogLevel.ERROR, context)
    }

    debug(message: string, context?: LogContext) {
        this.logWithLevel(message, LogLevel.DEBUG, context)
    }

    verbose(message: string, context?: LogContext) {
        this.debug(message, context)
    }

    withComponent(component: string | undefined, fn: (logger: Logger) => void) {
        const previousComponent = this.component
        this.component = component
        try {
            fn(this)
        } finally {
            this.component = previousComponent
        }
    }

    addLabels(labels: LogLabels) {
        this.labels = { ...this.labels, ...labels }
    }

    logBanner() {
        const banner = map(this.labels, (val, key) => `${key}=${val}`).join(', ')
        this.info(banner)
    }

    private logWithLevel(message: string, level: LogLevel, context?: LogContext) {
        if (level < this.logLevel) {
            return
        }

        const record: LogRecord = {
            name: this.name,
            timestamp: DateTime.utc(),
            component: this.component,
            level,
            levelName: levelNames[level],
            message,
            context: {
                ...context,
                labels: { ...this.labels, ...context?.labels },
            },
        }

        this.dispatch(record)
    }

    private dispatch(record: LogRecord) {
        for (const t of this.transports) {
            t.log(record)
        }
    }
}
