import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const ledgerHoldershipTypeSchema = z.enum(['FULL_OWNERSHIP', 'BARE_OWNERSHIP', 'USUFRUCT'])

export type LedgerHoldershipType = z.infer<typeof ledgerHoldershipTypeSchema>

const ledgerHoldershipTypes: Record<KnownLocale, Record<LedgerHoldershipType, string>> = {
    FR: {
        FULL_OWNERSHIP: 'Pleine propriété',
        BARE_OWNERSHIP: 'Nue propriété',
        USUFRUCT: 'Usufruit',
    },
    EN: {
        FULL_OWNERSHIP: 'Full ownership',
        BARE_OWNERSHIP: 'Bare ownership',
        USUFRUCT: 'Usufruct',
    },
}

const ledgerHoldershipTypeAbbreviations: Record<KnownLocale, Record<LedgerHoldershipType, string>> = {
    FR: {
        FULL_OWNERSHIP: 'PP',
        BARE_OWNERSHIP: 'NP',
        USUFRUCT: 'UU',
    },
    EN: {
        FULL_OWNERSHIP: 'FO',
        BARE_OWNERSHIP: 'BO',
        USUFRUCT: 'UU',
    },
}

export const ledgerHoldershipTypeLookup = new LookupList(ledgerHoldershipTypes)
export const ledgerHoldershipTypeAbbreviationLookup = new LookupList(ledgerHoldershipTypeAbbreviations)
