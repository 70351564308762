import { Button, Card, Col, Result, Row } from 'antd'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    notFound: {
        marginTop: '100px',
    },
})

type NotFoundProps = {
    path?: string
    title?: string
    description?: string
    card?: boolean
}

const useNotFoundTranslation = createUseTranslation({
    FR: {
        defaultTitle: `Page non trouvée`,
    },
    EN: {
        defaultTitle: 'Page not found',
    },
})

export const NotFound: FC<NotFoundProps> = ({ path = '/', title, description, card = false }) => {
    const styles = useStyles()
    const navigate = useNavigate()
    const { t } = useNotFoundTranslation()

    const resolvedTitle = title ?? t('defaultTitle')

    const goBack = useCallback(() => {
        navigate(path)
    }, [navigate, path])

    const backButton = useMemo(
        () => (
            <Button type="primary" onClick={goBack}>
                {t('back')}
            </Button>
        ),
        [goBack, t]
    )

    const result = <Result title={resolvedTitle} subTitle={description} extra={backButton} />

    return (
        <Row className={styles.notFound}>
            <Col offset={6} span={12}>
                {card ? <Card>{result}</Card> : result}
            </Col>
        </Row>
    )
}
