import { Button, ButtonProps } from 'antd'
import { useCallback } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

type LinkButtonProps = ButtonProps & {
    to: LinkProps['to']
}

type OnClickHandler = NonNullable<ButtonProps['onClick']>

export const LinkButton: FC<LinkButtonProps> = ({ to, onClick, children, ...props }) => {
    const navigate = useNavigate()

    const goTo: OnClickHandler = useCallback(
        ev => {
            navigate(to)

            if (onClick !== undefined) {
                onClick(ev)
            }
        },
        [navigate, to, onClick]
    )

    return (
        <Button {...props} onClick={goTo}>
            {children}
        </Button>
    )
}
