import { add_operation } from 'json-logic-js'

export const init = () => {
    initJsonLogicOperations()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonLogicAdditionalOperators: Record<'startsWith' | 'endsWith', (...args: any[]) => boolean> = {
    startsWith: (a: string, b: string) => a.startsWith(b),
    endsWith: (a: string, b: string) => a.endsWith(b),
}

const initJsonLogicOperations = () => {
    for (const [op, func] of Object.entries(jsonLogicAdditionalOperators)) {
        add_operation(op, func)
    }
}
