import type { DocumentStatus } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const documentStatuses: Record<KnownLocale, Record<DocumentStatus, string>> = {
    FR: {
        PENDING: 'En attente de génération',
        GENERATING: 'En cours de génération',
        READY: 'Prêt',
        PUBLISHED: 'Publié',
        AWAITING_SIGNATURE: 'En attente de signature',
        SIGNED: 'Signé',
        ERROR: 'Erreur',
        ARCHIVED: 'Archivé',
        ERROR_DURING_GENERATION: 'Erreur',
        ERROR_DURING_SIGNATURE: 'Erreur',
        SIGNATURE_NOT_COMPLETED: 'Signature incomplète',
        PENDING_SIGNATURE: 'En attente de signature',
    },
    EN: {
        PENDING: 'Awaiting generation',
        GENERATING: 'Generating',
        READY: 'Ready',
        PUBLISHED: 'Published',
        AWAITING_SIGNATURE: 'Awaiting signature',
        SIGNED: 'Signed',
        ERROR: 'Error',
        ARCHIVED: 'Archived',
        ERROR_DURING_GENERATION: 'Error',
        ERROR_DURING_SIGNATURE: 'Error',
        SIGNATURE_NOT_COMPLETED: 'Signing incomplete',
        PENDING_SIGNATURE: 'Awaiting signature',
    },
}

export const documentStatusLookup = new LookupList(documentStatuses)
