/* istanbul ignore file */
import isNumber from 'lodash/isNumber'
import { z } from 'zod'

export const numberSchema = z.union([z.number(), z.string()]).transform((val, ctx) => {
    if (isNumber(val)) {
        return val
    }
    const parsed = parseInt(val)
    if (isNaN(parsed)) {
        ctx.addIssue({
            code: 'custom',
            message: 'Not a number',
        })
        return z.NEVER
    }
    return parsed
})
