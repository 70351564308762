import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { CreateOperation } from './CreateOperation'

export const OperationControls: FC = () => {
    const styles = utils.useControlsStyles()

    return (
        <div className={styles.controls}>
            <CreateOperation />
        </div>
    )
}
