import { Space, Tag } from 'antd'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'
import React, { ReactNode } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanDate } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'

import { EmailStatusTags } from '../EmailStatus'
import { useStyles } from './styles'
import { Email } from './types'

const EmailBody = React.lazy(async () => import('./EmailBody').then(({ EmailBody }) => ({ default: EmailBody })))

type EmailViewProps = {
    editable: boolean
    saving: boolean
    email: Email
    onBodyChange?: (body: string) => void
}

export const EmailView: FC<EmailViewProps> = ({ editable, email, saving, onBodyChange }) => {
    return (
        <>
            <EmailHeaders email={email} />
            <EmailBody email={email} editable={editable} saving={saving} onBodyChange={onBodyChange} />
        </>
    )
}

type EmailHeaderProps = {
    label: string
}

const EmailHeader: FC<EmailHeaderProps> = ({ label, children }) => {
    const styles = useStyles()

    return (
        <div className={styles.header}>
            <Space size={1}>
                <span key="label" className={styles.headerLabel}>
                    {label}
                </span>
                {children}
            </Space>
        </div>
    )
}

type EmailHeaderAddressProps = {
    label: string
    addresses: string[] | string
}

const EmailHeaderAddress: FC<EmailHeaderAddressProps> = ({ label, addresses }) => {
    return (
        <EmailHeader label={label}>
            {(isArray(addresses) ? addresses : [addresses]).map((addr, key) => (
                <Tag key={key}>{addr}</Tag>
            ))}
        </EmailHeader>
    )
}

type EmailHeadersProps = {
    email: Email & { cc: string[]; bcc: string[]; sentAt?: DateTime | null | undefined }
}

const useEmailHeadersTranslation = createUseTranslation({
    FR: {
        subject: 'Objet',
        to: 'À',
        cc: 'CC',
        bcc: 'BCC',
        sent: 'Envoyé',
        state: 'État',
    },
    EN: {
        subject: 'Subject',
        to: 'To',
        cc: 'CC',
        bcc: 'BCC',
        sent: 'Sent',
        state: 'State',
    },
})

const EmailHeaders: FC<EmailHeadersProps> = ({ email }) => {
    const styles = useStyles()
    const { t } = useEmailHeadersTranslation()
    const { cc, bcc, subject, sentAt, to } = email

    const headers: ReactNode[] = []

    headers.push(
        <EmailHeader key="subject" label={t('subject')}>
            {subject}
        </EmailHeader>
    )

    if (!isNil(to)) {
        headers.push(<EmailHeaderAddress key="to" label={t('to')} addresses={to} />)
    }

    if (cc.length > 0) {
        headers.push(<EmailHeaderAddress key="cc" label={t('cc')} addresses={cc} />)
    }

    if (bcc.length > 0) {
        headers.push(<EmailHeaderAddress key="bcc" label={t('bcc')} addresses={bcc} />)
    }

    if (!isNil(sentAt)) {
        headers.push(
            <EmailHeader key="sentAt" label={t('sent')}>
                {humanDate(sentAt)}
            </EmailHeader>
        )
    }

    headers.push(
        <EmailHeader key="state" label={t('state')}>
            <EmailStatusTags emails={email} displayCount={false} />
        </EmailHeader>
    )

    return <div className={styles.headers}>{headers}</div>
}
