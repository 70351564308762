import { ThemeConfig, theme } from 'antd'
import { useMemo } from 'react'
import { selector, useRecoilValue } from 'recoil'
import { z } from 'zod'

import { colors } from '@publica/ui-common-styles'
import { config } from '@publica/ui-common-utils'
import { useIsDarkModeEnabled } from '@publica/ui-web-state'

import { booleanStringSchema } from '../../../../../common/src/lib/boolean'
import { numberSchema } from '../../../../../utils/src/lib/number'
import * as sizes from './sizes'

export const defaultPrimary = colors.nexdoOrange
export const defaultSecondary = colors.blue1
export const defaultFont = 'Poppins, sans-serif'

type ThemeConfigAdditionalProps = {
    tenant: {
        name: string
        shouldDisplayName: boolean
        variant: 'light' | 'dark'
        headerLogo: {
            width: number
            url: string
        }
        loginLogo: {
            url: string
        }
        colors: {
            primary: string
            secondary: string
            linkColor: string
        }
    }
}

export const useTheme = (): ThemeConfig & ThemeConfigAdditionalProps => {
    const themeConfig = useRecoilValue(configWithThemeState)
    const isDarkModeEnabled = useIsDarkModeEnabled()

    const linkColor = isDarkModeEnabled ? themeConfig.theme.colors.darkModeLink : themeConfig.theme.colors.primary

    return useMemo(
        () => ({
            algorithm: isDarkModeEnabled ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                colorPrimary: themeConfig.theme.colors.primary,
                fontFamily: themeConfig.theme.font,
                colorLinkHover: linkColor,
                colorLink: linkColor,
            },
            components: {
                Layout: {
                    headerBg: themeConfig.theme.colors.secondary,
                    headerColor: '#FFF',
                    headerHeight: sizes.layoutHeaderHeight,
                },
                Button: {
                    colorLink: isDarkModeEnabled ? '#FFF' : '#000',
                },
                Tabs: {
                    colorPrimary: linkColor,
                    colorPrimaryHover: linkColor,
                    colorPrimaryActive: linkColor,
                },
                Result: {
                    titleFontSize: 18,
                },
            },
            tenant: {
                name: themeConfig.tenant.name,
                shouldDisplayName: themeConfig.theme.displayTenantName,
                variant: themeConfig.theme.variant,
                headerLogo: {
                    width: themeConfig.theme.headerLogo.width,
                    url: themeConfig.theme.headerLogo.url,
                },
                loginLogo: {
                    url: themeConfig.theme.loginLogo.url,
                },
                colors: {
                    primary: themeConfig.theme.colors.primary,
                    secondary: themeConfig.theme.colors.secondary,
                    linkColor,
                },
            },
        }),
        [isDarkModeEnabled]
    )
}

export const useGlobalToken = () => {
    const appTheme = useTheme()
    return useMemo(() => theme.getDesignToken(appTheme), [])
}

const configWithThemeSchema = z
    .object({
        APP_THEME_PRIMARY_COLOR: z.string().default(defaultPrimary),
        APP_THEME_DARK_MODE_LINK_COLOR: z.string().optional(),
        APP_THEME_SECONDARY_COLOR: z.string().default(defaultSecondary),
        APP_THEME_FONT: z.string().default(defaultFont),
        APP_TENANT_NAME: z.string(),
        APP_THEME_DISPLAY_TENANT_NAME: booleanStringSchema.default('true'),
        APP_THEME_CUSTOM_CSS: z.string().optional(),
        APP_THEME_VARIANT: z.enum(['light', 'dark']).default('dark'),
        APP_THEME_HEADER_LOGO_URL: z.string(),
        APP_THEME_HEADER_LOGO_WIDTH: numberSchema.default(64),
        APP_THEME_LOGIN_LOGO_URL: z.string(),
    })
    .transform(
        ({
            APP_THEME_FONT,
            APP_THEME_PRIMARY_COLOR,
            APP_THEME_DARK_MODE_LINK_COLOR,
            APP_THEME_SECONDARY_COLOR,
            APP_TENANT_NAME,
            APP_THEME_DISPLAY_TENANT_NAME,
            APP_THEME_CUSTOM_CSS,
            APP_THEME_VARIANT,
            APP_THEME_HEADER_LOGO_WIDTH,
            APP_THEME_HEADER_LOGO_URL,
            APP_THEME_LOGIN_LOGO_URL,
        }) => ({
            theme: {
                colors: {
                    primary: APP_THEME_PRIMARY_COLOR,
                    secondary: APP_THEME_SECONDARY_COLOR,
                    darkModeLink: APP_THEME_DARK_MODE_LINK_COLOR ?? APP_THEME_PRIMARY_COLOR,
                },
                font: APP_THEME_FONT,
                displayTenantName: APP_THEME_DISPLAY_TENANT_NAME,
                customCSS: APP_THEME_CUSTOM_CSS,
                variant: APP_THEME_VARIANT,
                headerLogo: {
                    url: APP_THEME_HEADER_LOGO_URL,
                    width: APP_THEME_HEADER_LOGO_WIDTH,
                },
                loginLogo: {
                    url: APP_THEME_LOGIN_LOGO_URL,
                },
            },
            tenant: {
                name: APP_TENANT_NAME,
            },
        })
    )

const configWithThemeState = selector({
    key: 'configWithTheme',
    get: async ({ get }) => {
        const baseConfig = get(config.configState)
        const theme = configWithThemeSchema.parse(baseConfig)

        if (theme.theme.customCSS !== undefined) {
            addCSSLink(theme.theme.customCSS)
        }

        return theme
    },
})

const addCSSLink = (url: string) => {
    const head = document.head
    const link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url

    head.appendChild(link)
}
