import { Result } from 'antd'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

const useOperationNotMutableTranslation = createUseTranslation({
    EN: {
        notModifiable: `You cannot modify this operation`,
    },
    FR: {
        notModifiable: `Vous ne pouvez pas modifier cette opération`,
    },
})

export const OperationNotMutable: FC = () => {
    const navigate = useNavigate()
    const { t } = useOperationNotMutableTranslation()

    const back = useCallback(() => {
        navigate('..')
    }, [navigate])

    const backButton = useMemo(
        () => [
            <ActionButton key="back" size="middle" onClick={back}>
                {t('back')}
            </ActionButton>,
        ],
        [back, t]
    )

    return <Result status="warning" title={t('notModifiable')} extra={backButton} />
}
