import { Collapse, CollapseProps, FormInstance, Modal, Table } from 'antd'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'
import { atomFamily, useRecoilState } from 'recoil'
import { v4 as uuid } from 'uuid'

import * as graphql from '@publica/api-graphql'
import { FieldGroup, FieldParameters, LocalizedString } from '@publica/api-graphql'
import {
    createValueFieldInstanceWithDefaultParametersForGraphQLType,
    renderValueFieldInstanceWithValue,
} from '@publica/fields'
import { resolveLocalizedString } from '@publica/locales'
import { GraphQLValue, Value, WithValues, valuesToFormValues } from '@publica/render'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { fieldGroupLabel } from '@publica/ui-common-labels'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import * as styles from '@publica/ui-web-styles'
import { RequireTypeName } from '@publica/utils'

import { ActionButton } from '../ActionButton'
import { EditParticipantValues } from '../EditParticipantValues'

type Field<P = RequireTypeName<FieldParameters>> = {
    key: string
    position?: null | number
    name: LocalizedString
    group: FieldGroup
    parameters: P
}

type Participant = {
    values: GraphQLValue[]
}

export type ShowParticipantValuesProps = {
    participant: WithValues<Participant>
    fields: Field[]
    fieldGroups: graphql.FieldGroup[]
    editable?: {
        form?: FormInstance
        onSubmit: () => Promise<void>
    }
}

const editableFieldGroups: Set<FieldGroup> = new Set([
    'PERSONAL_INFORMATION',
    'FINANCIAL_INFORMATION',
    'BANKING_INFORMATION',
])

export const ShowParticipantValues: FC<ShowParticipantValuesProps> = ({
    participant,
    fields,
    fieldGroups,
    editable,
}) => {
    const { values } = participant
    const locale = useCurrentLocale()

    const valueMap = values.reduce((m, value) => ({ ...m, [value.key]: value }), {} as Record<string, Value>)
    const fieldValues: FieldValue[] = useMemo(
        () =>
            sortBy(fields, field => field.position).map(field => ({
                key: field.key,
                name: resolveLocalizedString(field.name, locale),
                renderedValue: renderValueFieldInstanceWithValue(
                    createValueFieldInstanceWithDefaultParametersForGraphQLType(
                        field.key,
                        field as Field<FieldParameters>,
                        locale
                    ),
                    valueMap,
                    'web',
                    true
                ),
                field,
                value: valueMap[field.key],
                editable: editableFieldGroups.has(field.group),
            })),
        [fields, locale, valueMap]
    )

    const items = useMemo(
        () =>
            fieldGroups.reduce(
                (items, fieldGroup) => {
                    const values = fieldValues.filter(f => f.field.group === fieldGroup)

                    if (values.length === 0) {
                        return items
                    }

                    return [
                        ...items,
                        {
                            key: fieldGroup,
                            label: fieldGroupLabel(fieldGroup),
                            children: <ParticipantInfoCard values={values} editable={editable} />,
                        },
                    ]
                },
                [] as NonNullable<CollapseProps['items']>
            ),
        [editable, fieldGroups, fieldValues]
    )

    return (
        <Collapse defaultActiveKey={fieldGroups} items={items}>
            {/* {fieldGroups.map(key => {
                const values = fieldValues.filter(f => f.field.group === key)

                if (values.length === 0) {
                    return null
                }

                return (
                    <Collapse.Panel key={key} header={fieldGroupLabel(key)} className={participantStyles.panel}>
                        <ParticipantInfoCard values={values} editable={editable} />
                    </Collapse.Panel>
                )
            })} */}
        </Collapse>
    )
}

type FieldValue = {
    key: string
    name: string
    renderedValue: string | undefined
    value: Value | undefined
    field: Field
    editable: boolean
}

type ParticipantInfoCardProps = {
    values: FieldValue[]
    editable?: {
        form?: FormInstance
        onSubmit: () => Promise<void>
    }
}

const fieldValueFamily = atomFamily<FieldValue | undefined, string>({
    key: 'valueFieldState',
    default: undefined,
})

const useParticipantInfoCardTranslation = createUseTranslation({
    FR: {
        edit: 'Modifier',
    },
    EN: {
        edit: 'Edit',
    },
})

const ParticipantInfoCard: FC<ParticipantInfoCardProps> = ({ values, editable }) => {
    const { t } = useParticipantInfoCardTranslation()
    const key = useMemo(() => uuid(), [])
    const [selectedFieldValue, setSelectedFieldValue] = useRecoilState(fieldValueFamily(key))

    const isEditable = editable !== undefined
    const open = selectedFieldValue !== undefined

    const fields = useMemo<Field[]>(
        () => (selectedFieldValue === undefined ? [] : [selectedFieldValue.field]),
        [selectedFieldValue]
    )
    const formValues = useMemo(
        () =>
            selectedFieldValue === undefined || selectedFieldValue.value === undefined
                ? {}
                : valuesToFormValues([selectedFieldValue.value], fields),
        [fields, selectedFieldValue]
    )

    const onClose = useCallback(() => {
        setSelectedFieldValue(undefined)
    }, [setSelectedFieldValue])

    const onSubmit = useAsyncCallback(async () => {
        await editable?.onSubmit()
        setSelectedFieldValue(undefined)
    }, [editable, setSelectedFieldValue])

    const editValue = useCallback(
        (_: unknown, fieldValue: FieldValue) => {
            if (!fieldValue.editable) {
                return null
            }

            // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
            const onClick = () => {
                setSelectedFieldValue(fieldValue)
            }

            const disabled = selectedFieldValue !== undefined
            const inProgress = selectedFieldValue?.field.key === fieldValue.field.key

            return (
                // eslint-disable-next-line react/jsx-no-bind
                <ActionButton inProgress={inProgress} disabled={disabled} onClick={onClick}>
                    {t('edit')}
                </ActionButton>
            )
        },
        [setSelectedFieldValue, t, selectedFieldValue]
    )

    return (
        <>
            <Table<FieldValue> bordered={false} dataSource={values} pagination={false} rowKey="key" showHeader={false}>
                <Table.Column<FieldValue> width={330} render={renderFieldValueName} />
                <Table.Column<FieldValue> render={renderFieldValueValue} />
                {isEditable ? <Table.Column<FieldValue> render={editValue} align="right" /> : null}
            </Table>
            {isEditable ? (
                <Modal open={open} onCancel={onClose} destroyOnClose onOk={onSubmit} okText={t('edit')}>
                    <EditParticipantValues fields={fields} values={formValues} form={editable.form} />
                </Modal>
            ) : null}
        </>
    )
}

const renderFieldValueName = (_: unknown, fieldValue: FieldValue) => fieldValue.name
const renderFieldValueValue = (_: unknown, fieldValue: FieldValue) => {
    if (fieldValue.renderedValue === undefined || fieldValue.renderedValue.trim().length === 0) {
        return noVal
    }
    return fieldValue.renderedValue
}

const useNoValueTranslation = createUseTranslation({
    FR: {
        noValue: 'Aucune valeur renseignée',
    },
    EN: {
        noValue: 'No value provided',
    },
})

const NoValue: FC = () => {
    const noValueStyles = styles.participant.useNoValueStyles()
    const { t } = useNoValueTranslation()
    return <span className={noValueStyles.noValue}>{t('noValue')}</span>
}

const noVal = <NoValue />
