import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { Operation } from '../../../data'
import { AddParticipants } from './AddParticipants'
import { ParticipantsIndex } from './ParticipantsIndex'
import { ShowParticipant } from './ShowParticipant'

type ParticipantsProps = {
    operation: Pick<Operation, 'id' | 'status' | 'name'>
}

const showParticipant = <ShowParticipant />

export const Participants: FC<ParticipantsProps> = ({ operation }) => {
    const participantsIndex = useMemo(() => <ParticipantsIndex operation={operation} />, [operation])
    const participantsAdd = useMemo(() => <AddParticipants operationId={operation.id} />, [operation.id])

    return (
        <Routes>
            <Route path="participants/*">
                <Route index element={participantsIndex} />
                <Route path="add" element={participantsAdd} />
                <Route path=":participantId" element={showParticipant} />
            </Route>
        </Routes>
    )
}
