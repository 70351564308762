import { Form } from 'antd'

import { currenciesLookup } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { LookupSelect } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

const currencyPath = ['parameters', 'currency']

type FloatCurrencyProps = {
    disabled?: boolean
}

const useFloatCurrencyTranslation = createUseTranslation({
    EN: {
        currency: 'Currency',
        currencyNote: `If the value represents a monetary figure, you can specify the currency`,
    },
    FR: {
        currency: 'Devise',
        currencyNote: `Si la valeur représente une valeur monétaire, vous pouvez indiquer la devise.`,
    },
})

export const FloatCurrency: FC<FloatCurrencyProps> = ({ disabled }) => {
    const styles = utils.useFormStyles()
    const { t } = useFloatCurrencyTranslation()

    return (
        <Form.Item label={t('currency')}>
            <div className={styles.formLabelNote}>{t('currencyNote')}</div>
            <Form.Item name={currencyPath} hasFeedback className={styles.nestedFormItem}>
                <LookupSelect lookup={currenciesLookup} allowClear disabled={disabled} />
            </Form.Item>
        </Form.Item>
    )
}
