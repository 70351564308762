import { Select, SelectProps } from 'antd'
import sortBy from 'lodash/sortBy'
import React from 'react'

import { normalizeString } from '@publica/utils'

type SortedSelectProps<I> = {
    items: I[]
    keyForItem: (item: I) => string
    labelForItem: (item: I) => string
    valueForItem: (item: I) => string | number | null
} & SelectProps

type SelectItem = {
    key: string
    label: string
    value: string | number | null
}

export const SortedSelect = <I,>({
    items,
    keyForItem,
    labelForItem,
    valueForItem,
    ...props
}: SortedSelectProps<I>): React.JSX.Element => {
    const selectItems: SelectItem[] = items.map(item => ({
        key: keyForItem(item),
        value: valueForItem(item),
        label: labelForItem(item),
    }))

    const sortedItems = sortBy(selectItems, item => normalizeString(item.label))

    return (
        <Select {...props}>
            {sortedItems.map(item => (
                <Select.Option key={item.key} value={item.value}>
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    )
}

const identity = <I,>(i: I) => i

type SortedStringSelectProps<I extends string> = {
    items: I[]
    keyForItem?: (item: I) => string
    labelForItem?: (item: I) => string
    valueForItem?: (item: I) => string | number
} & SelectProps

export const SortedStringSelect = <I extends string>({
    items,
    keyForItem,
    labelForItem,
    valueForItem,
    ...props
}: SortedStringSelectProps<I>): React.JSX.Element => {
    const resolvedKeyForItem = keyForItem ?? identity
    const resolvedLabelForItem = labelForItem ?? identity
    const resolvedValueForItem = valueForItem ?? identity

    return (
        <SortedSelect
            items={items}
            {...props}
            labelForItem={resolvedLabelForItem}
            keyForItem={resolvedKeyForItem}
            valueForItem={resolvedValueForItem}
        />
    )
}
