import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Alert, Collapse } from 'antd'
import { Rule } from 'antd/lib/form'
import { TableRowSelection } from 'antd/lib/table/interface'
import { apply } from 'json-logic-js'
import isString from 'lodash/isString'
import orderBy from 'lodash/orderBy'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { RQBJsonLogic, RuleGroupType, defaultRuleProcessorJsonLogic, formatQuery } from 'react-querybuilder'

import { GraphQLValue } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { buildMap, normalizeString } from '@publica/utils'

import * as graphql from '../../../data'
import {
    ParticipantFilter,
    useJSONLogicCompatibleParticipantValues,
    useParticipantFilterQueryFilters,
} from '../ParticipantFilter'

type Participant = Pick<graphql.Participant, 'id'> & {
    info: { name: string; code: string }
    values: GraphQLValue[]
    groups: Group[]
}
type Field = Pick<graphql.Field, 'id' | 'name' | 'key' | 'group' | 'position' | 'parameters'>
type Group = Pick<graphql.Group, 'key' | 'name'>

export type ParticipantRequirement<P extends Participant> = (p: P) => boolean

export type ParticipantSelectProps<P extends Participant = Participant> = {
    value?: P[]
    participants: P[]
    fields: Field[]
    groups: Group[]
    requirements?: ParticipantRequirement<P>[]
    onChange?: (value: P[]) => void
}

type ParticipantWithEligibility<P extends Participant> = P & {
    satisfiesRequirements: boolean
    selected: boolean
}

export const createFieldRequirement =
    (fields: { key: string }[]): ParticipantRequirement<Participant> =>
    participant => {
        const keyValueMap = buildMap('key', participant.values)
        return fields.every(({ key }) => keyValueMap[key] !== undefined)
    }

const useParticipantSelectStyles = createUseStyles({
    alert: {
        marginBottom: 10,
    },
    count: {
        padding: 10,
        color: colors.grey7,
    },
    filterBox: {
        marginBottom: 10,
        '& .ant-collapse-content-box': {
            padding: 8,
        },
        '& .ruleGroup[data-level="0"]': {
            border: 'none',
        },
    },
})

const useParticipantSelectTranslation = createUseTranslation({
    FR: {
        selectedCount_one: '{{count}} participant selectionné',
        selectedCount_other: '{{count}} participants selectionnés',
        missingKeys: `Certains participants n'ont pas encore fourni les informations nécessaires pour ce modèle`,
        code: 'Code',
        participant: 'Participant',
        filter: 'Filtrer',
    },
    EN: {
        selectedCount_one: '{{count}} participant selected',
        selectedCount_other: '{{count}} participants selected',
        missingKeys: `Some participants have not yet provided the information required by this template`,
        code: 'Code',
        participant: 'Participant',
        filter: 'Filter',
    },
})

export const ParticipantSelect = <P extends Participant>({
    participants,
    value,
    groups,
    onChange,
    fields,
    requirements = [],
}: ParticipantSelectProps<P>) => {
    const { t } = useParticipantSelectTranslation()

    const [selectedParticipantCount, setSelectedParticipantCount] = useState(value?.length ?? 0)
    const [selectedParticipantIds, setSelectedParticipantIds] = useState<Set<string>>(
        new Set((value ?? []).map(participant => participant.id))
    )
    const [filteredParticipants, setFilteredParticipants] = useState(participants)

    const participantsWithEligibility = useMemo<ParticipantWithEligibility<P>[]>(() => {
        const participantsWithEligibility = filteredParticipants.map(
            (participant): ParticipantWithEligibility<P> => ({
                ...participant,
                satisfiesRequirements: requirements.every(requirement => requirement(participant)),
                selected: selectedParticipantIds.has(participant.id),
            })
        )

        return orderBy(participantsWithEligibility, [p => p.selected, p => p.info.code], ['desc', 'asc'])
    }, [filteredParticipants, requirements, selectedParticipantIds])

    const participantEligibilityMap = useMemo(
        () =>
            participantsWithEligibility.reduce(
                (eligibility, participant) => ({
                    ...eligibility,
                    [participant.id]: participant.satisfiesRequirements,
                }),
                {} as Record<string, boolean>
            ),
        [participantsWithEligibility]
    )

    const someParticipantsAreDisabled = participantsWithEligibility.some(
        participant => !participant.satisfiesRequirements
    )

    const rowStyles = utils.useRowStyles()
    const participantSelectStyles = useParticipantSelectStyles()

    const rowClassName = useCallback(
        (participant: ParticipantWithEligibility<P>): string =>
            participant.satisfiesRequirements ? '' : rowStyles.deactivatedRow,
        [rowStyles.deactivatedRow]
    )

    const rowSelection = useMemo<TableRowSelection<ParticipantWithEligibility<P>>>(
        () => ({
            preserveSelectedRowKeys: true,
            onChange: (_, participants) => {
                setSelectedParticipantCount(participants.length)
                setSelectedParticipantIds(new Set(participants.map(participant => participant.id)))
                if (onChange !== undefined) {
                    onChange(participants)
                }
            },
            selectedRowKeys:
                value === undefined ? [] : (value ?? []).filter(p => !!participantEligibilityMap[p.id]).map(p => p.id),
            getCheckboxProps: participant => ({ disabled: !participant.satisfiesRequirements }),
        }),
        [onChange, participantEligibilityMap, value]
    )

    const jsonLogicCompatibleParticipantValues = useJSONLogicCompatibleParticipantValues(participants)

    const queryFields = useParticipantFilterQueryFilters(fields, groups)

    const onParticipantFilterUpdate = useCallback(
        (query: RuleGroupType | undefined) => {
            const filterExpression = buildFilterExpressionFromQuery(query)

            if (filterExpression === undefined || filterExpression === false) {
                setFilteredParticipants(participants)
                return
            }

            setFilteredParticipants(
                jsonLogicCompatibleParticipantValues.reduce((participants, p) => {
                    if (apply(filterExpression, p.data)) {
                        return [...participants, p.participant]
                    }
                    return participants
                }, [] as P[])
            )
        },
        [jsonLogicCompatibleParticipantValues, participants]
    )

    const columns = useMemo<FilterColumnType<ParticipantWithEligibility<P>>[]>(
        () => [
            {
                title: t('code'),
                render: (_, participant) => participant.info.code,
                align: 'left',
                width: 180,
                sorter: (a, b) => a.info.code.localeCompare(b.info.code),
            },
            {
                title: t('participant'),
                render: (_, participant) => participant.info.name,
            },
        ],
        [t]
    )

    return (
        <>
            {someParticipantsAreDisabled ? (
                <Alert className={participantSelectStyles.alert} message={t('missingKeys')} />
            ) : null}

            <Collapse className={participantSelectStyles.filterBox} expandIcon={collapseButton}>
                <Collapse.Panel header={t('filter')} key="filter">
                    <ParticipantFilter queryFields={queryFields} onChange={onParticipantFilterUpdate} />
                </Collapse.Panel>
            </Collapse>

            <FilterTable
                dataSource={participantsWithEligibility}
                showHeader={true}
                rowKey="id"
                rowSelection={rowSelection}
                rowClassName={rowClassName}
                columns={columns}
            />
            {selectedParticipantCount > 0 ? (
                <div className={participantSelectStyles.count}>
                    {t('selectedCount', { count: selectedParticipantCount })}
                </div>
            ) : null}
        </>
    )
}

const collapseButton = ({ isActive }: { isActive?: boolean }): ReactNode => {
    if (isActive === false) {
        return <PlusCircleOutlined />
    }
    return <MinusCircleOutlined />
}

const buildFilterExpressionFromQuery = (query: undefined | RuleGroupType): RQBJsonLogic | undefined => {
    if (query === undefined) {
        return undefined
    }

    return formatQuery(query, {
        format: 'jsonlogic',
        ruleProcessor: (rule, options) => {
            const transformedRule = { ...rule }

            if (rule.operator === 'before') {
                transformedRule.operator = '<'
            } else if (rule.operator === 'after') {
                transformedRule.operator = '>'
            }

            if (isString(rule.value)) {
                transformedRule.value = normalizeString(rule.value).toLowerCase()
            }

            return defaultRuleProcessorJsonLogic(transformedRule, options)
        },
    })
}

const useParticipantSelectRulesTranslation = createUseTranslation({
    EN: {
        atLeastOne: 'You must select at least one participant',
    },
    FR: {
        atLeastOne: 'Vous devez choisir au moins un participant',
    },
})

export const useParticipantSelectRules = (): Rule[] => {
    const { t } = useParticipantSelectRulesTranslation()

    return useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('atLeastOne'),
            },
            {
                validator: async (_, participants: Participant[]) => {
                    if (participants.length === 0) {
                        return Promise.reject(t('atLeastOne'))
                    }
                    return Promise.resolve()
                },
            },
        ],
        [t]
    )
}
