import { ColumnType, ExpandableConfig, TablePaginationConfig } from 'antd/lib/table/interface'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanCreatedAt } from '@publica/ui-common-labels'
import { FC, useAsyncCallback, usePaginationConfig } from '@publica/ui-common-utils'
import { DocumentStatusTags, FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationPaginatedDocumentSetsQuery } from '../../../../../data'
import { selectedDocumentSetsState } from '../state'
import { DocumentSet, Operation } from '../types'
import { DocumentSetActions } from './DocumentSetActions'
import { DocumentTableForDocumentSet } from './DocumentTableForDocumentSet'

type DocumentSetTableProps = {
    operation: Operation
}

type DocumentSetTableFilters = {
    name?: string[]
    state?: DocumentSetStatus[]
}

const documentSetStatusFilters = ['archived'] as const

type DocumentSetStatus = (typeof documentSetStatusFilters)[number]

const metaColumnWidth = 200

const useDocumentSetTableTranslation = createUseTranslation({
    EN: {
        name: 'Name',
        state: 'State',
        actions: 'Actions',
    },
    FR: {
        name: 'Nom',
        state: 'État',
        actions: 'Actions',
    },
})

export const DocumentSetTable: FC<DocumentSetTableProps> = ({ operation }) => {
    const mutable = operationIsOpen(operation)
    const { t } = useDocumentSetTableTranslation()
    const setSelectedDocumentSets = useSetRecoilState(selectedDocumentSetsState)

    const { data, loading, fetchMore } = useGetOperationPaginatedDocumentSetsQuery({
        variables: {
            operationId: operation.id,
        },
        pollInterval: usePollingRate(),
        notifyOnNetworkStatusChange: true,
    })

    const documentSets = useMemo(
        () =>
            orderBy(
                (data?.operation?.documentSetsConnection.edges ?? []).map(edge => edge.node),
                ds => ds.createdAt,
                'desc'
            ),
        [data?.operation?.documentSetsConnection.edges]
    )

    const expandable: ExpandableConfig<DocumentSet> = useMemo(
        () => ({
            expandedRowRender: (documentSet: DocumentSet) => (
                <DocumentTableForDocumentSet documentSet={documentSet} mutable={mutable} operation={operation} />
            ),
        }),
        [mutable, operation]
    )

    const columns = useMemo<(ColumnType<DocumentSet> | FilterColumnType<DocumentSet>)[]>(
        () => [
            {
                title: t('name'),
                key: 'name',
                align: 'left',
                render: (_, { name }) => name,
                filterValue: true,
            },
            {
                title: t('state'),
                key: 'state',
                render: (_, { documents }) => <DocumentStatusTags documents={documents} />,
                filters: documentSetStatusFilters.map(status => ({
                    text: <DocumentSetStatusFilterLabel status={status} />,
                    value: status,
                })),
                width: 350,
            },
            {
                title: t('created'),
                render: (_, documentSet) => humanCreatedAt(documentSet),
                width: metaColumnWidth,
            },
            {
                title: t('actions'),
                width: 300,
                align: 'right',
                render: (_, documentSet) => <DocumentSetActions documentSet={documentSet} />,
            },
        ],
        [t]
    )

    const pagination = usePaginationConfig(data?.operation?.documentSetsConnection.pageInfo)

    const onChange = useAsyncCallback(
        async (pagination: TablePaginationConfig, filters: DocumentSetTableFilters) => {
            const { pageSize, current } = pagination
            const { state, name } = filters

            const statuses = (state ?? []).reduce(
                (statuses, state) => ({ ...statuses, [state]: true }),
                {} as Partial<Record<DocumentSetStatus, boolean>>
            )

            await fetchMore({
                variables: {
                    page: Math.max((current ?? 1) - 1, 0),
                    pageSize,
                    filters: {
                        name,
                        ...statuses,
                    },
                },
            })
        },
        [fetchMore]
    )

    const onChangesOccurredInSelectedRows = useCallback(
        (rows: DocumentSet[]) => {
            setSelectedDocumentSets(rows)
        },
        [setSelectedDocumentSets]
    )

    return (
        <FilterTable
            dataSource={documentSets}
            rowKey="id"
            loading={loading}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
            expandable={expandable}
            onChangesOccurredInSelectedRows={onChangesOccurredInSelectedRows}
        />
    )
}

const useDocumentSetStatusFilterLabelTranslation = createUseTranslation({
    EN: {
        archived: 'Archived',
    },
    FR: {
        archived: 'Archivé',
    },
})

const DocumentSetStatusFilterLabel = ({ status }: { status: DocumentSetStatus }) => {
    const { t } = useDocumentSetStatusFilterLabelTranslation()
    return <>{t(status)}</>
}
