import { FC } from '@publica/ui-common-utils'

import { StatusTags } from '../Status'
import { HasDocumentStatus, useDocumentStatusDisplayInfo } from './documentStatusInfo'

type DocumentStatusTagsProps = {
    documents: HasDocumentStatus[]
}

export const DocumentStatusTags: FC<DocumentStatusTagsProps> = ({ documents }) => {
    const documentStatusDisplayInfo = useDocumentStatusDisplayInfo()
    return <StatusTags records={documents} statusDisplayInfo={documentStatusDisplayInfo} />
}
