export const insertBetweenElements = <T, I>(array: T[], fn: (idx: number) => I): (T | I)[] =>
    array.reduce((items: (T | I)[], item, idx) => {
        const newItems = [...items]

        if (newItems.length > 0) {
            newItems.push(fn(idx))
        }

        newItems.push(item)

        return newItems
    }, [])
