import { Tag } from 'antd'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import { ReactNode, useCallback, useMemo } from 'react'
import React from 'react'

import { currenciesLookup } from '@publica/lookups'
import { createUseTranslation, getCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable, TagField } from '@publica/ui-web-components'

import {
    DateFieldParameters,
    FieldParameters,
    FloatFieldParameters,
    LookupFieldParameters,
    MapFieldParameters,
    TextFieldParameters,
    useSetFieldOrderMutation,
} from '../../../../../data'
import * as graphql from '../../../../../data'
import { Field } from '../types'

type FieldGroupTableField<P extends FieldParameters = FieldParameters> = Pick<
    Field<P>,
    'name' | 'parameters' | 'group' | 'id'
> & {
    groups: Pick<graphql.Group, 'id' | 'name' | 'key'>[]
}

export type FieldGroupTableProps<P extends FieldParameters = FieldParameters> = {
    fields: FieldGroupTableField<P>[]
    fieldControlForField: (field: FieldGroupTableField<P>) => ReactNode
    operation: Pick<graphql.Operation, 'id'>
}

const useFieldGroupTableTranslation = createUseTranslation({
    EN: {
        field: 'Field',
        actions: 'Actions',
        type: 'Type',
        groups: 'Groups',
    },
    FR: {
        field: 'Champ',
        actions: 'Actions',
        type: 'Type',
        groups: 'Groupes',
    },
})

export const FieldGroupTable = <P extends FieldParameters = FieldParameters>({
    fields,
    fieldControlForField,
    operation,
}: FieldGroupTableProps<P>): React.JSX.Element => {
    const [setFieldOrderMutation] = useSetFieldOrderMutation()
    const resolveLocalizedString = useLocalizedStringResolver()
    const { t } = useFieldGroupTableTranslation()

    const tagsForField = useTagsForField()

    const columns = useMemo<FilterColumnType<FieldGroupTableField<P>>[]>(
        () => [
            {
                title: t('field'),
                render: (_, field) => resolveLocalizedString(field.name),
            },
            {
                title: t('type'),
                render: (_, field) => (
                    <TagField>
                        {tagsForField(field).map((tagText, idx) => (
                            <Tag key={`tag-${idx}`}>{tagText}</Tag>
                        ))}
                    </TagField>
                ),
                width: 600,
            },
            {
                title: t('groups'),
                render: (_, field) =>
                    field.groups.map(group => <Tag key={group.id}>{resolveLocalizedString(group.name)}</Tag>),
            },
            {
                title: t('actions'),
                render: (_, field) => fieldControlForField(field),
                align: 'right',
                width: 200,
            },
        ],
        [fieldControlForField, resolveLocalizedString, t, tagsForField]
    )

    const onSort = useAsyncCallback(
        async (fields: Field[]) => {
            await setFieldOrderMutation({
                variables: {
                    fieldIds: fields.map(field => field.id),
                    operationId: operation.id,
                },
            })
        },
        [operation.id, setFieldOrderMutation]
    )

    return <FilterTable<FieldGroupTableField<P>> columns={columns} dataSource={fields} canDragSort onSort={onSort} />
}

const useTagsForField = () => {
    const tagsForFloatField = useTagsForFloatField()
    const tagsForTextField = useTagsForTextField()
    const tagsForDateField = useTagsForDateField()
    const tagsForLookupField = useTagsForLookupField()
    const tagsForMapField = useTagsForMapField()

    return useCallback(
        ({ parameters }: FieldGroupTableField): string[] => {
            switch (parameters.__typename) {
                case 'FloatFieldParameters':
                    return tagsForFloatField(parameters)
                case 'TextFieldParameters':
                    return tagsForTextField(parameters)
                case 'DateFieldParameters':
                    return tagsForDateField(parameters)
                case 'LookupFieldParameters':
                    return tagsForLookupField(parameters)
                case 'MapFieldParameters':
                    return tagsForMapField(parameters)
            }
        },
        [tagsForDateField, tagsForFloatField, tagsForLookupField, tagsForMapField, tagsForTextField]
    )
}

const useTagsForTextFieldTranslation = createUseTranslation({
    EN: {
        email: 'Email',
        iban: 'IBAN',
        telephone: 'Telephone',
        text: 'Text',
    },
    FR: {
        email: 'Email',
        iban: 'IBAN',
        telephone: 'Téléphone',
        text: 'Texte',
    },
})

const useTagsForTextField = () => {
    const { t } = useTagsForTextFieldTranslation()

    return useCallback(
        (parameters: TextFieldParameters): string[] => {
            const tags: string[] = []

            switch (parameters.textFormat) {
                case 'EMAIL':
                    tags.push(t('email'))
                    break
                case 'IBAN':
                    tags.push(t('iban'))
                    break
                case 'PHONE':
                    tags.push(t('telephone'))
                    break
                case 'TEXT':
                    tags.push(t('text'))
            }

            return tags
        },
        [t]
    )
}

const useTagsForDateFieldTranslation = createUseTranslation({
    EN: {
        future: 'Date in the future',
        past: 'Date in the past',
    },
    FR: {
        future: 'Date Future',
        past: 'Date passée',
    },
})

const useTagsForDateField = () => {
    const { t } = useTagsForDateFieldTranslation()

    return useCallback(
        (parameters: DateFieldParameters): string[] => {
            const tags = ['Date']

            switch (parameters.range) {
                case 'ONLY_FUTURE':
                    tags.push(t('future'))
                    break
                case 'ONLY_PAST':
                    tags.push(t('past'))
                    break
            }

            return tags
        },
        [t]
    )
}

const useTagsForMapFieldTranslation = createUseTranslation({
    EN: {
        address: 'Address',
    },
    FR: {
        address: 'Adresse',
    },
})

const useTagsForMapField = () => {
    const { t } = useTagsForMapFieldTranslation()

    return useCallback(
        (parameters: MapFieldParameters): string[] => {
            switch (parameters.subType) {
                case 'ADDRESS':
                    return [t('address')]
            }
        },
        [t]
    )
}

const useTagsForLookupFieldTranslation = createUseTranslation({
    EN: {
        country: 'Country',
        maritalStatus: 'Marital status',
        nationality: 'Nationality',
        title: 'Title',
    },
    FR: {
        country: 'Pays',
        maritalStatus: 'Statut marital',
        nationality: 'Nationalité',
        title: 'Civilité',
    },
})

const useTagsForLookupField = () => {
    const { t } = useTagsForLookupFieldTranslation()

    return useCallback(
        (parameters: LookupFieldParameters): string[] => {
            switch (parameters.dictionary) {
                case 'COUNTRY':
                    return [t('country')]
                case 'MARITAL_STATUS':
                    return [t('maritalStatus')]
                case 'NATIONALITY':
                    return [t('nationality')]
                case 'TITLE':
                    return [t('title')]
            }
        },
        [t]
    )
}

const useTagsForFloatFieldTranslation = createUseTranslation({
    FR: {
        numerical: 'Valeur numérique',
        precision_zero: 'Aucun chiffre après la virgule',
        precision_one: '{{count}} chiffre après la virgule',
        precision_other: '{{count}} chiffres après la virgule',
    },
    EN: {
        numerical: 'Numerical value',
        precision_zero: 'No numbers after the decimal point',
        precision_one: '{{count}} decimal place',
        precision_other: '{{count}} decimal places',
    },
})

const useTagsForFloatField = () => {
    const { t } = useTagsForFloatFieldTranslation()

    return useCallback(
        (parameters: FloatFieldParameters): string[] => {
            const tags = [t('numerical')]
            const currency = parameters.currency
            const locale = getCurrentLocale()

            if (!isNil(currency)) {
                tags.push(currenciesLookup.labelForKeyAndLocale(currency, locale))
            }

            const precision = parameters.precision

            if (isNumber(precision)) {
                tags.push(t('precision', { count: precision }))
            }
            return tags
        },
        [t]
    )
}
