import { InMemoryCache } from '@apollo/client'
import 'react-querybuilder/dist/query-builder.scss'

import '@publica/ui-common-polyfill'
import { boot } from '@publica/ui-web-boot'

import App from './app/app'
import { Login, authStateBuilder } from './auth'
import './styles.less'

// TODO(admin-ui): useEffect audit
// FIXME-P1(admin-ui): optional unique identifier

boot(<App />, {
    auth: {
        stateBuilder: authStateBuilder,
        login: <Login />,
    },
    apollo: {
        cache: new InMemoryCache({
            typePolicies: {
                Operation: {
                    fields: {
                        participantsConnection: {
                            keyArgs: false,
                        },
                        documentSetsConnection: {
                            keyArgs: false,
                        },
                    },
                },
            },
        }),
    },
})
