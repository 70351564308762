export const removeTypeName = <T extends Record<string, unknown>>(
    object: T | undefined
): typeof object extends undefined ? undefined : Omit<T, '__typename'> => {
    if (object === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return object as any
    }

    const { __typename, ...other } = object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return other as any
}
