import { Divider, Input } from 'antd'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { addressMapKeysLookup, countryLookup } from '@publica/lookups'
import { AddressMapValue } from '@publica/render'
import { useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { LookupSelect } from '../../../'
import { Field, MapFieldParameters } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type AddressValueFormItemProps = {
    field: Field<MapFieldParameters>
}

type AddressPath = [string, keyof AddressMapValue]

export const AddressValueFormItem: FC<AddressValueFormItemProps> = ({ field }) => {
    const addressValueRules = useCommonRules()
    const resolveLocalizedString = useLocalizedStringResolver()
    const locale = useCurrentLocale()

    const addressPath = useMemo<AddressPath>(() => [field.key, 'address'], [field])
    const cityPath = useMemo<AddressPath>(() => [field.key, 'city'], [field])
    const postCodePath = useMemo<AddressPath>(() => [field.key, 'postCode'], [field])
    const countryPath = useMemo<AddressPath>(() => [field.key, 'country'], [field])

    const addressLabel = useMemo(
        () => <AddressValueFormItemLabel label={addressMapKeysLookup.labelForKeyAndLocale('address', locale)} />,
        [locale]
    )
    const cityLabel = useMemo(
        () => <AddressValueFormItemLabel label={addressMapKeysLookup.labelForKeyAndLocale('city', locale)} />,
        [locale]
    )
    const postCodeLabel = useMemo(
        () => <AddressValueFormItemLabel label={addressMapKeysLookup.labelForKeyAndLocale('postCode', locale)} />,
        [locale]
    )
    const countryLabel = useMemo(
        () => <AddressValueFormItemLabel label={addressMapKeysLookup.labelForKeyAndLocale('country', locale)} />,
        [locale]
    )

    const items = useMemo(() => {
        const postCode = (
            <FormItem key="postCode" label={postCodeLabel} field={field} name={postCodePath} rules={addressValueRules}>
                <Input />
            </FormItem>
        )

        const city = (
            <FormItem key="city" label={cityLabel} field={field} name={cityPath} rules={addressValueRules}>
                <Input />
            </FormItem>
        )

        if (locale === 'EN') {
            return [city, postCode]
        }

        return [postCode, city]
    }, [addressValueRules, cityLabel, cityPath, field, locale, postCodeLabel, postCodePath])

    return (
        <>
            <Divider />
            <h4>{resolveLocalizedString(field.name)}</h4>
            <FormItem label={addressLabel} field={field} name={addressPath} rules={addressValueRules}>
                <Input />
            </FormItem>
            {items}
            <FormItem label={countryLabel} field={field} name={countryPath} rules={addressValueRules}>
                <LookupSelect lookup={countryLookup} />
            </FormItem>
            <Divider />
        </>
    )
}

const useStyles = createUseStyles({
    label: {
        color: colors.grey7,
    },
})

const AddressValueFormItemLabel: FC<{ label: string }> = ({ label }) => {
    const styles = useStyles()
    return <span className={styles.label}>{label}</span>
}
