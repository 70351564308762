import isString from 'lodash/isString'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import { type KnownLocale, defaultLocale } from '@publica/locales'

type LookupItemValueObject = { label: string }
export type LookupItemValue = string | LookupItemValueObject

export type LookupListData<K extends string, V extends LookupItemValue = string> = Record<KnownLocale, Record<K, V>>

export type LookupItem<K extends string, V extends LookupItemValue = string> = {
    key: K
    value: V
}

export class LookupList<K extends string, V extends LookupItemValue = string, F extends K = K> {
    constructor(
        private data: LookupListData<K, V>,
        public readonly frequentKeys?: F[]
    ) {}

    keys(): [K, ...K[]] {
        return Object.keys(this.data[defaultLocale]) as [K, ...K[]]
    }

    itemsForLocale(locale: KnownLocale): Record<K, V> {
        return this.data[locale]
    }

    entriesForLocale(locale: KnownLocale): LookupItem<K, V>[] {
        return this.keys().map((key): { key: K; value: V } => {
            return {
                key,
                value: this.data[locale][key],
            }
        })
    }

    labelForKeyAndLocale(key: string, locale: KnownLocale): string {
        const label = this.data[locale][key as K] as V | undefined

        if (label === undefined) {
            throw new Error(`No value for key: ${key}@${locale}`)
        }

        if (isString(label)) {
            return label
        }

        return label.label
    }

    keyIsValid(key: string): key is K {
        return this.keys().includes(key as K)
    }

    validateKey(key: string) {
        if (this.keyIsValid(key)) {
            return key
        }

        throw new Error(`Unknown key ${key} for lookup. Valid keys are ${this.keys().join(', ')}`)
    }

    createFilteredLookup(keyFilter: (key: K) => boolean): LookupList<K, V, F> {
        const filteredFrequentKeys = this.frequentKeys === undefined ? undefined : this.frequentKeys.filter(keyFilter)
        const pickFilter = (_: unknown, key: K) => keyFilter(key)
        const filteredData = mapValues(this.data, lookup => pickBy(lookup, pickFilter)) as LookupListData<K, V>
        return new LookupList(filteredData, filteredFrequentKeys)
    }
}
