import { ColumnFilterItem } from 'antd/lib/table/interface'

import { LookupList } from '@publica/lookups'
import { useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

export const filterItemsFromLookup = <S extends string>(
    lookup: LookupList<S>,
    options?: { pick?: S[]; exclude?: S[] }
): ColumnFilterItem[] => {
    const { pick, exclude } = options ?? {}

    return lookup.keys().reduce((items, key) => {
        if (exclude !== undefined && exclude.includes(key)) {
            return items
        }

        if (pick !== undefined) {
            if (pick.includes(key)) {
                return [...items, { value: key, text: <LookupFilterItem lookupKey={key} lookup={lookup} /> }]
            } else {
                return items
            }
        }

        return [...items, { value: key, text: <LookupFilterItem lookupKey={key} lookup={lookup} /> }]
    }, [] as ColumnFilterItem[])
}

type LookupFilterItemProps = {
    lookup: LookupList<string, string>
    lookupKey: string
}

const LookupFilterItem: FC<LookupFilterItemProps> = ({ lookupKey, lookup }) => {
    const locale = useCurrentLocale()
    return <>{lookup.labelForKeyAndLocale(lookupKey, locale)}</>
}
