import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const ledgerAccountTypeSchema = z.enum(['STANDARD', 'PEA', 'PEA_PME', 'PLEDGE'])

export type LedgerAccountType = z.infer<typeof ledgerAccountTypeSchema>

const ledgerAccountTypes: Record<KnownLocale, Record<LedgerAccountType, string>> = {
    FR: {
        STANDARD: 'CTO',
        PEA: 'PEA',
        PEA_PME: 'PEA-PME',
        PLEDGE: 'Nantissement',
    },
    EN: {
        STANDARD: 'Standard',
        PEA: 'PEA',
        PEA_PME: 'PEA-PME',
        PLEDGE: 'Pledge',
    },
}

export const ledgerAccountTypeLookup = new LookupList(ledgerAccountTypes)
