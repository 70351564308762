import { z } from 'zod'

import type { JsonValue } from '@publica/utils'

// ISO 639-1 codes
export const availableLocaleTags = ['FR', 'EN'] as const

export type KnownLocale = (typeof availableLocaleTags)[number]

export const availableLocales: Record<KnownLocale, Intl.Locale> = {
    EN: new Intl.Locale('en'),
    FR: new Intl.Locale('fr'),
} as const

export const defaultLocale = 'FR'

export const localeSchema = z.enum(availableLocaleTags).default(defaultLocale)

export const localizedStringSchema = z
    .object<{ [K in KnownLocale]: z.ZodOptional<z.ZodNullable<z.ZodString>> }>({
        EN: z.string().nullish(),
        FR: z.string().nullish(),
    })
    .extend({
        [defaultLocale]: z.string(),
    })

export type LocalizedString = z.infer<typeof localizedStringSchema>

export type HasRawLocalizedStrings<K extends string> = {
    [Key in K]: JsonValue
}

export type WithLocalizedStrings<K extends string, R extends HasRawLocalizedStrings<K>> = Omit<R, K> & {
    [Key in K]: LocalizedString
}

export const parseLocalizedString = <K extends string, R extends HasRawLocalizedStrings<K>>(
    key: K,
    record: R
): WithLocalizedStrings<K, R> => {
    const { [key]: rawLocalizedString, ...other } = record

    const parsed = localizedStringSchema.parse(rawLocalizedString)

    return {
        ...other,
        [key]: parsed,
    } as WithLocalizedStrings<K, R>
}

export const resolveLocalizedString = (localized: LocalizedString, locale: KnownLocale): string =>
    localized[locale] ?? localized[defaultLocale]

export const isKnownLocale = (maybeLocale: string): maybeLocale is KnownLocale =>
    // eslint-disable-next-line lodash/prefer-includes
    availableLocaleTags.indexOf(maybeLocale as KnownLocale) >= 0
