import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'

import { getResource } from '@publica/trace'
import { logger } from '@publica/ui-common-logger'
import { config } from '@publica/ui-common-utils'

let provider: WebTracerProvider | undefined | null

const defaultCollectorTracesEndpoint = 'http://localhost:4318'

const setupTracing = () => {
    if (provider !== undefined) {
        return
    }

    provider = null

    void config
        .get()
        .then(config => {
            if (!config.trace) {
                provider = null
                logger.info('Tracing deactivated')
                return
            }

            provider = new WebTracerProvider({
                resource: new Resource(getResource()),
            })

            const host = config.traceCollector ?? defaultCollectorTracesEndpoint

            provider.addSpanProcessor(
                new BatchSpanProcessor(
                    new OTLPTraceExporter({
                        url: `${host}/v1/traces`,
                        headers: {
                            'x-publica-trace': 'yes',
                        },
                    })
                )
            )

            provider.register({
                // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
                contextManager: new ZoneContextManager(),
                propagator: new W3CTraceContextPropagator(),
            })

            // Registering instrumentations
            registerInstrumentations({
                instrumentations: [
                    getWebAutoInstrumentations({
                        '@opentelemetry/instrumentation-fetch': {
                            // TODO(opentelemetry): real URLS
                            propagateTraceHeaderCorsUrls: [/.*/],
                            clearTimingResources: true,
                        },
                        '@opentelemetry/instrumentation-xml-http-request': {
                            // TODO(opentelemetry): real URLS
                            propagateTraceHeaderCorsUrls: [/.*/],
                        },
                    }),
                ],
            })

            logger.info('Tracing activated')
        })
        .catch(() => {
            logger.warn('Error initializing tracing')
        })
}

setupTracing()
