import { Modal } from 'antd'
import { ReactNode, useCallback, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ControlledFormWithElement, FormValues } from '@publica/ui-web-utils'

import { ActionButton } from '../ActionButton'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalFormProps<Result = any, Values extends FormValues = any> = {
    form: ControlledFormWithElement<Result, Values>
    text: string
    icon: ReactNode
    disabled?: boolean
}

export const ModalForm: FC<ModalFormProps> = ({ text, icon, form, disabled = false }) => {
    const [open, setOpen] = useState(false)

    const { t } = useModalFormTranslation()

    const showForm = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        form.control.reset()
    }, [form])

    const submit = useAsyncCallback(async () => {
        await form.control.submit().then(({ validated }) => {
            if (validated) {
                close()
            }
        })
    }, [close, form.control])

    return (
        <>
            <ActionButton size="middle" icon={icon} onClick={showForm} disabled={disabled}>
                {text}
            </ActionButton>
            <Modal
                open={open}
                onCancel={close}
                cancelText={t('cancel')}
                title={text}
                confirmLoading={form.control.loading}
                okText={t('create')}
                onOk={submit}
                destroyOnClose
            >
                {form.element}
            </Modal>
        </>
    )
}

const useModalFormTranslation = createUseTranslation({
    FR: {},
    EN: {},
})
