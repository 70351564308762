import { Form, Modal } from 'antd'
import { useCallback, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { GetOperationDocument, useUpdateOperationMutation } from '../../../../../data'
import { OperationForm, OperationFormValues } from '../../../../components'

type EditOperationProps = {
    operation: {
        id: string
        name: string
        code: string
        fromAddress?: string | null
    }
}

const useUpdateOperationTranslation = createUseTranslation({
    FR: {
        updateOperation: `Modifier l'opération`,
    },
    EN: {
        updateOperation: 'Edit the operation',
    },
})

export const EditOperation: FC<EditOperationProps> = ({ operation }) => {
    const [open, setOpen] = useState(false)

    const [form] = Form.useForm<OperationFormValues>()
    const [updateOperationMutation, { loading }] = useUpdateOperationMutation()

    const { t } = useUpdateOperationTranslation()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        form.resetFields()
    }, [form])

    const update = useCallback(async () => {
        const { name, fromAddress } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(fromAddress)

        return updateOperationMutation({
            variables: {
                id: operation.id,
                operation: {
                    name,
                    fromAddress,
                },
            },
            refetchQueries: [GetOperationDocument],
        }).then(() => {
            close()
        })
    }, [form, updateOperationMutation, operation.id, close])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await update()
    }, [update, form])

    return (
        <>
            <ActionButton size="middle" onClick={onClick}>
                {t('updateOperation')}
            </ActionButton>
            <Modal
                open={open}
                onCancel={close}
                cancelText={t('cancel')}
                title={t('updateOperation')}
                confirmLoading={loading}
                okText={t('update')}
                onOk={onOk}
            >
                <OperationForm form={form} operation={operation} />
            </Modal>
        </>
    )
}
