const makeId = (length: number): string => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

type IDGenerator = () => string

type IDGeneratorContext = {
    defaultGenerator: IDGenerator
    providedGenerator: IDGenerator | undefined
    setGenerator: (generator: IDGenerator | undefined) => void
    getId: () => string
}

export const idGenerator: IDGeneratorContext = {
    defaultGenerator: () => makeId(5),
    providedGenerator: undefined,

    /* istanbul ignore next */
    setGenerator(generator) {
        this.providedGenerator = generator
    },

    getId(): string {
        return (this.providedGenerator ?? this.defaultGenerator)()
    },
}
