import isError from 'lodash/isError'

export class RenderableError extends Error {
    public readonly isRenderable = true

    constructor(
        public message: string,
        public options?: { title?: string; back?: string }
    ) {
        super(message)
        Object.setPrototypeOf(this, RenderableError.prototype)
    }
}

export class NetworkError extends RenderableError {
    constructor(message: string) {
        super(message)
    }
}

export class NotFoundError extends RenderableError {
    constructor(message: string) {
        super(message)
    }
}

const isRenderableError = (e: unknown): e is RenderableError =>
    isError(e) && (e as RenderableError).isRenderable === true

type ErrorMessage = {
    title?: string
    message: string
    back?: string
}

export const getErrorMessage = (e: unknown, defaultTitle: string, defaultMessage: string): ErrorMessage => {
    if (!isRenderableError(e)) {
        return {
            title: defaultTitle,
            message: defaultMessage,
        }
    }

    return {
        title: e.options?.title,
        message: e.message,
        back: e.options?.back,
    }
}
