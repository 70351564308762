import { Card, Col, Result, Row } from 'antd'
import isNil from 'lodash/isNil'
import { useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { useApiClient } from '@publica/ui-common-network'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useCreateOperationExportLinkMutation, useGetOperationExportSuspenseQuery } from '../../../../data'

const useDownloadOperationExportTranslation = createUseTranslation({
    FR: {
        unknownExport: `Aucun export trouvé`,
        exportNotReady: `L'export n'est pas encore disponible au téléchargement`,
        exportExpired: `L'export n'est plus disponible au téléchargement`,
        createNewExport: `Veuillez créer un nouvel export`,
        ready: `L'export de l'opération est disponible au téléchargement`,
        download: `Télécharger l'export`,
        error: `Une erreur s'est produite lors du téléchargement`,
    },
    EN: {
        unknownExport: 'Unknown export',
        exportNotReady: 'The export is not yet ready for download',
        exportExpired: `The export is no longer available`,
        createNewExport: `Please create a new export`,
        ready: 'The operation export is ready for download',
        download: `Download the export`,
        error: `An error occurred during the download`,
    },
})

const useStyles = createUseStyles({
    result: {
        marginTop: 30,
    },
    download: {
        marginTop: 25,
    },
    downloadContext: {
        display: 'none',
    },
})

export const DownloadOperationExport: FC = () => {
    const { operationExportId } = useParams()

    assert.defined(operationExportId)

    const { data } = useGetOperationExportSuspenseQuery({
        variables: {
            id: operationExportId,
        },
    })

    const operationExport = data.operationExport

    if (isNil(operationExport)) {
        return <UnknownOperationExport />
    }

    switch (operationExport.status) {
        case 'CREATING':
        case 'PENDING':
        case 'ERROR':
            return <OperationExportNotReady />
        case 'EXPIRED':
            return <OperationExportExpired />
        case 'READY':
            return <OperationExportReady operationExportId={operationExportId} />
    }
}

type OperationExportResultProps = {
    title: string
    subTitle?: string
    status: 'error' | 'warning' | 'success'
}

const OperationExportResult: FC<OperationExportResultProps> = ({ children, status, title, subTitle }) => {
    const styles = useStyles()

    return (
        <Row justify="center" className={styles.result}>
            <Col span={10}>
                <Card>
                    <Result title={title} subTitle={subTitle} status={status} extra={children} />
                </Card>
            </Col>
        </Row>
    )
}

const UnknownOperationExport: FC = () => {
    const { t } = useDownloadOperationExportTranslation()
    return <OperationExportResult status="error" title={t('unknownExport')} />
}

const OperationExportNotReady: FC = () => {
    const { t } = useDownloadOperationExportTranslation()
    return <OperationExportResult status="warning" title={t('exportNotReady')} />
}

const OperationExportExpired: FC = () => {
    const { t } = useDownloadOperationExportTranslation()
    return <OperationExportResult status="error" title={t('exportExpired')} subTitle={t('createNewExport')} />
}

type OperationExportReadyProps = {
    operationExportId: string
}

const OperationExportReady: FC<OperationExportReadyProps> = ({ operationExportId }) => {
    const { t } = useDownloadOperationExportTranslation()
    const styles = useStyles()

    const [createOperationExportLinkMutation] = useCreateOperationExportLinkMutation({
        variables: {
            id: operationExportId,
        },
    })

    const [inProgress, setInProgress] = useState(false)
    const [downloaded, setDownloaded] = useState(false)
    const downloadContextRef = useRef<HTMLDivElement>(null)
    const endpoints = useApiClient().endpoints

    const download = useAsyncCallback(async () => {
        setInProgress(true)

        const { data } = await createOperationExportLinkMutation()

        const ref = downloadContextRef.current

        assert.defined(data)
        assert.defined(ref)

        const url = endpoints.operations.downloadOperationExport(data.createOperationExportLink.id)

        const el = document.createElement('a')

        el.href = url
        ref.append(el)
        el.click()
        el.remove()

        setDownloaded(true)
        setInProgress(false)
    }, [createOperationExportLinkMutation, endpoints.operations])

    const disabled = inProgress || downloaded

    return (
        <OperationExportResult status="success" title={t('ready')}>
            <ActionButton className={styles.download} size="middle" onClick={download} disabled={disabled}>
                {t('download')}
            </ActionButton>
            <div className={styles.downloadContext} ref={downloadContextRef} />
        </OperationExportResult>
    )
}
