import { Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/interface'
import flatten from 'lodash/flatten'
import { useCallback, useMemo } from 'react'

import { DocumentFileType } from '@publica/api-graphql'
import { documentFileDownloadMenuItemKeys, documentFileTypeDisplayOrder, documentIsGenerated } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ArrayItem, lang } from '@publica/utils'

import { DocumentControlsProps } from '../DocumentTable'
import { DownloadButton } from '../DownloadButton'
import { useDownloadArchive } from '../hooks/downloadDocuments'

type Document = ArrayItem<DocumentControlsProps['documents']>
type DocumentFile = ArrayItem<Document['files']>

type DocumentSetDownloadButtonProps = {
    operationId: string
    documents: Document[]
}

export const DocumentSetDownloadButton: FC<DocumentSetDownloadButtonProps> = ({ documents, operationId }) => {
    const downloadableDocuments = documents.filter(d => documentIsGenerated(d))

    const onlyHasOriginals = downloadableDocuments.every(
        doc => doc.files.length === 1 && doc.files[0]?.type === 'ORIGINAL'
    )

    if (onlyHasOriginals) {
        return <DocumentSetActionsDownloadSingleButton documents={downloadableDocuments} operationId={operationId} />
    }

    return <DocumentSetActionsDownloadMultipleButton documents={downloadableDocuments} operationId={operationId} />
}

const useDocumentSetActionsDownloadSingleTranslation = createUseTranslation({
    FR: {
        download_one: `Télécharger {{count}} document`,
        download_other: `Télécharger {{count}} documents`,
    },
    EN: {
        download_one: `Download {{count}} document`,
        download_other: `Download {{count}} documents`,
    },
})

type DocumentSetActionsDownloadSingleButtonProps = {
    operationId: string
    documents: Document[]
}

const DocumentSetActionsDownloadSingleButton: FC<DocumentSetActionsDownloadSingleButtonProps> = ({
    documents,
    operationId,
}) => {
    const count = documents.length
    const hasDownloadableDocuments = count > 0
    const documentFileIds = documents.map(d => d.id)

    const [downloading, downloadDocuments, notificationContext] = useDownloadArchive({ operationId, documentFileIds })
    const downloadDocumentsEnabled = hasDownloadableDocuments && !downloading

    const { t } = useDocumentSetActionsDownloadSingleTranslation()

    return (
        <>
            {notificationContext}
            <DownloadButton
                size="middle"
                inProgress={downloading}
                disabled={!downloadDocumentsEnabled}
                onClick={downloadDocuments}
            >
                {downloadDocumentsEnabled ? t('download', { count }) : undefined}
            </DownloadButton>
        </>
    )
}

const useDocumentSetActionsDownloadMultipleTranslation = createUseTranslation({
    FR: {
        signed_one: 'Document signé',
        signed_other: '{{count}} Documents signés',
        certificates_one: 'Certificat',
        certificates_other: '{{count}} Certificats',
        unsigned_one: 'Document non-signé',
        unsigned_other: '{{count}} Documents non-signés',
        all: 'Tous les documents',
    },
    EN: {
        signed_one: 'Signed document',
        signed_other: '{{count}} signed documents',
        certificates_one: 'Certificate',
        certificates_other: '{{count}} certificates',
        unsigned_one: 'Unsigned document',
        unsigned_other: '{{count}} unsigned documents',
        all: 'All documents',
    },
})

type DocumentSetActionsDownloadMultipleButtonProps = {
    operationId: string
    documents: Document[]
}

const DocumentSetActionsDownloadMultipleButton: FC<DocumentSetActionsDownloadMultipleButtonProps> = ({
    documents,
    operationId,
}) => {
    const { t } = useDocumentSetActionsDownloadMultipleTranslation()

    const documentFilesByType = useMemo(
        () =>
            documents.reduce(
                (byType, document) => {
                    for (const documentFile of document.files) {
                        let forType: DocumentFile[] | undefined = byType[documentFile.type]

                        if (forType === undefined) {
                            forType = byType[documentFile.type] = []
                        }

                        forType.push(documentFile)
                    }

                    return byType
                },
                {} as Partial<Record<DocumentFileType, DocumentFile[]>>
            ),
        [documents]
    )

    const [downloadingAll, downloadAllDocuments, allNotificationContext] = useDownloadArchive({
        operationId,
        documentFileIds: flatten(Object.values(documentFilesByType)).map(documentFile => documentFile.id),
        groupBy: 'participant',
    })
    const [downloadingOriginals, downloadOriginalDocuments, originalsNotificationContext] = useDownloadArchive({
        operationId,
        name: t('unsigned'),
        documentFileIds: (documentFilesByType['ORIGINAL'] ?? []).map(documentFile => documentFile.id),
    })
    const [downloadingSigned, downloadSignedDocuments, signedNotificationContext] = useDownloadArchive({
        operationId,
        name: t('signed'),
        documentFileIds: (documentFilesByType['SIGNED'] ?? []).map(documentFile => documentFile.id),
    })
    const [downloadingCertificates, downloadCertificateDocuments, certificateNotificationContext] = useDownloadArchive({
        name: t('certificates'),
        operationId,
        documentFileIds: (documentFilesByType['CERTIFICATE'] ?? []).map(documentFile => documentFile.id),
    })

    const notificationContexts = [
        allNotificationContext,
        originalsNotificationContext,
        signedNotificationContext,
        certificateNotificationContext,
    ]

    const downloading = downloadingAll || downloadingOriginals || downloadingSigned || downloadingCertificates

    const items = useMemo(() => {
        const items = documentFileTypeDisplayOrder.reduce((items, fileType) => {
            const fileTypeItems: DocumentFile[] | undefined = documentFilesByType[fileType]

            if (fileTypeItems === undefined) {
                return items
            }

            const count = fileTypeItems.length

            let item: ItemType

            switch (fileType) {
                case 'ORIGINAL':
                    item = {
                        key: documentFileDownloadMenuItemKeys.ORIGINAL,
                        label: t('unsigned', { count }),
                    }
                    break

                case 'SIGNED':
                    item = {
                        key: documentFileDownloadMenuItemKeys.SIGNED,
                        label: t('signed', { count }),
                    }
                    break

                case 'CERTIFICATE':
                    item = {
                        key: documentFileDownloadMenuItemKeys.CERTIFICATE,
                        label: t('certificates', { count }),
                    }
                    break

                default:
                    throw lang.NotExhaustedError(fileType)
            }

            return [...items, item]
        }, [] as ItemType[])

        return [...items, { type: 'divider' } as const, { key: documentFileDownloadMenuItemKeys.ALL, label: t('all') }]
    }, [documentFilesByType, t])

    const downloadArchiveFromMenu = useCallback(
        ({ key }: { key: string }) => {
            switch (key) {
                case documentFileDownloadMenuItemKeys.ALL:
                    downloadAllDocuments()
                    break
                case documentFileDownloadMenuItemKeys.ORIGINAL:
                    downloadOriginalDocuments()
                    break
                case documentFileDownloadMenuItemKeys.SIGNED:
                    downloadSignedDocuments()
                    break
                case documentFileDownloadMenuItemKeys.CERTIFICATE:
                    downloadCertificateDocuments()
                    break
            }
        },
        [downloadAllDocuments, downloadCertificateDocuments, downloadOriginalDocuments, downloadSignedDocuments]
    )

    const menu = useMemo(
        () => ({
            onClick: downloadArchiveFromMenu,
            items,
        }),
        [downloadArchiveFromMenu, items]
    )
    const downloadButton = useMemo(() => <DownloadButton size="middle" />, [])
    const buttonsRender = useCallback(() => [undefined, downloadButton], [downloadButton])

    return (
        <>
            {notificationContexts}
            <Dropdown.Button buttonsRender={buttonsRender} menu={menu} disabled={downloading} />
        </>
    )
}
