import { z } from 'zod'

import { datetimeSchema } from '@publica/datetime'

export const textValueSchema = z.object({
    key: z.string(),
    textValue: z.string(),
    type: z.enum(['text', 'TextValue']).transform(() => 'TextValue' as const),
})

export const floatValueSchema = z.object({
    key: z.string(),
    floatValue: z.number(),
    type: z.enum(['float', 'FloatValue']).transform(() => 'FloatValue' as const),
})

export const booleanValueSchema = z.object({
    key: z.string(),
    booleanValue: z.boolean(),
    type: z.enum(['boolean', 'BooleanValue']).transform(() => 'BooleanValue' as const),
})

export const dateValueSchema = z.object({
    key: z.string(),
    dateValue: datetimeSchema,
    type: z.enum(['date', 'DateValue']).transform(() => 'DateValue' as const),
})

export const lookupValueSchema = z.object({
    key: z.string(),
    lookupValue: z.object({
        key: z.string(),
        dictionary: z.enum(['COUNTRY', 'NATIONALITY', 'MARITAL_STATUS', 'TITLE']),
    }),
    type: z.literal('LookupValue'),
})

export const mapValueEntrySchema = z.object({
    key: z.string(),
    value: z.string(),
})

export const mapValueSchema = z.object({
    key: z.string(),
    mapValue: z.array(mapValueEntrySchema),
    type: z.literal('MapValue'),
})

export const valueSchema = z.union([
    textValueSchema,
    floatValueSchema,
    booleanValueSchema,
    dateValueSchema,
    lookupValueSchema,
    mapValueSchema,
])

export const valuesArraySchema = z.array(valueSchema)
