import { CheckCircleTwoTone, ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons'
import { Alert, Button, Space, Tag } from 'antd'
import sortBy from 'lodash/sortBy'
import { FC, ReactElement, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { fullName } from '@publica/common'
import { participantTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { ActionButton, FilterColumnType, FilterTable, TagField, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { lang } from '@publica/utils'

import { AddParticipantActionWithProgress } from '../../types'

type ImportParticipantsPlanSummaryProps = {
    actions: AddParticipantActionWithProgress[]
    loading: boolean
    inProgress: boolean
    onConfirm: () => void
    onCancel: () => void
}

export const ImportParticipantsPlanSummary: FC<ImportParticipantsPlanSummaryProps> = ({
    actions,
    loading,
    inProgress,
    onCancel,
    onConfirm,
}) => {
    const controlsStyles = utils.useControlsStyles()
    const { t } = useImportParticipantsPlanSummaryTranslation()

    const columns = useImportParticipantsPlanSummaryColumns()
    const rows = useMemo(() => sortBy(actions, p => p.row), [actions])

    const status = useMemo<'error' | 'pending' | 'completed'>(() => {
        if (actions.some(participant => participant.status === 'ERROR')) {
            return 'error'
        }

        if (actions.some(participant => participant.progress === 'COMPLETE')) {
            return 'completed'
        }

        return 'pending'
    }, [actions])

    return (
        <>
            {status === 'error' ? <ErrorsInUploadedFile /> : <ImportCount participants={rows} />}

            <FilterTable<AddParticipantActionWithProgress>
                dataSource={rows}
                rowKey="row"
                columns={columns}
                loading={loading}
            />
            <div className={controlsStyles.footerControls}>
                <Space>
                    {status !== 'completed' ? (
                        <>
                            <Button onClick={onCancel} disabled={inProgress}>
                                {t('cancel')}
                            </Button>
                            <ActionButton
                                size="middle"
                                inProgress={inProgress}
                                disabled={status === 'error'}
                                onClick={onConfirm}
                            >
                                {t('confirm')}
                            </ActionButton>
                        </>
                    ) : (
                        <ActionButton size="middle" onClick={onCancel}>
                            {t('back')}
                        </ActionButton>
                    )}
                </Space>
            </div>
        </>
    )
}

const useImportParticipantsPlanSummaryTranslation = createUseTranslation({
    EN: {
        confirm: 'Confirm',
    },
    FR: {
        confirm: 'Confirmer',
    },
})

const useImportParticipantsPlanSummaryColumns: () => FilterColumnType<AddParticipantActionWithProgress>[] = () => {
    const { t } = useImportParticipantsPlanSummaryColumnsTranslation()
    const locale = useCurrentLocale()

    return useMemo<FilterColumnType<AddParticipantActionWithProgress>[]>(
        () => [
            {
                title: t('row'),
                width: 50,
                render: (_, { row }) => row,
            },
            {
                align: 'center',
                width: 50,
                render: (_, { progress }) => {
                    switch (progress) {
                        case 'NOT_STARTED':
                            return <ClockCircleTwoTone twoToneColor={colors.grey6} />
                        case 'IN_PROGRESS':
                            return icons.Loading
                        case 'COMPLETE':
                            return <CheckCircleTwoTone twoToneColor={colors.success} />
                        case 'WONT_START':
                            return <WarningTwoTone twoToneColor={colors.warning} />
                    }
                },
            },
            {
                title: t('participant'),
                render: (_, participant) => {
                    if (participant.status !== 'ERROR') {
                        return participant.uploadedParticipant.legalEntity.name
                    }

                    switch (participant.errorType) {
                        case 'PARSE_ERROR':
                            return t('parseError')
                        case 'ACCOUNT_IS_ADMIN':
                            return t('accountIsAdmin')
                        case 'ACCOUNT_LEGAL_ENTITY_MISMATCH':
                            return t('accountDoesNotMatchLegalEntity')
                        case 'DUPLICATE_PARTICIPANT':
                            return t('duplicateParticipant')
                    }
                },
            },
            {
                title: t('participantType'),
                render: (_, participant) => {
                    if (participant.status === 'ERROR') {
                        return ''
                    }

                    return participantTypeLookup.labelForKeyAndLocale(
                        participant.uploadedParticipant.legalEntity.type,
                        locale
                    )
                },
            },
            {
                title: t('account'),
                render: (_, participant) => {
                    if (participant.status === 'ERROR') {
                        return ''
                    }

                    return fullName(participant.uploadedParticipant.account)
                },
            },
            {
                title: t('actionsToTake'),
                render: (_, { status }) => {
                    const tags: ReactElement[] = []

                    switch (status) {
                        case 'CREATE_ACCOUNT':
                            tags.push(
                                <Tag key="createAccount" icon={icons.AddUser} color="success">
                                    {t('createAccount')}
                                </Tag>
                            )
                        // eslint-disable-next-line no-fallthrough
                        case 'CREATE_LEGAL_ENTITY':
                            tags.push(
                                <Tag key="createLegalEntity" icon={icons.AddUser} color="success">
                                    {t('createLegalEntity')}
                                </Tag>
                            )
                        // eslint-disable-next-line no-fallthrough
                        case 'CREATE_PARTICIPANT':
                            tags.push(
                                <Tag key="createParticipant" icon={icons.AddUser} color="success">
                                    {t('createParticipant')}
                                </Tag>
                            )
                            break
                        case 'NO_ACTION_REQUIRED':
                            tags.push(
                                <Tag key="noop" icon={icons.Done}>
                                    {t('noop')}
                                </Tag>
                            )
                            break
                        case 'ERROR':
                            tags.push(
                                <Tag key="error" icon={icons.Warning}>
                                    {t('error')}
                                </Tag>
                            )
                            break
                        default:
                            throw lang.NotExhaustedError(status)
                    }

                    return <TagField>{tags}</TagField>
                },
                align: 'left',
            },
        ],
        [locale, t]
    )
}

const useImportParticipantsPlanSummaryColumnsTranslation = createUseTranslation({
    EN: {
        row: 'Row',
        account: 'Account',
        participant: 'Participant',
        participantType: 'Type',
        actionsToTake: `Planned actions`,
        createAccount: `An account will be created`,
        createLegalEntity: `A legal entity will be created`,
        createParticipant: `A participant will be created`,
        noop: `No actions are required`,
        error: `An error occurred`,
        parseError: `There is an unknown problem with the line`,
        accountIsAdmin: 'The specified account is an administrator',
        accountDoesNotMatchLegalEntity: 'The account does not match the legal entity',
        duplicateParticipant: 'The participant appears multiple times in the import file',
    },
    FR: {
        row: 'Ligne',
        account: 'Compte',
        participant: 'Participant',
        participantType: 'Type',
        actionsToTake: `Actions à prendre`,
        createAccount: `Un compte sera créé`,
        createLegalEntity: `Une entité légale sera créée`,
        createParticipant: `Un participant sera créé`,
        noop: `Aucune action n'est requise`,
        error: `Une erreur s'est produite`,
        parseError: `La ligne n'est pas correctement renseignée`,
        accountIsAdmin: 'Le compte correspond à un compte administrateur',
        accountDoesNotMatchLegalEntity: `Le compte ne correspond pas à l'entité légale`,
        duplicateParticipant: `Le participant apparaît plusieurs fois dans l'import`,
    },
})

const useErrorsInUploadedFileTranslation = createUseTranslation({
    FR: {
        error: `Le fichier contient une ou plusieurs lignes erronées`,
        errorTitle: `Le fichier n'est pas exploitable`,
    },
    EN: {
        error: 'The file contains one or more rows with errors',
        errorTitle: 'The file is invalid',
    },
})

const ErrorsInUploadedFile: FC = () => {
    const styles = useImportMessageStyles()
    const { t } = useErrorsInUploadedFileTranslation()

    const message = t('errorTitle')
    const description = t('error')

    return <Alert className={styles.message} type="error" message={message} description={description} />
}

type ImportCountProps = {
    participants: unknown[]
}

const useImportCountTranslation = createUseTranslation({
    FR: {
        message_one: `Un participant sera importé`,
        message_other: `{{count}} participants seront importés`,
    },
    EN: {
        message_one: `A single participant will be imported`,
        message_other: `{{count}} participants will be imported`,
    },
})

const ImportCount: FC<ImportCountProps> = ({ participants }) => {
    const styles = useImportMessageStyles()
    const { t } = useImportCountTranslation()

    return (
        <Alert className={styles.message} type="success" description={t('message', { count: participants.length })} />
    )
}

const useImportMessageStyles = createUseStyles({
    message: {
        '& .ant-alert-message': {
            fontSize: '1em',
            fontWeight: 'bold',
            marginBottom: 10,
        },
        marginBottom: 15,
    },
    list: {
        '& li:not(:last-child)': {
            marginBottom: 5,
        },
        marginBottom: 0,
    },
})
