import { selector, useRecoilValue } from 'recoil'
import { z } from 'zod'

import { config } from '../'

const configSchema = z
    .object({
        APP_MSAL_CLIENT_ID: z.string(),
        APP_MSAL_AUTHORITY: z.string(),
        APP_MSAL_REDIRECT_URI: z.string(),
        APP_MSAL_SCOPES: z.string().transform(scopes => scopes.split(' ')),
    })
    .transform(({ APP_MSAL_AUTHORITY, APP_MSAL_CLIENT_ID, APP_MSAL_REDIRECT_URI, APP_MSAL_SCOPES, ...other }) => ({
        msal: {
            clientId: APP_MSAL_CLIENT_ID,
            authority: APP_MSAL_AUTHORITY,
            redirectUri: APP_MSAL_REDIRECT_URI,
            scopes: APP_MSAL_SCOPES,
            ...other,
        },
    }))

const configState = selector({
    key: 'configWithMSAL',
    get: ({ get }) => {
        const baseConfig = get(config.configState)
        return parseConfig(baseConfig)
    },
})

export const parseConfig = (config: config.BaseConfig) => {
    const { apiHost, environment, ...other } = config

    const configWithMSAL = configSchema.parse(other)

    return {
        apiHost,
        environment,
        ...configWithMSAL,
    }
}

export const useConfig = () => useRecoilValue(configState)
