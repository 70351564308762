import { useCallback } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { useDarkMode, useSetDarkMode } from '@publica/ui-web-state'

import { FooterToggle } from '../FooterToggle'

const useDarkModeToggleTranslation = createUseTranslation({
    FR: {
        forceDarkMode: `Active le mode sombre`,
        forceLightMode: `Désactiver le mode sombre`,
        auto: `Adapter automatiquement le mode sombre`,
    },
    EN: {
        forceDarkMode: 'Enable dark mode',
        forceLightMode: 'Disable dark mode',
        auto: 'Use automatic dark mode',
    },
})

export const DarkModeToggle: FC = () => {
    const darkMode = useDarkMode()
    const setDarkMode = useSetDarkMode()
    const { t } = useDarkModeToggleTranslation()

    const forceDarkMode = useCallback(() => {
        setDarkMode(() => true)
    }, [setDarkMode])

    const forceLightMode = useCallback(() => {
        setDarkMode(() => false)
    }, [setDarkMode])

    const activateAutoDarkMode = useCallback(() => {
        setDarkMode(() => 'auto')
    }, [setDarkMode])

    let text: string
    let onClick: () => void

    switch (darkMode) {
        case 'auto':
            text = t('forceLightMode')
            onClick = forceLightMode
            break
        case false:
            text = t('forceDarkMode')
            onClick = forceDarkMode
            break
        case true:
            text = t('auto')
            onClick = activateAutoDarkMode
            break
    }

    return <FooterToggle text={text} onClick={onClick} />
}
