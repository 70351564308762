import { Spin } from 'antd'
import { Suspense } from 'react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    spinner: {
        textAlign: 'center',
        padding: [30, 0],
    },
})

export const Spinner: FC = () => {
    const styles = useStyles()

    return (
        <div className={styles.spinner}>
            <Spin size="large" />
        </div>
    )
}

const suspenseSpinner = <Spinner />

export const SuspenseSpinner: FC = ({ children }) => <Suspense fallback={suspenseSpinner}>{children}</Suspense>
