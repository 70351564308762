import { Alert, ButtonProps, Popconfirm } from 'antd'
import { ReactNode, useCallback, useMemo, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { OperationStatus, useChangeOperationStatusMutation } from '../../../../../data'

type Operation = {
    id: string
    name: string
    status: OperationStatus
}

type OperationStatusControlProps = {
    operation: Operation
}

const useOperationStatusControlTranslation = createUseTranslation({
    EN: {
        open: 'Start the operation',
        openConfirm: 'Do you want to start the operation?',
        irreversible: 'This is irreversible',
        reOpen: 'Re-open the operation',
        reOpenConfirm: 'Do you want to re-open the operation?',
        close: 'Close the operation',
        closeConfirm: 'Do you want to close the operation?',
        reversible: 'This is reversible',
        confirm: 'Confirm',
    },
    FR: {
        open: `Lancer l'opération`,
        openConfirm: `Souhaitez-vous lancer l'opération ?`,
        irreversible: 'Cette action est irréversible',
        reOpen: "Ré-ouvrir l'opération",
        reOpenConfirm: "Souhaitez-vous ré-ouvrir l'opération ?",
        close: `Clôturer l'opération`,
        closeConfirm: `Souhaitez-vous clôturer l'opération ?`,
        reversible: 'Cette action est réversible',
        confirm: 'Confirmer',
    },
})

export const OperationStatusControl: FC<OperationStatusControlProps> = ({ operation }) => {
    const styles = utils.useConfirmationStyles()
    const [changeOperationStatusMutation, { loading }] = useChangeOperationStatusMutation()
    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false)
    const { t } = useOperationStatusControlTranslation()

    const [label, status, title] = useMemo<[string, OperationStatus, ReactNode]>(() => {
        switch (operation.status) {
            case 'DRAFT':
                return [
                    t('open'),
                    'OPEN',
                    <>
                        <p>{t('openConfirm')}</p>
                        <Alert
                            type="warning"
                            message={t('irreversible')}
                            className={styles.confirmationAlert}
                            showIcon
                        />
                    </>,
                ]
            case 'CLOSED':
                return [t('reOpen'), 'OPEN', t('reOpenConfirm')]
            case 'OPEN':
                return [
                    t('close'),
                    'CLOSED',
                    <>
                        <p>{t('closeConfirm')}</p>
                        <Alert type="info" message={t('reversible')} className={styles.confirmationAlert} showIcon />
                    </>,
                ]
        }
    }, [operation.status, styles.confirmationAlert, t])

    const displayConfirmation = useCallback(() => {
        setConfirmationIsOpen(true)
    }, [])

    const hideConfirmation = useCallback(() => {
        setConfirmationIsOpen(false)
    }, [])

    const changeOperationStatus = useCallback(() => {
        hideConfirmation()
        void changeOperationStatusMutation({
            variables: {
                id: operation.id,
                status,
            },
        })
    }, [changeOperationStatusMutation, hideConfirmation, operation.id, status])

    const okButtonProps = useMemo<ButtonProps>(() => ({ loading }), [loading])

    return (
        <Popconfirm
            title={title}
            open={confirmationIsOpen}
            placement={'bottom'}
            onCancel={hideConfirmation}
            onConfirm={changeOperationStatus}
            okText={t('confirm')}
            okButtonProps={okButtonProps}
            icon={null}
            overlayClassName={styles.confirmation}
        >
            <ActionButton size="middle" inProgress={confirmationIsOpen || loading} onClick={displayConfirmation}>
                {label}
            </ActionButton>
        </Popconfirm>
    )
}
