import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const usePageLoadStyles = createUseStyles({
    initializing: {
        background: colors.grey9,
        height: '100%',
        '& .ant-spin': {
            color: '#FFF',
        },
        '& .ant-spin-dot-item': {
            backgroundColor: '#FFF',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
const indicator = <LoadingOutlined style={{ fontSize: 96, color: colors.grey8 }} spin />

export const PageLoad: FC = () => {
    const styles = usePageLoadStyles()

    return (
        <div className={styles.initializing}>
            <Spin size="large" indicator={indicator} />
        </div>
    )
}
