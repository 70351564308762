import { Form } from 'antd'
import { FormItemProps as BaseFormItemProps } from 'antd/lib/form'

import { useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { Field } from '../../types'

type FormItemProps = {
    field: Field
} & Omit<BaseFormItemProps, 'hasFeedback'>

export const FormItem: FC<FormItemProps> = ({ children, field, label, name, ...props }) => {
    const resolveLocalizedString = useLocalizedStringResolver()

    return (
        <Form.Item
            label={label ?? resolveLocalizedString(field.name)}
            name={name ?? field.key}
            key={field.key}
            hasFeedback
            {...props}
        >
            {children}
        </Form.Item>
    )
}
