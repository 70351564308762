import { Form } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'

import { SortedSelect } from '../../../../../components'

const precisionPath = ['parameters', 'precision']

type FloatPrecisionProps = {
    disabled?: boolean
}

const useFloatPrecisionTranslation = createUseTranslation({
    EN: {
        precision: 'Decimal Points',
        precisionNote: `You can specify the number of decimal points that will be used during rendering. This can be changed within templates.`,
    },
    FR: {
        precision: 'Chiffres après la virgule',
        precisionNote: `Vous pouvez indiquer le nombre de chiffres après la virgule. Ce règlage peut être modifié au cas par cas dans les modèles.`,
    },
})

export const FloatPrecision: FC<FloatPrecisionProps> = () => {
    const styles = utils.useFormStyles()
    const { t } = useFloatPrecisionTranslation()

    return (
        <Form.Item label={t('precision')}>
            <div className={styles.formLabelNote}>{t('precisionNote')}</div>
            <Form.Item name={precisionPath} hasFeedback className={styles.nestedFormItem}>
                <SortedSelect<number>
                    items={precisionOptions}
                    labelForItem={labelForPrecision}
                    keyForItem={keyForPrecision}
                    valueForItem={valueForPrecision}
                />
            </Form.Item>
        </Form.Item>
    )
}

const keyForPrecision = (precision: number): string => precision.toString()
const labelForPrecision = keyForPrecision

const valueForPrecision = (precision: number) => precision

const precisionOptions = [2, 0]
