import isFunction from 'lodash/isFunction'
import mapValues from 'lodash/mapValues'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Endpoint = (...args: any[]) => string

type EndpointCollection = { [key: string]: Endpoint | EndpointCollection }

export const addHostToEndpoints = <C extends EndpointCollection>(host: string, endpoints: C): C =>
    mapValues(endpoints, (val, _key) => {
        if (isFunction(val)) {
            return (...args: unknown[]) => {
                const endpointWithoutHost = val(...args)
                return `${host}${endpointWithoutHost}`
            }
        } else {
            return addHostToEndpoints(host, val)
        }
    }) as C
