import { z } from 'zod'

const truthyValues = ['yes', 'y', 'true', '1', 'on'] as const
const falsyValues = ['no', 'n', 'false', '0', 'off'] as const

export const booleanStringSchema = z
    .enum([...truthyValues, ...falsyValues])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .transform(val => truthyValues.includes(val as any))

export const falsyStringSchema = z.enum(falsyValues).transform((): false => false)

export const truthyStringSchema = z.enum(truthyValues).transform((): true => true)
