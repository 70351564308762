import { TableProps } from 'antd'
import { ExpandableConfig, TableRowSelection } from 'antd/lib/table/interface'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'
import { ReactNode, useMemo, useState } from 'react'

import {
    Document as BaseDocument,
    DocumentFile as BaseDocumentFile,
    Signatory as BaseSignatory,
} from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanCreatedAt } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'

import { DocumentStatusIcon } from '../DocumentStatus'
import { FilterColumnType, FilterTable } from '../FilterTable'
import { SignatoryStatusIcon, SignatoryStatusTags } from '../SignatoryStatus'
import { OperationsInProgressState, useOperationsInProgressState } from '../hooks/operationsInProgressState'

type Document = Pick<BaseDocument, 'id' | 'status' | 'name' | 'createdAt' | 'requiresSignature'> & {
    participant: { info: { code: string; name: string } }
    signatories: Signatory[]
    files: DocumentFile[]
}

type DocumentFile = Pick<BaseDocumentFile, 'type' | 'id' | 'name' | 'format' | 'url'>

type Signatory = Pick<BaseSignatory, 'name' | 'status' | 'order' | 'id'>

export type DocumentTableConfig = {
    mutable: boolean
    displaySignatureStatus: boolean
    groupByParticipant: boolean
    columns: Partial<{
        participantName: boolean
        documentName: 'SHORT' | 'FULL'
        createdAt: boolean
    }>
}

export type DocumentControlsProps = {
    operationId: string
    documents: Document[]
    selectedDocuments: Document[]
    config: DocumentTableConfig
    setDocumentOperationState: (state: OperationsInProgressState) => void
    isDocumentOperationInProgress: (id: string) => boolean
}

export type DocumentActionsProps = {
    operationId: string
    document: Document
    config: DocumentTableConfig
    setDocumentOperationState: (state: OperationsInProgressState) => void
    isDocumentOperationInProgress: (id: string) => boolean
}

export type DocumentTableProps = {
    operationId: string
    documents: Document[]
    documentControls?: (props: DocumentControlsProps) => ReactNode
    documentActions?: (props: DocumentActionsProps) => ReactNode
    config?: Partial<DocumentTableConfig>
} & Pick<TableProps<Document>, 'bordered' | 'loading' | 'showHeader'>

const defaultConfig: DocumentTableConfig = {
    mutable: false,
    displaySignatureStatus: false,
    groupByParticipant: false,
    columns: {
        participantName: true,
        documentName: undefined,
        createdAt: false,
    },
}

const useDocumentTableTranslation = createUseTranslation({
    FR: {
        signatoryStatus: 'État des signataires',
        created: 'Créé',
        participant: 'Participant',
        code: 'Code',
        document: 'Document',
        actions: 'Actions',
    },
    EN: {
        signatoryStatus: 'Signatory status',
        created: 'Created',
        participant: 'Participant',
        code: 'Code',
        document: 'Document',
        actions: 'Actions',
    },
})

// TODO(admin-ui): add modifyable document name

export const DocumentTable: FC<DocumentTableProps> = ({ config, ...props }) => {
    const resolvedConfig: DocumentTableConfig = merge({}, defaultConfig, config)

    if (!resolvedConfig.groupByParticipant) {
        return <UngroupedDocumentTable config={resolvedConfig} {...props} />
    }

    return <GroupedDocumentTable config={resolvedConfig} {...props} />
}

type ParticipantWithDocuments = Document['participant'] & { documents: Document[]; id: string }

type GroupedDocumentTableProps = Omit<DocumentTableProps, 'config'> & { config: DocumentTableConfig }

const GroupedDocumentTable: FC<GroupedDocumentTableProps> = ({ documents, config, ...props }) => {
    const { t } = useDocumentTableTranslation()

    const participants = Object.values(
        documents.reduce(
            (participants, document) => {
                const participant = document.participant
                let participantWithDocuments = participants[participant.info.code]

                if (participantWithDocuments === undefined) {
                    participantWithDocuments = { ...participant, id: participant.info.code, documents: [document] }
                    return { ...participants, [participant.info.code]: participantWithDocuments }
                }

                participantWithDocuments.documents.push(document)
                return participants
            },
            {} as Record<string, ParticipantWithDocuments>
        )
    )

    const ungroupedDocumentTableConfig = useMemo<DocumentTableConfig>(
        () => ({
            ...config,
            columns: {
                ...config.columns,
                participantName: false,
                documentName: 'SHORT',
            },
        }),
        [config]
    )

    const expandable: ExpandableConfig<ParticipantWithDocuments> = useMemo(
        () => ({
            expandedRowRender: ({ documents }) => (
                <UngroupedDocumentTable config={ungroupedDocumentTableConfig} documents={documents} {...props} />
            ),
        }),
        [ungroupedDocumentTableConfig, props]
    )

    const columns = useMemo<FilterColumnType<ParticipantWithDocuments>[]>(
        () => [
            {
                title: t('code'),
                render: (_, participant) => participant.info.code,
                width: 120,
                align: 'left',
            },
            {
                title: t('participant'),
                render: (_, participant) => participant.info.name,
                align: 'left',
            },
        ],
        [t]
    )

    return (
        <FilterTable<ParticipantWithDocuments>
            columns={columns}
            expandable={expandable}
            rowKey="id"
            dataSource={participants}
        />
    )
}

type UngroupedDocumentTableProps = Omit<DocumentTableProps, 'config'> & { config: DocumentTableConfig }

const UngroupedDocumentTable: FC<UngroupedDocumentTableProps> = ({
    operationId,
    documents,
    config,
    documentControls,
    documentActions,
    ...props
}) => {
    const [isDocumentOperationInProgress, setDocumentOperationState] = useOperationsInProgressState()
    const { t } = useDocumentTableTranslation()

    const sortedDocuments = useMemo(() => sortBy(documents, doc => doc.participant.info.code), [documents])
    const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([])

    const expandable = useMemo<ExpandableConfig<Document> | undefined>(() => {
        if (!config.displaySignatureStatus) {
            return
        }

        return {
            rowExpandable: doc => doc.signatories.length > 1,
            expandedRowRender: ({ signatories }) => <DocumentSignatoryTable signatories={signatories} />,
        }
    }, [config.displaySignatureStatus])

    const displaySelection = documentControls !== undefined || documentActions !== undefined

    const handleSelection: TableRowSelection<Document> | undefined = !displaySelection
        ? undefined
        : {
              onChange: (_, documents) => {
                  setSelectedDocuments(documents)
              },
          }

    const columns = useMemo<FilterColumnType<Document>[]>(() => {
        const cols: FilterColumnType<Document>[] = [
            {
                render: (_, doc) => <DocumentStatusIcon document={doc} />,
                align: 'center',
                width: 40,
            },
        ]

        if (config.columns.participantName) {
            cols.push({
                title: t('code'),
                render: (_, doc) => doc.participant.info.code,
                width: 120,
                align: 'left',
            })

            cols.push({
                title: t('participant'),
                render: (_, doc) => doc.participant.info.name,
                align: 'left',
            })
        }

        if (config.columns.documentName === 'SHORT') {
            cols.push({
                title: t('document'),
                align: 'left',
                render: (_, { name }) => name,
                sorter: (a, b) => a.name.localeCompare(b.name),
            })
        } else if (config.columns.documentName === 'FULL') {
            cols.push({
                title: t('document'),
                align: 'left',
                render: (_, { files, id }) => files.find(file => file.id === id)?.name,
                sorter: (a, b) => a.name.localeCompare(b.name),
            })
        }

        if (config.displaySignatureStatus) {
            cols.push({
                title: t('signatoryStatus'),
                align: 'left',
                render: (_, { signatories }) => <SignatoryStatusTags signatories={signatories} />,
            })
        }

        if (config.columns.createdAt) {
            cols.push({
                title: t('created'),
                render: (_, doc) => humanCreatedAt(doc),
                width: 220,
                sorter: (a, b) => a.createdAt.diff(b.createdAt).milliseconds,
            })
        }

        if (documentActions !== undefined) {
            cols.push({
                title: t('actions'),
                align: 'right',
                width: 250,
                render: (_, document) =>
                    documentActions({
                        operationId,
                        document,
                        config,
                        isDocumentOperationInProgress,
                        setDocumentOperationState,
                    }),
            })
        }

        return cols
    }, [config, documentActions, t, operationId, isDocumentOperationInProgress, setDocumentOperationState])

    return (
        <>
            {documentControls === undefined
                ? null
                : documentControls({
                      operationId,
                      documents,
                      selectedDocuments,
                      config,
                      isDocumentOperationInProgress,
                      setDocumentOperationState,
                  })}

            <FilterTable<Document>
                expandable={expandable}
                dataSource={sortedDocuments}
                rowKey="id"
                rowSelection={handleSelection}
                columns={columns}
                {...props}
            />
        </>
    )
}

const useDocumentSignatoryTranslation = createUseTranslation({
    FR: {
        signatory: 'Signataire',
    },
    EN: {
        signatory: 'Signatory',
    },
})

type DocumentSignatoryTableProps = {
    signatories: Signatory[]
}

const DocumentSignatoryTable: FC<DocumentSignatoryTableProps> = ({ signatories }) => {
    const sortedSignatories = useMemo(() => sortBy(signatories, signatory => signatory.order), [signatories])
    const { t } = useDocumentSignatoryTranslation()

    const columns = useMemo<FilterColumnType<Signatory>[]>(
        () => [
            {
                width: 40,
                align: 'center',
                render: (_, sig) => <SignatoryStatusIcon signatory={sig} />,
            },
            {
                title: t('signatory'),
                render: (_, { name }) => name,
            },
        ],
        [t]
    )

    return <FilterTable<Signatory> dataSource={sortedSignatories} rowKey="id" columns={columns} />
}
