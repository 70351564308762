import { Select, SelectProps } from 'antd'
import isString from 'lodash/isString'
import sortBy from 'lodash/sortBy'
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select'
import { useCallback, useMemo } from 'react'

import { LookupItem, LookupItemValue, LookupList } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { matches, normalizeString } from '@publica/utils'

type LookupSelectProps<K extends string, V extends LookupItemValue> = {
    lookup: LookupList<K, V>
    label?: (item: LookupItem<K, V>) => string
} & Omit<SelectProps<K>, 'showSearch' | 'autoClearSearchValue' | 'filterSort'>

const sortLookupOptions = <K extends string, V extends LookupItemValue>(items: LookupItem<K, V>[]) =>
    sortBy(items, ({ value }) => (isString(value) ? normalizeString(value) : normalizeString(value.label)))

const lookupOptionsToSelectOptions = <K extends string, V extends LookupItemValue>(
    items: LookupItem<K, V>[],
    label?: (item: LookupItem<K, V>) => string
) =>
    items.map(({ key, value }) => (
        <Select.Option key={key} value={key} label={label?.({ key, value })}>
            {isString(value) ? value : value.label}
        </Select.Option>
    ))

const useLookupSelectTranslation = createUseTranslation({
    FR: {
        frequent: 'Fréquemment utilisées',
        other: 'Autres',
    },
    EN: {
        frequent: 'Frequently used',
        other: 'Others',
    },
})

export const LookupSelect = <K extends string, V extends LookupItemValue>({
    lookup,
    label,
    ...props
}: LookupSelectProps<K, V>) => {
    const { t } = useLookupSelectTranslation()
    const locale = useCurrentLocale()

    const search = useCallback<FilterFunc<DefaultOptionType>>((input, option) => {
        if (option !== undefined) {
            const label = option.children as unknown as string
            return matches.matchesFilter(label, input)
        }
        return false
    }, [])

    const [frequentOptions, otherOptions] = useMemo(() => {
        const items = sortLookupOptions(lookup.entriesForLocale(locale))

        let frequentOptions: LookupItem<K, V>[] = []
        let otherOptions: LookupItem<K, V>[] = []

        if (lookup.frequentKeys === undefined) {
            frequentOptions = []
            otherOptions = items
        } else {
            for (const item of items) {
                if (lookup.frequentKeys.includes(item.key)) {
                    frequentOptions.push(item)
                } else {
                    otherOptions.push(item)
                }
            }
        }

        return [
            lookupOptionsToSelectOptions<K, V>(frequentOptions, label),
            lookupOptionsToSelectOptions<K, V>(otherOptions, label),
        ] as const
    }, [label, locale, lookup])

    const hasFrequentOptions = frequentOptions.length > 0

    return (
        <Select
            popupMatchSelectWidth={false}
            {...props}
            showSearch
            autoClearSearchValue
            filterOption={search}
            optionLabelProp={label === undefined ? undefined : 'label'}
        >
            {hasFrequentOptions ? (
                <>
                    <Select.OptGroup label={t('frequent')}>{frequentOptions}</Select.OptGroup>
                    <Select.OptGroup label={t('other')}>{otherOptions}</Select.OptGroup>
                </>
            ) : (
                otherOptions
            )}
        </Select>
    )
}
