import { Tag } from 'antd'

import { OperationStatus } from '@publica/api-graphql'
import { operationStatusLabel } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'

type OperationStatusTagProps = {
    status: OperationStatus
}

export const OperationStatusTag: FC<OperationStatusTagProps> = ({ status }) => {
    const label = operationStatusLabel(status)

    switch (status) {
        case 'CLOSED':
            return <Tag color="blue">{label}</Tag>
        case 'DRAFT':
            return <Tag>{label}</Tag>
        case 'OPEN':
            return <Tag color="green">{label}</Tag>
    }
}
