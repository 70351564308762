import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const titleSchema = z.union([z.literal('MR'), z.literal('MRS')])

export type Title = z.infer<typeof titleSchema>

const titles: Record<KnownLocale, Record<Title, string>> = {
    FR: {
        MR: 'Monsieur',
        MRS: 'Madame',
    },
    EN: {
        MR: 'Mister',
        MRS: 'Madam',
    },
}

export const titlesLookup = new LookupList(titles)
