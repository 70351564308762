import { FC } from '@publica/ui-common-utils'

import { StatusIcon } from '../Status'
import { HasDocumentStatus, useDocumentStatusDisplayInfo } from './documentStatusInfo'

type DocumentStatusIconProps = {
    document: HasDocumentStatus
}

export const DocumentStatusIcon: FC<DocumentStatusIconProps> = ({ document }) => {
    const documentStatusDisplayInfo = useDocumentStatusDisplayInfo()
    return <StatusIcon record={document} statusDisplayInfo={documentStatusDisplayInfo} />
}
