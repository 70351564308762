import { Token, useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { AppAuthState } from '@publica/ui-web-auth'
import { ActionButton, icons } from '@publica/ui-web-components'

const useLoginTranslation = createUseTranslation({
    EN: {
        login: 'Log in',
    },
    FR: {
        login: 'Se connecter',
    },
})

export const Login: FC = () => {
    const { isAuthenticating, state } = useAuthState<Token, AppAuthState>()
    const { t } = useLoginTranslation()

    const login = useAsyncCallback(async () => state.login(), [state])

    return (
        <ActionButton inProgress={isAuthenticating} onClick={login} size="large" icon={icons.Login}>
            {t('login')}
        </ActionButton>
    )
}
