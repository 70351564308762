import { Button } from 'antd'
import { useCallback } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { UploadWell, UploadedFileRequest } from '@publica/ui-web-components'

import { getUploadedParticipants, useDownloadTemplate } from './template'
import { UploadedParticipantOrError } from './types'

type UploadExcelFileProps = {
    onUploadedParticipants: (participants: UploadedParticipantOrError[]) => Promise<void>
}

export const UploadExcelFile: FC<UploadExcelFileProps> = ({ onUploadedParticipants }) => {
    const template = useDownloadTemplate()
    const styles = useUploadParticipantsWellStyles()
    const { t } = useUploadParticipantsWellTranslations()

    const onUploaded = useCallback(
        async (upload: UploadedFileRequest) => {
            const uploadedParticipants = await getUploadedParticipants(upload.file)
            await onUploadedParticipants(uploadedParticipants)
        },
        [onUploadedParticipants]
    )

    return (
        <>
            <UploadWell onUpload={onUploaded} accept=".xlsx" multiple />

            <p className={styles.subText}>
                <Button type="link" onClick={template}>
                    {t('model')}
                </Button>
            </p>
        </>
    )
}

const useUploadParticipantsWellStyles = createUseStyles({
    subText: {
        textAlign: 'center',
        marginTop: 15,
        '& button': {
            color: colors.grey7,
        },
    },
})

const useUploadParticipantsWellTranslations = createUseTranslation({
    EN: {
        model: `You can download a template here`,
    },
    FR: {
        model: `Vous pouvez télécharger un modèle à remplir ici`,
    },
})
