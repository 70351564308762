import { ApiEndpoints, apiEndpointsWithHost } from '@publica/endpoints'

import { AttachmentsClient } from './attachments'
import { AuthClient } from './auth'
import { DocumentSetsClient } from './documentSets'
import { DocumentsClient } from './documents'
import { OperationsClient } from './operations'
import { ParticipantsClient } from './participants'
import { TemplatesClient } from './templates'

export class Client {
    public readonly endpoints: ApiEndpoints

    public readonly auth: AuthClient
    public readonly documents: DocumentsClient
    public readonly documentSets: DocumentSetsClient
    public readonly attachments: AttachmentsClient
    public readonly templates: TemplatesClient
    public readonly participants: ParticipantsClient
    public readonly operations: OperationsClient

    constructor(host: string, getToken?: () => Promise<string>) {
        this.endpoints = apiEndpointsWithHost(host)
        this.auth = new AuthClient(this.endpoints, getToken)
        this.documents = new DocumentsClient(this.endpoints, getToken)
        this.attachments = new AttachmentsClient(this.endpoints, getToken)
        this.templates = new TemplatesClient(this.endpoints, getToken)
        this.participants = new ParticipantsClient(this.endpoints, getToken)
        this.documentSets = new DocumentSetsClient(this.endpoints, getToken)
        this.operations = new OperationsClient(this.endpoints, getToken)
    }
}
