import type { LogTransport } from './types'

export const consoleTransport: LogTransport = {
    log: ({ levelName, message, name, component, context, timestamp }) => {
        let messageWithPrefix: string
        const { labels: _, ...contextWithoutLabels } = context

        const serializedContext =
            __DEBUG__ && Object.keys(contextWithoutLabels).length > 0
                ? ` (${JSON.stringify(contextWithoutLabels, null, 2)})`
                : ''

        const ts = timestamp.toISOTime()

        if (component !== undefined) {
            messageWithPrefix = `[${ts}][${name}][${component}][${levelName}]: ${message}${serializedContext}`
        } else {
            messageWithPrefix = `[${ts}][${name}][${levelName}]: ${message}${serializedContext}`
        }

        // eslint-disable-next-line no-console
        console[levelName](messageWithPrefix)
    },
}
