import { InputNumber } from 'antd'
import { CSSProperties, useMemo } from 'react'

import { KnownLocale } from '@publica/locales'
import { useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { Field, FloatFieldParameters } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type FloatValueFormItemProps = {
    field: Field<FloatFieldParameters>
}

const fullWidth: CSSProperties = {
    width: '100%',
}

export const FloatValueFormItem: FC<FloatValueFormItemProps> = ({ field }) => {
    const floatValueRules = useCommonRules()

    const precision = field.parameters.precision
    const locale = useCurrentLocale()
    const decimalSeparator = useMemo(() => getDecimalSeparator(locale), [locale])

    return (
        <FormItem field={field} rules={floatValueRules}>
            <InputNumber style={fullWidth} precision={precision} decimalSeparator={decimalSeparator} controls={false} />
        </FormItem>
    )
}

const getDecimalSeparator = (locale: KnownLocale) => {
    const numberWithDecimalSeparator = 1.1
    const format = Intl.NumberFormat(locale)
    return format.formatToParts(numberWithDecimalSeparator).find(part => part.type === 'decimal')!.value
}
