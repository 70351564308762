import { Space } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'
import { ReactNode, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { Email as BaseEmail } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanCreatedAt } from '@publica/ui-common-labels'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { EmailStatusIcon } from '../EmailStatus'
import { FilterColumnType, FilterTable } from '../FilterTable'
import { OperationsInProgressState, useOperationsInProgressState } from '../hooks/operationsInProgressState'

const useStyles = createUseStyles({
    modifiedIndicator: {
        color: colors.grey6,
    },
})

type Email = Pick<BaseEmail, 'id' | 'status' | 'manuallyModified' | 'subject' | 'createdAt'> & {
    participant: { info: { code: string; name: string } }
}

export type EmailTableConfig = {
    mutable: boolean
    columns: Partial<{
        participantName: boolean
        emailSubject: boolean
        createdAt: boolean
    }>
    sortBy: (email: Email) => unknown
}

export type EmailControlsProps = {
    emails: Email[]
    selectedEmails: Email[]
    config: EmailTableConfig
    setEmailOperationState: (state: OperationsInProgressState) => void
    isEmailOperationInProgress: (id: string) => boolean
}

export type EmailActionsProps = {
    email: Email
    config: EmailTableConfig
    setEmailOperationState: (state: OperationsInProgressState) => void
    isEmailOperationInProgress: (id: string) => boolean
}

export type EmailTableProps = {
    emails: Email[]
    config?: Partial<EmailTableConfig>
    emailControls?: (props: EmailControlsProps) => ReactNode
    emailActions?: (props: EmailActionsProps) => ReactNode
    bordered?: boolean
    loading?: boolean
}

const defaultConfig: EmailTableConfig = {
    mutable: false,
    columns: {
        participantName: true,
        emailSubject: false,
        createdAt: false,
    },
    sortBy: email => email.participant.info.code,
}

const useEmailTableTranslation = createUseTranslation({
    FR: {
        participant: 'Participant',
        code: 'Code',
        subject: 'Objet',
        actions: 'Actions',
    },
    EN: {
        participant: 'Participant',
        code: 'Code',
        subject: 'Subject',
        actions: 'Actions',
    },
})

export const EmailTable: FC<EmailTableProps> = ({ emails, config, emailActions, emailControls, ...props }) => {
    const resolvedConfig: EmailTableConfig = merge({}, defaultConfig, config)
    const { t } = useEmailTableTranslation()

    const [isEmailOperationInProgress, setEmailOperationState] = useOperationsInProgressState()

    const [selectedEmails, setSelectedEmails] = useState<Email[]>([])
    const sortedEmails = sortBy(emails, resolvedConfig.sortBy)

    const displaySelection = emailControls !== undefined || emailActions !== undefined

    const handleSelection: TableRowSelection<Email> | undefined = !displaySelection
        ? undefined
        : {
              onChange: (_, emails) => {
                  setSelectedEmails(emails)
              },
          }

    const columns = useMemo<FilterColumnType<Email>[]>(() => {
        const cols: FilterColumnType<Email>[] = [
            {
                render: (_, email) => <EmailStatusIcon email={email} />,
                align: 'center',
                width: 40,
            },
        ]

        if (resolvedConfig.columns.participantName) {
            cols.push({
                title: t('code'),
                align: 'left',
                width: 120,
                render: (_, email) => email.participant.info.code,
            })

            cols.push({
                title: t('participant'),
                align: 'left',
                render: (_, email) => (
                    <WithModifiedMarker email={email}>{email.participant.info.name}</WithModifiedMarker>
                ),
            })
        }

        if (resolvedConfig.columns.emailSubject) {
            cols.push({
                title: t('subject'),
                align: 'left',
                render: (_, email) => <WithModifiedMarker email={email}>{email.subject}</WithModifiedMarker>,
            })
        }

        if (resolvedConfig.columns.createdAt) {
            cols.push({
                title: t('created'),
                render: (_, email) => humanCreatedAt(email),
                width: 220,
            })
        }

        if (emailActions !== undefined) {
            cols.push({
                title: t('actions'),
                align: 'right',
                width: 250,
                render: (_, email) =>
                    emailActions({ email, config: resolvedConfig, isEmailOperationInProgress, setEmailOperationState }),
            })
        }

        return cols
    }, [emailActions, isEmailOperationInProgress, resolvedConfig, setEmailOperationState, t])

    return (
        <>
            {emailControls === undefined
                ? null
                : emailControls({
                      emails,
                      selectedEmails,
                      config: resolvedConfig,
                      isEmailOperationInProgress,
                      setEmailOperationState,
                  })}
            <FilterTable<Email>
                dataSource={sortedEmails}
                rowKey="id"
                rowSelection={handleSelection}
                columns={columns}
                {...props}
            ></FilterTable>
        </>
    )
}

type WithModifiedMarkerProps = {
    email: Email
}

const useWithModifiedMarker = createUseTranslation({
    FR: {
        modified: 'modifié',
    },
    EN: {
        modified: 'modified',
    },
})

const WithModifiedMarker: FC<WithModifiedMarkerProps> = ({ email, children }) => {
    const styles = useStyles()
    const { t } = useWithModifiedMarker()
    return (
        <Space>
            {children}
            {email.manuallyModified ? <em className={styles.modifiedIndicator}>({t('modified')})</em> : null}
        </Space>
    )
}
