import { Form, Input } from 'antd'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { availableLocaleTags, defaultLocale } from '@publica/locales'
import { languagesLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'
import { useCommonRules } from '@publica/ui-web-utils'

type LocalizedStringInputProps = {
    path: string
}

const useLocalizedStringStyles = createUseStyles({
    itemList: {
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
    },
})

const useLocalizedStringTranslation = createUseTranslation({
    FR: {
        instruction: 'Veuillez renseigner les valeurs pour toutes les langues.',
    },
    EN: {
        instruction: 'Please provide values for all languages',
    },
})

export const LocalizedStringInput: FC<LocalizedStringInputProps> = ({ path }) => {
    const locale = useCurrentLocale()
    const languages = languagesLookup.itemsForLocale(locale)
    const styles = utils.useFormStyles()
    const localizedStringStyles = useLocalizedStringStyles()
    const { t } = useLocalizedStringTranslation()
    const { required } = useCommonRules()

    const items = useMemo(
        () => (
            <>
                <div className={styles.formLabelNote}>{t('instruction')}</div>
                <div>
                    {availableLocaleTags.map(locale => (
                        <Form.Item
                            key={locale}
                            // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                            name={[path, locale]}
                            className={localizedStringStyles.itemList}
                            hasFeedback
                            rules={locale === defaultLocale ? required : undefined}
                        >
                            <Input placeholder={languages[locale]} />
                        </Form.Item>
                    ))}
                </div>
            </>
        ),
        [languages, localizedStringStyles.itemList, path, required, styles.formLabelNote, t]
    )

    return <div>{items}</div>
}
