import { DateTime } from 'luxon'
import { z } from 'zod'

const baseTransactionSchema = z.object({
    comment: z.string().optional(),
    occurredAt: z.custom<DateTime>(val => DateTime.isDateTime(val)),
})

const debitOnlyTransactionSchema = baseTransactionSchema.extend({
    debitAmount: z.number(),
    debitorAccountId: z.string(),
    creditAmount: z.undefined().optional(),
    creditorAccountId: z.undefined().optional(),
})

const creditOnlyTransactionSchema = baseTransactionSchema.extend({
    creditAmount: z.number(),
    creditorAccountId: z.string(),
    debitAmount: z.undefined().optional(),
    debitorAccountId: z.undefined().optional(),
})

const creditAndDebitTransactionSchema = baseTransactionSchema.extend({
    debitAmount: z.number(),
    debitorAccountId: z.string(),
    creditAmount: z.number(),
    creditorAccountId: z.string(),
})

export const ledgerCashSubscriptionTransactionSchema = creditOnlyTransactionSchema
    .extend({
        type: z.literal('SUBSCRIPTION_CASH'),
    })
    .strict()

export const ledgerEquitySubscriptionTransactionSchema = creditOnlyTransactionSchema
    .extend({
        type: z.literal('SUBSCRIPTION_EQUITY'),
    })
    .strict()

export const ledgerEmissionTransactionSchema = creditOnlyTransactionSchema
    .extend({
        type: z.literal('EMISSION'),
    })
    .strict()

export const ledgerCashTransferTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('TRANSFER_CASH'),
    })
    .strict()

export const ledgerEquityTransferTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('TRANSFER_EQUITY'),
    })
    .strict()

export const ledgerUsufructDonationTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('DONATION_USUFRUCT'),
    })
    .strict()

export const ledgerBareOwnershipDonationTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('DONATION_BARE_OWNERSHIP'),
    })
    .strict()

export const ledgerFullOwnershipTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('DONATION_FULL_OWNERSHIP'),
    })
    .strict()

export const ledgerPledgeTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('PLEDGE'),
    })
    .strict()

export const ledgerPledgeReleaseTransactionSchema = creditAndDebitTransactionSchema
    .extend({
        type: z.literal('PLEDGE_RELEASE'),
    })
    .strict()

export const ledgerCancellationTransactionSchema = debitOnlyTransactionSchema
    .extend({
        type: z.literal('CANCELLATION'),
    })
    .strict()

export const ledgerTransactionSchema = z.union([
    ledgerCancellationTransactionSchema,
    ledgerBareOwnershipDonationTransactionSchema,
    ledgerFullOwnershipTransactionSchema,
    ledgerUsufructDonationTransactionSchema,
    ledgerEmissionTransactionSchema,
    ledgerPledgeReleaseTransactionSchema,
    ledgerPledgeTransactionSchema,
    ledgerCashSubscriptionTransactionSchema,
    ledgerEquitySubscriptionTransactionSchema,
    ledgerCashTransferTransactionSchema,
    ledgerEquityTransferTransactionSchema,
])
