import { CheckCircleTwoTone, ClockCircleTwoTone, ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons'
import { Card, Divider, Form, Input, Select, Space } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import { atom, selectorFamily, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { v4 as uuid } from 'uuid'

import { documentCategoryLookup } from '@publica/lookups'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { logger } from '@publica/ui-common-logger'
import { useApiClient } from '@publica/ui-common-network'
import { colors } from '@publica/ui-common-styles'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import {
    ActionButton,
    FilterTable,
    LinkButton,
    LookupSelect,
    SuspenseSpinner,
    UploadWell,
    UploadedFileRequest,
} from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { useCommonRules } from '@publica/ui-web-utils'
import { Scheduler, assert, matches } from '@publica/utils'

import * as graphql from '../../../../data'
import { DocumentCategory, useCreateManualDocumentSetMutation } from '../../../../data'

type Operation = Pick<graphql.Operation, 'id'>

const useImportDocumentSetTranslations = createUseTranslation({
    FR: {
        import: 'Importer des documents',
    },
    EN: {
        import: 'Import documents',
    },
})

export type ImportDocumentSetProps = {
    operation: Operation
}

export const ImportDocumentSet: FC<ImportDocumentSetProps> = ({ operation }) => {
    const { t } = useImportDocumentSetTranslations()

    return (
        <Card title={t('import')}>
            <SuspenseSpinner>
                <ImportDocumentSetForm operation={operation} />
            </SuspenseSpinner>
        </Card>
    )
}

type ImportedDocument = {
    id: string
    name: string
    participantId: string
    categories?: DocumentCategory[]
    document: Blob
}

type PartialImportedDocument = Partial<Omit<ImportedDocument, 'id'>> & {
    id: string
}

type ImportDocumentSetFormValues = {
    name: string
    importedDocuments: ImportedDocument[]
}

type ImportDocumentSetFormProps = {
    operation: Operation
}

const initialValues: ImportDocumentSetFormValues = {
    name: '',
    importedDocuments: [],
}

const useImportDocumentSetFormTranslations = createUseTranslation({
    FR: {
        import: 'Importer',
        name: `Nom de l'import`,
        documents: 'Documents',
    },
    EN: {
        import: 'Import',
        name: 'Name',
        documents: 'Documents',
    },
})

const useImportDocumentSetFormStyles = createUseStyles({
    name: {
        width: '500px',
    },
})

const ImportDocumentSetForm: FC<ImportDocumentSetFormProps> = ({ operation }) => {
    const [form] = Form.useForm<ImportDocumentSetFormValues>()
    const { t } = useImportDocumentSetFormTranslations()
    const formStyles = useImportDocumentSetFormStyles()
    const controlsStyles = utils.useControlsStyles()
    const apiClient = useApiClient()
    const reset = useResetRecoilState(importedDocumentsStatusState)
    const importedDocumentsStatus = useSetRecoilState(importedDocumentsStatusState)
    const navigate = useNavigate()
    const rules = useCommonRules()

    useEffect(() => {
        reset()
    }, [reset])

    const [createManualDocumentSetMutation] = useCreateManualDocumentSetMutation()

    const { data } = graphql.useGetOperationParticipantsSuspenseQuery({
        variables: {
            operationId: operation.id,
        },
    })

    const [importState, setImportState] = useState<'EMPTY' | 'CONFIGURING' | 'IMPORTING' | 'IMPORTED'>('EMPTY')

    const onImport = useAsyncCallback(async () => {
        if (importState === 'IMPORTED') {
            navigate('..')
            return
        }

        setImportState('IMPORTING')

        try {
            await form.validateFields()
        } catch (e) {
            setImportState('CONFIGURING')
            return
        }

        const { name, importedDocuments } = form.getFieldsValue()

        const { data } = await createManualDocumentSetMutation({
            variables: {
                operationId: operation.id,
                name,
            },
        })

        assert.defined(data)

        const documentSetId = data.createManualDocumentSet.id
        const scheduler = new Scheduler(3)

        await Promise.all(
            importedDocuments.map(async ({ document, id, ...info }) =>
                scheduler.schedule(async () => {
                    importedDocumentsStatus(statuses => ({ ...statuses, [id]: 'IMPORTING' }))
                    try {
                        await apiClient.documentSets.createDocumentInDocumentSets(documentSetId, info, document)
                        importedDocumentsStatus(statuses => ({ ...statuses, [id]: 'IMPORTED' }))
                    } catch (error) {
                        importedDocumentsStatus(statuses => ({ ...statuses, [id]: 'ERROR' }))
                        logger.error('Error uploading document', { error })
                    }
                })
            )
        )

        setImportState('IMPORTED')
    }, [
        apiClient.documentSets,
        createManualDocumentSetMutation,
        form,
        importState,
        importedDocumentsStatus,
        navigate,
        operation.id,
    ])

    const onFieldValuesChange = useCallback(() => {
        if (importState === 'IMPORTED' || importState === 'IMPORTING') {
            return
        }

        const values = form.getFieldsValue()

        // For some reason, when removing the last item from the array, an item
        // is inserted without an ID - so we can check for that
        setImportState(values.importedDocuments[0]?.id !== undefined ? 'CONFIGURING' : 'EMPTY')
    }, [form, importState])

    const op = data.operation
    assert.defined(op)
    const participants = op.participants

    const importing = importState === 'IMPORTING'
    const disabled = importState === 'IMPORTED' || importState === 'IMPORTING'
    const importButtonDisabled = importState === 'EMPTY'

    return (
        <Form form={form} layout="vertical" initialValues={initialValues} onFieldsChange={onFieldValuesChange}>
            <Form.Item label={t('name')} name="name" hasFeedback required rules={rules.required}>
                <Input className={formStyles.name} disabled={disabled} />
            </Form.Item>
            <Divider />
            <Form.Item label={t('documents')} name="importedDocuments" hasFeedback noStyle>
                <ImportDocuments participants={participants} disabled={disabled} />
            </Form.Item>
            <Divider />
            <div className={controlsStyles.footerControls}>
                <Space direction="horizontal">
                    <LinkButton to=".." disabled={disabled}>
                        {t('cancel')}
                    </LinkButton>
                    <ActionButton
                        size="middle"
                        disabled={importButtonDisabled}
                        inProgress={importing}
                        onClick={onImport}
                    >
                        {importState === 'IMPORTED' ? t('back') : t('import')}
                    </ActionButton>
                </Space>
            </div>
        </Form>
    )
}

type Participant = {
    id: string
    info: {
        code: string
        name: string
    }
}

type ImportDocumentsProps = {
    id?: string
    value?: PartialImportedDocument[]
    onChange?: (value: PartialImportedDocument[]) => void
    participants: Participant[]
    disabled?: boolean
}

const ImportDocuments: FC<ImportDocumentsProps> = ({ id, participants, value, onChange, disabled }) => {
    const [uploadedDocs, setUploadedDocs] = useState<PartialImportedDocument[]>([])

    const onUpload = useCallback(
        async (upload: UploadedFileRequest) => {
            const document: PartialImportedDocument = {
                name: upload.file.name.split('.').slice(0, -1).join('.'),
                document: upload.file,
                id: uuid(),
                participantId: findParticipantIdForName(upload.file.name, participants),
            }

            setUploadedDocs(docs => [...docs, document])
        },
        [participants]
    )

    const onRemove = useCallback(
        (docToRemove: PartialImportedDocument) => {
            onChange?.((value ?? []).filter(doc => doc.id !== docToRemove.id))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    )

    useEffect(() => {
        if (uploadedDocs.length === 0) {
            return
        }

        onChange?.([...(value ?? []), ...uploadedDocs])
        setUploadedDocs([])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedDocs, value])

    return (
        <div id={id}>
            <UploadWell multiple={true} accept=".docx,.pdf" onUpload={onUpload} disabled={disabled} />
            <ConfigureImportedDocuments
                value={value}
                onRemove={onRemove}
                participants={participants}
                disabled={disabled}
            />
        </div>
    )
}

const findParticipantIdForName = (name: string, participants: Participant[]): string | undefined => {
    for (const { info, id } of participants) {
        if (name.includes(info.code)) {
            return id
        }
    }

    return undefined
}

type ConfigureImportedDocumentsProps = {
    participants: Participant[]
    value?: PartialImportedDocument[]
    onChange?: (value: PartialImportedDocument[]) => void
    onRemove?: (value: PartialImportedDocument) => void
    disabled?: boolean
}

const useConfigureImportedDocumentsStyles = createUseStyles({
    inTableFormItem: {
        margin: 0,
        '& .ant-form-item-explain': {
            display: 'none',
        },
    },
})

const useConfigureImportedDocumentsTranslations = createUseTranslation({
    FR: {
        participant: 'Participant',
        name: 'Nom du document',
        categories: 'Catégories',
        remove: 'Supprimer',
    },
    EN: {
        participant: 'Participant',
        name: 'Document Name',
        categories: 'Categories',
        remove: 'Remove',
    },
})

const filterParticipants = (input: string, option: DefaultOptionType | undefined) =>
    matches.matchesFilter(option?.label?.toString(), input)

const ConfigureImportedDocuments: FC<ConfigureImportedDocumentsProps> = ({
    value,
    onRemove,
    participants,
    disabled,
}) => {
    const styles = useConfigureImportedDocumentsStyles()
    const [rows, setRows] = useState(value ?? [])
    const length = value?.length ?? 0
    const { t } = useConfigureImportedDocumentsTranslations()
    const rules = useCommonRules()

    useEffect(() => {
        setRows(value ?? [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length])

    const columns = useMemo<ColumnType<PartialImportedDocument>[]>(
        () => [
            {
                width: 50,
                render: (_, doc) => <ImportedDocumentStatus document={doc} />,
                align: 'center',
            },
            {
                title: t('participant'),
                width: 300,
                render: (_, _doc, idx) => (
                    <Form.Item
                        // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                        name={['importedDocuments', idx, 'participantId']}
                        hasFeedback
                        required
                        rules={rules.required}
                        className={styles.inTableFormItem}
                    >
                        <Select
                            disabled={disabled}
                            showSearch
                            filterOption={filterParticipants}
                            popupMatchSelectWidth={false}
                        >
                            {participants.map(participant => (
                                <Select.Option
                                    key={participant.id}
                                    value={participant.id}
                                    label={`${participant.info.code} - ${participant.info.name}`}
                                >
                                    {participant.info.code} - {participant.info.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ),
            },
            {
                title: t('name'),
                render: (_, _doc, idx) => (
                    <Form.Item
                        // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                        name={['importedDocuments', idx, 'name']}
                        hasFeedback
                        required
                        rules={rules.required}
                        className={styles.inTableFormItem}
                    >
                        <Input disabled={disabled} />
                    </Form.Item>
                ),
            },
            {
                title: t('categories'),
                width: 350,
                render: (_, _doc, idx) => (
                    <Form.Item
                        // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                        name={['importedDocuments', idx, 'categories']}
                        hasFeedback
                        className={styles.inTableFormItem}
                    >
                        <LookupSelect disabled={disabled} lookup={documentCategoryLookup} allowClear mode="multiple" />
                    </Form.Item>
                ),
            },
            {
                render: (_, doc) => (
                    // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                    <ActionButton disabled={disabled} onClick={() => onRemove?.(doc)}>
                        {t('remove')}
                    </ActionButton>
                ),
                align: 'center',
                width: 150,
            },
        ],
        [disabled, onRemove, participants, rules.required, styles.inTableFormItem, t]
    )

    if (rows.length === 0) {
        return null
    }

    return (
        <>
            <Divider />
            <FilterTable<PartialImportedDocument> dataSource={rows} columns={columns} rowKey="id" />
        </>
    )
}

type ImportDocumentStatus = 'PENDING' | 'IMPORTING' | 'IMPORTED' | 'ERROR'

const importedDocumentsStatusState = atom<Record<string, ImportDocumentStatus>>({
    key: 'importedDocumentsStatus',
    default: {},
})

const importedDocumentStatusFamily = selectorFamily<ImportDocumentStatus, string>({
    key: 'importedDocumentStatus',
    get:
        id =>
        ({ get }) => {
            const statuses = get(importedDocumentsStatusState)
            return statuses[id] ?? 'PENDING'
        },
})

type ImportedDocumentStatusProps = {
    document: PartialImportedDocument
}

const ImportedDocumentStatus: FC<ImportedDocumentStatusProps> = ({ document }) => {
    const status = useRecoilValue(importedDocumentStatusFamily(document.id))

    switch (status) {
        case 'PENDING':
            return <ClockCircleTwoTone twoToneColor={colors.grey6} />
        case 'IMPORTING':
            return <LoadingOutlined color={colors.grey6} />
        case 'IMPORTED':
            return <CheckCircleTwoTone twoToneColor={colors.success} />
        case 'ERROR':
            return <ExclamationCircleTwoTone color={colors.failure} />
    }
}
