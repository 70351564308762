import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    DownCircleOutlined,
    DownloadOutlined,
    EyeOutlined,
    FileAddOutlined,
    FolderAddOutlined,
    HistoryOutlined,
    LoadingOutlined,
    LoginOutlined,
    LogoutOutlined,
    PauseOutlined,
    PlusCircleOutlined,
    SearchOutlined,
    SendOutlined,
    SettingOutlined,
    UpCircleOutlined,
    UploadOutlined,
    UserAddOutlined,
    UserOutlined,
    UsergroupAddOutlined,
    WarningOutlined,
} from '@ant-design/icons'

export const Loading = <LoadingOutlined />

export const Download = <DownloadOutlined />

export const Publish = <UpCircleOutlined />

export const Unpublish = <DownCircleOutlined />

export const View = <EyeOutlined />

export const Send = <SendOutlined />

export const AddFile = <FileAddOutlined />

export const Login = <LoginOutlined />

export const AddFolder = <FolderAddOutlined />

export const AddUsers = <UsergroupAddOutlined />

export const AddUser = <UserAddOutlined />

export const Search = <SearchOutlined />

export const Invite = <PlusCircleOutlined />

export const Done = <CheckCircleOutlined />

export const Waiting = <ClockCircleOutlined />

export const Logout = <LogoutOutlined />

export const Settings = <SettingOutlined />

export const Upload = <UploadOutlined />

export const Delete = <DeleteOutlined />

export const Archive = <PauseOutlined />

export const Account = <UserOutlined />

export const Warning = <WarningOutlined />

export const Add = <PlusCircleOutlined />

export const History = <HistoryOutlined />
