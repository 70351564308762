import { Select } from 'antd'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { allParticipantsGroupKey } from '@publica/common'
import { LocalizedString } from '@publica/locales'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

type GroupFilterProps = {
    groups: { key: string; name: LocalizedString }[]
    onChange?: (val: string[]) => void
}

const useGroupFilterStyles = createUseStyles({
    filter: {
        minWidth: 400,
        marginLeft: 30,
    },
})

const useGroupFilterTranslations = createUseTranslation({
    FR: {
        filterByGroup: 'Filtrer par groupes',
    },
    EN: {
        filterByGroup: 'Filter by group',
    },
})

export const GroupFilter: FC<GroupFilterProps> = ({ groups, onChange }) => {
    const resolver = useLocalizedStringResolver()
    const { t } = useGroupFilterTranslations()

    const options = useMemo(
        () =>
            groups
                .filter(group => group.key !== allParticipantsGroupKey)
                .map(group => ({ label: resolver(group.name), value: group.key })),
        [groups, resolver]
    )

    const onSelectChange = useCallback(
        (value: string[]) => {
            onChange?.(value)
        },
        [onChange]
    )

    const styles = useGroupFilterStyles()

    if (groups.length === 0) {
        return null
    }

    return (
        <p>
            <span>{t('filterByGroup')}</span>
            <Select className={styles.filter} options={options} allowClear mode="multiple" onChange={onSelectChange} />
        </p>
    )
}
