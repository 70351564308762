import { FC } from '@publica/ui-common-utils'

import { Field, MapFieldParameters } from '../../types'
import { AddressValueFormItem } from './AddressValueFormItem'

type MapValueFormItemProps = {
    field: Field<MapFieldParameters>
}

export const MapValueFormItem: FC<MapValueFormItemProps> = ({ field }) => {
    switch (field.parameters.subType) {
        case 'ADDRESS':
            return <AddressValueFormItem field={field} key={field.key} />
    }
}
