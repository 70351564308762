import { Rule, RuleObject } from 'antd/lib/form'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'

const useCommonRulesTranslation = createUseTranslation({
    FR: {
        required: 'Cette information est requise',
    },
    EN: {
        required: 'This information is required',
    },
})

export const useCommonRules = (type?: RuleObject['type']): Rule[] => {
    const { t } = useCommonRulesTranslation()

    return useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('required'),
                warningOnly: true,
                type,
            },
        ],
        [t, type]
    )
}
