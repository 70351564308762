import { Space } from 'antd'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, DocumentControlsProps, DocumentSetDownloadButton, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { ArrayItem } from '@publica/utils'

import { usePublishDocumentsMutation, useUnpublishDocumentsMutation } from '../../../data'

type Document = ArrayItem<DocumentControlsProps['documents']>

const useDocumentControlsTranslation = createUseTranslation({
    FR: {
        publish: 'Publier',
        retract: 'Retirer',
        sendForSignature: 'Envoyer pour signature',
        sendForSignatureSummary_one: 'Envoyer {{count}} document pour signature',
        sendForSignatureSummary_other: 'Envoyer {{count}} documents pour signature',
        publishSummary_one: `Publier {{count}} document`,
        publishSummary_other: `Publier {{count}} documents`,
        retractSummary_one: `Retirer {{count}} document`,
        retractSummary_other: `Retirer {{count}} documents`,
    },
    EN: {
        publish: 'Publish',
        retract: 'Retract',
        sendForSignature: 'Send for signature',
        sendForSignatureSummary_one: 'Send {{count}} document for signature',
        sendForSignatureSummary_other: 'Send {{count}} documents for signature',
        publishSummary_one: `Publish {{count}} document`,
        publishSummary_other: `Publish {{count}} documents`,
        retractSummary_one: `Retract {{count}} document`,
        retractSummary_other: `Retract {{count}} documents`,
    },
})

export const DocumentControls: FC<DocumentControlsProps> = ({
    operationId,
    documents,
    selectedDocuments,
    config,
    isDocumentOperationInProgress,
    setDocumentOperationState,
}) => {
    const styles = utils.useControlsStyles()
    const { t } = useDocumentControlsTranslation()

    const allSameType = new Set(documents.map(doc => doc.requiresSignature)).size <= 1

    if (config.displaySignatureStatus && !allSameType) {
        throw new Error('Attempting to display signature information for non-signature documents')
    }

    const [publishDocumentsEnabled, _, publishDocuments, publishableDocuments] = useChangeDocumentState(
        selectedDocuments,
        config.mutable,
        'PUBLISHED',
        isDocumentOperationInProgress,
        setDocumentOperationState
    )

    const [unpublishDocumentsEnabled, unpublishableDocumentCount, unpublishDocuments] = useChangeDocumentState(
        selectedDocuments,
        config.mutable,
        'READY',
        isDocumentOperationInProgress,
        setDocumentOperationState
    )

    const publishLabel: string = useMemo(() => {
        if (!publishDocumentsEnabled) {
            return config.displaySignatureStatus ? t('sendForSignature') : t('publish')
        }

        const count = publishableDocuments.length

        if (config.displaySignatureStatus) {
            return t('sendForSignatureSummary', { count })
        }

        return t('publishSummary', { count })
    }, [publishDocumentsEnabled, publishableDocuments.length, config.displaySignatureStatus, t])

    return (
        <div className={styles.controls}>
            <Space>
                {allSameType ? (
                    <ActionButton
                        size="middle"
                        icon={icons.Publish}
                        disabled={!publishDocumentsEnabled}
                        onClick={publishDocuments}
                    >
                        {publishLabel}
                    </ActionButton>
                ) : null}

                {!config.displaySignatureStatus && allSameType ? (
                    <ActionButton
                        size="middle"
                        icon={icons.Publish}
                        disabled={!unpublishDocumentsEnabled}
                        onClick={unpublishDocuments}
                    >
                        {unpublishDocumentsEnabled
                            ? t('retractSummary', { count: unpublishableDocumentCount })
                            : t('retract')}
                    </ActionButton>
                ) : null}

                <DocumentSetDownloadButton documents={selectedDocuments} operationId={operationId} />
            </Space>
        </div>
    )
}

const useChangeDocumentState = (
    documents: Document[],
    mutable: boolean,
    targetState: 'READY' | 'PUBLISHED',
    isDocumentOperationInProgress: (id: string) => boolean,
    setDocumentOperationState: (state: Record<string, boolean>) => void
) => {
    const [publishDocumentsMutation] = usePublishDocumentsMutation()
    const [unpublishDocumentsMutation] = useUnpublishDocumentsMutation()

    const initialState = targetState === 'PUBLISHED' ? 'READY' : 'PUBLISHED'

    const actionableDocuments = documents.filter(
        d =>
            d.status === initialState &&
            !isDocumentOperationInProgress(d.id) &&
            !(targetState === 'READY' && d.requiresSignature)
    )
    const actionableDocumentsCount = actionableDocuments.length
    const hasActionableDocuments = actionableDocumentsCount > 0
    const actionForDocumentsEnabled = hasActionableDocuments && mutable

    const changeDocumentsState = useAsyncCallback(async () => {
        setDocumentOperationState(
            actionableDocuments.reduce(
                (ds, doc) => ({
                    ...ds,
                    [doc.id]: true,
                }),
                {} as Record<string, true>
            )
        )

        if (targetState === 'PUBLISHED') {
            await publishDocumentsMutation({
                variables: {
                    ids: actionableDocuments.map(d => d.id),
                },
            })
        } else {
            await unpublishDocumentsMutation({
                variables: {
                    ids: actionableDocuments.map(d => d.id),
                },
            })
        }
    }, [
        setDocumentOperationState,
        actionableDocuments,
        targetState,
        publishDocumentsMutation,
        unpublishDocumentsMutation,
    ])

    return [actionForDocumentsEnabled, actionableDocumentsCount, changeDocumentsState, actionableDocuments] as const
}
