import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'
import { useTheme } from '@publica/ui-web-styles'

type StyleProps = {
    colors: {
        primary: string
        secondary: string
    }
    logo: {
        url: string
    }
}

const useStyles = createUseStyles<
    'loginFrame' | 'loginFormContainer' | 'loginForm' | 'loginLogo' | 'loginDivider' | 'loginFormContent',
    StyleProps
>({
    loginFrame: {
        minHeight: '100%',
        width: '100%',
        backgroundColor: props => props.colors.secondary,
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
    },
    loginFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '35%',
    },
    loginForm: {
        border: '1px solid',
        borderColor: props => props.colors.primary,
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 25,
        marginBottom: 150,
    },
    loginLogo: {
        backgroundImage: props => `url('${props.logo.url}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '120px',
    },
    loginDivider: {
        borderBottomColor: props => props.colors.primary,
        borderBottom: '1px solid',
    },
    loginFormContent: {
        textAlign: 'center',
    },
})

export const LoginFrame: FC = ({ children }) => {
    const theme = useTheme()
    const styles = useStyles({
        colors: theme.tenant.colors,
        logo: theme.tenant.loginLogo,
    })

    return (
        <div className={styles.loginFrame}>
            <div className={styles.loginFormContainer}>
                <div className={styles.loginForm}>
                    <div className={styles.loginLogo} />
                    <div className={styles.loginDivider} />
                    <div className={styles.loginFormContent}>{children}</div>
                </div>
            </div>
        </div>
    )
}
