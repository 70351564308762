import { Card } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { OperationHeader, OperationTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationsQuery } from '../../../../data'
import { OperationControls } from './OperationControls'

const title = <OperationHeader />

export const OperationIndex: FC = () => {
    const { loading, data } = useGetOperationsQuery({ pollInterval: usePollingRate() })

    const operations = data?.operations ?? []

    return (
        <Card title={title}>
            <OperationControls />
            <OperationTable loading={loading} operations={operations} />
        </Card>
    )
}
