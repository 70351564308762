import { Card } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { ImportParticipantsFromExcel } from './ImportParticipantsFromExcel'

type AddParticipantsProps = {
    operationId: string
}

const useAddParticipantsTranslation = createUseTranslation({
    EN: {
        invite: 'Add participants to the operation',
        manual: 'Add manually',
        file: 'Upload a file',
    },
    FR: {
        invite: `Rajouter des participants à l'opération`,
        manual: 'Gérer manuellement',
        file: 'Télécharger un fichier',
    },
})

export const AddParticipants: FC<AddParticipantsProps> = ({ operationId }) => {
    const { t } = useAddParticipantsTranslation()

    return (
        <Card title={t('invite')}>
            <ImportParticipantsFromExcel operationId={operationId} />
        </Card>
    )
}
