import { ButtonProps } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { LinkButton, icons } from '@publica/ui-web-components'

import { Participant } from '../../types'

export const participantGoTo = (participant: Participant, title?: string, type?: ButtonProps['type']) => (
    <ParticipantGoTo participant={participant} title={title} type={type} />
)

type ParticipantGoToProps = {
    participant: Participant
    title?: string
    type?: ButtonProps['type']
}

export const ParticipantGoTo: FC<ParticipantGoToProps> = ({ participant, title, type }) => (
    <LinkButton
        size="small"
        to={participant.id}
        type={type ?? 'link'}
        icon={type === 'primary' ? icons.View : undefined}
    >
        {title ?? participant.info.name}
    </LinkButton>
)
