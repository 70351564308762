import { DateTime } from 'luxon'
import { z } from 'zod'

export const datetimeSchema = z
    .string()
    .superRefine((val, ctx) => {
        try {
            const parsedDateTime = DateTime.fromISO(val)
            if (!parsedDateTime.isValid) {
                throw new Error()
            }
        } catch (e) {
            ctx.addIssue({
                code: 'custom',
                message: `Invalid datetime: ${val}`,
            })
        }
    })
    .transform(val => DateTime.fromISO(val).setZone('utc'))
