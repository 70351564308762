import { Space } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { Operation, Participant } from '../../types'
import { ParticipantGoTo } from '../ParticipantGoTo'
import { SendParticipantInvitation } from './SendParticipantInvitation'

type ParticipantActionsProps = {
    participant: Participant
    operation: Operation
}

export const ParticipantActions: FC<ParticipantActionsProps> = ({ participant, operation }) => {
    const { t } = useParticipantActionsTranslation()

    return (
        <Space>
            <ParticipantGoTo participant={participant} title={t('view')} type="primary" />
            <SendParticipantInvitation participant={participant} operation={operation} />
        </Space>
    )
}

const useParticipantActionsTranslation = createUseTranslation({
    EN: {},
    FR: {},
})
