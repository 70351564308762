import kebabCase from 'lodash/kebabCase'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { z } from 'zod'

import { clientIdKey, fingerprint, instanceIdKey } from './fingerprint'

export const platformHeaderPrefix = `x-publica-`

export const platformHeader = <S extends string>(key: S): `${typeof platformHeaderPrefix}${Lowercase<S>}` =>
    `${platformHeaderPrefix}${key.toLowerCase() as Lowercase<S>}`

const fingerprintHeaderKeys = new Set([clientIdKey, instanceIdKey])

const fingerprintHeaders: Record<string, string> = reduce(
    fingerprint,
    (fingerprintHeaders, value, key) => {
        if (fingerprintHeaderKeys.has(key)) {
            return {
                ...fingerprintHeaders,
                [platformHeader(kebabCase(key))]: value ?? 'unknown',
            }
        }
        return fingerprintHeaders
    },
    {} as Record<string, string>
)

export const platformHeaders: Record<string, string> = {
    [platformHeader('component')]: __APP__,
    [platformHeader('version')]: __VERSION__,
    ...fingerprintHeaders,
}

const platformHeadersSchema = z
    .object({
        [platformHeader('component')]: z.string().optional(),
        [platformHeader('version')]: z.string().optional(),
    })
    .passthrough()

export const parsePlatformHeaders = (headers: unknown) => platformHeadersSchema.parse(headers)
