import { Card, Collapse, Form } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { LocalizedString } from '@publica/api-graphql'
import { operationIsOpen } from '@publica/common'
import { GraphQLValue, Value, WithValues, formValuesToGraphQLValueInputs, graphQLValueToValue } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import {
    DocumentTable,
    DocumentTableConfig,
    DocumentTableProps,
    EmailTable,
    EmailTableConfig,
    EmailTableProps,
    NotFound,
    ShowParticipantValues,
    ShowParticipantValuesProps,
    Spinner,
    VerticalSpacer,
} from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'
import { ArrayItem, assert } from '@publica/utils'

import { FieldGroup, useGetParticipantQuery, useUpdateParticipantValuesMutation } from '../../../../data'
import * as graphql from '../../../../data'
import {
    AttachmentTable,
    AttachmentTableProps,
    DocumentActions,
    DocumentControls,
    EmailActions,
    EmailControls,
    GroupTable,
} from '../../../components'

type Group = {
    id: string
    name: LocalizedString
}

type Participant = {
    id: string
    emails: EmailTableProps['emails']
    documents: DocumentTableProps['documents']
    uploadedAttachments: UploadedAttachment[]
    attachments: Attachment[]
    values: GraphQLValue[]
    info: {
        name: string
    }
    groups: Group[]
    fields: Field[]
}

type Operation = Pick<graphql.Operation, 'status' | 'id'>

type Attachment = ArrayItem<AttachmentTableProps['attachments']>
type UploadedAttachment = ArrayItem<AttachmentTableProps['uploadedAttachments']>
type Field = ArrayItem<ShowParticipantValuesProps['fields']>

const verticalSpacing = 20

const useShowParticipantTranslation = createUseTranslation({
    EN: {
        unknownParticipant: 'Unknown participant',
    },
    FR: {
        unknownParticipant: 'Participant inconnu',
    },
})

export const ShowParticipant: FC = () => {
    const { participantId } = useParams()
    const { t } = useShowParticipantTranslation()

    assert.defined(participantId, 'Undefined participant ID')

    const { data, loading } = useGetParticipantQuery({ variables: { participantId }, pollInterval: usePollingRate() })

    if (loading || data === undefined) {
        return <Spinner />
    }

    const { participant } = data

    // FIXME: use error boundary
    if (isNil(participant)) {
        return <NotFound title={t('unknownParticipant')} path=".." />
    }

    return <ParticipantCard participant={participant} operation={participant.operation} />
}

type ParticipantCardProps = {
    participant: Participant
    operation: Operation
}

const useParticipantCardTranslation = createUseTranslation({
    EN: {
        attachments: 'KYC Documents',
        emails: 'Emails',
        documents: 'Documents',
        groups: 'Groups',
    },
    FR: {
        attachments: 'Documents KYC',
        emails: 'Emails',
        documents: 'Documents',
        groups: 'Groupes',
    },
})

const ParticipantCard: FC<ParticipantCardProps> = ({ participant, operation }) => {
    const mutable = operationIsOpen(operation)
    const { t } = useParticipantCardTranslation()
    const [updateParticipantValuesMutation] = useUpdateParticipantValuesMutation({
        refetchQueries: [graphql.GetOperationPaginatedParticipantsDocument],
    })

    const participantValues: WithValues<Participant> = useMemo(
        () => ({ ...participant, values: participant.values.map(v => graphQLValueToValue(v) as Value) }),
        [participant]
    )

    const documentTableConfig: Partial<DocumentTableConfig> = useMemo(
        () => ({
            ...baseDocumentTableConfig,
            mutable,
            displaySignatureStatus: false,
        }),
        [mutable]
    )

    const emailTableConfig: EmailTableConfig = useMemo(() => ({ ...baseEmailTableConfig, mutable }), [mutable])

    const [form] = Form.useForm()

    const onSubmit = useCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        const values = formValuesToGraphQLValueInputs(form.getFieldsValue(), participant.fields)

        await updateParticipantValuesMutation({
            variables: {
                participantValues: [
                    {
                        id: participant.id,
                        values,
                    },
                ],
            },
        })
    }, [form, participant.fields, participant.id, updateParticipantValuesMutation])

    const editable = useMemo(() => ({ form, onSubmit }), [form, onSubmit])

    return (
        <Card title={participant.info.name} bodyStyle={participantCardStyle}>
            <VerticalSpacer size={verticalSpacing}>
                <ShowParticipantValues
                    participant={participantValues}
                    fields={participant.fields}
                    fieldGroups={infoKeys}
                    editable={editable}
                />

                <Collapse>
                    <Collapse.Panel key="groups" header={t('groups')}>
                        <GroupTable groups={participant.groups} />
                    </Collapse.Panel>
                </Collapse>

                <Collapse>
                    <Collapse.Panel key="attachments" header={t('attachments')}>
                        <AttachmentTable
                            attachments={participant.attachments}
                            uploadedAttachments={participant.uploadedAttachments}
                        />
                    </Collapse.Panel>
                </Collapse>

                <Collapse>
                    <Collapse.Panel key="documents" header={t('documents')}>
                        <DocumentTable
                            operationId={operation.id}
                            documents={participant.documents}
                            config={documentTableConfig}
                            documentActions={renderDocumentActions}
                            documentControls={renderDocumentControls}
                        />
                    </Collapse.Panel>
                </Collapse>

                <Collapse>
                    <Collapse.Panel key="emails" header={t('emails')}>
                        <EmailTable
                            emails={participant.emails}
                            config={emailTableConfig}
                            emailActions={renderEmailActions}
                            emailControls={renderEmailControls}
                        />
                    </Collapse.Panel>
                </Collapse>
            </VerticalSpacer>
        </Card>
    )
}

type Document = ArrayItem<DocumentTableProps['documents']>

const baseDocumentTableConfig = {
    columns: {
        participantName: false,
        documentName: 'SHORT',
        createdAt: true,
    },
    sortBy: (doc: Document) => doc.createdAt,
} as const

const renderDocumentActions: DocumentTableProps['documentActions'] = props => <DocumentActions {...props} />
const renderDocumentControls: DocumentTableProps['documentControls'] = props => <DocumentControls {...props} />

type Email = ArrayItem<EmailTableProps['emails']>

const baseEmailTableConfig = {
    columns: {
        participantName: false,
        emailSubject: true,
        createdAt: true,
    },
    sortBy: (email: Email) => email.createdAt,
}

const renderEmailActions: EmailTableProps['emailActions'] = props => <EmailActions {...props} />
const renderEmailControls: EmailTableProps['emailControls'] = props => <EmailControls {...props} />

const participantCardStyle = { padding: 10 }

const infoKeys: FieldGroup[] = [
    'ACCOUNT_HOLDER_INFORMATION',
    'PERSONAL_INFORMATION',
    'BANKING_INFORMATION',
    'FINANCIAL_INFORMATION',
    'PLATFORM_INFORMATION',
]
