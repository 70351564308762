import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton } from '@publica/ui-web-components'

import { useArchiveDocumentSetsMutation } from '../../../../../../../data'
import { DocumentSet } from '../../../types'

const useArchiveDocumentSetTranslation = createUseTranslation({
    FR: {
        archive: 'Archiver',
    },
    EN: {
        archive: 'Archive',
    },
})

type ArchiveDocumentSetProps = {
    documentSet: DocumentSet
}

export const ArchiveDocumentSet: FC<ArchiveDocumentSetProps> = ({ documentSet }) => {
    const { t } = useArchiveDocumentSetTranslation()
    const [archiveDocumentSetsMutation, { loading }] = useArchiveDocumentSetsMutation()

    const enabled = useMemo(() => !loading && documentSetIsArchivable(documentSet), [documentSet, loading])

    const onClick = useAsyncCallback(async () => {
        await archiveDocumentSetsMutation({
            variables: {
                ids: [documentSet.id],
            },
        })
    }, [archiveDocumentSetsMutation, documentSet.id])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('archive')}
        </ActionButton>
    )
}

const documentSetIsArchivable = (documentSet: DocumentSet) =>
    !documentSet.isArchived && documentSet.documents.every(doc => doc.status === 'READY')
