import { z } from 'zod'

import type { EmailStatus } from '@publica/api-graphql'

type WithEmailStatus = {
    status: EmailStatus
}

export const emailIsGenerated = (e: WithEmailStatus): boolean => e.status === 'READY' || e.status === 'SENT'

export const emailSchema = z.string().toLowerCase().trim().email()
