import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const addressMapKeySchema = z.enum(['postCode', 'city', 'address', 'country'])

export type AddressMapKey = z.infer<typeof addressMapKeySchema>

const addressMapKeys: Record<KnownLocale, Record<AddressMapKey, string>> = {
    FR: {
        postCode: 'Code Postal',
        city: 'Ville',
        country: 'Pays',
        address: 'Numéro et voie',
    },
    EN: {
        postCode: 'Post Code',
        city: 'City',
        country: 'Country',
        address: 'Street address',
    },
}

export const addressMapKeysLookup = new LookupList(addressMapKeys)
