import { createUseTranslation } from '@publica/ui-common-i18n'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, EmailControlsProps, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { useSendEmailsMutation } from '../../../data'

const useEmailControlsTranslation = createUseTranslation({
    FR: {
        send: 'Envoyer',
        sendSummary_one: `Envoyer {{count}} email`,
        sendSummary_other: `Envoyer {{count}} emails`,
    },
    EN: {
        send: 'Send',
        sendSummary_one: `Send {{count}} email`,
        sendSummary_other: `Send {{count}} emails`,
    },
})

export const EmailControls: FC<EmailControlsProps> = ({
    selectedEmails,
    config,
    setEmailOperationState,
    isEmailOperationInProgress,
}) => {
    const styles = utils.useControlsStyles()
    const { t } = useEmailControlsTranslation()
    const [sendEmailsMutation] = useSendEmailsMutation()

    const unsentEmails = selectedEmails.filter(e => e.status === 'READY' && !isEmailOperationInProgress(e.id))
    const unsentEmailCount = unsentEmails.length
    const hasUnsentEmails = unsentEmailCount > 0
    const sendEmailsEnabled = hasUnsentEmails && config.mutable

    const sendEmails = useAsyncCallback(async () => {
        setEmailOperationState(
            unsentEmails.reduce(
                (es, email) => ({
                    ...es,
                    [email.id]: true,
                }),
                {} as Record<string, true>
            )
        )

        await sendEmailsMutation({
            variables: {
                ids: unsentEmails.map(e => e.id),
            },
        })
    }, [sendEmailsMutation, setEmailOperationState, unsentEmails])

    return (
        <div className={styles.controls}>
            <ActionButton size="middle" icon={icons.Send} disabled={!sendEmailsEnabled} onClick={sendEmails}>
                {sendEmailsEnabled ? t('sendSummary', { count: unsentEmailCount }) : t('send')}
            </ActionButton>
        </div>
    )
}
