import { Token } from './token'
import Emittery from 'emittery'
import { logger } from '@publica/ui-common-logger'
import { KnownLocale } from '@publica/locales'
import { Client } from '@publica/api-client'
import { Title } from '@publica/lookups'
import { i18n } from '@publica/ui-common-i18n'

const baseEvents = ['login', 'refresh'] as const

export type Account = {
    id: string
    firstName: string
    lastName: string
    email: string
    title: Title | null
    locale: KnownLocale
    phoneNumber: string | null
}

export type AccountTokenPair<T extends Token = Token> = {
    account: Account
    token: T
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Item<A extends ReadonlyArray<any>> = A extends ReadonlyArray<infer I> ? I : never
type AuthBaseEventType = Item<typeof baseEvents>

type AsAttempt<S extends string> = `${S}Attempt`
type AsFailure<S extends string> = `${S}Failure`
type AsSuccess<S extends string> = `${S}Success`

type AuthEventTypeWithPayload<A extends AccountTokenPair<T>, T extends Token> = Record<
    'logout' | 'initialized' | AsAttempt<AuthBaseEventType> | AsFailure<AuthBaseEventType>,
    undefined
> &
    Record<AsSuccess<AuthBaseEventType>, A>

type AuthEventsInternal<A extends AccountTokenPair<T>, T extends Token> = Emittery<AuthEventTypeWithPayload<A, T>>

type AuthEvents<A extends AccountTokenPair<T>, T extends Token> = Pick<AuthEventsInternal<A, T>, 'on' | 'off' | 'once'>

export interface AuthState<T extends Token = Token> {
    isInitialized: () => boolean
    markAsInitialized: () => void
    isAuthenticated: () => boolean
    isAuthenticating: () => boolean
    getAccountToken: () => Promise<AccountTokenPair<T>>
    getAccount: () => Account | undefined
    refreshToken: () => Promise<AccountTokenPair<T>>
    logout: (fn?: () => Promise<void>) => Promise<void>
    events: AuthEvents<AccountTokenPair<T>, T>
}

type AuthConfig = {
    host: string
}

const localStorageLocaleKey = 'locale'

export abstract class BaseAuthState<T extends Token = Token, C extends AuthConfig = AuthConfig>
    implements AuthState<T>
{
    private initialized = false
    private isAttemptingLogin = false
    protected accountTokenPair: AccountTokenPair<T> | undefined
    protected readonly config: C
    protected account: Account | undefined
    protected readonly internalEvents: AuthEventsInternal<AccountTokenPair<T>, T>

    constructor(config: C, autoInitialize = false) {
        this.config = config

        this.internalEvents = new Emittery()

        this.internalEvents.on(`loginAttempt`, () => logger.info(`Attempting login`))
        this.internalEvents.on(`loginFailure`, () => logger.warn(`Login failure`))
        this.internalEvents.on(`loginSuccess`, token => logger.info(`Login success`, { payload: { token } }))

        this.internalEvents.on(`refreshAttempt`, () => logger.info(`Attempting refresh`))
        this.internalEvents.on(`refreshFailure`, () => logger.warn(`Refresh failure`))
        this.internalEvents.on(`refreshSuccess`, () => logger.info(`Refresh success`))

        this.internalEvents.on('logout', () => logger.log('Logout event'))

        this.internalEvents.on('initialized', () => logger.log('Initialized event'))

        this.internalEvents.on('loginAttempt', () => {
            this.isAttemptingLogin = true
        })

        this.internalEvents.on(['loginFailure', 'loginSuccess'], () => {
            this.isAttemptingLogin = false
        })

        if (autoInitialize) {
            void this.markAsInitialized()
        }
    }

    isAuthenticated() {
        return this.accountTokenPair?.token.isValid() === true
    }

    isAuthenticating() {
        return this.isAttemptingLogin
    }

    isInitialized() {
        return this.initialized
    }

    async markAsInitialized() {
        this.initialized = true
        await this.internalEvents.emit('initialized')
    }

    get events(): AuthEvents<AccountTokenPair<T>, T> {
        return this.internalEvents
    }

    async getAccountToken(): Promise<AccountTokenPair<T>> {
        const accountTokenPair = this.accountTokenPair

        if (accountTokenPair !== undefined && !accountTokenPair.token.expiresSoon()) {
            return accountTokenPair
        }

        return this.refreshToken()
    }

    getAccount(): Account | undefined {
        return this.account
    }

    abstract refreshToken(): Promise<AccountTokenPair<T>>
    abstract logout(fn?: () => Promise<void>): Promise<void>

    protected async performLogin(fn: () => Promise<AccountTokenPair<T>>): Promise<AccountTokenPair<T>> {
        return this.tokenIssueEvent('login', fn)
    }

    protected async peformRefresh(fn: () => Promise<AccountTokenPair<T>>): Promise<AccountTokenPair<T>> {
        return this.tokenIssueEvent('refresh', fn)
    }

    protected async performLogout(fn?: () => Promise<void>): Promise<void> {
        if (fn !== undefined) {
            await fn()
        }
        this.accountTokenPair = undefined
        window.localStorage.removeItem(localStorageLocaleKey)
        await this.internalEvents.emit('logout')
    }

    protected async getAccountTokenPairForToken(token: T): Promise<AccountTokenPair<T>> {
        return {
            token,
            account: await this.refreshAccountForToken(token),
        }
    }

    private async refreshAccountForToken(token: T): Promise<Account> {
        const apiClient = new Client(this.config.host, async () => Promise.resolve(token.accessToken))
        this.account = await apiClient.auth.account()
        const locale = this.account.locale

        window.localStorage.setItem(localStorageLocaleKey, locale)

        await i18n.changeLanguage(locale)

        return this.account
    }

    private async tokenIssueEvent<E extends AuthBaseEventType>(
        event: E,
        fn: () => Promise<AccountTokenPair<T>>
    ): Promise<AccountTokenPair<T>> {
        await this.internalEvents.emit(`${event}Attempt`)

        try {
            this.accountTokenPair = await fn()
            await this.internalEvents.emit(`${event}Success`, this.accountTokenPair)
            return this.accountTokenPair
        } catch (e) {
            await this.internalEvents.emit(`${event}Failure`)
            throw e
        }
    }
}
