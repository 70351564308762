import { Button, Modal, Space } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, EmailActionsProps, EmailView, Spinner, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { assert } from '@publica/utils'

import { useGetEmailQuery, useSendEmailsMutation, useUpdateEmailBodyMutation } from '../../../data'

const useEmailActionsTranslation = createUseTranslation({
    FR: {
        send: 'Envoyer',
        view: 'Voir',
    },
    EN: {
        send: 'Send',
        view: 'View',
    },
})

export const EmailActions: FC<EmailActionsProps> = ({
    email,
    config,
    isEmailOperationInProgress,
    setEmailOperationState,
}) => {
    const styles = utils.useActionsStyles()
    const { t } = useEmailActionsTranslation()
    const [sendEmailsMutation] = useSendEmailsMutation()

    const [showEmail, setShowEmail] = useState(false)

    // This clears the flag after the email has actually been sent
    useEffect(() => {
        setEmailOperationState({ [email.id]: false })
    }, [email.id, email.status, setEmailOperationState])

    const sendEmail = useAsyncCallback(async () => {
        setEmailOperationState({ [email.id]: true })
        await sendEmailsMutation({
            variables: {
                ids: [email.id],
            },
        })
    }, [email.id, sendEmailsMutation, setEmailOperationState])

    const viewEmail = useCallback(() => {
        setShowEmail(true)
    }, [])

    const closeEmail = useCallback(() => {
        setShowEmail(false)
    }, [])

    const sending = isEmailOperationInProgress(email.id)

    const canView = email.status === 'SENT' || email.status === 'READY'
    const canSend = config.mutable && email.status === 'READY' && !sending

    return (
        <>
            <ShowEmail open={showEmail} id={email.id} onClose={closeEmail} mutable={config.mutable} />
            <Space size="middle">
                <ActionButton
                    inProgress={sending}
                    className={styles.actions}
                    icon={icons.Send}
                    disabled={!canSend}
                    onClick={sendEmail}
                >
                    {t('send')}
                </ActionButton>
                <ActionButton className={styles.actions} icon={icons.View} disabled={!canView} onClick={viewEmail}>
                    {t('view')}
                </ActionButton>
            </Space>
        </>
    )
}

type ShowEmailProps = {
    open: boolean
    id: string
    mutable: boolean
    onClose: () => void
}

const ShowEmail: FC<ShowEmailProps> = ({ open, id, onClose, mutable }) => {
    const [updateEmailBodyMutation] = useUpdateEmailBodyMutation()
    const { t } = useEmailActionsTranslation()

    const { data, loading } = useGetEmailQuery({ variables: { id: id } })

    const loaded = !(data === undefined || loading)

    const [saving, setSaving] = useState(false)
    const [body, setBody] = useState<string>()

    const updateEmailBody = useAsyncCallback(async () => {
        setSaving(true)

        assert.defined(body)

        await updateEmailBodyMutation({
            variables: {
                id: id,
                body,
            },
        })

        onClose()

        setSaving(false)
    }, [body, id, onClose, updateEmailBodyMutation])

    const onBodyChange = useCallback((newBody: string) => {
        setBody(newBody)
    }, [])

    const email = data?.email
    const editable = mutable && email?.status === 'READY'
    const modified = body !== undefined

    const modalBody = !loaded ? (
        <Spinner />
    ) : (
        <EmailView editable={editable} email={email!} saving={saving} onBodyChange={onBodyChange} />
    )

    const footer = useMemo(() => {
        if (!editable) {
            return (
                <ActionButton size="middle" key="close" onClick={onClose}>
                    {t('close')}
                </ActionButton>
            )
        }

        return (
            <>
                <Button key="close" onClick={onClose} disabled={saving}>
                    {t('cancel')}
                </Button>
                <ActionButton
                    size="middle"
                    key="save"
                    inProgress={saving}
                    onClick={updateEmailBody}
                    disabled={!modified || !loaded}
                >
                    {t('update')}
                </ActionButton>
            </>
        )
    }, [editable, loaded, modified, onClose, saving, t, updateEmailBody])

    return (
        <Modal title={email?.subject} width={800} open={open} onCancel={onClose} footer={footer}>
            {modalBody}
        </Modal>
    )
}
