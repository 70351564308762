import { z } from 'zod'

import { fieldGroupSchema } from './field'

export const exportParticipantValuesRequestSchema = z.object({
    operationId: z.string(),
    fieldGroups: z.array(fieldGroupSchema).optional(),
})

export type ExportParticipantValuesRequest = z.infer<typeof exportParticipantValuesRequestSchema>
