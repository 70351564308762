import { AxiosResponse } from 'axios'
import { parse } from 'content-disposition-attachment'
import isString from 'lodash/isString'

export type Download = {
    data: Blob
    name?: string
}

export const getFilenameFromHeaders = (headers: AxiosResponse['headers']) => {
    // We access the lowercase version, as Axios sets all headers to lowercase
    const contentDisposition = headers['content-disposition']

    if (!isString(contentDisposition)) {
        return undefined
    }

    const parsed = parse(contentDisposition)

    if (parsed.attachment && parsed.filename !== undefined) {
        return decodeURI(parsed.filename)
    }

    return undefined
}

export const createDownloadFromResponse = (response: AxiosResponse<Blob>): Download => ({
    data: response.data,
    name: getFilenameFromHeaders(response.headers),
})
