import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useTagFieldStyles = createUseStyles({
    tagField: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: 10,
    },
})

export const TagField: FC = ({ children }) => {
    const styles = useTagFieldStyles()
    return <div className={styles.tagField}>{children}</div>
}
