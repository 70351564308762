import { FieldGroup } from '@publica/api-graphql'
import { fieldGroupLookup } from '@publica/lookups'
import { getCurrentLocale } from '@publica/ui-common-i18n'

export const fieldGroupLabel = (fieldGroup: FieldGroup): string => {
    return fieldGroupLookup.labelForKeyAndLocale(fieldGroup, getCurrentLocale())
}

// In order
export const fieldGroups: FieldGroup[] = [
    'ACCOUNT_HOLDER_INFORMATION',
    'PERSONAL_INFORMATION',
    'BANKING_INFORMATION',
    'FINANCIAL_INFORMATION',
    'PLATFORM_INFORMATION',
]
