import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export type MaritalStatus =
    | 'MARRIED_SEPARATION_OF_PROPERTY'
    | 'MARRIED_UNIVERSAL_COMMUNITY'
    | 'MARRIED_JOINT_OWNERSHIP'
    | 'DIVORCED'
    | 'CIVIL_UNION'
    | 'WIDOW'
    | 'SINGLE'

const maritalStatuses: Record<KnownLocale, Record<MaritalStatus, string>> = {
    FR: {
        MARRIED_SEPARATION_OF_PROPERTY: 'Marié(e) (séparation de biens)',
        MARRIED_UNIVERSAL_COMMUNITY: 'Marié(e) (communauté de biens)',
        MARRIED_JOINT_OWNERSHIP: 'Marié(e) (communauté réduite aux acquêts)',
        DIVORCED: 'Divorcé(e)',
        CIVIL_UNION: 'Pacsé(e)',
        WIDOW: 'Veuf(ve)',
        SINGLE: 'Célibataire',
    },
    EN: {
        MARRIED_SEPARATION_OF_PROPERTY: 'Married (separation of property)',
        MARRIED_UNIVERSAL_COMMUNITY: 'Married (universal community)',
        MARRIED_JOINT_OWNERSHIP: 'Married (joint ownership reduced to the property acquired after the marriage)',
        DIVORCED: 'Divorced',
        CIVIL_UNION: 'Civil Union',
        WIDOW: 'Widower/Widow',
        SINGLE: 'Single',
    },
}

export const maritalStatusLookup = new LookupList(maritalStatuses)
