import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons'
import { useMemo } from 'react'

import { DocumentStatus } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'

import { HasStatus, StatusDisplayInfo } from '../Status'
import { useStatuses } from '../statuses'

export type HasDocumentStatus = HasStatus<DocumentStatus>

const useDocumentStatusDisplayInfoTranslation = createUseTranslation({
    FR: {
        published: 'Document publié',
        publishedDetail_one: `{{count}} publié`,
        publishedDetail_other: `{{count}} publiés`,
        signature_incomplete_t: `La signature a été abandonée`,
        signature_incomplete_one: '{{count}} signature incomplète',
        signature_incomplete_other: '{{count}} signatures incomplètes',
        error_during_signature_t: `Une erreur s'est produite lors de la signature`,
    },
    EN: {
        published: 'Document published',
        publishedDetail_one: `{{count}} published`,
        publishedDetail_other: `{{count}} published`,
        signature_incomplete_one: '{{count}} incomplete signature',
        signature_incomplete_other: '{{count}} incomplete signatures',
        signature_incomplete_t: `The signing process was abandoned`,
        error_during_signature_t: `An error occurred during signing`,
    },
})

export const useDocumentStatusDisplayInfo: () => StatusDisplayInfo<DocumentStatus> = () => {
    const { t } = useDocumentStatusDisplayInfoTranslation()
    const statuses = useStatuses()

    return useMemo(
        () => ({
            ...statuses,
            PUBLISHED: {
                tooltip: t('published'),
                icon: <CheckCircleTwoTone twoToneColor={colors.success} />,
                message: count => t('publishedDetail', { count }),
                color: 'green',
            },
            SIGNATURE_NOT_COMPLETED: {
                tooltip: t('signature_incomplete_t'),
                message: count => t('signature_incomplete', { count }),
                icon: <ExclamationCircleTwoTone twoToneColor={colors.failure} />,
                color: 'red',
            },
            ERROR_DURING_SIGNATURE: {
                ...statuses.ERROR,
                tooltip: t('error_during_signature_t'),
            },
            PENDING_SIGNATURE: {
                ...statuses.AWAITING_SIGNATURE,
            },
        }),
        [statuses, t]
    )
}
