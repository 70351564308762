import { Select } from 'antd'
import { Rule } from 'antd/lib/form'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanDate } from '@publica/ui-common-labels'
import { colors } from '@publica/ui-common-styles'
import { buildIdMap } from '@publica/utils'

import * as graphql from '../../../data'

export type SelectedTemplate = Pick<graphql.Template, 'id' | 'target' | 'name' | 'type'> & {
    latestVersion: Pick<graphql.TemplateVersion, 'version' | 'id' | 'signatureCount'>
}

type TemplateSelectProps<T extends SelectedTemplate = SelectedTemplate> = {
    templates: T[]
    templateTarget?: graphql.TemplateTarget
    value?: T
    onChange?: (value: T | undefined) => void
}

const useTemplateSelectStyles = createUseStyles({
    version: {
        color: colors.grey6,
    },
})

export const TemplateSelect = <T extends SelectedTemplate = SelectedTemplate>({
    templates,
    templateTarget,
    value,
    onChange,
}: TemplateSelectProps<T>) => {
    const styles = useTemplateSelectStyles()

    const filteredTemplates = useMemo(() => {
        const filtered =
            templateTarget === undefined ? templates : templates.filter(template => template.target === templateTarget)

        return sortBy(filtered, template => template.name.toLowerCase())
    }, [templateTarget, templates])

    const [selectedTemplate, setSelectedTemplate] = useState<T | undefined>(value)

    // This is because we want to send back the template object, not just the ID
    const templateMap = buildIdMap(filteredTemplates)

    useEffect(() => {
        // It's ok to be conditional on selectedTemplate as you can't
        // clear the selection - this avoids an initial onChange trigger
        if (onChange !== undefined && selectedTemplate !== undefined) {
            onChange(selectedTemplate)
        }
    }, [selectedTemplate, onChange])

    // This is to flush out the stale selected template if the
    // list of templates changes
    useEffect(() => {
        if (selectedTemplate !== undefined) {
            setSelectedTemplate(templateMap[selectedTemplate.id])
        }
    }, [selectedTemplate, templateMap])

    const idOnChange = useCallback<(id: string) => void>(
        id => {
            setSelectedTemplate(templateMap[id])
        },
        [templateMap]
    )

    return (
        <Select onChange={idOnChange} value={selectedTemplate?.id}>
            {filteredTemplates.map(template => (
                <Select.Option key={template.id} value={template.id}>
                    {template.name}{' '}
                    <span className={styles.version}> - {humanDate(template.latestVersion.version)}</span>
                </Select.Option>
            ))}
        </Select>
    )
}

const useTemplateSelectRulesTranslation = createUseTranslation({
    EN: {
        required: 'You must select a template',
    },
    FR: {
        required: 'Vous devez choisir un modèle',
    },
})

export const useTemplateSelectRules = (): Rule[] => {
    const { t } = useTemplateSelectRulesTranslation()

    return useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('required'),
            },
        ],
        [t]
    )
}
