import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { DocumentsIndex, DocumentsIndexProps } from './DocumentsIndex'
import { ImportDocumentSet, ImportDocumentSetProps } from './ImportDocumentSet/ImportDocumentSet'
import { NewDocumentSet, NewDocumentSetProps } from './NewDocumentSet'

type DocumentsProps = {
    operation: NewDocumentSetProps['operation'] & DocumentsIndexProps['operation'] & ImportDocumentSetProps['operation']
}

export const Documents: FC<DocumentsProps> = ({ operation }) => {
    const documentsIndex = useMemo(() => <DocumentsIndex operation={operation} />, [operation])
    const newDocumentSet = useMemo(() => <NewDocumentSet operation={operation} />, [operation])
    const importDocumentSet = useMemo(() => <ImportDocumentSet operation={operation} />, [operation])

    return (
        <Routes>
            <Route path="documents">
                <Route index element={documentsIndex} />
                <Route path="new" element={newDocumentSet} />
                <Route path="import" element={importDocumentSet} />
            </Route>
        </Routes>
    )
}
