import { Col, Layout, Row, Space } from 'antd'
import { useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC, useConfig } from '@publica/ui-common-utils'
import { defaultFont, sizes, useGlobalToken, useTheme } from '@publica/ui-web-styles'

import { icons } from '..'
import { ActionButton } from '../ActionButton'

type HeaderStylesProps = {
    fontSize: number
    primaryColor: string
}

const _useHeaderStyles = createUseStyles<'header' | 'headerControls' | 'headerFont' | 'row', HeaderStylesProps>({
    headerControls: {
        textAlign: 'right',
    },
    header: {
        height: 'auto !important',
        '& *::selection': {
            background: 'initial',
        },
        borderBottom: props => `5px solid ${props.primaryColor}`,
    },
    headerFont: {
        fontWeight: 200,
        fontSize: props => props.fontSize * 2.5,
    },
    row: {
        justifyContent: 'space-between',
    },
})

const useHeaderStyles = () => {
    const token = useGlobalToken()
    return _useHeaderStyles({ fontSize: token.fontSize, primaryColor: token.colorPrimary })
}

const useHeaderTranslation = createUseTranslation({
    FR: {
        logout: 'Déconnexion',
        account: 'Gérer votre compte',
    },
    EN: {
        logout: 'Log out',
        account: 'Manage your account',
    },
})

export const Header: FC = () => {
    const authState = useAuthState(true)
    const loggedIn = authState?.isAuthenticated ?? false
    const { t } = useHeaderTranslation()
    const styles = useHeaderStyles()

    const navigate = useNavigate()

    const logout = useCallback(() => {
        if (authState?.isAuthenticated) {
            void authState.state.logout()
        }
    }, [authState])

    const manageAccount = useCallback(() => {
        if (authState?.isAuthenticated) {
            navigate('/account')
        }
    }, [authState?.isAuthenticated, navigate])

    return (
        <Layout.Header className={styles.header}>
            <Row>
                <Col span={6} offset={9}>
                    <EnvironmentBanner />
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col span={6} className={styles.headerFont}>
                    <NexdoBrand />
                </Col>
                <Col className={styles.headerFont} flex="auto">
                    <TenantLogo />
                </Col>
                <Col className={styles.headerControls} span={6}>
                    {loggedIn ? (
                        <Space>
                            <ActionButton icon={icons.Account} size="middle" onClick={manageAccount}>
                                {t('account')}
                            </ActionButton>
                            <ActionButton icon={icons.Logout} size="middle" onClick={logout}>
                                {t('logout')}
                            </ActionButton>
                        </Space>
                    ) : null}
                </Col>
            </Row>
        </Layout.Header>
    )
}

type NexdoBrandProps = {
    variant: 'light' | 'dark'
}

const useNexdoBrandStyle = createUseStyles<'brand' | 'x', NexdoBrandProps>({
    brand: {
        fontFamily: defaultFont,
        color: props => (props.variant === 'dark' ? '#fff' : '#000'),
    },
    x: {
        color: colors.nexdoOrange,
    },
})

const NexdoBrand: FC = () => {
    const theme = useTheme()
    const style = useNexdoBrandStyle({ variant: theme.tenant.variant })
    return (
        <span className={style.brand}>
            Ne<span className={style.x}>x</span>do
        </span>
    )
}

const tenantLogoMargin = 15

type TenantLogoProps = {
    logo: {
        width: number
        url: string
    }
}

const _useTenantLogoStyles = createUseStyles<'logo' | 'logoContainer', TenantLogoProps>({
    logo: {
        height: sizes.layoutHeaderHeight - tenantLogoMargin * 2,
        width: props => props.logo.width - tenantLogoMargin * 2,
        backgroundImage: props => `url('${props.logo.url}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        margin: tenantLogoMargin,
    },
    logoContainer: {
        justifyContent: 'center',
    },
})

const useTenantLogoStyles = () => {
    const theme = useTheme()
    return _useTenantLogoStyles({ logo: theme.tenant.headerLogo })
}

const TenantLogo: FC = () => {
    const styles = useTenantLogoStyles()
    const headerStyles = useHeaderStyles()
    const theme = useTheme()

    return (
        <Row className={styles.logoContainer}>
            <Col>
                <div className={styles.logo} />
            </Col>
            {theme.tenant.shouldDisplayName ? (
                <Col className={headerStyles.headerFont}>
                    <div>{theme.tenant.name}</div>
                </Col>
            ) : null}
        </Row>
    )
}

const EnvironmentBanner: FC = () => {
    const config = useConfig()

    if (config.environment === 'prod') {
        return null
    }

    return <NonProductionEnvironmentBanner environment={config.environment} />
}

type NonProductionEnvironmentBannerProps = {
    environment: 'dev'
}

const useEnvironmentBannerTranslation = createUseTranslation({
    EN: {
        dev: 'Development',
        devLong: 'Development Environment',
    },
    FR: {
        dev: 'Dévéloppement',
        devLong: 'Environnement de Dévéloppement',
    },
})

type BannerStylesProps = {
    primary: string
}

const _useBannerStyles = createUseStyles<'banner', BannerStylesProps>({
    banner: {
        backgroundColor: props => props.primary,
        fontSize: '12px',
        height: '32px',
        padding: [5, 5, 0, 5],
        lineHeight: 'normal',
        borderRadius: [0, 0, 5, 5],
        textAlign: 'center',
    },
})

const useBannerStyles = () => {
    const token = useGlobalToken()
    return _useBannerStyles({ primary: token.colorPrimary })
}

const NonProductionEnvironmentBanner: FC<NonProductionEnvironmentBannerProps> = ({ environment }) => {
    const { t } = useEnvironmentBannerTranslation()
    const styles = useBannerStyles()

    useEffect(() => {
        document.title = `Nexdo - ${t(environment)}`
    }, [environment, t])

    const envLong: 'devLong' = `${environment}Long`

    return <div className={styles.banner}>{t(envLong)}</div>
}
