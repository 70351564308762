import { FC } from '@publica/ui-common-utils'

import { DocumentSet } from '../../types'
import { ArchiveDocumentSet } from './ArchiveDocumentSet'
import { RestoreDocumentSet } from './RestoreDocumentSet'

type DocumentSetActionsProps = {
    documentSet: DocumentSet
}

export const DocumentSetActions: FC<DocumentSetActionsProps> = ({ documentSet }) => {
    if (!documentSet.isArchived) {
        return <ArchiveDocumentSet documentSet={documentSet} />
    }
    return <RestoreDocumentSet documentSet={documentSet} />
}
