import baseI18n, { TOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import uniqueId from 'lodash/uniqueId'
import { useCallback } from 'react'
import { useTranslation as baseUseTranslation, initReactI18next, useTranslation } from 'react-i18next'
import { BehaviorSubject, distinctUntilChanged } from 'rxjs'

import {
    KnownLocale,
    LocalizedString,
    availableLocaleTags,
    defaultLocale,
    isKnownLocale,
    resolveLocalizedString,
} from '@publica/locales'
import { logger } from '@publica/ui-common-logger'

export const i18n = baseI18n.createInstance()

type I18nEvents = {
    type: 'changed'
    locale: KnownLocale
}

const localeChangeSubject = new BehaviorSubject<I18nEvents>({
    type: 'changed',
    locale: defaultLocale,
})

export const localeChangeEvents = localeChangeSubject.pipe(distinctUntilChanged())

i18n.on('languageChanged', locale => {
    if (isKnownLocale(locale)) {
        localeChangeSubject.next({
            type: 'changed',
            locale,
        })
    }
})

type CommonKeys =
    | 'back'
    | 'login'
    | 'operation'
    | 'cancel'
    | 'download'
    | 'created'
    | 'close'
    | 'view'
    | 'update'
    | 'create'

const commonResources: Record<LocaleSubset, Record<'common', Record<CommonKeys, string>>> = {
    FR: {
        common: {
            back: 'Retour',
            login: 'Se connecter',
            operation: 'Opération',
            cancel: 'Annuler',
            download: 'Télécharger',
            created: 'Créé',
            view: 'Voir',
            close: 'Fermer',
            update: 'Mettre à jour',
            create: 'Créer',
        },
    },
    EN: {
        common: {
            back: 'Back',
            login: 'Log in',
            operation: 'Operation',
            cancel: 'Cancel',
            download: 'Download',
            created: 'Created',
            view: 'View',
            close: 'Close',
            update: 'Update',
            create: 'Create',
        },
    },
}

void i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: __DEBUG__,

        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],

            // keys or params to lookup language from
            lookupQuerystring: 'locale',
            lookupCookie: 'locale',
            lookupLocalStorage: 'locale',
            lookupSessionStorage: 'locale',
        },

        resources: commonResources,

        supportedLngs: availableLocaleTags,
        fallbackLng: defaultLocale,
        fallbackNS: 'common',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        saveMissing: true,
        missingKeyHandler(lngs, ns, key, fallbackValue) {
            logger.warn(`Missing translation key ${ns}.${key} for ${lngs.join(', ')}`, {
                payload: {
                    fallbackValue,
                    type: 'translation',
                },
            })
        },
    })

// TRANSLATE: extend to italian
type LocaleSubset = 'FR' | 'EN'

export const createUseTranslation = <K extends string = CommonKeys>(
    translations?: Record<LocaleSubset, Record<K, string>>
) => {
    const ns = `i18n-ns-${uniqueId()}`

    if (translations !== undefined) {
        for (const locale in translations) {
            if (isKnownLocale(locale)) {
                // TRANSLATE: extend to italian
                const castLocale = locale as LocaleSubset
                i18n.addResources(locale, ns, translations[castLocale])
            }
        }
    }

    return () => {
        const { t, i18n } = baseUseTranslation(ns)
        return { t: t as I18TFunc<WithoutPluralSuffix<K | CommonKeys>>, i18n }
    }
}

type PluralSuffixes = 'one' | 'other'
type WithoutPluralSuffix<T extends string> = T extends `${infer S}_${PluralSuffixes}` ? S : T

export const useCurrentLocale = () => {
    const { i18n } = useTranslation()
    const currentLocale = i18n.language

    return validLocaleOrDefault(currentLocale)
}

export const getCurrentLocale = () => {
    return validLocaleOrDefault(i18n.language)
}

export const useLocalizedStringResolver = () => {
    const locale = useCurrentLocale()
    return useCallback((string: LocalizedString) => resolveLocalizedString(string, locale), [locale])
}

const validLocaleOrDefault = (locale: string) => {
    if (isKnownLocale(locale)) {
        return locale
    } else {
        return defaultLocale
    }
}

export type I18TFunc<K extends string, TInterpolationMap extends object = { [key: string]: unknown }> = (
    keys: K | K[],
    options?: TOptions<TInterpolationMap> | string
) => string
