import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'

type Attachment = {
    id: string
}

type UploadedAttachment = {
    attachment: {
        id: string
    }
}

export const useAttachmentsWithUploads = <A extends Attachment, U extends UploadedAttachment>(
    attachments: A[],
    uploadedAttachments: U[],
    readonly: boolean
) => {
    return useMemo(() => {
        const uploadedAttachmentsByAttachment = groupBy(
            uploadedAttachments,
            uploadedAttachment => uploadedAttachment.attachment.id
        )

        return attachments.map(attachment => ({
            ...attachment,
            readonly,
            uploadedAttachments: uploadedAttachmentsByAttachment[attachment.id] ?? [],
        }))
    }, [attachments, readonly, uploadedAttachments])
}
