import type { AttachmentType } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const attachmentTypes: Record<KnownLocale, Record<AttachmentType, string>> = {
    FR: {
        DOCUMENT: 'Document',
        IMAGE: 'Image',
    },
    EN: {
        DOCUMENT: 'Document',
        IMAGE: 'Image',
    },
}

export const attachmentTypeLookup = new LookupList(attachmentTypes)
