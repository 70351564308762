import { Rule } from 'antd/lib/form'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormRules<F extends Record<string, any>> = Partial<Record<keyof F, Rule[]>>

const useRulesTranslation = createUseTranslation({
    FR: {
        required: 'Ne peut pas être vide',
        validEmail: 'Doit être un email valide',
    },
    EN: {
        required: 'You must provide a value',
        validEmail: 'Must be a valid email',
    },
})

export const useCommonRules = () => {
    const { t } = useRulesTranslation()

    const required = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('required'),
            },
        ],
        [t]
    )

    const email = useMemo<Rule[]>(
        () => [
            {
                type: 'email',
                message: t('validEmail'),
            },
        ],
        [t]
    )

    const requiredEmail = useMemo<Rule[]>(() => [...required, ...email], [email, required])

    return {
        required,
        email,
        requiredEmail,
    }
}
