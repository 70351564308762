import isEqual from 'lodash/isEqual'
import { useCallback, useState } from 'react'
import { atomFamily, useRecoilState } from 'recoil'
import { v4 as uuid } from 'uuid'

export type OperationsInProgressState = Record<string, boolean>

const operationsInProgressStateFamily = atomFamily<OperationsInProgressState, string>({
    key: 'operationsInProgress',
    default: {},
})

export const useOperationsInProgressState = () => {
    const [componentId] = useState(uuid())
    const operationsInProgressState = operationsInProgressStateFamily(componentId)
    const [operationsInProgress, setOperationsInProgressState] = useRecoilState(operationsInProgressState)

    const isOperationInProgress: (id: string) => boolean = useCallback(
        id => operationsInProgress[id] === true,
        [operationsInProgress]
    )

    const setOperationsInProgress: (state: OperationsInProgressState) => void = useCallback(
        state => {
            setOperationsInProgressState(curr => {
                const newState = { ...curr, ...state }

                if (!isEqual(curr, newState)) {
                    return newState
                } else {
                    return curr
                }
            })
        },
        [setOperationsInProgressState]
    )

    return [isOperationInProgress, setOperationsInProgress] as const
}
