import { Space } from 'antd'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    verticalSpacer: {
        width: '100%',
    },
})

type VerticalSpacerProps = {
    size?: number
}

export const VerticalSpacer: FC<VerticalSpacerProps> = ({ children, size = 50 }) => {
    const styles = useStyles()

    return (
        <Space size={size} direction="vertical" className={styles.verticalSpacer}>
            {children}
        </Space>
    )
}
