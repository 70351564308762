import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetOperationEmailSetsQuery } from '../../../data'
import { EmailsIndex, EmailsIndexProps } from './EmailsIndex'
import { NewEmailSet, NewEmailSetProps } from './NewEmailSet'

type EmailsProps = {
    operation: NewEmailSetProps['operation'] & EmailsIndexProps['operation']
}

export const Emails: FC<EmailsProps> = ({ operation }) => {
    const { data, loading } = useGetOperationEmailSetsQuery({
        variables: { operationId: operation.id },
        pollInterval: usePollingRate(),
    })

    const emailIndex = useMemo(() => {
        const emailSets = data?.operation?.emailSets ?? []
        return <EmailsIndex operation={operation} loading={loading} emailSets={emailSets} />
    }, [data?.operation?.emailSets, loading, operation])

    const newEmailSet = useMemo(() => <NewEmailSet operation={operation} />, [operation])

    return (
        <Routes>
            <Route path="emails">
                <Route index element={emailIndex} />
                <Route path="new" element={newEmailSet} />
            </Route>
        </Routes>
    )
}
