import { TabsProps } from 'antd'
import { ReactNode, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

// This assumes that the last segment is the tab key
export const useActiveTab = (tabs: Tab[]): string | undefined => {
    const location = useLocation()

    // We can cast as the array is at least 1 element long
    const stem = location.pathname.split('/').pop() as string

    for (const tab of tabs) {
        const matcher = tab.matches
        // If we have a matcher, use that
        if (matcher !== undefined) {
            if (matcher(location)) {
                return tab.key
            }

            // Else, fall back to assuming the last segment is the key
        } else if (stem === tab.key) {
            return tab.key
        }
    }

    return undefined
}

type _Tab = NonNullable<TabsProps['items']>[number]

export type Tab = {
    key: string
    label: string
    content: ReactNode
    icon?: ReactNode
    matches?: (location: ReturnType<typeof useLocation>) => boolean
}

type TabConfig = {
    items: _Tab[]
    activeTab: string | undefined
    onChange: (key: string) => void
}

export const useTabs = <T extends Tab[]>(items: T): TabConfig => {
    const validKeys = useMemo(() => items.map(({ key }) => key), [items])
    const activeTab = useActiveTab(items)
    const navigate = useNavigate()

    const onChange = useCallback(
        (key: string) => {
            if (validKeys.includes(key)) {
                navigate(key)
            }
        },
        [navigate, validKeys]
    )

    const tabItems = useMemo(
        () =>
            items.map(({ key, content, label, icon }) => ({
                key,
                children: content,
                label: <span>{label}</span>,
                icon,
            })),
        [items]
    )

    return {
        items: tabItems,
        activeTab,
        onChange,
    }
}
