import { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { Layout, NotFound } from '@publica/ui-web-components'

import { Account, Operations } from './pages'

const account = <Account />
const operations = <Operations />
const notFound = <NotFound />

// TODO(admin-ui): manage legal entities
// TODO(admin-ui): manage accounts

export const App: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/operations')
        }
    }, [location, navigate])

    return (
        <Layout>
            <Routes>
                <Route path="/account" element={account} />
                <Route path="/operations/*" element={operations} />
                <Route path="*" element={notFound} />
            </Routes>
        </Layout>
    )
}

export default App
