import { useMemo } from 'react'

import { Client } from '@publica/api-client'
import { useAuthState } from '@publica/ui-common-auth'
import { useConfig } from '@publica/ui-common-utils'

export const useUnauthenticatedApiClient = () => {
    const config = useConfig()

    return useMemo(() => new Client(config.apiHost), [config.apiHost])
}

export const useApiClient = () => {
    const config = useConfig()
    const { state } = useAuthState()

    return useMemo(
        () => new Client(config.apiHost, async () => state.getAccountToken().then(({ token }) => token.accessToken)),
        [config.apiHost, state]
    )
}
