import type { DocumentCategory } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const documentCategories: Record<KnownLocale, Record<DocumentCategory, string>> = {
    FR: {
        INFORMATION: 'Documents remis pour information',
        BANK: 'Documents pour transmission aux banques',
        FINAL: 'Documentation finale signée',
        DRAFT: 'Projets de documents',
    },
    EN: {
        INFORMATION: 'Informational documents',
        BANK: 'Documents to be shared with the banks',
        FINAL: 'Final signed documents',
        DRAFT: 'Draft documents',
    },
}

export const documentCategoryLookup = new LookupList(documentCategories)
