import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { ImportParticipantsPlan } from './ImportParticipantsPlan'
import { UploadExcelFile } from './UploadExcelFile'
import { UploadedParticipantOrError } from './types'

type ImportParticipantsFromExcelProps = {
    operationId: string
}

type ImportParticipantsFromExcelState = 'pending' | 'planning'

export const ImportParticipantsFromExcel: FC<ImportParticipantsFromExcelProps> = ({ operationId }) => {
    const [participantsPendingImport, setParticipantsPendingImport] = useState<UploadedParticipantOrError[]>([])

    const importState = useMemo<ImportParticipantsFromExcelState>(() => {
        if (participantsPendingImport.length > 0) {
            return 'planning'
        }

        return 'pending'
    }, [participantsPendingImport.length])

    const onUploadedParticipants = useCallback(async (uploadedParticipants: UploadedParticipantOrError[]) => {
        setParticipantsPendingImport(uploadedParticipants)
    }, [])

    const onCancelImport = useCallback(() => {
        setParticipantsPendingImport([])
    }, [])

    const navigate = useNavigate()

    const onComplete = useCallback(() => {
        navigate('..')
    }, [navigate])

    switch (importState) {
        case 'pending':
            return <UploadExcelFile onUploadedParticipants={onUploadedParticipants} />
        case 'planning':
            return (
                <ImportParticipantsPlan
                    participantsPendingImport={participantsPendingImport}
                    operationId={operationId}
                    onCancel={onCancelImport}
                    onComplete={onComplete}
                />
            )
    }
}
