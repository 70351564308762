import { Button } from 'antd'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { useTheme } from '@publica/ui-web-styles'

type FooterToggleStylesProps = {
    primaryColor: string
}

const _useFooterToggleStyles = createUseStyles<'button', FooterToggleStylesProps>({
    button: {
        color: [colors.grey5, '!important'],
        padding: 2,
        '&:hover': {
            color: props => [props.primaryColor, '!important'],
        },
    },
})

const useFooterToggleStyles = () => {
    const theme = useTheme()

    return _useFooterToggleStyles({
        primaryColor: theme.tenant.colors.linkColor,
    })
}

type FooterToggleProps = {
    text: string
    onClick: () => void
}

export const FooterToggle: FC<FooterToggleProps> = ({ onClick, text }) => {
    const styles = useFooterToggleStyles()

    return (
        <Button onClick={onClick} className={styles.button} type="link">
            {text}
        </Button>
    )
}
