import { FC } from '@publica/ui-common-utils'

import { StatusTags } from '../Status'
import { HasSignatoryStatus, useSignatoryStatusDisplayInfo } from './signatoryStatusInfo'

type SignatoryStatusTagsProps = {
    signatories: HasSignatoryStatus[]
}

export const SignatoryStatusTags: FC<SignatoryStatusTagsProps> = ({ signatories }) => {
    const signatoryStatusDisplayInfo = useSignatoryStatusDisplayInfo()
    return <StatusTags records={signatories} statusDisplayInfo={signatoryStatusDisplayInfo} />
}
