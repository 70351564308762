import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'

export const isPromise = (value: unknown): value is Promise<unknown> => {
    return (
        value instanceof Promise ||
        (isObject(value) &&
            // eslint-disable-next-line @typescript-eslint/unbound-method
            isFunction((value as Promise<unknown>).then) &&
            // eslint-disable-next-line @typescript-eslint/unbound-method
            isFunction((value as Promise<unknown>).catch))
    )
}
