import { Button, ButtonProps } from 'antd'

import { FC } from '@publica/ui-common-utils'

import { icons } from '..'

export type ActionButtonProps = ButtonProps & {
    inProgress?: boolean
}

export const ActionButton: FC<ActionButtonProps> = ({ children, icon, disabled, inProgress, ...props }) => {
    return (
        <Button
            type="primary"
            size="small"
            disabled={inProgress || disabled}
            icon={inProgress === true ? icons.Loading : icon}
            {...props}
        >
            {children}
        </Button>
    )
}
