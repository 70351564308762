import * as ot from '@opentelemetry/api'

import { decorator } from '@publica/utils'
import { isPromise } from '@publica/utils'

export const SpanKind = ot.SpanKind

const getTracer = () => ot.trace.getTracer(__APP__, __VERSION__)

export const withContext = <R>(contextValues: Record<string, unknown>, fn: () => R): R => {
    let ctx: ot.Context = ot.context.active()

    for (const key in contextValues) {
        const ctxKey = ot.createContextKey(key)
        ctx = ctx.setValue(ctxKey, contextValues[key])
    }

    return ot.context.with(ctx, fn)
}

export const addSpanAttributes = (attributes: ot.Attributes) => {
    ot.trace.getSpan(ot.context.active())?.setAttributes(attributes)
}

export const getTracingIds = (): { spanId: string; traceId: string } | undefined => {
    const spanContext = ot.trace.getSpanContext(ot.context.active())
    if (spanContext !== undefined) {
        return {
            spanId: spanContext.spanId,
            traceId: spanContext.traceId,
        }
    }
    return undefined
}

export const withSpan = <R>(name: string, fn: () => R, options?: ot.SpanOptions): R => {
    const tracer = getTracer()

    return tracer.startActiveSpan(name, options ?? {}, ot.context.active(), currentSpan => {
        let res: R

        try {
            res = fn()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            currentSpan.recordException(e)
            currentSpan.setStatus({
                code: ot.SpanStatusCode.ERROR,
                message: e.message,
            })
            currentSpan.end()
            throw e
        }

        if (!isPromise(res)) {
            currentSpan.setStatus({ code: ot.SpanStatusCode.OK })
            currentSpan.end()
            return res
        } else {
            return res
                .then(v => {
                    currentSpan.setStatus({ code: ot.SpanStatusCode.OK })
                    currentSpan.end()
                    return v
                })
                .catch(e => {
                    currentSpan.recordException(e)
                    currentSpan.setStatus({
                        code: ot.SpanStatusCode.ERROR,
                        message: e.message,
                    })
                    currentSpan.end()
                    throw e
                }) as unknown as R
        }
    })
}

type Cls = {
    constructor: {
        name: string
    }
}

// Liberal amounts of any here, to allow it to be used with
// type overloads
export const Span =
    (options?: ot.SpanOptions & { name?: string }) =>
    <T>(target: Cls, method: string, descriptor: T): T => {
        const { name, attributes, ...remainingOptions } = options ?? {}
        const component = target.constructor.name
        const traceName = name ?? `${component}:${method}`

        const resolvedAttributes = {
            'code.component': component,
            'code.method': method,
            ...attributes,
        }

        /* eslint-disable  */
        return (decorator as any).wrap(descriptor, (orig: any) => {
            return function (this: unknown, ...args: any) {
                return withSpan(traceName, () => orig.apply(this, args), {
                    attributes: resolvedAttributes,
                    ...remainingOptions,
                })
            }
        })
        /* eslint-enable */
    }
