import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isError from 'lodash/isError'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
// eslint-disable-next-line you-dont-need-lodash-underscore/map
import map from 'lodash/map'
import { DateTime } from 'luxon'

import { getResource, getTracingIds } from '@publica/trace'

import { consoleTransport } from '../console'
import { Logger } from '../logger'
import type { LogLevelName, LogRecord, LogTransport } from '../types'
import {
    AnyValue,
    ArrayValue,
    InstrumentationScope,
    KeyValue,
    KeyValueList,
} from './proto/opentelemetry/proto/common/v1/common_pb'
import {
    LogsData,
    LogRecord as OTLogRecord,
    ResourceLogs,
    ScopeLogs,
    SeverityNumber,
} from './proto/opentelemetry/proto/logs/v1/logs_pb'
import { Resource } from './proto/opentelemetry/proto/resource/v1/resource_pb'

const defaultCollectorLogsEndpoint = 'http://localhost:4318'
const batchWindowInMs = 5000

export class OpenTelemetryTransport implements LogTransport {
    private readonly resource: Resource
    private pending: [InstrumentationScope, OTLogRecord][]
    private hasTimer = false
    private logger = new Logger({
        name: 'OpenTelemetryTransport',
        transport: consoleTransport,
    })

    private visibilityChangeListener?: () => void
    private pageHideListener?: () => void

    private collectorLogsEndpoint: string

    constructor(collectorEndpoint = defaultCollectorLogsEndpoint) {
        this.collectorLogsEndpoint = `${collectorEndpoint}/v1/logs`
        this.resource = new Resource({
            attributes: map(getResource(), (value, key): KeyValue => this.toKeyValue(key, value)),
        })
        this.pending = []

        if (typeof document !== 'undefined') {
            this.visibilityChangeListener = () => {
                if (document.visibilityState === 'hidden') {
                    void this.flushPendingLogs()
                }
            }

            this.pageHideListener = () => {
                void this.flushPendingLogs()
            }

            document.addEventListener('visibilitychange', this.visibilityChangeListener)
            // use 'pagehide' event as a fallback for Safari; see https://bugs.webkit.org/show_bug.cgi?id=116769
            document.addEventListener('pagehide', this.pageHideListener)
        }
    }

    log(record: LogRecord) {
        const { levelName, timestamp, context, component, message } = record
        const { labels, payload, error } = context
        let { trace } = context

        trace = trace ?? getTracingIds()

        const ts = toNanoseconds(timestamp)
        const encoder = new TextEncoder()

        const raw = new OTLogRecord({
            body: new AnyValue({
                value: {
                    case: 'kvlistValue',
                    value: new KeyValueList({
                        values: [this.toKeyValue('message', message)],
                    }),
                },
            }),
            timeUnixNano: ts,
            observedTimeUnixNano: ts,
            spanId: trace === undefined ? undefined : encoder.encode(trace.spanId),
            traceId: trace === undefined ? undefined : encoder.encode(trace.traceId),
            severityText: levelName,
            severityNumber: this.levelToSeverity(levelName),

            attributes: [
                {
                    key: 'gcp.log_name',
                    value: new AnyValue({
                        value: {
                            case: 'stringValue',
                            value: `${__APP__}`,
                        },
                    }),
                },
                ...map(labels, (value, key) => this.toKeyValue(key, value)),
            ],
        })

        if (error !== undefined) {
            const value = raw.body?.value

            if (value?.case === 'kvlistValue') {
                value.value.values.push(this.toKeyValue('error', error))
            }
        }

        if (payload !== undefined) {
            const value = raw.body?.value

            if (value?.case === 'kvlistValue') {
                value.value.values.push(this.toKeyValue('payload', payload))
            }
        }

        const scope = new InstrumentationScope({
            name: component ?? __APP__,
            version: __VERSION__,
        })

        // Immediately send error logs
        if (levelName === 'error') {
            this.flushLogs([[scope, raw]])
        } else {
            this.pending.push([scope, raw])
            this.maybeStartTimer()
        }
    }

    // https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/logs/data-model.md#field-severitynumber
    private levelToSeverity(level: LogLevelName): SeverityNumber {
        switch (level) {
            case 'debug':
                return 5
            case 'error':
                return 17
            case 'info':
                return 9
            case 'warn':
                return 13
        }
    }

    private maybeStartTimer() {
        if (this.hasTimer) {
            return
        }

        setTimeout(() => {
            this.flushPendingLogs()
            this.hasTimer = false
        }, batchWindowInMs)

        this.hasTimer = true
    }

    private flushPendingLogs() {
        const logMessages = this.pending
        this.pending = []
        this.flushLogs(logMessages)
    }

    private flushLogs(logMessages: [InstrumentationScope, OTLogRecord][]) {
        this.logger.debug('Flushing logs')

        const logsByScope: Record<string, [InstrumentationScope, OTLogRecord[]]> = {}

        for (const [scope, logRecord] of logMessages) {
            const key = `${scope.name}-${scope.version}`.toLowerCase().trim()
            let logsForScope = logsByScope[key]

            if (logsForScope === undefined) {
                logsForScope = [scope, [logRecord]]
                logsByScope[key] = logsForScope
            } else {
                logsForScope[1].push(logRecord)
            }
        }

        const logsData = new LogsData({
            resourceLogs: [
                new ResourceLogs({
                    resource: this.resource,
                    scopeLogs: Object.values(logsByScope).map(
                        ([, logRecords]) =>
                            new ScopeLogs({
                                // TODO(opentelemetry): reactivate scope: https://github.com/GoogleCloudPlatform/opentelemetry-operations-go/pull/436
                                // scope,
                                logRecords,
                            })
                    ),
                }),
            ],
        })

        void this.sendMessage(logsData)
    }

    private async sendMessage(message: LogsData) {
        const payload = new Blob([JSON.stringify(message.toJson())], {
            type: 'application/json',
        })

        const queued = navigator.sendBeacon(this.collectorLogsEndpoint, payload)

        if (!queued) {
            this.logger.warn('Remote logs were not queued')
        }
    }

    private toKeyValue(key: string, value: unknown): KeyValue {
        const kv = new KeyValue({ key })

        if (isNil(value)) {
            return kv
        }

        kv.value = this.toValue(value)

        if (kv.value === undefined) {
            this.logger.warn(`No suitable representation for key ${key}`)
        }

        return kv
    }

    private toValue(value: unknown): AnyValue {
        if (isNil(value)) {
            throw new Error('Unable to convert null or undefined value')
        }

        let encodedValue: AnyValue | undefined

        if (isString(value)) {
            encodedValue = new AnyValue({
                value: {
                    case: 'stringValue',
                    value,
                },
            })
        } else if (isNumber(value)) {
            if (isInt(value)) {
                encodedValue = new AnyValue({
                    value: {
                        case: 'intValue',
                        value: BigInt(value),
                    },
                })
            } else {
                encodedValue = new AnyValue({
                    value: {
                        case: 'doubleValue',
                        value,
                    },
                })
            }
        } else if (isBoolean(value)) {
            encodedValue = new AnyValue({
                value: {
                    case: 'boolValue',
                    value,
                },
            })
        } else if (isError(value)) {
            encodedValue = new AnyValue({
                value: {
                    case: 'stringValue',
                    value: `${value.name}: ${value.message} ${value.stack}`,
                },
            })
        } else if (isArray(value)) {
            encodedValue = new AnyValue({
                value: {
                    case: 'arrayValue',
                    value: new ArrayValue({
                        values: value.map(item => this.toValue(item)),
                    }),
                },
            })
        } else if (isObject(value)) {
            encodedValue = new AnyValue({
                value: {
                    case: 'kvlistValue',
                    value: new KeyValueList({
                        values: map(value, (value, key) => this.toKeyValue(key, value)),
                    }),
                },
            })
        }

        if (encodedValue === undefined) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unable to map value: ${value}`)
        }

        return encodedValue
    }
}

const toNanoseconds = (date: DateTime): bigint => BigInt(date.toMillis() * Math.pow(10, 6))

const isInt = (val: number) => val % 1 === 0
