import { DefaultValue, atom, selectorFamily } from 'recoil'

import { Participant, ParticipantInvitationStatus } from './types'

export const participantsInvitationStatusState = atom<Record<string, ParticipantInvitationStatus>>({
    key: 'participantsInvitationStatus',
    default: {},
})

export const participantInvitationStatusState = selectorFamily<ParticipantInvitationStatus, string>({
    key: 'participantInvitationStatusState',
    get:
        participantId =>
        ({ get }) => {
            const states = get(participantsInvitationStatusState)
            return states[participantId] ?? { isSending: false, isCooling: false }
        },
    set:
        participantId =>
        ({ set }, value) => {
            set(participantsInvitationStatusState, state => ({
                ...state,
                [participantId]: value instanceof DefaultValue ? { isSending: false, isCooling: false } : value,
            }))
        },
})

export const selectedParticipantsState = atom<Participant[]>({
    key: 'selectedParticipants',
    default: [],
})
