import { CheckCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons'
import { Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanDate } from '@publica/ui-common-labels'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const useParticipantInvitationStatusTranslation = createUseTranslation({
    EN: {
        invitationLastSent: 'An invitation was sent on {{lastInvitationSentAt}}',
        invitationNeverSent: 'No invitation has been sent',
    },
    FR: {
        invitationLastSent: 'Une invitation a été envoyée {{lastInvitationSentAt}}',
        invitationNeverSent: `Le participant n'a pas encore été invité`,
    },
})

type ParticipantInvitationStatusProps = {
    participant: {
        lastInvitationSentAt?: DateTime | null | undefined
    }
}

export const ParticipantInvitationStatus: FC<ParticipantInvitationStatusProps> = ({ participant }) => {
    const { lastInvitationSentAt } = participant
    const hasBeenInvited = !isNil(lastInvitationSentAt)
    const { t } = useParticipantInvitationStatusTranslation()

    return hasBeenInvited ? (
        <Tooltip title={t('invitationLastSent', { lastInvitationSentAt: humanDate(lastInvitationSentAt) })}>
            <CheckCircleTwoTone twoToneColor={colors.success} />
        </Tooltip>
    ) : (
        <Tooltip title={t('invitationNeverSent')}>
            <ClockCircleTwoTone twoToneColor={colors.grey6} />
        </Tooltip>
    )
}
