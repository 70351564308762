import debounce from 'lodash/debounce'
import { DependencyList, useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncCallback = <A extends Array<any>, T extends (...args: A) => Promise<any>>(
    callback: T,
    deps: DependencyList
) =>
    useCallback(
        (...args: A) => {
            void (async () => callback(...args))()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [callback, ...deps]
    )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedAsyncCallback = <A extends Array<any>, T extends (...args: A) => Promise<any>>(
    callback: T,
    deps: DependencyList,
    wait = 250
) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
        debounce((...args: A) => {
            void callback(...args)
        }, wait),
        deps
    )
