import { DateTime } from 'luxon'

import { getCurrentLocale } from '@publica/ui-common-i18n'

export const humanDate = (date: DateTime): string =>
    date.toLocal().toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, { locale: getCurrentLocale() })

type WithCreatedAt = {
    createdAt: DateTime
}

export const humanCreatedAt = (withCreatedAt: WithCreatedAt) => humanDate(withCreatedAt.createdAt)
