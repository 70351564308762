import { Space } from 'antd'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { Operation } from '../types'
import { CreateGroupedEnvelopeFromDocumentSetsControl } from './CreateGroupedEnvelopeFromDocumentSetsControl/CreateGroupedEnvelopeFromDocumentSetsControl'

type DocumentSetControlsProps = {
    operation: Operation
}

const useDocumentControlsTranslation = createUseTranslation({
    FR: {
        generate: 'Générer des documents',
        import: 'Importer des documents',
    },
    EN: {
        generate: 'Generate documents',
        import: 'Import documents',
    },
})

export const DocumentSetControls: FC<DocumentSetControlsProps> = ({ operation }) => {
    const mutable = operationIsOpen(operation)
    const navigate = useNavigate()
    const styles = utils.useControlsStyles()
    const { t } = useDocumentControlsTranslation()

    const onClickNew = useCallback(() => {
        navigate('new')
    }, [navigate])

    const onClickImport = useCallback(() => {
        navigate('import')
    }, [navigate])

    return (
        <div className={styles.controls}>
            <Space>
                <ActionButton size="middle" onClick={onClickNew} disabled={!mutable} icon={icons.AddFile}>
                    {t('generate')}
                </ActionButton>
                <ActionButton size="middle" onClick={onClickImport} disabled={!mutable} icon={icons.AddFile}>
                    {t('import')}
                </ActionButton>
                <CreateGroupedEnvelopeFromDocumentSetsControl />
            </Space>
        </div>
    )
}
