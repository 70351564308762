import {
    CheckCircleTwoTone,
    ClockCircleTwoTone,
    ExclamationCircleTwoTone,
    LoadingOutlined,
    PauseCircleTwoTone,
} from '@ant-design/icons'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'

import { StatusDisplayInfo } from './Status/types'

type SharedStatuses =
    | 'ERROR'
    | 'READY'
    | 'GENERATING'
    | 'PENDING'
    | 'ARCHIVED'
    | 'ERROR_DURING_GENERATION'
    | 'SIGNED'
    | 'AWAITING_SIGNATURE'

const useStatusesTranslation = createUseTranslation({
    FR: {
        awaiting_signature_t: 'En attente de signature',
        signed_t: 'Signé',
        error_t: `Une erreur s'est produite`,
        error_during_generation_t: `Une erreur s'est produite lors de la génération`,
        generated_t: `Généré`,
        generating_t: 'En cours de génération',
        pending_t: 'En attente de génération',
        archived_t: 'Archivé',
        awaiting_signature_one: '{{count}} en attente de signature',
        awaiting_signature_other: '{{count}} en attente de signature',
        signed_one: '{{count}} signé',
        signed_other: '{{count}} signés',
        error_one: '{{count}} erreur',
        error_other: '{{count}} erreurs',
        ready_one: '{{count}} généré',
        ready_other: '{{count}} générés',
        generating_one: '{{count}} en cours de génération',
        generating_other: '{{count}} en cours de génération',
        pending_one: '{{count}} en attente de génération',
        pending_other: '{{count}} en attente de génération',
        archived_one: '{{count}} archivé',
        archived_other: '{{count}} archivés',
    },
    EN: {
        awaiting_signature_t: 'Awaiting signature',
        signed_t: 'Signed',
        error_t: `An error has occurred`,
        error_during_generation_t: `An error occurred during generation`,
        generated_t: `Generated`,
        generating_t: 'Generating',
        pending_t: 'Pending generation',
        archived_t: 'Archived',
        awaiting_signature_one: '{{count}} awaiting signature',
        awaiting_signature_other: '{{count}} awaiting signatures',
        signed_one: '{{count}} signed',
        signed_other: '{{count}} signed',
        error_one: '{{count}} error',
        error_other: '{{count}} errors',
        ready_one: '{{count}} generated',
        ready_other: '{{count}} generated',
        generating_one: '{{count}} generating',
        generating_other: '{{count}} generating',
        pending_one: '{{count}} pending generation',
        pending_other: '{{count}} pending generation',
        archived_one: '{{count}} archived',
        archived_other: '{{count}} archived',
    },
})

export const useStatuses: () => StatusDisplayInfo<SharedStatuses> = () => {
    const { t } = useStatusesTranslation()

    return useMemo(
        () =>
            ({
                AWAITING_SIGNATURE: {
                    message: count => t('awaiting_signature', { count }),
                    tooltip: t('awaiting_signature_t'),
                    icon: <ClockCircleTwoTone twoToneColor={colors.grey6} />,
                },
                SIGNED: {
                    message: count => t('signed', { count }),
                    tooltip: t('signed_t'),
                    icon: <CheckCircleTwoTone twoToneColor={colors.success} />,
                    color: 'green',
                },
                ERROR: {
                    message: count => t('error', { count }),
                    tooltip: t('error_t'),
                    icon: <ExclamationCircleTwoTone twoToneColor={colors.failure} />,
                    color: 'red',
                },
                ERROR_DURING_GENERATION: {
                    message: count => t('error', { count }),
                    tooltip: t('error_during_generation_t'),
                    icon: <ExclamationCircleTwoTone twoToneColor={colors.failure} />,
                    color: 'red',
                },
                READY: {
                    tooltip: t('generated_t'),
                    icon: <CheckCircleTwoTone />,
                    message: count => t('ready', { count }),
                    color: 'blue',
                },
                GENERATING: {
                    tooltip: t('generating_t'),
                    icon: <LoadingOutlined />,
                    message: count => t('generating', { count }),
                },
                PENDING: {
                    tooltip: t('pending_t'),
                    message: count => t('pending', { count }),
                    icon: <ClockCircleTwoTone twoToneColor={colors.grey6} />,
                },
                ARCHIVED: {
                    tooltip: t('archived_t'),
                    message: count => t('archived', { count }),
                    icon: <PauseCircleTwoTone twoToneColor={colors.grey6} />,
                },
            }) as const,
        [t]
    )
}
