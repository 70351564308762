import type { HasId, HasProperty } from './types'

/* istanbul ignore next */
export const buildIdMap = <I extends string | number, R extends HasId<I>>(records: R[]): Record<I, R> =>
    buildMap('id', records)

/* istanbul ignore next */
export const buildMap = <I extends string | number, P extends string, R extends HasProperty<P, I>>(
    key: P,
    records: R[]
): Record<I, R> =>
    records.reduce(
        (m, curr) => ({
            ...m,
            [curr[key]]: curr,
        }),
        {} as Record<I, R>
    )

/* istanbul ignore next */
export const buildMapWithFn = <I extends string | number, R>(fn: (record: R) => I, records: R[]): Record<I, R> =>
    records.reduce(
        (m, curr) => ({
            ...m,
            [fn(curr)]: curr,
        }),
        {} as Record<I, R>
    )
