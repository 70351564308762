import { Form, Modal } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { GetOperationsDocument, useCreateOperationMutation } from '../../../../../../data'
import { OperationForm, OperationFormValues } from '../../../../../components'

const useCreateOperationTranslation = createUseTranslation({
    FR: {
        createOperation: 'Créer une opération',
    },
    EN: {
        createOperation: 'Create an operation',
    },
})

export const CreateOperation: FC = () => {
    const [open, setOpen] = useState(false)

    const [form] = Form.useForm<OperationFormValues>()
    const [createOperationMutation, { loading }] = useCreateOperationMutation()

    const navigate = useNavigate()
    const { t } = useCreateOperationTranslation()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        form.resetFields()
    }, [form])

    const create = useCallback(async () => {
        const { name, fromAddress, code } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(fromAddress)
        assert.defined(code)

        return createOperationMutation({
            variables: {
                operation: {
                    name,
                    fromAddress,
                    code,
                },
            },
            refetchQueries: [GetOperationsDocument],
        }).then(({ data }) => {
            const id = data?.createOperation.id
            if (!isNil(id)) {
                navigate(id)
            }
        })
    }, [createOperationMutation, form, navigate])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await create()
    }, [create, form])

    return (
        <>
            <ActionButton size="middle" icon={icons.AddFolder} onClick={onClick}>
                {t('createOperation')}
            </ActionButton>
            <Modal
                open={open}
                onCancel={close}
                cancelText={t('cancel')}
                title={t('createOperation')}
                confirmLoading={loading}
                okText={t('create')}
                onOk={onOk}
            >
                <OperationForm form={form} />
            </Modal>
        </>
    )
}
